import { render, staticRenderFns } from "./PageTrocasDevolucoes.vue?vue&type=template&id=aaf2763e&scoped=true&"
import script from "./PageTrocasDevolucoes.vue?vue&type=script&lang=js&"
export * from "./PageTrocasDevolucoes.vue?vue&type=script&lang=js&"
import style0 from "./PageTrocasDevolucoes.vue?vue&type=style&index=0&id=aaf2763e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aaf2763e",
  null
  
)

export default component.exports