<template>
<div>
          <div :class="validacss2(source,'col-6 linhaban')">
            <!-- VerificaTipo(ItemHome.homeBanner.homeBannerId) == 1 -->
            <b-carousel v-if="source.homeBanner.tipoBlocoHome.tipoBlocoHomeId == 1" id="carousel-fade" :class="validacss(source,null)"
                        controls
                        indicators
                        fade
                        v-model="slide"
                        :interval="6000"
                        img-width="1350"
                        img-height="330"
                        @sliding-end="endSlide"
                        @sliding-start="initCarousel"
                        style="text-shadow: 2px 3px 3px #222222;">
                        <!-- CarregaImagem(ItemHome.homeBanner.homeBannerId) -->
                <b-carousel-slide v-for="ItemImagem in source.homeBanner.homeBannerItens.filter(x => x.visivel == true)" :key="ItemImagem.homeBannerItemId" caption="" :img-src="$Url + ItemImagem.urlImg">
                </b-carousel-slide>
            </b-carousel>
            <!-- VerificaTipo(ItemHome.homeBanner.homeBannerId) == 2 -->
            <carousel v-if="source.homeBanner.tipoBlocoHome.tipoBlocoHomeId == 2" :class="validacss(source,null)"
                      :perPageCustom="[[340, 4],[768, 5], [1024,8], [1600,12]]"
                      autoplay="false"
                      :autoplayTimeout=40000
                      autoplayHoverPause
                      :paginationEnabled="false"
                      :paginationSize=0
                      :loop="true">
                <slide v-for="ItemImagem in source.homeBanner.homeBannerItens.filter(x => x.visivel == true)" :key="ItemImagem.homeBannerItemId" class="produto-item">
                    <a href="" class="produto-link" @click="$redirect_reload(ItemImagem.link)">
                        <img class="imagem" :src="$Url + ItemImagem.urlImg" alt="" style="width: 100%;">
                    </a>
                    <a href="" class="produto-link" @click="$redirect_reload(ItemImagem.link)">{{ ItemImagem.texto }}</a>
                </slide>
            </carousel>
            <!-- VerificaTipo(ItemHome.homeBanner.homeBannerId) == 9 -->
            <div v-if="source.homeBanner.tipoBlocoHome.tipoBlocoHomeId == 9" :class="validacss(source,' banner-linha respiro')">
                <div v-for="ItemImagem in source.homeBanner.homeBannerItens.filter(x => x.visivel == true)" :key="ItemImagem.homeBannerItemId">
                    <a href="" class="produto-link" @click="$redirect_reload(ItemImagem.link)">
                        <img class="img-fluid set-img" :src="$Url + ItemImagem.urlImg" alt="">
                    </a>
                </div>
            </div>
            <!-- VerificaTipo(ItemHome.homeBanner.homeBannerId) == 4 -->
            <div v-if="source.homeBanner.tipoBlocoHome.tipoBlocoHomeId == 4" :class="validacss(source,' container banner-1linha respiro')">
                <div class="row">
                    <div v-for="ItemImagem in source.homeBanner.homeBannerItens.filter(x => x.visivel == true)" :key="ItemImagem.homeBannerItemId" class="col-md-12">
                        <a href="" class="produto-link" @click="$redirect_reload(ItemImagem.link)">
                            <img class="img-fluid set-img" :src="$Url + ItemImagem.urlImg" alt="">
                        </a>
                    </div>
                </div>
            </div>
              <!-- VerificaTipo(ItemHome.homeBanner.homeBannerId) == 5 -->
            <div v-if="source.homeBanner.tipoBlocoHome.tipoBlocoHomeId == 5" :class="validacss(source,' container banner-2linha respiro')">
                <div class="row">
                    <div v-for="ItemImagem in source.homeBanner.homeBannerItens.filter(x => x.visivel == true)" :key="ItemImagem.homeBannerItemId" class="col-md-6 col-12 mb-4">
                        <a href="" class="produto-link" @click="$redirect_reload(ItemImagem.link)">
                            <img class="img-fluid set-img" :src="$Url + ItemImagem.urlImg" alt="">
                        </a>
                    </div>
                </div>
            </div>
            <!-- VerificaTipo(ItemHome.homeBanner.homeBannerId) == 6 -->
            <div v-if="source.homeBanner.tipoBlocoHome.tipoBlocoHomeId == 6" :class="validacss(source,' container banner-3linha respiro')">
                <div class="row">
                    <div v-for="ItemImagem in source.homeBanner.homeBannerItens.filter(x => x.visivel == true)" :key="ItemImagem.homeBannerItemId" class="col-md-4 col-12 mb-4">
                        <a href="" class="produto-link" @click="$redirect_reload(ItemImagem.link)">
                            <img class="img-fluid set-img" :src="$Url + ItemImagem.urlImg" alt="">
                        </a>
                    </div>
                </div>
            </div>
            <!-- VerificaTipo(ItemHome.homeBanner.homeBannerId) == 7 -->
            <div v-if="source.homeBanner.tipoBlocoHome.tipoBlocoHomeId == 7" :class="validacss(source,' container banner-4linha respiro')">
                <div class="row">
                    <div v-for="ItemImagem in source.homeBanner.homeBannerItens.filter(x => x.visivel == true)" :key="ItemImagem.homeBannerItemId" class="col-md-3 col-6 mb-4">
                        <a href="" class="produto-link" @click="$redirect_reload(ItemImagem.link)">
                            <img class="img-fluid set-img" :src="$Url + ItemImagem.urlImg" alt="">
                        </a>
                    </div>
                </div>
            </div>
            <!-- VerificaTipo(ItemHome.homeBanner.homeBannerId) == 16 -->
            <!--4 quadrados topo mobile -->
            <div v-if="source.homeBanner.tipoBlocoHome.tipoBlocoHomeId == 16">
                <a href="" class="produto-link" @click="$redirect_reload('/pageprodutosbanner/' + source.homeBanner.homeBannerId)">
                    <img class="img-fluid set-img" :src="$Url + source.homeBanner.urlImagem" alt="" style="width:97%; border-bottom: 2px solid red !important;margin-top:5px;margin-left:3px;margin-right:0px;">
                    <h3 class="text-center" style="font-size: 14px; border-bottom: 2px solid red; color: red; margin: 5px 14px;text-transform:uppercase;font-weight:400;font-family: 'Open Sans',sans-serif;">{{ source.homeBanner.nomeBloco.replaceAll('mobile','').replaceAll('desktop','') }}</h3>
                </a>
            </div>

            <div v-if="source.homeBanner.tipoBlocoHome.tipoBlocoHomeId == 8" :class="validacss(source,' container banner-6linha respiro')">
                <div class="row">
                    <div v-for="ItemImagem in source.homeBanner.homeBannerItens.filter(x => x.visivel == true)" :key="ItemImagem.homeBannerItemId" class="col-md-2 col-4 mb-4">
                        <a href="" class="produto-link" @click="$redirect_reload(ItemImagem.link)">
                            <img class="img-fluid set-img" :src="$Url + ItemImagem.urlImg" alt="">
                        </a>
                    </div>
                </div>
            </div>

            <div v-if="source.homeBanner.tipoBlocoHome.tipoBlocoHomeId == 10" :class="validacss(source,' produtos container respiro')">
                <div class="col-md-12 text-center">
                    <h3 class="titulo_section titulo_section-xl text-center">{{ source.homeBanner.nomeBloco.replaceAll('mobile','').replaceAll('desktop','') }}</h3>
                </div>
                <div class="row">
                    <div class="col-md-6 col-lg-6 col-12 mb-4 mb-lg-0" v-for="ItemImagem in source.homeBanner.homeBannerItens.filter(x => x.visivel == true).slice(0,2)" :key="ItemImagem.homeBannerItemId">
                        <div class="produto-card produto-card_2" style="padding-bottom: 20px;">
                            <!--<h3 class="titulo_section titulo_section-xl text-center">{{ ItemImagem.link }}</h3>-->
                            <a @click="$redirect_reload('/produtoDetalhe/' + ItemImagem.produto.produtoId)" href="">
                                <div class="produto-img set-img set-tam2" :style="{ backgroundImage: 'url('  + CarregaImagemUnica(ItemImagem.produto.imagens) +  ')',backgroundSize:'contain' }">
                                    <img v-for="ItemEtiqueta in ItemImagem.produto.premios" :key="ItemEtiqueta.produtoPremioId" :src="ItemEtiqueta.urlImg" alt="">
                                    <div class="middle middle_2">
                                        <div class="text">
                                            Ver Mais Detalhes
                                        </div>
                                    </div>
                                </div>
                            </a>
                            <div class="produto-content" style="padding-top:10px;">
                                <h6 class="titulo-card">{{  ItemImagem.produto.nomeProduto }}</h6>
                                <span v-if="ItemImagem.produto.valorDe > 0" class="preco-antigo">De {{   parseFloat(ItemImagem.produto.valorDe,10).toFixed(2).toString().replace('.',',') }}</span>
                                <p>
                                    <span class="texto-destaque">{{ parcelas }} x</span>
                                    de R$ <span class="texto-destaque"> {{ parseFloat(ItemImagem.produto.valorAte/parcelas,10).toFixed(2).toString().replace('.',',') }}</span> <span class="texto-destaque2">{{ parcelasTexto }}</span>
                                </p>
                                <p>
                                    R$ <span class="texto-destaque3"> {{ parseFloat(ItemImagem.produto.valorAte,10).toFixed(2).toString().replace('.',',') }}</span>
                                </p>
                                <a href="" v-if="ItemImagem.produto.indisponivel != true" @click="$redirect_reload('/produtoDetalhe/' + ItemImagem.produto.produtoId)" class="btn btn-card" style="margin-top: 10px !important;">Ver Detalhes</a>
                                <a v-else class="texto-destaque3" style="margin-top: 5px;">Indispon&iacute;vel</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="source.homeBanner.tipoBlocoHome.tipoBlocoHomeId == 11" :class="validacss(source,' produtos container respiro')">
                <div class="col-md-12 text-center">
                    <h3 class="titulo_section titulo_section-xl text-center">{{ source.homeBanner.nomeBloco.replaceAll('mobile','').replaceAll('desktop','') }}</h3>
                </div>
                <div class="row">
                    <div class="col-md-4 col-lg-4 col-12 mb-4 mb-lg-0" v-for="ItemImagem in source.homeBanner.homeBannerItens.filter(x => x.visivel == true).slice(0,3)" :key="ItemImagem.homeBannerItemId">
                        <div class="produto-card produto-card_2" style="padding-bottom: 20px;">
                            <!--<h3 class="titulo_section titulo_section-xl text-center">{{ ItemImagem.link }}</h3>-->
                            <a href="" @click="$redirect_reload('/produtoDetalhe/' + ItemImagem.produto.produtoId)">
                                <div class="produto-img set-img set-tam3" :style="{ backgroundImage: 'url(' + CarregaImagemUnica(ItemImagem.produto.imagens) +  ')',backgroundSize:'contain' }">
                                    <img v-for="ItemEtiqueta in ItemImagem.produto.premios" :key="ItemEtiqueta.produtoPremioId" :src="ItemEtiqueta.urlImg" alt="">
                                    <div class="middle middle_2">
                                        <div class="text">
                                            Ver Mais Detalhes
                                        </div>
                                    </div>
                                </div>
                            </a>
                            <div class="produto-content" style="padding-top:10px;">
                                <h6 class="titulo-card">{{  ItemImagem.produto.nomeProduto }}</h6>
                                <span v-if="ItemImagem.produto.valorDe > 0" class="preco-antigo">De {{   parseFloat(ItemImagem.produto.valorDe,10).toFixed(2).toString().replace('.',',') }}</span>
                                <p>
                                    <span class="texto-destaque">{{ parcelas }} x</span>
                                    de R$ <span class="texto-destaque"> {{ parseFloat(ItemImagem.produto.valorAte/parcelas,10).toFixed(2).toString().replace('.',',') }}</span> <span class="texto-destaque2">{{ parcelasTexto }}</span>
                                </p>
                                <p>
                                    R$ <span class="texto-destaque3"> {{ parseFloat(ItemImagem.produto.valorAte,10).toFixed(2).toString().replace('.',',') }}</span>
                                </p>
                                <a href="" v-if="ItemImagem.produto.indisponivel != true" @click="$redirect_reload('/produtoDetalhe/' + ItemImagem.produto.produtoId)" class="btn btn-card" style="margin-top: 10px !important;">Ver Detalhes</a>
                                <a v-else class="texto-destaque3" style="margin-top: 5px;">Indispon&iacute;vel</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="source.homeBanner.tipoBlocoHome.tipoBlocoHomeId == 12" :class="validacss(source,' produtos container respiro')">
                <div class="row">
                    <div class="col-md-12 text-center">
                        <h3 class="titulo_section titulo_section-xl text-center">{{ source.homeBanner.nomeBloco.replaceAll('mobile','').replaceAll('desktop','') }}</h3>
                    </div>
                    <div class="col-md-3 col-lg-3 col-6" v-for="ItemImagem in source.homeBanner.homeBannerItens.filter(x => x.visivel == true).slice(0,4)" :key="ItemImagem.homeBannerItemId">
                        <div class="produto-card produto-card_2" style="padding-bottom: 20px;">
                            <a  href="" @click="$redirect_reload('/produtoDetalhe/' + ItemImagem.produto.produtoId)">
                                <div class="produto-img set-img set-tam4" :style="{ backgroundImage: 'url(' + CarregaImagemUnica(ItemImagem.produto.imagens) + ')',backgroundSize:'contain' }">
                                    <img v-for="ItemEtiqueta in ItemImagem.produto.premios" :key="ItemEtiqueta.produtoPremioId" :src="ItemEtiqueta.urlImg" alt="">
                                    <div class="middle middle_2">
                                        <div class="text">Ver Mais Detalhes</div>
                                    </div>
                                </div>
                            </a>
                            <div class="produto-content" style="padding-top:10px;">
                                <h6 class="titulo-card">{{  ItemImagem.produto.nomeProduto }}</h6>
                                <span v-if="ItemImagem.produto.valorDe > 0" class="preco-antigo">De {{   parseFloat(ItemImagem.produto.valorDe,10).toFixed(2).toString().replace('.',',') }}</span>
                                <p>
                                    <span class="texto-destaque">{{ parcelas }} x</span>
                                    de R$ <span class="texto-destaque"> {{ parseFloat(ItemImagem.produto.valorAte/parcelas,10).toFixed(2).toString().replace('.',',') }}</span> <span class="texto-destaque"></span><span class="texto-destaque2">{{ parcelasTexto }}</span>
                                </p>
                                <p>
                                    R$ <span class="texto-destaque3"> {{ parseFloat(ItemImagem.produto.valorAte,10).toFixed(2).toString().replace('.',',') }}</span>
                                </p>
                                <a href="" v-if="ItemImagem.produto.indisponivel != true" @click="$redirect_reload('/produtoDetalhe/' + ItemImagem.produto.produtoId)" style="margin-top: 10px !important;">Ver Detalhes</a>
                                <a v-else class="texto-destaque3" style="margin-top: 5px;">Indispon&iacute;vel</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="source.homeBanner.tipoBlocoHome.tipoBlocoHomeId == 13" :class="validacss(source,' produtos container respiro')">
                <div class="row">
                    <div class="col-md-12 text-center">
                        <h3 class="titulo_section titulo_section-xl text-center">{{ source.homeBanner.nomeBloco.replaceAll('mobile','').replaceAll('desktop','') }}</h3>
                    </div>
                    <div class="col-md-2 col-lg-2 col-6" v-for="ItemImagem in source.homeBanner.homeBannerItens.filter(x => x.visivel == true).slice(0,6)" :key="ItemImagem.homeBannerItemId">
                        <div class="produto-card produto-card_2" style="padding-bottom: 20px;">
                            <a href="" @click="$redirect_reload('/produtoDetalhe/' + ItemImagem.produto.produtoId)">
                                <div class="produto-img set-img set-tam6" :style="{ backgroundImage: 'url(' + CarregaImagemUnica(ItemImagem.produto.imagens) +  ')',backgroundSize:'contain' }">
                                    <img v-for="ItemEtiqueta in ItemImagem.produto.premios" :key="ItemEtiqueta.produtoPremioId" :src="ItemEtiqueta.urlImg" alt="">
                                    <div class="middle middle_2">
                                        <div class="text">Ver Mais Detalhes</div>
                                    </div>
                                </div>
                            </a>
                            <div class="produto-content" style="padding-top:10px;">
                                <h6 class="titulo-card" style="min-height:40px;">{{  ItemImagem.produto.nomeProduto }}</h6>
                                <!--<span v-if="ItemImagem.produto.valorDe > 0" class="preco-antigo">De {{   parseFloat(ItemImagem.produto.valorDe,10).toFixed(2).toString().replace('.',',') }}</span>-->
                                <p>
                                    <span class="texto-destaque">{{ parcelas }} x</span>
                                    de R$ <span class="texto-destaque"> {{ parseFloat(ItemImagem.produto.valorAte/parcelas,10).toFixed(2).toString().replace('.',',') }}</span> <br /><span class="texto-destaque2">{{ parcelasTexto }}</span>
                                </p>
                                <p>
                                    R$ <span class="texto-destaque3"> {{ parseFloat(ItemImagem.produto.valorAte,10).toFixed(2).toString().replace('.',',') }}</span>
                                </p>
                                <a href="" v-if="ItemImagem.produto.indisponivel != true" @click="$redirect_reload('/produtoDetalhe/' + ItemImagem.produto.produtoId)" class="btn btn-card" style="margin-top: 10px !important;">Ver Detalhes</a>
                                <a v-else class="texto-destaque3" style="margin-top: 5px;">Indispon&iacute;vel</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!--carrossel de linha inteira produtos -->
            <div v-if="source.homeBanner.tipoBlocoHome.tipoBlocoHomeId == 3" :class="validacss(source,null)" style="padding-top:20px;">
                <h3 class="titulo_section titulo_section-xl text-center">{{ source.homeBanner.nomeBloco.replaceAll('mobile','').replaceAll('desktop','') }}</h3>
            </div>
            <carousel id="carro1" v-if="source.homeBanner.tipoBlocoHome.tipoBlocoHomeId == 3" :class="validacss(source,null)"
                      :perPageCustom="[[340, 2],[768, 4], [1024,7], [1600,8]]"
                      autoplay="false"
                      :autoplayTimeout=40000
                      autoplayHoverPause
                      paginationEnabled
                      :paginationSize=0
                      :loop="true">

                <slide v-for="ItemImagem in source.homeBanner.homeBannerItens.filter(x => x.visivel == true)" :key="ItemImagem.homeBannerItemId" class="produtos respiro">
                    <div class="produto-card produto-card_2">
                        <a href="" @click="$redirect_reload('/produtoDetalhe/' + ItemImagem.produto.produtoId)">
                            <div class="produto-img set-img set-tam6" :style="{ backgroundImage: 'url('  + CarregaImagemUnica(ItemImagem.produto.imagens) + ')',backgroundSize:'contain' }">
                                <img v-for="ItemEtiqueta in ItemImagem.produto.premios" :key="ItemEtiqueta.produtoPremioId" :src="ItemEtiqueta.urlImg" alt="">
                                <div class="middle middle_2">
                                    <div class="text">Ver Mais Detalhes</div>
                                </div>
                            </div>
                        </a>
                        <div class="produto-content" style="padding-top:10px;">
                            <h6 class="titulo-card" style="min-height:40px;">{{  ItemImagem.produto.nomeProduto }}</h6>
                            <span v-if="ItemImagem.produto.valorDe > 0" class="preco-antigo">De {{   parseFloat(ItemImagem.produto.valorDe,10).toFixed(2).toString().replace('.',',') }}</span>
                            <p>
                                <span class="texto-destaque">{{ parcelas }} x</span>
                                de R$ <span class="texto-destaque"> {{ parseFloat(ItemImagem.produto.valorAte/parcelas,10).toFixed(2).toString().replace('.',',') }}</span> <br /><span class="texto-destaque2">{{ parcelasTexto }}</span>
                            </p>
                            <p>
                                R$ <span class="texto-destaque3"> {{ parseFloat(ItemImagem.produto.valorAte,10).toFixed(2).toString().replace('.',',') }}</span>
                            </p>
                            <a href="" v-if="ItemImagem.produto.indisponivel != true" @click="$redirect_reload('/produtoDetalhe/' + ItemImagem.produto.produtoId)" class="btn btn-card" style="margin-top: 10px !important;">Ver Detalhes</a>
                            <a v-else class="texto-destaque3" style="margin-top: 5px;">Indispon&iacute;vel</a>
                        </div>
                    </div>
                </slide>
            </carousel>

            <!--carrossel de 4 produtos -->
            <div v-if="source.homeBanner.tipoBlocoHome.tipoBlocoHomeId == 15" :class="validacss(source,'container')">
                <div class="row">
                    <div class="col-md-12 col-lg-12 col-12">
                        <div style="padding-top:20px;">
                            <h3 class="titulo_section titulo_section-xl text-center">{{ source.homeBanner.nomeBloco.replaceAll('mobile','').replaceAll('desktop','') }}</h3>
                        </div>
                        <carousel id="carro2" :class="validacss(source,null)"
                                  :perPageCustom="[[340, 2],[768, 3], [1024,4], [1600,4]]"
                                  autoplay="false"
                                  :autoplayTimeout=40000
                                  autoplayHoverPause
                                  paginationEnabled
                                  :paginationSize=0
                                  :loop="true">

                            <slide v-for="ItemImagem in source.homeBanner.homeBannerItens.filter(x => x.visivel == true)" :key="ItemImagem.homeBannerItemId" class="produtos respiro">
                                <div class="produto-card produto-card_2">
                                    <a href="" @click="$redirect_reload('/produtoDetalhe/' + ItemImagem.produto.produtoId)">
                                        <div class="produto-img set-img set-tam4" :style="{ backgroundImage: 'url('  + CarregaImagemUnica(ItemImagem.produto.imagens) + ')',backgroundSize:'contain' }">
                                            <img v-for="ItemEtiqueta in ItemImagem.produto.premios" :key="ItemEtiqueta.produtoPremioId" :src="ItemEtiqueta.urlImg" alt="">
                                            <div class="middle middle_2">
                                                <div class="text">Ver Mais Detalhes</div>
                                            </div>
                                        </div>
                                    </a>
                                    <div class="produto-content" style="padding-top:10px;">
                                        <h6 class="titulo-card" style="min-height:40px;">{{  ItemImagem.produto.nomeProduto }}</h6>
                                        <span v-if="ItemImagem.produto.valorDe > 0" class="preco-antigo">De {{   parseFloat(ItemImagem.produto.valorDe,10).toFixed(2).toString().replace('.',',') }}</span>
                                        <p>
                                            <span class="texto-destaque">{{ parcelas }} x</span>
                                            de R$ <span class="texto-destaque"> {{ parseFloat(ItemImagem.produto.valorAte/parcelas,10).toFixed(2).toString().replace('.',',') }}</span> <br /><span class="texto-destaque2">{{ parcelasTexto }}</span>
                                        </p>
                                        <p>
                                            R$ <span class="texto-destaque3"> {{ parseFloat(ItemImagem.produto.valorAte,10).toFixed(2).toString().replace('.',',') }}</span>
                                        </p>
                                        <a href="" v-if="ItemImagem.produto.indisponivel != true" @click="$redirect_reload('/produtoDetalhe/' + ItemImagem.produto.produtoId)" class="btn btn-card" style="margin-top: 10px !important;">Ver Detalhes</a>
                                        <a v-else class="texto-destaque3" style="margin-top: 5px;">Indispon&iacute;vel</a>
                                    </div>
                                </div>
                            </slide>
                        </carousel>
                    </div>
                </div>
            </div>

        </div>
        </div>

</template>

<script>
    import { BCarousel } from 'bootstrap-vue'
    import { Carousel, Slide } from 'vue-carousel';
    import { VMoney } from 'v-money'

     export default {
          name: 'item-component',
        props: {
          source: { 
            type: Object,
            default () {
              return {}
            }
          }
        },
        components: {
            BCarousel,
            Carousel,
            Slide,
        },
        directives: { money: VMoney },
        data() {
            return {
               ItensHome: this.source,
                ItemHome: {},
                ListaFotosProd: [],
                slide: 0,
                sliding: null,
                parcelas: 0,
                parcelasTexto: '',
                money: {
                    decimal: ',',
                    thousands: '.',
                    precision: 2,
                    masked: true
                },
            };
        },
        methods: {
             validacss(value, txt) {
                let recebe = '';
                if (value.visivelMobile && value.visivelDesktop) {
                    recebe = ''
                }

                if (value.visivelMobile && !value.visivelDesktop) {
                    recebe = ' hidden-lg hidden-xl '
                }

                if (!value.visivelMobile && value.visivelDesktop) {
                    recebe = ' hidden-sm-md hidden-xs '
                }

                if (!value.visivelMobile && !value.visivelDesktop) {
                    recebe = ' hidden-lg hidden-xl hidden-sm-md hidden-xs '
                }

                if (txt != null) {
                    return recebe + txt;
                }
                else {
                    return recebe;
                }
            },   
            validacss2(value, txt) {
                              //console.log('value')
                //console.log(value)
                if (value.homeBanner.tipoBlocoHome.tipoBlocoHomeId == 16) {
                    let recebe = '';
                    if (value.visivelMobile && value.visivelDesktop) {
                        recebe = ''
                    }

                    if (value.visivelMobile && !value.visivelDesktop) {
                        recebe = ' hidden-lg hidden-xl '
                    }

                    if (!value.visivelMobile && value.visivelDesktop) {
                        recebe = ' hidden-sm-md hidden-xs '
                    }

                    if (!value.visivelMobile && !value.visivelDesktop) {
                        recebe = ' hidden-lg hidden-xl hidden-sm-md hidden-xs '
                    }

                    if (txt != null) {
                        return recebe + txt;
                    }
                    else {
                        return recebe;
                    }
                }
            },
            CarregaImagemUnica(value) {
                var retorno = value;
                if (retorno != null && retorno.length > 0) {
                    return this.$Url + retorno[0].urlImg;
                }
                else {
                    return this.$Url + "/imagens/indisponivel.jpg"
                }

            },
            initCarousel() {
                this.sliding = true
            },
            endSlide() {
                this.sliding = false
            }
        },
        created() {
               //console.log('this.source')
              // console.log(this.source)
               this.$http
                .get(this.$apiUrl + "/produtotextogeral")
                .then((res2) => res2.json())
                .then((tiu2) => {
                    this.parcelas = parseInt(tiu2[0].texto1, 10);
                    this.parcelasTexto = tiu2[0].texto2;
                });

         /*    this.$http
                .get(this.$apiUrl + "/ProdutoImagem")
                .then((res2) => res2.json())
                .then((tiu2) => {
                    this.ListaFotosProd = tiu2
                }); */
        },
        mounted() {

        },
    };
</script>
<style scoped>

    @import '/css/produtos.css';
    @import '/css/nav_produtos.css';
    @import '/css/titulo_section.css';

    .VueCarousel-pagination {
        width: 100%;
        text-align: center;
        display: none !important;
    }

    .linhaban {
        display: inline-flex;
        padding: 3px;
    }

   @media all and (max-width: 370px) 
    {
        /* .produtos{
            padding: 0px;
        } */
        .produto-img{
            text-align: center;
            background-size: 92% !important;
        }
    }
     @media all and (max-width: 450px) 
    {
        /* .produtos{
            padding: 0px;
        } */
        .produto-img{
            text-align: center;
            background-size: 99% !important;
        }
    }
</style>