<template>

    <section class="carrinho">

        <Header />
        <Fita class="hidden-sm" titulo="Presentes exclusivos para momentos especiais"></Fita>

        <section class="main-minha_conta">

            <div class="container">
                <div class="row">
                    <!-- Sidenav -->
                    <div class="col-lg-4 col-xl-3">

                        <ul class="lista-sidebar-suporte_links">
                            <h5 class="title-sidebar-suporte mb-4">Empresa</h5>
                            <li>
                                <router-link to="/sobre" class="texto">
                                    <i class="fas fa-angle-double-right icon-arrow"></i>
                                    Quem Somos
                                </router-link>
                            </li>
                            <li>
                                <router-link to="/contato" class="texto active">
                                    <i class="fas fa-angle-double-right icon-arrow"></i>
                                    Contato
                                </router-link>
                            </li>
                        </ul>
                    </div>
                    <!-- Main -->
                    <div class="col-lg-12 col-xl-9 mt-5 mt-lg-0">
                        <div class="row">
                            <div class="col-md-4">
                                <div class="content mt-2">
                                    <div v-html="Texto"></div>
                                </div>
                            </div>
                            <div class="col-md-8">
                                <form autocomplete="off">
                                    <div class="row">
                                        <div class="col-md-12 mb-2">
                                            <div class="form-group">
                                                <label>Nome</label>
                                                <input v-model="Nome" autocomplete="off" class="form-control" type="text">
                                            </div>
                                        </div>
                                        <div class="col-md-6 mb-2">
                                            <div class="form-group">
                                                <label>E-mail</label>
                                                <input v-model="Email" autocomplete="off" class="form-control" type="text">
                                            </div>
                                        </div>
                                        <div class="col-md-6 mb-2">
                                            <div class="form-group">
                                                <label>Telefone</label>
                                                <input v-model="Telefone" autocomplete="off" class="form-control" type="text">
                                            </div>
                                        </div>
                                        <div class="col-md-12 mb-2">
                                            <div class="form-group">
                                                <label>Mensagem</label>
                                                <textarea v-model="Mensagem" autocomplete="off" rows="8" class="form-control"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-md-12 mb-2" style="text-align:center;">
                                            <span style="color:red;">
                                                {{ Resposta }}
                                            </span>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12 text-center">
                                            <button type="button"
                                                    class="btn btn-success w-100 mt-4"
                                                    @click="Enviar()">
                                                Enviar Mensagem
                                            </button>
                                            <button type="button"
                                                    @click="Limpar()"
                                                    class="btn btn-link">
                                                Cancelar
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <FitaRodape class="ribbon-lg"></FitaRodape>
        <Footer />
    </section>
</template>
<script>
    import Header from '@/components/site/shared/Header.vue'
    import Fita from '@/components/site/shared/Fita.vue'
    import FitaRodape from '@/components/site/shared/FitaRodape.vue'
    import Footer from '@/components/site/shared/Footer.vue'
    export default {
        components: {
            Header,
            Fita,
            FitaRodape,
            Footer,
        },
        data() {
            return {
                Retorno: [],
                Texto:'',
                Nome: '',
                Email: '',
                Telefone: '',
                Mensagem: '',
                Resposta: '',
            }
        },
        methods: {
            Limpar() {
                this.$redirect_reload("/contato");
            },
            Enviar() {
                this.Resposta = "";
                if (this.Email == "") {
                    this.Resposta = "Por favor preencha o e-mail";
                } else if (this.Telefone == "") {
                    this.Resposta = "Por favor preencha Telefone";
                } else if (this.Mensagem == "") {
                    this.Resposta = "Por favor preencha Mensagem";
                } else {
                    let tmp = { ToEmail: this.Retorno[0].referencia, Subject: 'Contato do Site', Body: "Nome: " + this.Nome + " | Telefone: " + this.Telefone + " | Mensagem:  " + this.Mensagem };
                    //this.Resposta = "Envio de e-mail desabilitado";
                    //console.log(tmp)
                    this.$http
                        .post(
                            this.$apiUrl + "/contato/enviamail/" + 'Love Cestas', tmp
                        )
                        .then(
                            (response) => {
                                if (response.ok) {
                                    this.Resposta = "E-mail enviado com sucesso";
                                }
                            },
                            (error) => {
                                console.log(error);
                                this.Resposta = error.bodyText;
                            }
                        );
                }
            }
        },
        created() {
            this.$http
                .get(this.$apiUrl + "/institucional/telas/contato")
                .then((res2) => res2.json())
                .then((tiu2) => {
                    this.Retorno = tiu2;
                    if (this.Retorno != null) {
                        this.Texto = this.Retorno[0].resumo1;
                    }
                });
        },
        mounted() {
        },
    }
</script>
<style scoped>
    @import '/css_cart/carrinho.css';
    @import '/css_cart/minha_conta.css';
    @import '/css/suporte.css';
</style>
