<template>
  
  <footer v-if="Informa!=''" class="footer set-img" 
    :style="{ backgroundImage: 'url(' + $Url + Informa[0].institucionalImg[0].urlImagem + ')' }">
    <div class="container">
      <!-- Logo -->
      <div class="row justify-content-center">
        <div class="col-6 col-md-12 mb-5">
          <router-link to="/">
            <img class="img-fluid" src="/img/01.png" alt="Logo Love Cestas">
          </router-link>
        </div>
      </div>
      
      <div class="row">
        <div class="col-md-4 col-lg-3">
          <div class="footer-column">
            <h5 class="footer-titulo">Empresa</h5>
            <ul class="footer-list">
              <li><router-link to="/sobre" class="footer-content footer-link">Sobre</router-link></li>
              <li><router-link to="/contato" class="footer-content footer-link">Contato</router-link></li>
              <li v-if="Telefone!=''"><i class="fas fa-phone-alt"></i>
                  <span class="footer-content">{{ Telefone[0].nome }}</span>
              </li>
              <li v-for="What in Whats" :key="What.institucionalId">
                  <img src="/img/social/whatsapp.png" alt="">
                  <a class="footer-content footer-link" :href="What.linkBtn1">{{ What.nome}}</a>
              </li>             
            </ul>  
            <p class="footer-content" v-html="Informa[0].resumo1">
            </p>   
            <ul class="footer-list">
                <li v-if="Face!=null && Face!=''">
                    <a class="footer-content footer-link" style="text-decoration: none !important;color: #333333;" :href="Face[0].nome" target="_blank">
                        <img src="/img/social/facebook.png" :alt="Face[0].referencia">
                        {{ Face[0].referencia }}
                    </a>
                </li>
                <li v-if="Insta!=null && Insta!=''">
                    <a class="footer-content footer-link" style="text-decoration: none !important;color: #333333;" :href="Insta[0].nome" target="_blank">
                        <img src="/img/social/instagram.png" :alt="Insta[0].referencia">
                        {{ Insta[0].referencia }}
                    </a>
                </li>
            </ul>  

            <h5 class="footer-titulo">Minha Conta</h5>
            <ul class="footer-list">
              <li><a href="" @click="$redirect_reload('/login')" class="footer-content footer-link">Entrar</a></li>
              <li><a href="" @click="$redirect_reload('/meusDados')" class="footer-content footer-link">Meus Dados</a></li>
              <li><a href="" @click="$redirect_reload('/carrinho')" class="footer-content footer-link">Meu Carrinho</a></li>
              <li><a href="" @click="$redirect_reload('/meusPedidos')" class="footer-content footer-link">Meus Pedidos</a></li>                         
            </ul>    
          </div>
        </div>

        <div class="col-md-4 col-lg-3">
          <div class="footer-column">
            <h5 class="footer-titulo">Produtos</h5>
            <ul>
              <li v-for="segmento in Segmentos" :key="segmento.segmentoProdNivel1Id">
                  <a @click="$redirect_reload('/produtos/' + segmento.nomeSegmento.replaceAll(' ','-').replaceAll('/','') +  '/' +  segmento.segmentoProdNivel1Id)" class="footer-content footer-link">{{ segmento.nomeSegmento}}</a>
              </li>                       
            </ul>  

            <h5 class="footer-titulo">Suporte</h5>
            <ul>
                <li><router-link to="/comoComprar" class="footer-content footer-link">Como Comprar</router-link></li>
                <li><router-link to="/comopagar" class="footer-content footer-link">Como Pagar</router-link></li>
                <li><router-link to="/entregas" class="footer-content footer-link">Entrega</router-link></li>
                <li><router-link to="/fretes" class="footer-content footer-link">Frete</router-link></li>
                <li><router-link to="/duvidasfrequentes" class="footer-content footer-link">Dúvidas Frequentes</router-link></li>
                <li><router-link to="/trocasdevolucoes" class="footer-content footer-link">Trocas e Devoluções</router-link></li>
                <li><router-link to="/itensdecoracoes" class="footer-content footer-link">Sobre itens e decorações das cestas</router-link></li>
                <li><router-link to="/politicacookies" class="footer-content footer-link">Política de Cookies</router-link></li>
                <li><router-link to="/termosuso" class="footer-content footer-link">Termos de Uso</router-link></li>
                <li><router-link to="/politicaprivacidade" class="footer-content footer-link">Política de Privacidade</router-link></li>
            </ul>    
          </div>
        </div>

      </div>

      <div class="row">
        <div class="col">
          <div class="avance mt-4"><a href="https://avancedigital.com.br/" target="_blank">
            <img class="img-fluid" src="/img/social/logo_avance.png" alt=""></a>
          </div>
        </div>
      </div>
    </div>
  </footer>

</template>

<script>

    export default {
        data() {
            return {
                Face: [],
                Insta: [],
                Telefone: [],
                Segmentos: [],
                segmento: {},
                Whats: [],
                What: {},
                Informa: [],
            };
        },
        methods: {
        },
        created() {

            this.$http
                .get(this.$apiUrl + "/institucional/telas/facebook")
                .then((res2) => res2.json())
                .then((tiu2) => {
                    this.Face = tiu2.filter(x=> x.visivel)
                });

            this.$http
                .get(this.$apiUrl + "/institucional/telas/instagram")
                .then((res2) => res2.json())
                .then((tiu2) => {
                    this.Insta = tiu2.filter(x=> x.visivel)
                });

            this.$http
                .get(this.$apiUrl + "/institucional/telas/telefone")
                .then((res2) => res2.json())
                .then((tiu2) => {
                    this.Telefone = tiu2.filter(x=> x.visivel)
                });

            this.$http
                .get(this.$apiUrl + "/institucional/telas/Lista%20Whats")
                .then((res2) => res2.json())
                .then((tiu2) => {
                    this.Whats = tiu2.filter(x => x.visivel == true);
                });

            this.$http
                .get(this.$apiUrl + "/institucional/telas/informacoes")
                .then((res2) => res2.json())
                .then((tiu2) => {
                    this.Informa = tiu2;
                });

            this.$http
                .get(this.$apiUrl + "/SegmentoProdNivel1")
                .then((res2) => res2.json())
                .then((tiu2) => {
                    this.Segmentos = tiu2.filter(x => x.visivel).sort(function (a, b) {
                        return a.ordem - b.ordem;
                    });
                });
        },
        mounted() {

        },
        components: {
        },
    };
</script>

<style scoped>

  @import '/css/footer.css';



</style>