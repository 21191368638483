<template>

  <section>

    <header class="header header-cart vertical-alignment">      
      <div class="container-fluid">
        <div class="row">
          <!-- Pesquisar -->
          <div class="set-order-1 vertical-alignment col-3 col-lg-3 col-xl">
            <div class="input-group input-search hidden-lg hidden-xl">
              <label class="sr-only" for="#">Buscar</label>
              <input type="text" class="form-search hidden-sm" 
                placeholder="BUSCAR"
                onfocus="this.placeholder=''"
                onblur="this.placeholder='BUSCAR'"
                v-model="textobusca">
              
              <i class="fas fa-search icon-search"></i>
              
            </div>            
          </div>

          <!-- Menu -->
          <div class="set-order-3 vertical-alignment col-6 col-lg-12 col-xl-7 text-center">
            <router-link to="/">
              <img class="img-fluid tamlogo" src="img/01.png" alt="Logo Love Cestas">
            </router-link>
            
          </div>

          <!-- Entrar + Carrinho -->
          <div class="set-order-2 vertical-alignment col-3 col-lg-3 offset-lg-6 col-xl offset-xl-0">
            <div class="login hidden-lg hidden-xl">

              <div>
                <router-link to="/carrinho">
                  <img class="carrinho-img img-fluid" src="img/cart.png" alt="">  
                  <!-- <i class="fas fa-shopping-cart icon-cart"></i> -->
                </router-link>

                <!-- Only Mobile -->
                <a href="#">
                  <img class="whats-img hidden-lg hidden-xl" src="img/cart/icon_whats.png" alt="">
                  <!-- <i class="fab fa-whatsapp icon-whats text-success hidden-lg hidden-xl"></i> -->
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Controllers (NÃO DELETAR) -->
      <input class="d-none" type="checkbox" id="control-nav" />
      <label for="control-nav" class="control-nav"></label>
      <label for="control-nav" class="control-nav-close"></label>
      <!--  -->

      <nav class=" menu-nav-responsive">

        <!-- Pesquisar Mobile -->
        <!-- <div class="input-group input-search mb-5">
          <label class="sr-only" for="#">Buscar</label>
          <input type="text" class="form-search" placeholder="BUSCAR"
          onfocus="this.placeholder=''"
          onblur="this.placeholder='BUSCAR'">
          <i class="fas fa-search icon-search"></i>
        </div> -->

        <div class="mb-5">
          <router-link to="/">
            <img class="img-fluid" src="img/01.png" alt="Logo Love Cestas">
          </router-link>
        </div>

        <!-- Menu Mobile -->
        <ul class="menu-nav">
          <li><router-link class="menu-link" to="/produtos">Cestas e Presentes</router-link></li>
          <li><router-link class="menu-link" to="/.">Kits e Flores</router-link></li>
          <li><router-link class="menu-link" to="/..">Românticas</router-link></li>
          <li><router-link class="menu-link" to="/..">Comemorações</router-link></li>
          <li><router-link class="menu-link" to="/..">Corporativo</router-link></li>
        </ul>

        <!-- Apenas Mobile -->
        <div class="hidden-lg hidden-xl">
          <div>
            <img src="/img/social/whatsapp.png" alt="">
            <a class="menu-link menu-link-whatsapp" href="#">(51) 99332 6338</a>
          </div>
          <div>
            <img src="/img/social/whatsapp.png" alt="">
            <a class="menu-link menu-link-whatsapp" href="#">(51) 99681 7372</a>
          </div>
        </div>
        <!--  -->
        
      </nav>
    </header>

  </section>

</template>

<script>
  var coll = document.getElementsByClassName("collapsible");
  var i;

  for (i = 0; i < coll.length; i++) {
    coll[i].addEventListener("click", function() {
      this.classList.toggle("active");
      var content = this.nextElementSibling;
      if (content.style.display === "block") {
        content.style.display = "none";
      } else {
        content.style.display = "block";
      }
    });
  }
  
</script>

<style scoped>

  @import '/css/header.css';

</style>