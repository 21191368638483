<template>
    <div>
        {{ Produtos }}
    </div>
</template>

<script>

    export default {
        components: {

        },
        data() {
            return {
                Produto: [],
                Produtos: '',
                LigacaoProduto:[],
            }
        },
        methods: {
            CarregaSegmento(value) {
                let NomeSeg = this.LigacaoProduto.filter(x => x.produto.produtoId == parseInt(value.produtoId,10))

                if (NomeSeg != '') {
                    return NomeSeg[0].segmentoProdNivel2.segmentoProdNivel2Id;
                }
                else {
                    return 1;
                }
                

            },
        },
        created() {

        this.$http
            .get(this.$apiUrl + "/Ligacaosegmentoproduto")
            .then((res2) => res2.json())
            .then((tiu3) => {
                this.LigacaoProduto = tiu3;

                    this.$http
                        .get(this.$apiUrl + "/produto")
                        .then((res2) => res2.json())
                        .then((tiu2) => {
                            this.Produto = tiu2;
                                this.Produto.forEach(element => {
                                    if (element.imagens != '') {
                                       let dados = {
                                        kind: "content#product",
                                        offerId: element.produtoId,
                                        title:  element.nomeProduto,
                                        description: element.resuno,
                                        link: 'https://lovecestas.com.br/produtoDetalhe/' + element.produtoId ,
                                        imageLink:  this.$Url + element.imagens[0].urlImg,
                                        contentLanguage: "pt-br",
                                        targetCountry: "BR",
                                        availability: "in stock",
                                        brand: "Love Cestas",
                                        condition: "new",
                                        googleProductCategory: "Produtos",
                                        gtin: element.referencia,
                                        itemGroupId: this.CarregaSegmento(element),
                                        mpn: element.referencia,
                                        price: {
                                            value: element.valorAte,
                                            currency: "BRL"
                                               },
                                        } 

                                        this.$http.post(this.$apiUrl + "/post?url=https://shoppingcontent.googleapis.com/content/v2.1/{merchantId}/products", dados)
                                        .then(response => {
                                                if (response.ok) {
                                                    this.dados = response.body;
                                                }
                                            },
                                            error => {
                                                try {
                                                    console.log(error.data.error_response.general_errors[0].code + " - " + error.data.error_response.general_errors[0].message)

                                                } catch {
                                                    try {
                                                        console.log(error.data.error_response.validation_errors[0].code + " - " + error.data.error_response.validation_errors[0].message_complete)
                                                    } catch {
                                                        console.log(error)
                                                    }
                                                }
                                         });

                                    }
                                });
                        });
                });
        },
        mounted() {

        },
    }

</script>

<style scoped>
</style>