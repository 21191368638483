<template>
  
  <section class="carrinho">

    <HeaderCart />
    <Fita />

    <section class="main-minha_conta">
    
      <div class="container">
        <div class="row">
          <div class="col-lg-3">
            <ul class="lista-sidebar_links">
              <li><router-link to="/meusDados" class="texto active">Meus Dados</router-link></li>
              <li><router-link to="/meusPedidos" class="texto">Meus Pedidos</router-link></li>              
            </ul>             
          </div>
          <div class="col-lg-8 mt-5 mt-lg-0">
            <h3 class="titulo-principal text-center mb-4">Minha Conta</h3>
            <h6 class="subtitulo h5 mb-4">Meus Dados</h6>

            <!-- Form editar dados -->
            <form>
              <div class="row">
                <div class="col-md-6 mb-3">
                  <input type="text" class="form-control" placeholder="Nome Completo">
                </div>
                <div class="col-md-6 mb-3">
                  <input type="text" class="form-control" placeholder="CPF">
                </div>
                <div class="col-md-6 mb-3">
                  <input type="text" class="form-control" placeholder="Telefone">
                </div>
                <div class="col-md-6 mb-3">
                  <input type="text" class="form-control" placeholder="Whatsapp">
                </div>
                <div class="col-md-6 mb-3">
                  <input type="text" class="form-control" placeholder="Responsável">
                </div>
                <div class="col-md-6 mb-3">
                  <input type="text" class="form-control" placeholder="E-mail">
                </div>
                <div class="col-md-6 mb-3">
                  <input type="text" class="form-control" placeholder="Senha">
                </div>
                <div class="col-md-6 mb-3">
                  <input type="text" class="form-control" placeholder="Repita a senha">
                </div>
                <div class="col-md-6 mb-3">
                  <input type="text" class="form-control" placeholder="Data de Aniversário">
                </div>
                <div class="col-md-6 mb-3">
                  <input type="text" class="form-control" placeholder="CEP">
                </div>
                <div class="col-md-6 mb-3">
                  <input type="text" class="form-control" placeholder="Endereço">
                </div>
                <div class="col-md-6 mb-3">
                  <input type="text" class="form-control" placeholder="Complemento">
                </div>
                <div class="col-md-6 mb-3">
                  <v-select 
                    :options="estado"
                    placeholder="Estado">
                  </v-select>
                </div>
                <div class="col-md-6 mb-3">
                  <v-select 
                    :options="cidade"
                    placeholder="Cidade">
                  </v-select>
                </div>
                
              </div>
            </form>

            <!-- <div class="form-group mb-5">
              <label>Profissão:</label>
              <select class="form-control" style="line-height: 1.5 !important;"
                v-model="profissao">
                <option value="" disabled selected>Selecione uma opção...</option>
                <option 
                  v-for="(profissao, index) in profissoes"
                  :key="index"
                  :value="profissao">
                  {{ profissao }}
                </option>
              </select>
            </div>

            <h2>TESTE -> Captura do input: {{ profissao }}</h2> -->

            <div class="text-right">
              <router-link to="/meusDados" class="btn btn-success">Salvar Dados</router-link>
            </div>
          </div>
        </div>
      </div>

    </section>

    <Fita class="ribbon-lg" titulo="A melhor empresa de cestas e presentes do Rio Grande do Sul!! Há 8 anos levando carinho, amor e alegrias a nossos clientes!"></Fita>
    <Footer />

  </section>

</template>

<script>

  import HeaderCart from '@/components/site/shared/HeaderCart.vue'
  import Fita from '@/components/site/shared/Fita.vue'
  import Footer from '@/components/site/shared/Footer.vue'
  import vSelect from 'vue-select'

  export default {
    components: {
      HeaderCart,
      Fita,
      vSelect,
      Footer
    },
    data() {
      return {
        bg_imagem: 'img/33.png',
        arr_meusDados: [
      {
        id: 1,
        descricao: 'Nome Completo',
        valor: 'Nome Sobrenome'
      },
      {
        id: 2,
        descricao: 'CPF',
        valor: '123.456.789-89'
      },
      {
        id: 3,
        descricao: 'Telefone',
        valor: '(51) 3031 32 33'
      },
      {
        id: 4,
        descricao: 'Whatsapp',
        valor: '(51) 897 654 321'
      },
      {
        id: 5,
        descricao: 'Responsável',
        valor: 'Nome do responsável'
      },
      {
        id: 6,
        descricao: 'Email',
        valor: 'meuemail@gmail.com'
      },
      {
        id: 7,
        descricao: 'Senha',
        valor: '*********'
      },
      {
        id: 8,
        descricao: 'Data de Nascimento',
        valor: '123.456.789-89'
      },
      {
        id: 9,
        descricao: 'CEP',
        valor: '99880-777'
      },
      {
        id: 10,
        descricao: 'Endereço',
        valor: 'Av. Lorem Ipsum, 1234'
      },
      {
        id: 11,
        descricao: 'Complemento',
        valor: 'Apartamento 206'
      },
      {
        id: 12,
        descricao: 'Cidade',
        valor: 'Minha Cidade'
      },
      {
        id: 13,
        descricao: 'Estado',
        valor: 'RS'
      }
        ],
        profissao: '',
        profissoes: [
          'Desenvolvedor web',
          'Vendedor',
          'Motorista'
        ]
      }
    }
  }

</script>

<style scoped>

  @import '/css_cart/carrinho.css';
  @import '/css_cart/minha_conta.css';

  
</style>