import { render, staticRenderFns } from "./PagePoliticaPrivacidade.vue?vue&type=template&id=2591bc21&scoped=true&"
import script from "./PagePoliticaPrivacidade.vue?vue&type=script&lang=js&"
export * from "./PagePoliticaPrivacidade.vue?vue&type=script&lang=js&"
import style0 from "./PagePoliticaPrivacidade.vue?vue&type=style&index=0&id=2591bc21&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2591bc21",
  null
  
)

export default component.exports