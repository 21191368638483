<template>

    <section class="carrinho">

        <Header />
        <Fita class="hidden-sm" titulo="Presentes exclusivos para momentos especiais"></Fita>

        <section class="main-minha_conta">

            <!-- Escolha o Tema -->
            <Modal v-model="showModal_tema" title="">

                <section class="section-modal">
                    <div class="container">
                        <div class="row">
                            <div class="col text-center">
                                <h4 class="titulo-modal">
                                    {{ TextosTema[0].nome }}
                                </h4>
                            </div>
                        </div>
                        <div class="text-center px-lg-5">
                            <p v-html="TextosTema[0].resumo1"></p>
                        </div>
                        <div class="row mt-5">
                            <div class="col-4 col-md-3 col-lg-2 mb-4" v-for="temaMensage in TemaMensagem" :key="temaMensage.segmentoInstNivel1Id">
                                <div class="cartao-item text-center">
                                    <a @click="carregaModeloMensagem(temaMensage.segmentoInstNivel1Id)">
                                        <img v-if="temaMensage.imagens[0] != null" class="imagem-modal img-fluid" :src="$Url + temaMensage.imagens[0].urlImagem" alt="">
                                        <img v-else :src="$Url + '/imagens/indisponivel.jpg'" class="imagem-modal img-fluid" />
                                    </a>
                                    <a class="link-modal" @click="carregaModeloMensagem(temaMensage.segmentoInstNivel1Id)">
                                        {{ temaMensage.nomeSegmento }}
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="text-center px-lg-5">
                            <p v-html="TextosTema[0].resumo2"></p>
                        </div>
                    </div>
                </section>

                <footer class="modal_footer_fixo">
                    <div class="container">
                        <div class="row">
                            <div class="col-4 col-md-4 col-lg-3">
                                <div class="voltar d-flex align-items-center">

                                </div>
                            </div>
                            <div class="col-8 col-md-4 offset-md-4 col-lg-3 offset-lg-6">

                            </div>
                        </div>
                    </div>
                </footer>

            </Modal>

            <!-- Escolha o Modelo -->
            <Modal v-model="showModal_modelo" title="">

                <section class="section-modal">
                    <div class="container">
                        <div class="row">
                            <div class="col text-center">
                                <h4 class="titulo-modal"> {{ TextosModelo[0].nome }}</h4>
                                <h5 class="titulo-modal"> {{ TextosModelo[0].referencia }}</h5>
                            </div>
                        </div>
                        <div class="text-center px-lg-5">
                            <p v-html="TextosModelo[0].resumo1"></p>
                        </div>
                        <div class="row mt-5">
                            <div class="col-4 col-md-3 col-lg-2 mb-4" v-for="modeloMensage in ModeloMensagemFiltro" :key="modeloMensage.institucionalId">
                                <div class="cartao-item text-center">
                                    <a @click="carregaCartao(modeloMensage.institucionalId)">
                                        <img v-if="modeloMensage.institucionalImg[0] != null" class="imagem-modal img-fluid" :src="$Url + modeloMensage.institucionalImg[0].urlImagem" alt="">
                                        <img v-else :src="$Url + '/imagens/indisponivel.jpg'" class="imagem-modal img-fluid" />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="text-center px-lg-5">
                            <p v-html="TextosModelo[0].resumo2"></p>
                        </div>
                    </div>
                </section>

                <footer class="modal_footer_fixo">
                    <div class="container">
                        <div class="row">
                            <div class="col-4 col-md-4 col-lg-3">
                                <div class="voltar d-flex align-items-center">
                                    <a @click="showModal_tema=true, showModal_modelo=false">Voltar</a>
                                </div>
                            </div>
                            <div class="col-8 col-md-4 offset-md-4 col-lg-3 offset-lg-6">
                            </div>
                        </div>
                    </div>
                </footer>

            </Modal>

            <!-- Escreva a Mensagem -->
            <Modal v-model="showModal_mensagem" title="">

                <section class="section-modal">
                    <div class="container">
                        <div class="row">
                            <div class="col text-center">
                                <h4 class="titulo-modal">{{ InstrucaoCartao[0].nome }}</h4>
                            </div>
                        </div>

                        <!-- Instruções -->
                        <div class="row">
                            <div class="col text-center">
                                <p class="texto_detalhe" v-html="InstrucaoCartao[0].resumo1"></p>
                            </div>
                        </div>
                        <div v-if="TextosDe==true" class="row" style="padding-top:40px;">
                            <div class="text-center">
                                <h5 class="subtitulo mb-0">{{ Titulos[0].nome }}</h5>
                                <p class="texto-mensagem">{{ Titulos[0].referencia }}</p>
                            </div>
                            <form class="px-md-5">
                                <div class="form-group row">
                                    <div class="col-sm-4">
                                        <label for="inputEmail" class="col-sm-5 col-form-label login-label">De:</label>
                                        <input v-model="NomeDe" type="text" class="form-control" id="inputEmail">
                                        <div class="input-feedback">
                                            <span>Nome da pessoa que está enviando</span>
                                        </div>
                                    </div>
                                    <div class="col-sm-4">
                                        <label for="inputName" class="col-sm-5 col-form-label login-label">Para:</label>
                                        <input v-model="NomePara" type="text" class="form-control" id="inputName">
                                        <div class="input-feedback">
                                            <span>Nome da pessoa que vai receber</span>
                                        </div>
                                    </div>
                                    <div class="col-sm-4">
                                        <label for="inputwhats" class="col-sm-5 col-form-label login-label">Telefone:</label>
                                        <input v-model="TelefoneRecebe" type="text" class="form-control" id="inputwhats">
                                        <div class="input-feedback">
                                            <span>Telefone da pessoa que vai receber</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="input-alert text-center">
                                    <span class="text-danger">{{ Titulos[0].campoExtra1 }}</span>
                                </div>

                            </form>
                        </div>

                        <div class="row justify-content-center">
                            <div class="col-lg-9 col-xl-8">
                                <div class="editar-cartao mt-4 text-center" ref="content">
                                    <!--aqui regra do preenchimento do cartão -->
                                    <div id="conteudocartao" ref="content" v-if="ModeloCartaoEscolhidoUrl != ''" class="imagemCartao" :style="{ backgroundImage: 'url(' + ModeloCartaoEscolhidoUrl +  ')' }">
                                        <textarea id="txtarea" ref="contentsarea" v-model="ConteudoCartao" class="textareacartao" maxlength="1000" style="border:1px solid #cccccc;font-family: Handlee /*Dancing Script*/ !important;font-size: 21px;text-align: center;"></textarea>
                                    </div>
                                </div>
                               <!--  <button class="btn btn-success" @click="downloadWithCSS()">Gerar PDF</button> -->
                            </div>
                        </div>

                    </div>
                </section>

                <footer class="modal_footer_fixo">
                    <div id="divdepara" class="container divdepara">
                        <div class="row">
                            <div class="col-md-3 col-lg-4 col-xl-6">
                                <div class="voltar d-flex align-items-center mb-4 mb-md-0">
                                    <a @click="showModal_modelo=true, showModal_mensagem=false">Voltar</a>
                                </div>
                            </div>

                            <div class="col-6 col-md-4 col-lg-4 col-xl-3">

                            </div>

                            <div v-if="TextosDe==true" class="col-6 col-md-5 col-lg-4 col-xl-3">
                                <a class="btn btn-card mt-0"
                                   @click="carregaDadosCartao()">
                                    {{ InstrucaoCartao[0].textoBtn2 }}
                                </a>
                            </div>
                            <div v-else class="col-6 col-md-5 col-lg-4 col-xl-3">
                                <a class="btn btn-card mt-0"
                                   @click="liberadepara()">
                                    {{ InstrucaoCartao[0].textoBtn2 }}
                                </a>
                            </div>
                        </div>
                    </div>
                </footer>

            </Modal>

        </section>

        <FitaRodape class="ribbon-lg"></FitaRodape>
        <Footer />

    </section>

</template>

<script>

    import Header from '@/components/site/shared/Header.vue'
    import Fita from '@/components/site/shared/Fita.vue'
    import FitaRodape from '@/components/site/shared/FitaRodape.vue'
    import Footer from '@/components/site/shared/Footer.vue'
    import VueModal from '@kouts/vue-modal'
    //import html2canvas from 'html2canvas'
    import jsPDF from 'jspdf'
    import domtoimage from "dom-to-image";


    export default {
        components: {
            Header,
            Fita,
            FitaRodape,
            Footer,
            'Modal': VueModal,
        },
        data() {
            return {
                TextosTema: [],
                TemaMensagem: [],
                temaMensage: {},
                TextosModelo: [],
                ModeloMensagem: [],
                modeloMensage: {},
                ModeloMensagemFiltro: [],
                InstrucaoCartao: [],
                ModeloCartaoEscolhido: [],
                ModeloCartaoEscolhidoUrl: '',
                ConteudoCartao: '',
                showModal_tema: true,
                showModal_modelo: false,
                showModal_mensagem: false,
                Titulos: [],
                TextosDe: false,
                MensagemAvulsa: [],
                NomeDe: '',
                NomePara: '',
                TelefoneRecebe:'',
            }
        },
        methods: {
            liberadepara() {
                this.TextosDe = true;
            },
             downloadWithCSS() {       
                 domtoimage
                    .toPng(this.$refs.contentsarea)
                    .then(function (dataUrl) {
                        var img = new Image();
                        var img2 = new Image();
                        //img.src = "/imagens/institucional/334-institucional-238-geral-2-377-04-11-2021-15-32-44.jpg" //this.ModeloCartaoEscolhidoUrl;
                        img.src = "/imagens/institucional/331-institucional-237-geral-1-376-04-11-2021-15-22-06.jpg"
                        img2.src = dataUrl
                        const doc = new jsPDF({
                            orientation: "portrait",
                            // unit: "pt",
                            //format: [1772, 1240]
                        });
                        //doc.addImage(img, "JPEG", 22, -7, 165, 110);
                        //doc.addImage(img, "JPEG", 24, -5, 162, 109);
                        //doc.addImage(img, "JPEG", -10, -7, 0, 0, '', "false", 90);
                        /////////////
                        //doc.addImage(img, "JPEG", 25, 0, 160, 107);
                        //doc.addImage(img2, "PNG", 25, 0, 160, 107);
                        doc.addImage(img, "JPEG", 30, 5, 150, 103);
                        doc.addImage(img2, "PNG", 30, 5, 150, 103);

                        const date = new Date();
                        const filename =
                            "timechart_" +
                            date.getFullYear() +
                            ("0" + (date.getMonth() + 1)).slice(-2) +
                            ("0" + date.getDate()).slice(-2) +
                            ("0" + date.getHours()).slice(-2) +
                            ("0" + date.getMinutes()).slice(-2) +
                            ("0" + date.getSeconds()).slice(-2) +
                            ".pdf";
                        doc.save(filename); 
                    })
                    .catch(function (error) {
                        console.error("ocorreu uma falha!", error);
                    }); 
            },
            //desliza() {
            //    let element = document.querySelector(".divdepara");
            //    element.scrollIntoView();
            //},
            carregaModeloMensagem(value) {
                this.ModeloMensagemFiltro = this.ModeloMensagem.filter(x => x.segmentoInstNivel1.segmentoInstNivel1Id == parseInt(value, 10))
                this.showModal_modelo = true
                this.showModal_tema = false
            },
            carregaCartao(value) {
                this.ModeloCartaoEscolhido = this.ModeloMensagem.filter(x => x.institucionalId == parseInt(value, 10))
                this.ModeloCartaoEscolhidoUrl = this.ModeloCartaoEscolhido[0].institucionalImg[0].urlImagem
                this.showModal_mensagem = true
                this.showModal_modelo = false
            },
            carregaDadosCartao() {

                if (this.NomeDe == '') {
                    this.$mensagem_normal("Por favor preencha o nome da pessoa que está enviando");
                } else if (this.NomePara == '') {
                    this.$mensagem_normal("Por favor preencha o nome da pessoa que vai receber");
                } else {
                    let _MensagemAvulsa = {
                        MensagemAvulsaId: 0,
                        UrlImagem: this.ModeloCartaoEscolhidoUrl,
                        NomeDe: this.NomeDe,
                        NomePara: this.NomePara,
                        Texto: this.ConteudoCartao,
                        TelefoneRecebe: this.TelefoneRecebe,
                    }
                    this.$http
                        .post(this.$apiUrl + "/mensagemavulsa", _MensagemAvulsa)
                        .then(
                            response2 => {
                                // get body data
                                this.MensagemAvulsa = response2.body;
                                this.$redirect("/mensagemavulsafinalizada");
                            });
                }
            },
        },
        created() {
            //carrega titulos temas mensagens
            this.$http
                .get(this.$apiUrl + "/institucional/telas/textostema")
                .then((res2) => res2.json())
                .then((tiu2) => {
                    this.TextosTema = tiu2;
                });

            //carrega temas mensagens
            this.$http
                .get(this.$apiUrl + "/segmentoinstnivel1")
                .then((res2) => res2.json())
                .then((tiu2) => {
                    this.TemaMensagem = tiu2.filter(x => x.visivel);
                });

            //carrega titulos modelos mensagens
            this.$http
                .get(this.$apiUrl + "/institucional/telas/textosmodelo")
                .then((res2) => res2.json())
                .then((tiu2) => {
                    this.TextosModelo = tiu2;
                });

            //carrega modelos mensagens
            this.$http
                .get(this.$apiUrl + "/institucional/telas/modelo%20de%20cartoes")
                .then((res2) => res2.json())
                .then((tiu2) => {
                    this.ModeloMensagem = tiu2.filter(x => x.visivel);
                });

            //carrega instruções cartao
            this.$http
                .get(this.$apiUrl + "/institucional/telas/instrucoes%20cartao")
                .then((res2) => res2.json())
                .then((tiu2) => {
                    this.InstrucaoCartao = tiu2;
                });

            //----------------- Titulos planos
            this.$http
                .get(this.$apiUrl + "/institucional/telas/textoinformacao")
                .then((res) => res.json())
                .then((retornoAPI) => {
                    this.Titulos = retornoAPI;
                });

        },
        mounted() {

        },
    }

</script>

<style scoped>
    @import '/css/modais.css';


    @media (max-width:600px) {
        .textareacartao {
            background: transparent;
            color: #000000;
            resize: none;
            border: 0 none;
            width: 100%;
            outline: none;
            height: 270px;
            overflow: hidden;
            position: relative;
            padding: 10px;
            font-family: Handlee !important;
            font-size: 12px;
        }

        .imagemCartao {
            height: 270px;
            width: auto;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center top;
        }

        .imgcel {
            width: 90%;
        }
    }

    @media (min-width:608px) and (max-width:991px) {
        .textareacartao {
            background: transparent;
            color: #000000;
            resize: none;
            border: 0 none;
            width: 98%;
            outline: none;
            height: 450px;
            overflow: hidden;
            position: relative;
            padding: 10px;
            font-family: Handlee !important;
            font-size: 21px;
        }

        .imagemCartao {
            height: 450px;
            width: auto;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center top;
        }

        .imgcel {
            width: 80%;
        }
    }

    @media (min-width:992px) and (max-width:1199px) {
        .textareacartao {
            background: transparent;
            color: #000000;
            resize: none;
            border: 0 none;
            width: 90%;
            outline: none;
            height: 450px;
            overflow: hidden;
            position: relative;
            padding: 10px;
            font-family: Handlee !important;
            font-size: 21px;
        }

        .imagemCartao {
            height: 450px;
            width: auto;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center top;
        }

        .imgcel {
            width: 70%;
        }
    }

    @media (min-width:1200px) {
        .textareacartao {
            background: transparent;
            color: #000000;
            resize: none;
            border: 0 none;
            width: 90%;
            outline: none;
            height: 450px;
            overflow: hidden;
            position: relative;
            padding: 10px;
            font-family: Handlee !important;
            font-size: 21px;
        }

        .imagemCartao {
            height: 450px;
            width: auto;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center top;
        }

        .imgcel {
            width: 70%;
        }
    }
</style>
