<template>

    <section class="carrinho">

        <Header />
        <Fita class="hidden-sm" titulo="Presentes exclusivos para momentos especiais"></Fita>

        <section class="main-minha_conta">

            <!-- Selecione sua foto -->
            <Modal v-model="showModal_foto" title="">

                <section class="section-modal modal-foto mb-5">
                    <div class="container">
                        <div class="row">
                            <div class="col text-center">
                                <h4 class="titulo-modal mb-3">{{ TextosFotos[0].nome }}</h4>
                                <span style="display:none;">R$ {{ parseFloat(TextosFotos[0].valor1,10).toFixed(2).toString().replace('.',',') }}</span>
                                <div v-if="TextosDe==true" class="row" style="padding-top:40px;padding-bottom:40px;">
                                    <div class="text-center">
                                        <h5 class="subtitulo mb-0">{{ Titulos[0].nome }}</h5>
                                        <!--<p class="texto-mensagem">{{ Titulos[0].referencia }}</p>-->
                                    </div>
                                    <form class="px-md-5">
                                        <div class="form-group row">
                                            <div class="col-sm-4">
                                                <label for="inputEmail" class="col-sm-5 col-form-label login-label">De:</label>
                                                <input v-model="NomeDe" type="text" class="form-control" id="inputEmail">
                                                <div class="input-feedback">
                                                    <span>Nome da pessoa que está enviando</span>
                                                </div>
                                            </div>
                                            <div class="col-sm-4">
                                                <label for="inputName" class="col-sm-5 col-form-label login-label">Para:</label>
                                                <input v-model="NomePara" type="text" class="form-control" id="inputName">
                                                <div class="input-feedback">
                                                    <span>Nome da pessoa que vai receber</span>
                                                </div>
                                            </div>
                                            <div class="col-sm-4">
                                                <label for="inputwhats" class="col-sm-5 col-form-label login-label">Telefone:</label>
                                                <input v-model="TelefoneRecebe" type="text" class="form-control" id="inputwhats">
                                                <div class="input-feedback">
                                                    <span>Telefone da pessoa que vai receber</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="input-alert text-center">
                                            <span class="text-danger">{{ Titulos[0].campoExtra1 }}</span>
                                        </div>

                                    </form>
                                </div>

                                <p v-html="TextosFotos[0].resumo1"></p>
                                <input id="arquivo"
                                       type="file"
                                       class="" />
                                <button type="button"
                                        class="btn btn-success"
                                        @click="carregaFoto()"
                                        id="sa-custom-position">
                                    Salvar
                                </button>
                            </div>
                        </div>

                        <div class="row justify-content-center">
                            <div class="col-lg-8 col-xl-6 text-center">
                                <div class="editar-cartao mt-3" ref="content">
                                    <img v-if="FotoCarregada != null" :src="$Urllocal + FotoCarregada" class="img-fluid" />
                                </div>
                            </div>
                        </div>

                        <!-- Instruções -->
                        <div class="row justify-content-center">
                            <div class="col-lg-7 text-center mt-3">
                                <p v-html="TextosFotos[0].resumo2"></p>
                            </div>
                        </div>
                    </div>
                </section>

                <footer class="modal_footer_fixo">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-4 col-lg-6 mb-4 mb-md-0">
                                <div class="voltar d-flex align-items-center">

                                </div>
                            </div>

                            <div class="col-6 col-md-4 col-lg-3">

                            </div>

                            <div v-if="TextosDe==true" class="col-6 col-md-4 col-lg-3">
                                <a class="btn btn-card mt-0"
                                   @click="carregaDadosFoto()">
                                    {{ TextosFotos[0].textoBtn2 }}
                                </a>
                            </div>
                            <div v-else class="col-6 col-md-4 col-lg-3">
                                <a class="btn btn-card mt-0"
                                   @click="liberadepara()">
                                    {{ TextosFotos[0].textoBtn2 }}
                                </a>
                            </div>
                        </div>
                    </div>
                </footer>

            </Modal>

        </section>

        <FitaRodape class="ribbon-lg"></FitaRodape>
        <Footer />

    </section>

</template>

<script>

    import Header from '@/components/site/shared/Header.vue'
    import Fita from '@/components/site/shared/Fita.vue'
    import FitaRodape from '@/components/site/shared/FitaRodape.vue'
    import Footer from '@/components/site/shared/Footer.vue'
    import VueModal from '@kouts/vue-modal'


    export default {
        components: {
            Header,
            Fita,
            FitaRodape,
            Footer,
            'Modal': VueModal,
        },
        data() {
            return {
                TextosFotos: [],
                FotoCarregada: null,
                showModal_foto: true,
                FotoAvulsa: [],
                TextosDe: false,
                NomeDe: '',
                NomePara: '',
                TelefoneRecebe: '',
                Titulos: [],
            }
        },
        methods: {
            liberadepara() {
                if (this.FotoCarregada == null) {
                    this.$mensagem_normal("Você precisa adicionar foto.");
                }
                else {
                    this.TextosDe = true;
                }
            },
            carregaFoto() {
                let file = document.getElementById("arquivo").files[0];
                let erroarquivo = "";
                if (file != null) {
                    var ext = file.name.split(".").pop();

                    if (ext != "jpg" && ext != "png" && ext != "gif" && ext != "jpeg") {
                        erroarquivo =
                            "Por favor selecione uma imagem ('.jpg', '.jpeg', '.png' ou '.gif')";
                    } else {
                        erroarquivo = "";
                    }
                }
                if (erroarquivo != null && erroarquivo.length > 0) {
                    this.$mensagem_normal(erroarquivo);
                }
                else {
                    let data = new FormData();
                    if (file != null) {
                        data.append("arquivo", file);
                        this.$http.post(this.$apiUrl + "/cadoperadora/upload", data).then(
                            (response3) => {
                                // get body data
                                this.FotoCarregada = response3.body;
                            },
                            (response3) => {
                                // error callback
                                this.$mensagem_erro(response3.body);
                            }
                        );
                    }
                }
            },
            carregaDadosFoto() {
                if (this.FotoCarregada == null) {
                    this.$mensagem_normal("Você precisa adicionar foto.");
                }
                else if (this.NomeDe == '') {
                    this.$mensagem_normal("Por favor preencha o nome da pessoa que está enviando");
                } else if (this.NomePara == '') {
                    this.$mensagem_normal("Por favor preencha o nome da pessoa que vai receber");
                }
                else {
                    let _FotoAvulsa = {
                        FotoAvulsaId: 0,
                        UrlImagem: this.FotoCarregada,
                        NomeDe: this.NomeDe,
                        NomePara: this.NomePara,
                        TelefoneRecebe: this.TelefoneRecebe,
                    }
                    this.$http
                        .post(this.$apiUrl + "/fotoavulsa", _FotoAvulsa)
                        .then(
                            response2 => {
                                // get body data
                                this.FotoAvulsa = response2.body;
                                this.$redirect("/fotoavulsafinalizada");
                            });
                }

            },
        },
        created() {
            //carrega titulos textos fotos
            this.$http
                .get(this.$apiUrl + "/institucional/telas/textosfotos")
                .then((res2) => res2.json())
                .then((tiu2) => {
                    this.TextosFotos = tiu2;
                });

            //----------------- Titulos planos
            this.$http
                .get(this.$apiUrl + "/institucional/telas/textoinformacao")
                .then((res) => res.json())
                .then((retornoAPI) => {
                    this.Titulos = retornoAPI;
                });

        },
        mounted() {

        },
    }

</script>

<style scoped>
    @import '/css/modais.css';


    @media (max-width:600px) {
        .textareacartao {
            background: transparent;
            color: #000000;
            resize: none;
            border: 0 none;
            width: 100%;
            outline: none;
            height: 270px;
            overflow: hidden;
            position: relative;
            padding: 10px;
            font-family: Dancing Script !important;
            font-size: 12px;
        }

        .imagemCartao {
            height: 270px;
            width: auto;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center top;
        }

        .imgcel {
            width: 90%;
        }
    }

    @media (min-width:608px) and (max-width:991px) {
        .textareacartao {
            background: transparent;
            color: #000000;
            resize: none;
            border: 0 none;
            width: 98%;
            outline: none;
            height: 450px;
            overflow: hidden;
            position: relative;
            padding: 10px;
            font-family: Dancing Script !important;
            font-size: 21px;
        }

        .imagemCartao {
            height: 450px;
            width: auto;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center top;
        }

        .imgcel {
            width: 80%;
        }
    }

    @media (min-width:992px) and (max-width:1199px) {
        .textareacartao {
            background: transparent;
            color: #000000;
            resize: none;
            border: 0 none;
            width: 90%;
            outline: none;
            height: 450px;
            overflow: hidden;
            position: relative;
            padding: 10px;
            font-family: Dancing Script !important;
            font-size: 21px;
        }

        .imagemCartao {
            height: 450px;
            width: auto;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center top;
        }

        .imgcel {
            width: 70%;
        }
    }

    @media (min-width:1200px) {
        .textareacartao {
            background: transparent;
            color: #000000;
            resize: none;
            border: 0 none;
            width: 90%;
            outline: none;
            height: 450px;
            overflow: hidden;
            position: relative;
            padding: 10px;
            font-family: Dancing Script !important;
            font-size: 21px;
        }

        .imagemCartao {
            height: 450px;
            width: auto;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center top;
        }

        .imgcel {
            width: 70%;
        }
    }
</style>
