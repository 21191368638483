<template>
    <div>

        <div class="mb-4 px-md-4">

            <div class="agendamento">

                <div class="row">
                    <div class="col-sm-7 offset-sm-5" style="text-align:center;">
                        <img class="img-fluid" :src="$Url + ListaPagamentoBanco[0].cadPix.urlImg" style="width:200px;height:auto;" alt="">
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-7 offset-sm-5">
                        <p v-html="ListaPagamentoBanco[0].dadosChave" class="texto-strong d-inline-block mb-0 mr-2">
                        </p>
                        <p class="texto-destaque mt-4">Valor Total da compra R$ {{ parseFloat(TotalProdutos).toFixed(2).toString().replace('.', ',') }}</p>
                    </div>
                </div>
                <row>
                    <div class="col-sm-7 offset-sm-5 text-center">
                        <button v-if="btnFinaliza==true" @click="ComprovanteEfetuado()" class="btn btn-success w-100">Visualizar CHAVE PIX</button>
                    </div>
                </row>
            </div>
        </div>
        <hr>
    </div>

</template>

<script>


    export default {
        components: {

        },
        props: {
            PixId: {
                type: Number,
                required: true,
                default: 0
            }
        },
        data() {
            return {
                TotalProdutos: 0,
                showcomprovante: false,
                btnFinaliza: true,
                ListaPagamentoBanco: [],
                textocopiado: '',
                mostra: true,
                FotoCarregada: '',
            };
        },

        methods: {
            ComprovanteEfetuado() {
                let dadosArmazenados = localStorage.getItem('dadoscompralove');
                dadosArmazenados = JSON.parse(dadosArmazenados);
                dadosArmazenados.pagamento = []
                dadosArmazenados.pagamento.push({
                    FormaPgto: "Pix",
                    TipoPgto: 'pix',
                    CodId: this.PixId,
                    ValorTotal: this.TotalProdutos,
                    FotoComprova: this.FotoCarregada,
                });
                localStorage.setItem('dadoscompralove', JSON.stringify(dadosArmazenados))
                this.$redirect("/pedidoRealizado");
            },
        },
        created() {

            //carrega informações da lista de opções de pagamento
            this.$http
                .get(this.$apiUrl + "/configpix")
                .then((res2) => res2.json())
                .then((tiu2) => {
                    this.ListaPagamentoBanco = tiu2.filter(x => x.status && x.cadPix.cadPixId == this.PixId)
                });

            let dadosArmazenados = localStorage.getItem('dadoscompralove');
            dadosArmazenados = JSON.parse(dadosArmazenados);

            let _totalProdutos = 0;
            let _cupom = 0;
            if (dadosArmazenados.produtos != '') {
                dadosArmazenados.produtos.forEach((itemprod) => {
                    _totalProdutos += parseFloat(itemprod.produto.valorAte)
                });
            }

            if (dadosArmazenados.avulsos != '') {
                if (dadosArmazenados.avulsos[0].produtoAvulsoId != 0) {
                    dadosArmazenados.avulsos.forEach((itemavul) => {
                        _totalProdutos += parseFloat(itemavul.avulso.valorDe)
                    });
                }
            }

              if (dadosArmazenados.baloes != '') {
                if (dadosArmazenados.baloes[0].avulso != 'Sem Balao') {
                    dadosArmazenados.baloes.forEach((itemavul) => {
                        _totalProdutos += parseFloat(itemavul.avulso.valorDe)
                    });
                }
            }

            if (dadosArmazenados.foto != '') {
                if (dadosArmazenados.foto[0].imagemUrl != "Sem foto") {
                    dadosArmazenados.foto.forEach((itemfoto) => {
                        _totalProdutos += parseFloat(itemfoto.valor)
                    });
                }
            }

            if (dadosArmazenados.agenda != '') {
                if (dadosArmazenados.agenda[0].periodoEntrega != '') {
                    _totalProdutos += parseFloat(dadosArmazenados.agenda[0].periodoEntrega.valorFrete)

                }
            }

            if (dadosArmazenados.cupom != '') {
                _cupom = parseFloat(dadosArmazenados.cupom[0].ValorCupom, 10)
            }

            this.TotalProdutos = _totalProdutos - _cupom;
        },
        computed: {
        },
    };
</script>