<template>

<section class="alturaheader">
      
      <header class="header vertical-alignment" style="max-height:95px;">
          <div class="container-fluid">
              <div class="row">
                  <!-- Pesquisar -->
                  <div class="set-order-1 vertical-alignment col-3 col-lg-3 col-xl">
                      <div class="input-group input-search" style="padding-bottom: 10px;">
                          <label class="sr-only" for="#">Buscar</label>
                          <input v-model="textobusca" type="text" class="form-search hidden-sm"
                                 placeholder="BUSCAR" style="width: 70%;">

                          <i @keyup.enter="ValidaBusca()" @click="ValidaBusca()" class="fas fa-search icon-search hidden-sm hidden-xs"></i>
                          <i @click="MostraCaixa()" style="margin-top: 5px;" class="fas fa-search icon-search hidden-xl hidden-lg hidden-md"></i>
                          <div ref="divBusca" v-show="toggle" style="position: absolute;top: 26px;left: -11px;width: 100vw;background: #0000003b;padding: 6px;z-index: 9999999999;border-radius:5px;">
                              <input @keyup.enter="ValidaBusca()" style="width: 90%;float: left;" class="form-control form-search hidden-md hidden-lg"
                                 type="text" placeholder="BUSCAR" v-model="textobusca">
                                 <i @keyup.enter="ValidaBusca()" @click="ValidaBusca()" style="float: left;right: 0px;top: 9px;left: 3%;color:white;" class="fas fa-search icon-search hidden-md hidden-lg"></i>
                          </div>
                          

                      </div>
                  </div>

                  <!-- Menu -->
                  <div class="set-order-3 vertical-alignment col-6 col-lg-12 col-xl-7 text-center">
                      <router-link to="/">
                          <img class="img-fluid tamlogo" src="/img/01.png" alt="Logo Love Cestas">
                      </router-link>
                  </div>

                  <!-- Entrar + Carrinho -->
                  <div class="set-order-2 vertical-alignment col-3 col-lg-3 offset-lg-6 col-xl offset-xl-0">
                      <div class="login">

                          <!-- Only Desktop -->
                          <div class="hidden-sm-md divisor">
                              <i class="fas fa-user icon-login"></i>
                              <router-link to="/login">Entrar</router-link>
                          </div>

                          <div>
                              <router-link to="/carrinho">
                                  <!--<i class="fas fa-shopping-cart icon-cart"></i>-->
                                  <img class="carrinho-img img-fluid" src="/img/cart.png" alt=""> 
                              </router-link>

                              <!-- Only Desktop -->
                              <router-link class="cart hidden-sm-md" to="/carrinho">Carrinho</router-link>
                              <!--<div class="cart-number hidden-sm-md">0</div>-->
                              <!-- Only Mobile -->
                              <a :href="WhatIcone.linkBtn1" target="_blank">
                                  <img class="whats-img hidden-lg hidden-xl" src="/img/cart/icon_whats.png" alt="">
                                  <!-- <i class="fab fa-whatsapp icon-whats text-success hidden-lg hidden-xl"></i> -->
                              </a>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="row">
                  <div class="container-fluid text-center hidden-sm-md">
                      <div class="row">
                          <b-nav class="menu-nav menu-desktop" style="justify-content: center;">
                              <div v-for="segmento in Segmentos" :key="segmento.segmentoProdNivel1Id">
                                  <b-nav-item v-if="ValidaSegmento2(segmento.segmentoProdNivel1Id) == ''"
                                      @click="$redirect_reload('/produtos/'  + segmento.segmentoProdNivel1Id + '/' + '0' + '/' + segmento.nomeSegmento.replaceAll(' ','-').replaceAll('/',''))"
                                      style="padding-top: 0px !important;">
                                      <a class="menu-link" style="margin-top: -7px;">{{ segmento.nomeSegmento}}</a>
                                  </b-nav-item>
                                  <b-nav-item-dropdown v-else id="my-nav-dropdown" :text="segmento.nomeSegmento"
                                      toggle-class="menu-link nav-link-custom" right>
                                      <b-dropdown-item v-for="segmento2 in ValidaSegmento2(segmento.segmentoProdNivel1Id)"
                                          :key="segmento2.segmentoProdNivel2Id"
                                          @click="$redirect_reload('/produtos/'  + segmento.segmentoProdNivel1Id + '/' + segmento2.segmentoProdNivel2Id + '/' + segmento.nomeSegmento.replaceAll(' ','-').replaceAll('/',''))">
                                          <a class="menu-link">{{segmento2.nomeSegmento}}</a>
                                      </b-dropdown-item>
                                  </b-nav-item-dropdown>
                              </div>
                              <b-nav-item v-if="corporativo!='' && corporativo[0].visivel == true"
                                      @click="$redirect_reload('/corporativo')"
                                      style="padding-top: 0px !important;">
                                      <a class="menu-link" style="margin-top: -7px;color:#000000 !important;">Corporativo</a>
                                  </b-nav-item>
                          </b-nav>
                          <!-- <ul class="menu-nav menu-desktop ">
                              <li v-for="segmento in Segmentos" :key="segmento.segmentoProdNivel1Id">
                               
                                  <a @click="$redirect_reload('/produtos/'  + segmento.segmentoProdNivel1Id + '/' + '0' + '/' + segmento.nomeSegmento.replaceAll(' ','-').replaceAll('/',''))"
                                      class="menu-link">{{ segmento.nomeSegmento}}</a>
                              </li>
                          </ul> -->
                      </div>
                  </div>
              </div>
          </div>

          <!-- Controllers (NÃO DELETAR) -->
          <input class="d-none" type="checkbox" id="control-nav" />
          <label for="control-nav" class="control-nav"></label>
          <label for="control-nav" class="control-nav-close"></label>
          <!--  -->

          <nav class=" menu-nav-responsive">

              <!-- Pesquisar Mobile -->
              <!-- <div class="input-group input-search mb-5">
            <label class="sr-only" for="#">Buscar</label>
            <input type="text" class="form-search" placeholder="BUSCAR"
            onfocus="this.placeholder=''"
            onblur="this.placeholder='BUSCAR'">
            <i class="fas fa-search icon-search"></i>
          </div> -->
              <!--/produtos/:id/:idcategoria/:segmento/:nome-->
              <div class="mb-5">
                  <router-link to="/">
                      <img class="img-fluid" src="/img/01.png" alt="Logo Love Cestas">
                  </router-link>
              </div>

              <!-- Menu Mobile segmento 1 -->
              <b-nav class="menu-nav menu-desktop" style="justify-content: left;">
                  <div v-for="segmento in Segmentos" :key="segmento.segmentoProdNivel1Id">
                      <b-nav-item v-if="ValidaSegmento2(segmento.segmentoProdNivel1Id) == ''"
                          @click="$redirect_reload('/produtos/'  + segmento.segmentoProdNivel1Id + '/' + '0' + '/' + segmento.nomeSegmento.replaceAll(' ','-').replaceAll('/',''))"
                          style="padding-top: 0px !important;">
                          <a class="menu-link" style="padding-top: 0px !important;">{{ segmento.nomeSegmento}}</a>
                      </b-nav-item>
                      <b-nav-item-dropdown v-else id="my-nav-dropdown" :text="segmento.nomeSegmento"
                          toggle-class="menu-link nav-link-custom" right>
                          <b-dropdown-item v-for="segmento2 in ValidaSegmento2(segmento.segmentoProdNivel1Id)"
                              :key="segmento2.segmentoProdNivel2Id"
                              @click="$redirect_reload('/produtos/'  + segmento.segmentoProdNivel1Id + '/' + segmento2.segmentoProdNivel2Id + '/' + segmento.nomeSegmento.replaceAll(' ','-').replaceAll('/',''))">
                              <a class="menu-link">{{segmento2.nomeSegmento}}</a>
                          </b-dropdown-item>
                      </b-nav-item-dropdown>
                  </div>
                  <b-nav-item v-if="corporativo!='' && corporativo[0].visivel == true"
                                      @click="$redirect_reload('/corporativo')"
                                      style="padding-top: 0px !important;">
                                      <a class="menu-link" style="margin-top: -7px;color:#000000 !important;">Corporativo</a>
                                  </b-nav-item>
              </b-nav>
             <!--  <ul class="menu-nav">
                  <li v-for="segmento in Segmentos" :key="segmento.segmentoProdNivel1Id">
                      <a class="menu-link" @click="$redirect_reload('/produtos/' + segmento.segmentoProdNivel1Id + '/' + '0' + '/' + segmento.nomeSegmento.replaceAll(' ','-').replaceAll('/',''))">{{ segmento.nomeSegmento}}</a>
                  </li>
              </ul> -->
              <!-- Menu Mobile segmento 2 -->
              <ul v-if="SegmentoId!=0" class="menu-nav">
                  <li v-for="segmento2 in Segmentos2" :key="segmento2.segmentoProdNivel2Id">
                      <a class="menu-link" @click="$redirect_reload('/produtos/'  + segmento2.segmentoProdNivel1.segmentoProdNivel1Id + '/' + segmento2.segmentoProdNivel2Id + '/' + segmento2.segmentoProdNivel1.nomeSegmento.replaceAll(' ','-').replaceAll('/','') + '/' + segmento2.nomeSegmento.replaceAll(' ','-').replaceAll('/',''))">{{ segmento2.nomeSegmento}}</a>
                  </li>
              </ul>

              <!-- Apenas Mobile -->
              <div class="hidden-lg hidden-xl">
                  <div v-for="What in Whats" :key="What.institucionalId">
                      <img src="/img/social/whatsapp.png" alt="">
                      <a class="menu-link menu-link-whatsapp" :href="What.linkBtn1" target="_blank">{{ What.nome }}</a>
                  </div>
              </div>
              <!--  -->

          </nav>
          
      </header>
          <div v-if="WhatsFlutuante[0].visivel" class="whatsapp-fixo">
          <a :href="WhatIcone.linkBtn1" target="_blank">
              <img src="/images/atendimentowats.png"  alt="Fale Conosco pelo WhatsApp" />
          </a>
          </div>
  </section>

</template>

<script>
    var coll = document.getElementsByClassName("collapsible");
    var i;

    for (i = 0; i < coll.length; i++) {
        coll[i].addEventListener("click", function () {
            this.classList.toggle("active");
            var content = this.nextElementSibling;
            if (content.style.display === "block") {
                content.style.display = "none";
            } else {
                content.style.display = "block";
            }
        });
    }
    

    export default {
        data() {
            return {
                Whats: [],
                What: {},
                WhatIcone: {},
                Segmentos: [],
                segmento: {},
                Segmentos2: [],
                TodosSegmentos2:[],
                segmento2: {},
                toggle: false,
                textobusca: '',
                WhatsFlutuante:[],
                corporativo:[],
            };
        },
        props: {
            SegmentoId: {
                type: Number,
                required: true,
                default: 0
            }
        },
        methods: {
            ValidaSegmento2(value){
              var result = this.TodosSegmentos2.filter(x => x.segmentoProdNivel1!=null && x.segmentoProdNivel1.segmentoProdNivel1Id == value)
              return result.sort(function (a, b) {
                        if (a.ordem > b.ordem) {
                            return 1;
                        }
                        if (a.ordem < b.ordem) {
                            return -1;
                        }
                        return 0;
                    });
            },
            ValidaBusca() {
                if (this.textobusca != null && this.textobusca.length > 0) {
                    this.$redirect_reload("/produtosbusca/" + this.textobusca);
                }
                else {
                    this.$mensagem_normal("Digite uma palavra-chave e clique na lupa para pesquisar.");
                }

            },
            MostraCaixa(){
                this.toggle = !this.toggle;
            }
        },
        created() {

            this.$http
                .get(this.$apiUrl + "/institucional/telas/Lista%20Whats")
                .then((res2) => res2.json())
                .then((tiu2) => {
                    this.Whats = tiu2;
                    this.Whats = this.Whats.filter(x => x.visivel == true);
                   let num = Math.floor(Math.random() * this.Whats.length);
                    this.WhatIcone = this.Whats[num];
                });

              this.$http
                .get(this.$apiUrl + "/institucional/telas/Whats%20Flutuante")
                .then((res2) => res2.json())
                .then((tiu2) => {
                    this.WhatsFlutuante = tiu2;
                });

            if (this.SegmentoId != 0) {
                this.$http
                    .get(this.$apiUrl + "/SegmentoProdNivel2/Nivel1/" + parseInt(this.SegmentoId, 10))
                    .then((res2) => res2.json())
                    .then((tiu2) => {
                        this.Segmentos2 = tiu2.filter(x => x.visivel).sort(function (a, b) {
                            return a.ordem - b.ordem;
                        });
                    });
            }

                this.$http
                    .get(this.$apiUrl + "/SegmentoProdNivel1")
                    .then((res2) => res2.json())
                    .then((tiu2) => {
                        this.Segmentos = tiu2.filter(x => x.visivel).sort(function (a, b) {
                            return a.ordem - b.ordem;
                        });
                    });
            
                this.$http
                .get(this.$apiUrl + "/SegmentoProdNivel2")
                .then((res2) => res2.json())
                .then((tiu2) => {
                    this.TodosSegmentos2 = tiu2.filter(x => x.visivel)
                });

                this.$http
                .get(this.$apiUrl + "/institucional/telas/corporativotopo")
                .then((res2) => res2.json())
                .then((tiu2) => {
                    this.corporativo = tiu2;
                });
           
        },
        mounted() {

        },
        components: {
            
        },
    };
</script>

<style scoped>

    @import '/css/header.css';

      /* WHATSAPP FIXO */
  .whatsapp-fixo {
      position: fixed;
      bottom: 35%;
      right: 0px;
      z-index: 99999999;
  }

  .nav-link {
    display: block;
    padding: 0.5rem 0.6rem !important;
    color: #0d6efd;
    text-decoration: none;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}

.alturaheader{
    height: 100px;
}

@media (max-width:360px) {
    .nav {
    display: block;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}
}

@media (max-width:767px) {
    .alturaheader{
    height: 55px !important;
}
.nav {
    display: block;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}

}

@media (max-width:1368px) {
    .alturaheader{
    height: 100px;
}
}

@media (min-width:1190px) {
    .alturaheader{
    height: 100px !important;
}
.menu-desktop li{
  display: inline-block;
  padding: 4px 2px !important;
}

}

.dropdown-menu {
    position: absolute;
    z-index: 1000;
    display: none;
    min-width: 10rem;
    padding: .5rem 0;
    margin: 0;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 0px solid rgba(0,0,0,.15) !important;
    border-radius: .25rem;
}
</style>