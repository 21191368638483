<template>
    <div id="app">

        <Topbar v-if="checkTela()" class="hidden-sm" />

        <transition name="fadeIn">
            <router-view></router-view>
        </transition>

    </div>
</template>

<script>
    import Topbar from './components/site/shared/Topbar.vue'
  export default {
        name: 'App',
        metaInfo: {
            htmlAttrs: {
                lang: 'pt-br'
            },
            meta: [
                { charset: 'utf-8' },
                { name: 'viewport', content: 'width=device-width,initial-scale=1.0' }
            ]
        },
        data() {
            return {
                Whats: [],
                What: {},
            };
        },
        created() {
              this.$http
                .get(this.$apiUrl + "/institucional/telas/Lista%20Whats")
                .then((res2) => res2.json())
                .then((tiu2) => {
                    this.Whats = tiu2.filter(x => x.visivel == true);
                });
          },
        methods: {
            checkTela() {
                if (this.$route.fullPath == '/pageprodutosxml' || this.$route.fullPath == '/pageprodutosxml2') {
                    return false;
                }
                else {
                    return true;
                }
            }
        },
    components: {
      // Header,
        Topbar,
    },
  }
</script>

<style>

  @import '/css/reset.css';
  @import '/css/responsive.css';



  .vm {
    width: 100% !important;
  }

  .section_spacement-50-0 {
    margin: 50px 0;
  }

  .mr-10 {
    margin-right: 10px;
  }

  a.btn-card, 
  button.btn-card {
    background-color: #66d136;
    color: #f9f9f9;
    font-weight: 400;
    margin-top: 20px;
  }

  a.btn-card:hover,
  button.btn-card:hover {
    background-color: #59c229;
    color: #f9f9f9;
    font-weight: 400;
  }

  a.btn-outline-card, 
  button.btn-outline-card {
    background-color: transparent;
    border: 1px solid #66d136;
    color: #66d136;
    font-weight: 400;
    margin-top: 20px;
  }

  a.btn-outline-card:hover,
  button.btn-outline-card:hover {
    background-color: #59c229;
    color: #f9f9f9;
    font-weight: 400;
  }

  a.btn-voltar {
    color: #666;
    text-decoration: underline !important;
  }

  a.btn-voltar:hover {
    color: #999;
    text-decoration: none !important;
  }

  /* ### Animations ### */
  .fadeIn-enter {
    opacity: 0;
  }
  .fadeIn-enter-active {
    transition: opacity .8s;
  }
  .fadeIn-enter-to {
  opacity: 1;
  }
  /* ###### */


  /* ---------- Menu (Responsividade) ---------- */
  header {
  min-height: 50px;
  /* position: fixed;
  top: 40px;
  right: 0;
  left: 0; */
  background: #fff;
  z-index: 2;
  }

  #control-nav:checked ~ .control-nav-close {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
  }

  #control-nav:checked ~ nav {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    transform: translate(0, 0);
  }

  header .control-nav:before {
    content: "";
    display: block;
    height: 2px;
    background: #999;
  }

  /* Sombra do menu resp */
  header .control-nav-close {
    position: fixed;
    right: 0;
    top: 0;
    bottom: 0;
    left: 0;
    display: block;
    z-index: 1;
    /* background: rgba(0,0,0,0.4); */
    -webkit-transition: all 500ms ease;
    transition: all 500ms ease;
    -webkit-transform: translate(100%, 0);
    -ms-transform: translate(100%, 0);
    transform: translate(100%, 0);
  }

  header nav {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    width: 290px;
    border-left: 1px solid #ccc;
    background: #fff;
    overflow-x: auto;
    z-index: 2;
    -webkit-transition: all 500ms ease;
    transition: all 500ms ease;
    -webkit-transform: translate(100%, 0);
    -ms-transform: translate(100%, 0);
    transform: translate(100%, 0);
  }

  /* Smartphones */
  @media (max-width:767px) {
    header .control-nav {
      left: 14px;
      top: 16px;
    }
  }

  /* Tablets */
  @media (min-width:768px) and (max-width:991px) {
    header .control-nav {
      right: 20px;
      top: 11px;
    }
  }

  @media screen and (max-width: 991px) {
    header .control-nav {
      position: absolute;      
      display: block;
      width: 18px;
      padding: 5px 0;
      border: solid #999;
      border-width: 2px 0;
      z-index: 2;
      cursor: pointer;
    }
    /* ---------- Fim Menu (Responsividade) ---------- */

  }

  body {
    font-family: 'MyriadPro-Light' !important;
  } 
  
     .ql-size-large{
    font-size: 25px;
  }

  .ql-size-huge{
    font-size: 45px;
  }

  .ql-size-small{
     font-size: 12px;
  }

  .ql-align-justify{
    text-align: justify;
  }
    
</style>
