<template>

    <section class="produto-detalhe">

        <Header />

        <Fita class="hidden-sm-md hidden-xs" />

        <!-- Principal -->
        <div class="my-2">
            <div class="container">
                <div class="row">

                    <div class="col-md-6 col-xl-5">
                        <div class="detalhe-img mb-4 mb-xl-0">
                            <figure id="container2" style="z-index:1;">
                                <lingallery :iid.sync="currentId" :addons="{ enableLargeView: true }" :width="400" :height="350" :items="ImgProduto" />
                                <!--<img v-if="ModeloBalao!=''" class="img-fluid" :src="ImgProduto[0].src" alt="">-->
                                <figcaption style="text-align: center; width: 100%;">
                                    <span :style="{fontFamily : pfocused_font, color: pfocused_cor, width : '100%'}">{{ plinha1 }}</span>
                                    <br />
                                    <span :style="{fontFamily : pfocused_font2, color: pfocused_cor, width : '100%'}">{{ plinha2 }}</span>
                                    <br />
                                    <span :style="{fontFamily : pfocused_font3, color: pfocused_cor, width : '100%'}">{{ plinha3 }}</span>
                                </figcaption>
                            </figure>

                        </div>
                    </div>

                    <div class="col-md-6 col-xl-7">
                        <div class="detalhe-content">
                            <div class="row" style="text-align:center;padding-bottom: 20px;">
                                    <div class="col-md-6 col-lg-4" v-for="BannerOpcionais in BannersOpcionais"
                                        :key="BannerOpcionais.institucionalId" style="padding: 0px !important;">
                                        <div v-if="VerificaExisteOpcional(BannerOpcionais.institucionalId)"
                                            :style="Indisponivel == true ? 'pointer-events:none':''"
                                            class="navbar_detalhes-item navbar_item-3 text-center">
                                            <a class="navbar_detalhes-link" @click="carregaModalOpcional(BannerOpcionais.institucionalId)">
                                                <div class="text-center">
                                                    <img v-if="BannerOpcionais.institucionalImg[0] != null" class="imagem-modal img-fluid"
                                                        :src="$Url + BannerOpcionais.institucionalImg[0].urlImagem" alt=""
                                                        style="border-radius: 10px;padding-bottom:5px;width:90%;">
                                                    <img v-else :src="$Url + '/imagens/indisponivel.jpg'" class="imagem-modal img-fluid" />
                                                </div>
                                            </a>
                                            <!--<a class="" @click="carregaModalOpcional(BannerOpcionais.institucionalId)" :style="{color: BannerOpcionais.cor1 + '!important', }">
                                                                            <span style="color: #333; font-family: 'BELLGOTHIC'; font-size: 20px; font-weight: 200;"> {{ BannerOpcionais.nome }}</span>
                                                                        </a>-->
                                        </div>
                                    </div>
                                </div>
                            <h4 class="titulo_detalhes mb-2" style="text-align: center;">
                                {{ Produto.nomeProduto }}
                            </h4>
                            <div v-if="UrlimgBannerGeral != null && pshowcustomizado==false" class="text-center my-2">
                                <img class="img-fluid" :src="$Url + UrlimgBannerGeral" @click="CarregaModal(0)" alt="">
                            </div>
                            <div v-else></div>

                            <form v-if="pshowcustomizado==true" action="#" class="mb-2">
                                <div v-if="qtdeLinhas>=1" class="row">
                                    <div class="col-7 col-xl-7">
                                        <h6 class="subtitulo_detalhes mb-3">Mensagem</h6>
                                        <input v-model="plinha1" type="text" class="form-control mb-2" placeholder="Max 20 Caracteres" maxlength="20" style="width: 100%;">
                                    </div>
                                    <div class="col-5 col-xl-5">
                                        <h6 class="subtitulo_detalhes mb-3">Letra</h6>
                                        <select v-model="pfocused_font" class="mb-2" style="width: 100%; height: 32px; border: solid 1px #cccccc; border-radius: 3px;">
                                            <option value="" disabled selected>Font</option>
                                            <option v-for="pfont in pavailable_fonts" :key="pfont" v-bind:value="pfont.nome" v-bind:style="{fontFamily : pfont.nome}">{{ pfont.nome }}</option>
                                        </select>
                                    </div>
                                    <!--<div class="col-4 col-xl-3">
                                        <h6 class="subtitulo_detalhes mb-3">Cor</h6>
                                        <input v-model="pfocused_cor" type="color" class="form-control mb-2" style="width: 50px; height: 32px;">
                                    </div>-->
                                </div>
                                <div v-if="qtdeLinhas>=2" class="row">
                                    <div class="col-7 col-xl-7">
                                        <input v-model="plinha2" type="text" class="form-control mb-2" placeholder="Max 20 Caracteres" maxlength="20" style="width: 100%;">
                                    </div>
                                    <div class="col-5 col-xl-5">
                                        <select v-model="pfocused_font2" class="mb-2" style="width: 100%; height: 32px; border: solid 1px #cccccc; border-radius: 3px;">
                                            <option value="" disabled selected>Font</option>
                                            <option v-for="pfont in pavailable_fonts" :key="pfont" v-bind:value="pfont.nome" v-bind:style="{fontFamily : pfont.nome}">{{ pfont.nome }}</option>
                                        </select>
                                    </div>
                                    <!--<div class="col-4 col-xl-3">
                                        <input v-model="pfocused_cor2" type="color" class="form-control mb-2" style="width: 50px; height: 32px;">
                                    </div>-->
                                </div>
                                <div v-if="qtdeLinhas==3" class="row">
                                    <div class="col-7 col-xl-7">
                                        <input v-model="plinha3" type="text" class="form-control mb-2" placeholder="Max 20 Caracteres" maxlength="20" style="width: 100%;">
                                    </div>
                                    <div class="col-5 col-xl-5">
                                        <select v-model="pfocused_font3" class="mb-2" style="width: 100%; height: 32px; border: solid 1px #cccccc; border-radius: 3px;">
                                            <option value="" disabled selected>Font</option>
                                            <option v-for="pfont in pavailable_fonts" :key="pfont" v-bind:value="pfont.nome" v-bind:style="{fontFamily : pfont.nome}">{{ pfont.nome }}</option>
                                        </select>
                                    </div>
                                    <!--<div class="col-4 col-xl-3">
                                        <input v-model="pfocused_cor3" type="color" class="form-control mb-2" style="width: 50px; height: 32px;">
                                    </div>-->
                                </div>
                                <div class="row" style="padding-left:10px;">
                                    <!--Cores-->
                                    <div class="mt-4">
                                        <h6 class="subtitulo_detalhes mb-3">Escolha a cor das letras</h6>
                                        <div class="row">
                                            <div v-for="(pFitroCor,index) in FitroCores" :key="index" class="col-2">
                                                <a @click="pfocused_cor=pFitroCor.codigoCor,pFiltroCornome=pFitroCor.nomeCor">
                                                    <div class="color" :style="{backgroundColor : pFitroCor.codigoCor}"></div>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            <span class="texto_detalhe" v-html="Produto.resuno">
                            </span>
                            <h5 v-if="ProdutosIncluso != ''" class="subtitulo_detalhes mt-4" style="font-weight:800;">Itens da cesta</h5>
                            <ul style="width: 100%;font-size: 14px;">
                                <li v-for="ProdutoIncluso in ProdutosIncluso" :key="ProdutoIncluso.produtoInclusoId" style="display: inline-block;width:50%;">
                                    <p class="texto_detalhe">
                                        - {{ ProdutoIncluso.nomeProdutoIncluso }}
                                    </p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Navbar Detalhes desktop v-if="Produto.indisponivel!=true && barraVisivel" -->
        <div class="navbar_detalhes">
            <div class="container">
                <div class="row justify-content-center mb-1 mt-1">
                      <div class="col-8">
                          <h5 style="margin-bottom: 0.1rem;"><strong>{{ Produto.nomeProduto }}</strong></h5>
                          <span class="tamvalor" style="font-weight: 600; padding-right: 10px;"> R${{ parseFloat(Produto.valorAte,10).toFixed(2).toString().replace('.',',') }}</span>
                          <span class="tamvalor2" style="font-weight: 600;">Em até <span class="texto-destaque">{{ parcelas }} x</span></span>
                         <span class="tamvalo2" style="font-weight: 600;"> de</span><span class="texto-destaque"> R$ {{ parseFloat(Produto.valorAte/parcelas,10).toFixed(2).toString().replace('.',',') }}</span><span class="texto-destaque"> {{ parcelasTexto }} </span>
                    </div>
                    <div class="col-4" style="text-align: center;">
                        <a v-if="Produto.indisponivel!=true && btnFinal==false" class="btn btn-card" style="width: 100%;height: 40px;font-size: 20px;font-weight: 600;margin-top: 10px"
                            @click="CarregaModal(0)">
                            Comprar
                        </a>
                        <a v-if="Produto.indisponivel == true" class="texto-destaque" style="margin-top: 5px;">Indispon&iacute;vel</a>
                        <a v-if="btnFinal==true" class="btn btn-card vfinal" style="width: 100%;height: 40px;font-size: 20px;font-weight: 600;margin-top: 10px"
                            @click="CarregaFinal()">
                            Comprar
                        </a>
                    </div>
                 </div>
            </div>
        </div>
        <!-- Modal Calendário -->
        <Modal id="vModal" v-model="showModal_data">
            <section class="section-modal">
                <div class="container">
                    <div class="row">
                        <div class="col text-center">
                            <h4 class="titulo-modal" style="font-family: 'Back to Black'; font-weight: 800; color: #cf083f; font-size: 35px; ">{{ TextoModalCalendario.nome }}</h4>
                        </div>
                    </div>
                    <div class="row hidden-sm">
                        <div class="col-md-6 cabecalho first text-center pl-0">
                            <h6 class="subtitulo-modal" style="font-weight:800;">{{ TextoModalCalendario.campoExtra1  }}</h6>
                            <p class="texto-modal">
                                {{ currentDateTime(date)  }}
                            </p>
                        </div>
                        <div class="col-md-6 cabecalho second text-center pr-0">
                            <h6 class="subtitulo-modal" style="font-weight:800;">{{ TextoModalCalendario.campoExtra2 }}</h6>
                            <p class="texto-modal">{{ periodo.texto }}</p>
                        </div>
                    </div>

                    <div class="row py-3">

                        <!-- Formulário (Endereço) -->
                        <div class="col-lg mb-4 mb-lg-0">
                            <form action="#">
                                <!--<div class="input-group mb-2">
                                    <input v-model="nrocep" type="text" autocomplete="off" class="form-control" placeholder="CEP">
                                    <div class="input-group-append">
                                        <a class="input-group-text" @click="Buscacep(nrocep)">Verificar</a>
                                    </div>
                                </div>
                                <div class="text-center my-2"><h6 class="subtitulo-modal">Ou</h6></div>-->
                                <div class="input-group mb-2">
                                    <span style="font-size:18px;font-weight:600;font-style: italic;">Selecione Estado</span> 
                                    <small style="padding-top:5px;"><span style="color:red;padding-left:10px;"> *Obrigatório</span></small>
                                    <v-select v-model="Estadoselected" @input="MudaEstado"
                                              :options="Estados"
                                              :value="Estados.EstadoId"
                                              single-line
                                              :searchable="false"
                                              label="nomeEstado" class="style-chooser w-100"></v-select>
                                </div>
                                <div class="input-group col-md-6 mb-2">
                                    <span style="font-size:18px;font-weight:600;font-style: italic;">Selecione Cidade </span>
                                    <small style="padding-top:5px;"><span style="color:red;padding-left:10px;"> *Obrigatório</span></small>
                                    <v-select v-model="Cidadeselected" @input="MudaCidade"
                                              :options="Cidades"
                                              :value="Cidades.CidadeId"
                                              single-line
                                              :searchable="false"
                                              label="nomeCidade" class="style-chooser w-100"></v-select>
                                </div>
                                <div class="input-group mb-2">
                                    <span style="font-size:18px;font-weight:600;font-style: italic;">Selecione Bairro</span>
                                    <small style="padding-top:5px;"><span style="color:red;padding-left:10px;"> *Obrigatório</span></small>
                                    <v-select v-model="Bairroselected" @input="MudaBairro"
                                              :options="Bairros"
                                              :value="Bairros.BairroId"
                                              single-line
                                              :searchable="false"
                                              label="nomeBairro" class="style-chooser w-100"></v-select>

                                </div>
                                <div class="input-group mb-2">
                                    <span style="font-size:18px;font-weight:600;font-style: italic;">Endereço</span>
                                    <small style="padding-top:5px;"><span style="color:red;padding-left:10px;"> *Obrigatório</span></small>
                                    <input v-model="Endereco" type="text" class="form-control w-100" placeholder="">
                                </div>
                                <div class="input-group mb-2">
                                    <span style="font-size:18px;font-weight:600;font-style: italic;">Número</span>
                                    <small style="padding-top:5px;"><span style="color:red;padding-left:10px;"> *Obrigatório</span></small>
                                    <input v-model="Numero" type="number" class="form-control w-100" placeholder="" @blur="deslizaHorarios()">
                                </div>
                                <div class="input-group mb-2">
                                    <span style="font-size:18px;font-weight:600;font-style: italic;">Complemento</span>
                                    <input v-model="Complemento" type="text" class="form-control w-100" placeholder="">
                                </div>
                                <div class="input-group mb-2">
                                    <span style="font-size:18px;font-weight:600;font-style: italic;">CEP</span>
                                    <input v-model="CEP" type="text" v-mask="'#####-###'" inputmode="numeric" class="form-control w-100" placeholder="">
                                </div>
                            </form>

                        </div>

                        <!-- Calendário -->
                        <div class="col-md-4 col-lg-4 text-center">

                            <date-pick v-model="date"
                                       nextMonthCaption="Próximo Mês"
                                       prevMonthCaption="Mês anterior"
                                       :weekdays="['Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab', 'Dom']"
                                       :months="['Janeiro', 'Fevereiro', 'Março', 'Abril','Maio', 'Junho', 'Julho', 'Agosto','Setembro', 'Outubro', 'Novembro', 'Dezembro']"
                                       :displayFormat="'dd/mm/yyyy'"
                                       :hasInputElement="false"
                                       @input="carregaAgenda(),deslizaHorarios()"></date-pick>

                        </div>

                        <!-- Frete -->
                        <div id="vHorarios" v-if="ListasHorarios != null" class="col-md-7 offset-md-1 col-lg offset-lg-0 mt-4 mt-md-0 vHorarios">
                            <label style="font-size:17px;font-weight:800;">Selecione Horário da Entrega<span style="color:red;">*</span></label>
                            <div v-for="(vlistaHorario,index) in ListasHorarios" :key="index" class="form-check">
                                <input class="form-check-input"
                                       type="radio"
                                       :value="vlistaHorario"
                                       v-model="periodo">{{ vlistaHorario.texto }}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <footer class="modal_footer_fixo">
                <div class="container">
                    <div class="row">
                        <div class="col">
                            <a class="btn btn-card mt-0"
                               @click="validaAgenda()">{{ TextoModalCalendario.textoBtn1 }}</a>
                        </div>
                    </div>
                </div>
            </footer>

        </Modal>

        <!-- Escolha o Tema -->
        <Modal v-model="showModal_tema" title="">

            <section class="section-modal">
                <div class="container">
                    <div class="row">
                        <div class="col text-center">
                            <h4 v-if="TextosTema!=null" class="titulo-modal" style="font-family: 'Back to Black'; font-weight: 800; color: #cf083f; font-size: 35px; ">
                                {{ TextosTema[0].nome }}
                            </h4>
                        </div>
                    </div>
                    <div class="text-center px-lg-5">
                        <p v-if="TextosTema!=null" v-html="TextosTema[0].resumo1" style="font-size:20px;"></p>
                    </div>
                    <div class="row mt-5">
                        <div class="col-4 col-md-3 col-lg-2 mb-4" v-for="temaMensage in TemaMensagem" :key="temaMensage.segmentoInstNivel1Id">
                            <div class="cartao-item text-center">
                                <a @click="carregaModeloMensagem(temaMensage.segmentoInstNivel1Id)">
                                    <img v-if="temaMensage.imagens[0] != null" class="imagem-modal img-fluid" :src="$Url + temaMensage.imagens[0].urlImagem" alt="">
                                    <img v-else :src="$Url + '/imagens/indisponivel.jpg'" class="imagem-modal img-fluid" />
                                </a>
                                <a class="link-modal" @click="carregaModeloMensagem(temaMensage.segmentoInstNivel1Id)">
                                    {{ temaMensage.nomeSegmento }}
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="text-center px-lg-5">
                        <p v-html="TextosTema[0].resumo2"></p>
                    </div>
                </div>
            </section>

            <footer class="modal_footer_fixo">
                <div class="container">
                    <div class="row">
                        <div class="col-4 col-md-4 col-lg-3">
                            <div class="voltar d-flex align-items-center">
                                <a @click="showModal_tema=false">Voltar</a>
                            </div>
                        </div>
                        <div class="col-8 col-md-4 offset-md-4 col-lg-3 offset-lg-6">
                            <a class="btn btn-outline-danger position-right"
                               @click="EnviarSemMensagem()">
                                {{ TextosTema[0].textoBtn1 }}
                            </a>
                        </div>
                    </div>
                </div>
            </footer>

        </Modal>

        <!-- Escolha o Modelo -->
        <Modal v-model="showModal_modelo" title="">

            <section class="section-modal">
                <div class="container">
                    <div class="row">
                        <div class="col text-center">
                            <h4 class="titulo-modal" style="font-family: 'Back to Black'; font-weight: 800; color: #cf083f; font-size: 35px; "> {{ TextosModelo[0].nome }}</h4>
                            <h5 class="titulo-modal"> {{ TextosModelo[0].referencia }}</h5>
                        </div>
                    </div>
                    <div class="text-center px-lg-5">
                        <p v-html="TextosModelo[0].resumo1"></p>
                    </div>
                    <div class="row mt-5">
                        <div class="col-4 col-md-3 col-lg-2 mb-4" v-for="modeloMensage in ModeloMensagemFiltro" :key="modeloMensage.institucionalId">
                            <div class="cartao-item text-center">
                                <a @click="carregaCartao(modeloMensage.institucionalId)">
                                    <img v-if="modeloMensage.institucionalImg[0] != null" class="imagem-modal img-fluid" :src="$Url + modeloMensage.institucionalImg[0].urlImagem" alt="">
                                    <img v-else :src="$Url + '/imagens/indisponivel.jpg'" class="imagem-modal img-fluid" />
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="text-center px-lg-5">
                        <p v-html="TextosModelo[0].resumo2"></p>
                    </div>
                </div>
            </section>

            <footer class="modal_footer_fixo">
                <div class="container">
                    <div class="row">
                        <div class="col-4 col-md-4 col-lg-3">
                            <div class="voltar d-flex align-items-center">
                                <a @click="showModal_tema=true, showModal_modelo=false">Voltar</a>
                            </div>
                        </div>
                        <div class="col-8 col-md-4 offset-md-4 col-lg-3 offset-lg-6">
                            <a class="btn btn-outline-danger position-right"
                               @click="EnviarSemMensagem()">{{ TextosModelo[0].textoBtn1 }}</a>
                        </div>
                    </div>
                </div>
            </footer>

        </Modal>

        <!-- Escreva a Mensagem -->
        <Modal v-model="showModal_mensagem" title="">

            <section class="section-modal">
                <div class="container">
                    <div class="row">
                        <div class="col text-center">
                            <h4 class="titulo-modal" style="font-family: 'Back to Black'; font-weight: 800; color: #cf083f; font-size: 35px; ">{{ InstrucaoCartao[0].nome }}</h4>
                        </div>
                    </div>

                    <!-- Instruções -->
                    <div class="row">
                        <div class="col text-center">
                            <span class="texto_detalhe" v-html="InstrucaoCartao[0].resumo1" style="font-size: 18px;"></span>
                        </div>
                    </div>

                    <div class="row justify-content-center">
                        <div class="col-lg-9 col-xl-8">
                            <div class="editar-cartao mt-4 text-center">
                                <!--aqui regra do preenchimento do cartão -->
                                <div id="fromHTMLtestdiv" v-if="ModeloCartaoEscolhidoUrl != ''" class="imagemCartao" :style="{ backgroundImage: 'url(' + $Url  + ModeloCartaoEscolhidoUrl +  ')' }">
                                    <textarea v-model="ConteudoCartao" class="textareacartao" maxlength="1000" style="border:1px solid #cccccc;font-family: Handlee !important;text-align: center;"></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <footer class="modal_footer_fixo">
                <div class="container">
                    <div class="row">
                        <div class="col-2 col-md-3 col-lg-4 col-xl-6">
                            <div class="voltar d-flex align-items-center mb-4 mb-md-0">
                                <a @click="showModal_modelo=true, showModal_mensagem=false">Voltar</a>
                            </div>
                        </div>

                        <div class="col-5 col-md-4 col-lg-4 col-xl-3">
                            <a class="btn btn-outline-danger position-right"
                               @click="EnviarSemMensagem()">{{ InstrucaoCartao[0].textoBtn1 }}</a>
                        </div>

                        <div class="col-5 col-md-5 col-lg-4 col-xl-3">
                            <a class="btn btn-card mt-0"
                               @click="carregaDadosCartao()">
                                {{ InstrucaoCartao[0].textoBtn2 }}
                            </a>
                        </div>
                    </div>
                </div>
            </footer>

        </Modal>

        <!-- Escolha o Opcionais -->
        <Modal v-model="showModal_opcionais" title="">

            <section class="section-modal mb-5">
                <div class="container">
                    <div class="row">
                        <div class="col text-center">
                            <h4 class="titulo-modal" style="font-family: 'Back to Black'; font-weight: 800; color: #cf083f; font-size: 35px; ">{{ TextosOpcionais[0].nome }}</h4>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <div class="text-center px-lg-5">
                                <p v-html="TextosOpcionais[0].resumo1"></p>
                            </div>
                        </div>
                    </div>
                    <div class="row" style="text-align:center">
                        <div class="col-md-6 col-lg-4 mb-4" v-for="BannerOpcionais in BannersOpcionais" :key="BannerOpcionais.institucionalId">
                            <div class="navbar_detalhes-item navbar_item-3 text-center" :style="Indisponivel == true ? 'pointer-events:none':''">
                                <a class="navbar_detalhes-link" @click="carregaModalOpcional(BannerOpcionais.institucionalId)">
                                    <div class="text-center">
                                        <img v-if="BannerOpcionais.institucionalImg[0] != null" class="imagem-modal img-fluid" :src="$Url + BannerOpcionais.institucionalImg[0].urlImagem" alt="">
                                        <img v-else :src="$Url + '/imagens/indisponivel.jpg'" class="imagem-modal img-fluid" />
                                    </div>
                                </a>
                               <!--  <a class="" @click="carregaModalOpcional(BannerOpcionais.institucionalId)" :style="{color: BannerOpcionais.cor1 + '!important', }">
                                    <span style="color: #333; font-family: 'BELLGOTHIC'; font-size: 20px; font-weight: 200;"> {{ BannerOpcionais.nome }}</span>
                                </a> -->
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <footer class="modal_footer_fixo">
                <div class="container">
                    <div class="row">
                        <div class="col-1 col-md-3 col-lg-4 col-xl-6">
                            <div class="voltar d-flex align-items-center mb-md-0">
                                <a @click="showModal_opcionais=false" style="font-size: 14px;">Voltar</a>
                            </div>
                        </div>

                        <div class="col-6 col-md-4 col-lg-4 col-xl-3">
                            <a v-if="mostrabtnopcional==true" @click="EnviarSemOpcionais()" class="btn btn-outline-danger position-right" style="font-size: 15px;">
                                {{ TextosOpcionais[0].textoBtn1 }}
                            </a>
                        </div>

                        <div class="col-5 col-md-5 col-lg-4 col-xl-3">
                            <a @click="carregaOpcionais()" class="btn btn-card mt-0" style="font-size: 15px;">
                                {{ TextosOpcionais[0].textoBtn2 }}
                            </a>
                        </div>
                    </div>
                </div>
            </footer>

        </Modal>

        <!-- Selecione sua foto -->
        <Modal v-model="showModal_foto" title="">

            <section class="section-modal modal-foto mb-5">
                <div class="container">
                    <div class="row">
                        <div class="col text-center">
                            <h4 class="titulo-modal mb-3">{{ TextosFotos[0].nome }}</h4>
                            <!--<span>R$ {{ parseFloat(TextosFotos[0].valor1,10).toFixed(2).toString().replace('.',',') }}</span>-->
                            <p v-html="TextosFotos[0].resumo1"></p>
                           
                            <input id="arquivo"
                                   type="file"
                                   class="" />
                            <button type="button"
                                    class="btn btn-success"
                                    @click="carregaFoto()"
                                    id="sa-custom-position">
                                Salvar
                            </button>
                        </div>
                    </div>

                    <div class="row justify-content-center">
                        <div class="col-lg-8 col-xl-6 text-center">
                            <div class="editar-cartao mt-3">
                                <img v-if="FotoCarregada != null" :src="$Url + FotoCarregada" class="img-fluid" />
                            </div>
                        </div>
                    </div>

                    <!-- Instruções -->
                    <div class="row justify-content-center">
                        <div class="col-lg-7 text-center mt-3">
                            <p v-html="TextosFotos[0].resumo2"></p>
                        </div>
                    </div>
                </div>
            </section>

            <footer class="modal_footer_fixo">
                <div class="container">
                    <div class="row">
                        <div class="col-1 col-md-4 col-lg-6 mb-4 mb-md-0">
                            <div class="voltar d-flex align-items-center">
                                <a @click="showModal_foto=false,showModal_opcionais=false">Voltar</a>
                            </div>
                        </div>

                        <div class="col-5 col-md-4 col-lg-3">
                            <a class="btn btn-outline-danger position-right"
                               @click="EnviarSemFoto()">
                                {{ TextosFotos[0].textoBtn1 }}
                            </a>
                        </div>

                        <div class="col-6 col-md-4 col-lg-3">
                            <a class="btn btn-card mt-0"
                               @click="carregaDadosFoto()">
                                {{ TextosFotos[0].textoBtn2 }}
                            </a>
                        </div>
                    </div>
                </div>
            </footer>

        </Modal>

        <!-- Adicione mais Produtos -->
        <Modal v-model="showModal_addProd" title="">

            <section class="section-modal modal-produtos">
                <div class="container">
                    <div class="row">
                        <div class="col text-center">
                            <h4 class="titulo-modal">
                                {{ TextosAvulsos[0].nome }}
                            </h4>
                        </div>
                    </div>
                    <!-- Cartões -->
                    <div class="row">
                        <div class="col-6 col-md-4 col-lg-2 my-4" v-for="ProdutoAvulso in ProdutosAvulso" :key="ProdutoAvulso.produtoAvulsoId">
                            <div v-if="verificaAvulso(ProdutoAvulso)" class="produto-card produto-card_modal">
                                <a @click="carregaAvulsos(ProdutoAvulso)">
                                    <!-- <img :src="$Url + ProdutoAvulso.imagem" class="produto-img set-img"/> -->
                                    <div v-if="ProdutoAvulso.itemAvulso.urlImg != null" class="produto-img set-img" :style="'background-image: url('+ $Url + encodeURI(ProdutoAvulso.itemAvulso.urlImg)+')'">
                                        <div class="middle middle_modal">
                                            <div class="text"></div>
                                        </div>
                                    </div>
                                    <div v-else class="produto-img set-img" :style="{ backgroundImage: 'url(' + $Url + '/imagens/indisponivel.jpg' + ')' }">
                                        <div class="middle middle_modal">
                                            <div class="text"></div>
                                        </div>
                                    </div>
                                </a>
                                <div class="produto-content">
                                    <h6 class="titulo-card" style="font-size:16px !important;">
                                        {{ ProdutoAvulso.itemAvulso.nomeItemAvulso }}
                                    </h6>
                                    <!--<span v-if="ProdutoAvulso.itemAvulso.valorDe > 0" class="preco-antigo">De {{   parseFloat(ProdutoAvulso.itemAvulso.valorDe,10).toFixed(2).toString().replace('.',',') }}</span>-->
                                    <p>R$ <span class="texto-destaque"> {{ parseFloat(ProdutoAvulso.itemAvulso.valorDe,10).toFixed(2).toString().replace('.',',') }}</span></p>
                                    <a @click="carregaAvulsos(ProdutoAvulso)" class="btn btn-outline-card d-block" style="margin-top:0px !important;">
                                        Adicionar
                                    </a>
                                </div>
                            </div>
                            <div v-else class="produto-card produto-card_modal">
                                <a @click="RemoveAvulsos(ProdutoAvulso)">
                                    <div v-if="ProdutoAvulso.itemAvulso.urlImg != null" class="produto-img set-img" :style="{ backgroundImage: 'url('+ $Url + encodeURI(ProdutoAvulso.itemAvulso.urlImg) + ')',opacity: 0.5, }">
                                        <div class="middle middle_modal">
                                            <div class="text"></div>
                                        </div>
                                    </div>
                                    <div v-else class="produto-img set-img" :style="{ backgroundImage: 'url(' + $Url + '/imagens/indisponivel.jpg' + ')' }">
                                        <div class="middle middle_modal">
                                            <div class="text"></div>
                                        </div>
                                    </div>
                                </a>
                                <div class="produto-content">
                                    <h6 class="titulo-card" style="font-size:16px !important;">
                                        {{ ProdutoAvulso.itemAvulso.nomeItemAvulso }}
                                    </h6>
                                    <!--<span v-if="ProdutoAvulso.itemAvulso.valorDe > 0" class="preco-antigo">De {{   parseFloat(ProdutoAvulso.itemAvulso.valorDe,10).toFixed(2).toString().replace('.',',') }}</span>-->
                                    <p>R$ <span class="texto-destaque"> {{ parseFloat(ProdutoAvulso.itemAvulso.valorDe,10).toFixed(2).toString().replace('.',',') }}</span></p>
                                    <a @click="RemoveAvulsos(ProdutoAvulso)" class="btn btn-info d-block" style="margin-top:0px !important;">
                    Adicionado
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div v-if="ProdutosAvulso == ''" class="col-12 col-md-12 col-lg-12 my-4 text-center">
                            <h6 class="titulo-card" style="font-size:16px !important;">
                                Não existem produtos avulsos disponíveis para esta cesta.
                            </h6>
                        </div>
                    </div>
                </div>
            </section>

            <footer class="modal_footer_fixo">
                <div class="container">
                    <div class="row">
                        <div class="col-1 col-md-4 col-lg-6">
                            <div class="voltar d-flex align-items-center mb-4 mb-md-0">
                                <a @click="showModal_addProd=false,showModal_opcionais=false" style="font-size: 14px;">Voltar</a>
                            </div>
                        </div>

                        <div class="col-6 col-md-4 col-lg-3">
                            <a class="btn btn-outline-danger position-right"
                               @click="enviarSemAvulsos()" style="font-size: 14px;">
                                {{ TextosAvulsos[0].textoBtn1 }}
                            </a>
                        </div>

                        <div class="col-5 col-md-4 col-lg-3">
                            <a class="btn btn-card hidden-sm mt-0"
                               @click="carregarAvulsos()" style="font-size: 15px;">
                                {{ TextosAvulsos[0].textoBtn2 }}
                            </a>
                            <a class="btn btn-card hidden-md hidden-lg hidden-xl mt-0"
                               @click="carregarAvulsos()" style="font-size: 15px;">
                                {{ TextosAvulsos[0].textoBtn2 }}
                            </a>
                        </div>
                    </div>
                </div>
            </footer>

        </Modal>

        <!-- Escolha o Balão -->
        <Modal v-model="showModal_balao" title="">

            <section class="section-modal">
                <div class="container">
                    <div class="row">
                        <div class="col text-center">
                            <h4 class="titulo-modal">Clique no modelo e escolha o balão que deseja enviar!</h4>
                        </div>
                    </div>

                    <div class="row mt-5">
                        <div class="col-4 col-md-3 col-lg-2 mb-4" v-for="balao in arr_baloes" :key="balao.produtoAvulsoId">
                            <div class="balao-item text-center">
                                <a @click="carregaBalao(balao)">
                                    <img class="imagem-modal img-fluid" :src=" $Url + balao.itemAvulso.urlImg" alt="">
                                </a>
                                <a class="link-modal mt-2"
                                   @click="carregaBalao(balao)">
                                    {{ balao.itemAvulso.nomeItemAvulso }}
                                </a>
                                <p><span class="texto-destaque mt-2">R$ {{ parseFloat(balao.itemAvulso.valorDe,10).toFixed(2).toString().replace('.',',') }}</span></p>
                            </div>
                        </div>
                    </div>

                </div>
            </section>

            <footer class="modal_footer_fixo">
                <div class="container">
                    <div class="row">
                        <div class="col-4 col-md-4 col-lg-3">
                            <div class="voltar d-flex align-items-center">
                                <a @click="showModal_balao=false,showModal_opcionais=false">Voltar</a>
                            </div>
                        </div>
                        <div class="col-8 col-md-4 offset-md-4 col-lg-3 offset-lg-6">
                            <a class="btn btn-outline-danger position-right" @click="enviarSemBalao()">
                                Enviar sem Balão
                            </a>
                        </div>
                    </div>
                </div>
            </footer>

        </Modal>
        <!-- Editar Balão -->
        <Modal v-model="showModal_editarBalao" title="">

            <section class="modal-editarBalao">
                <div class="container">
                    <div class="row">
                        <div class="col-2 hidden-md hidden-lg hidden-xl"></div>
                        <div class="col-8 col-md-5 col-xl-4">
                            <div class=" rounded p-1" style="text-align: center;">
                                <figure id="container">
                                    <img v-if="ModeloBalao!=''" class="img-fluid cel" :src=" $Url + ModeloBalao[0].itemAvulso.urlImg" alt="">
                                    <figcaption style="text-align: center; width: 100%;">
                                        <span class="Txtcel" :style="{fontFamily : focused_font, color: focused_cor, width : '100%'}">{{ linha1 }}</span>
                                        <br />
                                        <span class="Txtcel" :style="{fontFamily : focused_font2, color: focused_cor, width : '100%'}">{{ linha2 }}</span>
                                        <br />
                                        <span class="Txtcel" :style="{fontFamily : focused_font3, color: focused_cor, width : '100%'}">{{ linha3 }}</span>
                                    </figcaption>
                                </figure>
                            </div>
                        </div>
                        <div class="col-md-7 col-xl-5">
                            <div class="detalhe-pedido mb-4 mb-xl-0">
                                <!--<h6 v-if="ModeloBalao!=''">R$ {{ parseFloat(ModeloBalao[0].itemAvulso.valorDe,10).toFixed(2).toString().replace('.',',') }}</h6>-->
                                <form action="#">
                                    <div class="row">
                                        <div class="col-7 col-xl-7">
                                            <h6 class="subtitulo_detalhes mb-3">Mensagem</h6>
                                            <input v-model="linha1" type="text" class="form-control mb-2" placeholder="Max 20 Caracteres" maxlength="20" style="width: 100%;">
                                        </div>
                                        <div class="col-5 col-xl-5">
                                            <h6 class="subtitulo_detalhes mb-3">Letra</h6>
                                            <select v-model="focused_font" class="mb-2" style="width: 100%; height: 32px; border: solid 1px #cccccc; border-radius: 3px;">
                                                <option value="" disabled selected>Font</option>
                                                <option v-for="font in available_fonts" :key="font.filtroMarcaId" v-bind:value="font.nomeMarca" v-bind:style="{fontFamily : font.nomeMarca}">{{ font.nomeMarca }}</option>
                                            </select>
                                        </div>
                                        <!--<div class="col-4 col-xl-3">
                                            <h6 class="subtitulo_detalhes mb-3">Cor</h6>
                                            <input v-model="focused_cor" type="color" class="form-control mb-2" style="width: 50px; height: 32px;">
                                        </div>-->
                                    </div>
                                    <div class="row">
                                        <div class="col-7 col-xl-7">
                                            <input v-model="linha2" type="text" class="form-control mb-2" placeholder="Max 20 Caracteres" maxlength="20" style="width: 100%;">
                                        </div>
                                        <div class="col-5 col-xl-5">
                                            <select v-model="focused_font2" class="mb-2" style="width: 100%; height: 32px; border: solid 1px #cccccc; border-radius: 3px;">
                                                <option value="" disabled selected>Font</option>
                                                <option v-for="font in available_fonts" :key="font.filtroMarcaId" v-bind:value="font.nomeMarca" v-bind:style="{fontFamily : font.nomeMarca}">{{ font.nomeMarca }}</option>
                                            </select>
                                        </div>
                                        <!--<div class="col-4 col-xl-3">
                                            <input v-model="focused_cor2" type="color" class="form-control mb-2" style="width: 50px; height: 32px;">
                                        </div>-->
                                    </div>
                                    <div class="row">
                                        <div class="col-7 col-xl-7">
                                            <input v-model="linha3" type="text" class="form-control mb-2" placeholder="Max 20 Caracteres" maxlength="20" style="width: 100%;">
                                        </div>
                                        <div class="col-5 col-xl-5">
                                            <select v-model="focused_font3" class="mb-2" style="width: 100%; height: 32px; border: solid 1px #cccccc; border-radius: 3px;">
                                                <option value="" disabled selected>Font</option>
                                                <option v-for="font in available_fonts" :key="font.filtroMarcaId" v-bind:value="font.nomeMarca" v-bind:style="{fontFamily : font.nomeMarca}">{{ font.nomeMarca }}</option>
                                            </select>
                                        </div>
                                        <!--<div class="col-4 col-xl-3">
                                            <input v-model="focused_cor3" type="color" class="form-control mb-2" style="width: 50px; height: 32px;">
                                        </div>-->
                                    </div>

                                </form>
                                <!--Cores-->
                                <div class="mt-4">
                                    <h6 class="subtitulo_detalhes mb-3">Escolha a cor das letras</h6>
                                    <div class="row">
                                        <div v-for="FitroCor in FitroCores" :key="FitroCor.filtroCorId" class="col-2">
                                            <a href="#" @click.prevent="focused_cor=FitroCor.codigoCor,FiltroCornome=FitroCor.nomeCor">
                                                <div class="color" :style="{backgroundColor : FitroCor.codigoCor}"></div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3">
                            <div class="py-3">
                                <h4 v-if="ModeloBalao!=''" class="titulo_detalhes mb-3">{{ ModeloBalao[0].itemAvulso.nomeItemAvulso }}</h4>
                                <span v-if="ModeloBalao!=''" v-html="ModeloBalao[0].itemAvulso.descricao"></span>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <footer class="modal_footer_fixo fixo2">
                <div class="container">
                    <div class="row">
                        <div class="col-2 col-md-4 col-lg-6">
                            <div class="voltar d-flex align-items-center">
                                <a @click="showModal_balao=true, showModal_editarBalao=false">Voltar</a>
                            </div>
                        </div>
                        <div class="col-5 col-md-4 col-lg-3">
                            <a class="btn btn-outline-danger position-right"
                               @click="enviarSemBalao()">
                                Enviar sem Balão
                            </a>
                        </div>
                        <div class="col-5 col-md-4 col-lg-3">
                            <a class="btn btn-card px-1 mt-0 d-block"
                               @click="carregaBaloes(ModeloBalao)">
                                Concluir e Avançar
                            </a>
                        </div>

                    </div>
                </div>
            </footer>

        </Modal>
        <!-- <NavDetalhes /> -->

    </section>

</template>

<script>

    import Header from '@/components/site/shared/Header.vue'
    import Fita from '@/components/site/shared/Fita.vue'
    import VueModal from '@kouts/vue-modal'
    import DatePick from 'vue-date-pick';
    import 'vue-date-pick/dist/vueDatePick.css';
    import { VMoney } from 'v-money'
    import moment from 'moment';
    import vSelect from 'vue-select';
    import Lingallery from 'lingallery';


    export default {
        components: {
            Header,
            Fita,
            'Modal': VueModal,
            DatePick,
            vSelect,
            Lingallery,
        },
        data() {
            return {
                Pagina: this.$route.params.pagina,
                vModal: this.$route.params.modal,
                ProdutoId: this.$route.params.id,
                Produto: {},
                ProdutosIncluso: [],
                ProdutoIncluso: {},
                IconesBarra: [],
                IconeBarra: {},
                TextoModalCalendario: {},
                EstadoId: 0,
                Estados: [],
                Estadoselected: null,
                CidadeId: 0,
                Cidades: [],
                Cidadeselected: null,
                BairroId: 0,
                Bairros: [],
                Bairroselected: null,
                money: {
                    decimal: ',',
                    thousands: '.',
                    precision: 2,
                    masked: true
                },
                Endereco: '',
                Numero: '',
                Complemento: '',
                CEP: '',
                date: null,
                Retornocep: {},
                UrlimgBannerGeral: '',
                ImgProduto: [],
                currentId: null,
                TextosTema: [],
                TemaMensagem: [],
                temaMensage: {},
                TextosModelo: [],
                ModeloMensagem: [],
                modeloMensage: {},
                ModeloMensagemFiltro: [],
                InstrucaoCartao: [],
                ModeloCartaoEscolhido: [],
                ModeloCartaoEscolhidoUrl: '',
                ConteudoCartao: '',
                TextosOpcionais: [],
                BannersOpcionais: [],
                BannerOpcionais: {},
                TextosFotos: [],
                TextosAvulsos: [],
                ProdutosAvulso: [],
                ProdutoAvulso: {},
                FotoCarregada: null,
                totalavulsos: 0,
                totalfoto: 0,
                DadosCompra: {
                    produtos: [],
                    mensagem: [],
                    agenda: [],
                    foto: [],
                    avulsos: [],
                    baloes: [],
                    informacao: [],
                    pagamento: [],
                    cupom: [],
                },
                trocafoto1: false,
                trocafoto2: false,
                trocafoto3: false,
                barraVisivel: true,
                btnFinal: false,
                ListaEntregaFrete: [],
                vAtraso: 0,
                vValor: 0,
                EntregaFreteId: 0,
                ListaHorariosEntregaFrete: [],
                ListaExcecao: [],
                EntregaExcecaoId: 0,
                ExcecaoAtrasoHs: 0,
                ExcecaohoraIni: 0,
                ExcecaominIni: 0,
                ExcecaohoraFim: 0,
                ExcecaominFim: 0,
                ListasHorarios: [],
                vlistaHorario: {},
                periodo: '',
                showModal_data: false,
                showModal_tema: false,
                showModal_modelo: false,
                showModal_mensagem: false,
                showModal_foto: false,
                showModal_opcionais: false,
                showModal_addProd: false,
                showModal_balao: false,
                showModal_editarBalao: false,
                mostrabtnopcional: true,
                ModeloBalao: [],
                linha1: '',
                linha2: '',
                linha3: '',
                focused_font: '',
                focused_font2: '',
                focused_font3: '',
                focused_cor: '#FFFFFF',
                FiltroCornome: '',
                focused_cor2: '',
                focused_cor3: '',
                plinha1: '',
                plinha2: '',
                plinha3: '',
                pfocused_font: '',
                pfocused_font2: '',
                pfocused_font3: '',
                pfocused_cor: '#FFFFFF',
                pFiltroCornome: '',
                pfocused_cor2: '',
                pfocused_cor3: '',
                pavailable_fonts: [],
                pshowcustomizado: false,
                FitroCores: [],
                FitroCor: {},
                pFitroCor: {},
                NomeFontes: [],
                qtdeLinhas: 0,
                Indisponivel: false,
                parcelas: 0,
                parcelasTexto: '',
            }
        },
        directives: { money: VMoney },
        methods: {
            CarregaModal(value) {
                //console.log("foto " + this.trocafoto2 + "value " + value)
                if (value == 0) {
                    let dadosArmazenados = localStorage.getItem('dadoscompralove');
                    if (dadosArmazenados) {
                        dadosArmazenados = JSON.parse(dadosArmazenados);
                        if (dadosArmazenados.agenda[0] == null) {
                            value = 1;
                        }
                        else if (dadosArmazenados.mensagem[0] == null) {
                            value = 2;
                        }
                        else if (dadosArmazenados.foto[0] == null && dadosArmazenados.avulsos[0] == null && dadosArmazenados.baloes[0] == null) {
                            value = 3;
                        } 
                        else {
                            value = 4;
                        }
                    }
                    console.log(value)
                }

                if (value == 1) {
                    this.showModal_data = true
                    if (this.Pagina == 'novaContaEndereco' || this.Pagina == 'carrinho') {
                        this.carregaAgenda();
                    }
                    this.showModal_tema = false
                    this.showModal_modelo = false
                    this.showModal_mensagem = false
                    this.showModal_foto = false
                    this.showModal_opcionais = false
                    this.showModal_addProd = false
                    this.showModal_balao = false
                    this.showModal_editarBalao = false
                }
                if (value == 2) {
                    let dadosArmazenados = localStorage.getItem('dadoscompralove');
                    if (dadosArmazenados) {
                        dadosArmazenados = JSON.parse(dadosArmazenados);
                        if (dadosArmazenados.mensagem[0] != null) {
                            if (dadosArmazenados.mensagem[0].imagemId == 0) {
                                this.trocafoto2 = false;
                            }
                        }
                    }
                    if (this.trocafoto2) {
                        this.showModal_data = false
                        this.showModal_tema = false
                        this.showModal_modelo = false
                        this.showModal_mensagem = true
                        this.showModal_foto = false
                        this.showModal_opcionais = false
                        this.showModal_addProd = false
                        this.showModal_balao = false
                        this.showModal_editarBalao = false
                    }
                    else {
                        this.showModal_data = false
                        this.showModal_tema = true
                        this.showModal_modelo = false
                        this.showModal_mensagem = false
                        this.showModal_foto = false
                        this.showModal_opcionais = false
                        this.showModal_addProd = false
                        this.showModal_balao = false
                        this.showModal_editarBalao = false
                    }


                }
                if (value == 3) {
                    this.showModal_data = false
                    this.showModal_tema = false
                    this.showModal_modelo = false
                    this.showModal_mensagem = false
                    this.showModal_foto = false
                    this.showModal_opcionais = true
                    this.showModal_addProd = false
                    this.showModal_balao = false
                    this.showModal_editarBalao = false
                    let dadosArmazenados = localStorage.getItem('dadoscompralove');
                    dadosArmazenados = JSON.parse(dadosArmazenados);
                    if (dadosArmazenados.avulsos != '' || dadosArmazenados.foto != '' || dadosArmazenados.baloes != '') {
                        this.trocafoto3 = true;
                        this.mostrabtnopcional = false;
                    }
                    else {
                        this.trocafoto3 = false;
                        this.mostrabtnopcional = true;
                    }
                }
                if (value == 4) {
                    if (this.Pagina != null) {
                        this.$redirect("/" + this.Pagina);
                    } else {
                        this.showModal_data = false
                        this.showModal_tema = false
                        this.showModal_modelo = false
                        this.showModal_mensagem = false
                        this.showModal_foto = false
                        this.showModal_opcionais = false
                        this.showModal_addProd = false
                        this.showModal_balao = false
                        this.showModal_editarBalao = false
                        this.btnFinal = true;
                        this.totalavulsos = this.totalizaavulsos();
                        this.totalfoto = this.totalizafoto();
                        let dadosArmazenados = localStorage.getItem('dadoscompralove');
                        dadosArmazenados = JSON.parse(dadosArmazenados);
                        if (dadosArmazenados.avulsos != '' || dadosArmazenados.foto != '' || dadosArmazenados.baloes != '') {
                            this.trocafoto3 = true;
                            this.mostrabtnopcional = false;
                        }
                        else {
                            this.trocafoto3 = false;
                            this.mostrabtnopcional = true;
                        }
                        //console.log("carregafinal")
                        this.CarregaFinal();
                        let element = document.querySelector(".vfinal");
                        element.scrollIntoView();
                      
                        
                    }
                }
            },
            carregaBalao(value) {
                this.ModeloBalao = this.arr_baloes.filter(x => x.produtoAvulsoId == parseInt(value.produtoAvulsoId, 10))
                console.log(this.ModeloBalao)
                this.showModal_balao = false
                this.showModal_editarBalao = true
            },
            currentDateTime(vdate) {
                //moment.locale("pt-br");
                //moment().endOf('second');
                //dateFormat(this.Cotacao.dataDia, "yyyy-mm-dd");
                //currentDateTime(Cotacao.dataDia)
                //return moment(vdate).format('DD/MM/YYYY')
                if (vdate != null) {
                    return moment(vdate).format('LL');
                }
                else {
                    return '';
                }

            },
            MudaEstado(value) {
                this.EstadoId = value.estadoId;
                this.CidadeId = 0;
                this.Cidades = [];
                this.Cidadeselected = null;
                this.$http
                    .get(this.$apiUrl + "/cidades/ativosEstados/" + this.EstadoId)
                    .then((res) => res.json())
                    .then((tiu) => (this.Cidades = tiu));
            },
            MudaCidade(value) {
                if (value != null) {
                    this.CidadeId = value.cidadeId;
                }
                else {
                    this.CidadeId = 0;
                }
                this.BairroId = 0;
                this.Bairros = [];
                this.Bairroselected = null;
                this.$http
                    .get(this.$apiUrl + "/bairro/ativosBairros/" + this.CidadeId)
                    .then((res) => res.json())
                    .then((tiu) => (this.Bairros = tiu));
            },
            MudaBairro(value) {
                if (value != null) {
                    this.BairroId = value.bairroId;
                }
                else {
                    this.BairroId = 0;
                }
                this.carregaAgenda();
            },
            Buscacep(value) {
                this.$http
                    //.get("https://ws.apicep.com/cep.json?code=" + value)
                    .get("https://viacep.com.br/ws/" + value + "/json/")
                    .then((res2) => res2.json())
                    .then((tiu2) => {
                        this.Retornocep = tiu2

                        if (this.Retornocep != null) {
                            this.$http
                                .get(this.$apiUrl + "/estados/ativos")
                                .then((res) => res.json())
                                .then((tiu) => {
                                    let _estado = tiu.filter(x => x.siglaEstado == this.Retornocep.uf);
                                    if (_estado[0] != null) {
                                        this.$http
                                            .get(this.$apiUrl + "/cidades/ativosEstados/" + parseInt(_estado[0].estadoId, 10))
                                            .then((res) => res.json())
                                            .then((tiu) => {
                                                this.Cidades = tiu;
                                                let _Cidade = tiu.filter(x => x.nomeCidade.toUpperCase() == this.Retornocep.localidade.toUpperCase());

                                                if (_Cidade[0] != null) {
                                                    this.Endereco = this.Retornocep.logradouro;
                                                    this.Numero = null;
                                                    this.Complemento = null;
                                                    this.Estadoselected = _estado;
                                                    this.Cidadeselected = _Cidade;
                                                    this.iBairro = this.Retornocep.bairro;
                                                    this.MudaCidade(_Cidade[0]);
                                                }
                                                else {
                                                    alert("Infelizmente não entregamos nessa cidade.");
                                                }
                                            });
                                    }
                                    else {
                                        alert("Infelizmente não entregamos nesse estado.")
                                    }
                                });
                        }
                        else {
                            alert("CEP inexistente");
                        }
                    });
            },
            validaAgenda() {
                if (this.Estadoselected == null) {
                    this.$mensagem_normal("Por favor escolha o estado");
                } else if (this.Cidadeselected == null) {
                    this.$mensagem_normal("Por favor escolha a cidade");
                } else if (this.Bairroselected == null) {
                    this.$mensagem_normal("Por favor escolha o bairro");
                } else if (this.Endereco == '') {
                    this.$mensagem_normal("Por favor preencha o endereço");
                } else if (this.Numero == '') {
                    this.$mensagem_normal("Por favor preencha o número");
                } else if (this.periodo == '') {
                    this.$mensagem_normal("Por favor selecione o dia e hora da entrega");
                } else {
                    this.ArmazenaDados('agenda', null);
                    this.trocafoto1 = true;
                    this.CarregaModal(0)
                }
                //aqui falta a parte do agendamento do horário.
            },
            carregaModeloMensagem(value) {
                this.ModeloMensagemFiltro = this.ModeloMensagem.filter(x => x.segmentoInstNivel1.segmentoInstNivel1Id == parseInt(value, 10))
                this.showModal_modelo = true
                this.showModal_tema = false
            },
            carregaCartao(value) {
                this.ModeloCartaoEscolhido = this.ModeloMensagem.filter(x => x.institucionalId == parseInt(value, 10))
                this.ModeloCartaoEscolhidoUrl = this.ModeloCartaoEscolhido[0].institucionalImg[0].urlImagem
                this.showModal_mensagem = true
                this.showModal_modelo = false
            },
            carregaDadosCartao() {
                this.ArmazenaDados('mensagem', null)
                this.$mensagem_normal("Mensagem Cadastrada com sucesso.");
                this.trocafoto2 = true;
                //this.CarregaModal(0)
                this.CarregaFinal();
            },
            EnviarSemMensagem() {
                let dadosArmazenados = localStorage.getItem('dadoscompralove');
                if (dadosArmazenados) {
                    dadosArmazenados = JSON.parse(dadosArmazenados);
                    if (dadosArmazenados.mensagem[0] != null) {
                        dadosArmazenados.mensagem[0] = {
                            imagemId: 0,
                            urlImagem: null,
                            textoMensagem: 'Sem mensagem',
                        };
                    }
                    else {
                        dadosArmazenados.mensagem.push({
                            imagemId: 0,
                            urlImagem: null,
                            textoMensagem: 'Sem mensagem',
                        });
                    }
                    this.trocafoto2 = true;
                }
                localStorage.setItem('dadoscompralove', JSON.stringify(dadosArmazenados))
                //this.CarregaModal(0)
                this.CarregaFinal();
            },
            EnviarSemFoto() {
                let dadosArmazenados = localStorage.getItem('dadoscompralove');
                if (dadosArmazenados) {
                    dadosArmazenados = JSON.parse(dadosArmazenados);
                    if (dadosArmazenados.foto != null) {
                        dadosArmazenados.foto[0] = {
                            imagemId: 0,
                            imagemUrl: 'Sem foto'
                        };
                        this.FotoCarregada = null;
                    }
                    else {
                        dadosArmazenados.foto.push({
                            imagemId: 0,
                            imagemUrl: 'Sem foto'
                        });
                        this.FotoCarregada = null;
                    }

                    if (dadosArmazenados.avulsos != '' || dadosArmazenados.foto != '') {
                        this.trocafoto3 = true;
                    }
                    else {
                        this.trocafoto3 = false;
                    }
                }
                localStorage.setItem('dadoscompralove', JSON.stringify(dadosArmazenados))
                this.showModal_opcionais = true
                this.showModal_foto = false
                this.mostrabtnopcional = false;
            },
            carregaAvulsos(value) {
                console.log(value)
                if (value.itemAvulso.tempoProducao > 0) {
                    this.$mensagem_normal("Este produto necessita um tempo de produção de " + value.itemAvulso.tempoProducao + " horas, isso poderá alterar a data e hora de entrega.");
                }
                this.ArmazenaDados('avulsos', value)
                this.mostrabtnopcional = false;
                this.$forceUpdate();
            },
            carregaBaloes(value) {
                this.ArmazenaDados('baloes', value)
                this.showModal_opcionais = true
                this.showModal_editarBalao = false
                let dadosArmazenados = localStorage.getItem('dadoscompralove');
                if (dadosArmazenados) {
                    dadosArmazenados = JSON.parse(dadosArmazenados);
                        if (dadosArmazenados.baloes != '') {
                            this.$mensagem_normal("Balão adicionado com sucesso.");
                        }
                    }
            },
            RemoveAvulsos(value) {
                let dadosArmazenados = localStorage.getItem('dadoscompralove');
                if (dadosArmazenados) {
                    dadosArmazenados = JSON.parse(dadosArmazenados);

                    let vrecebe = dadosArmazenados.avulsos.filter((x) => {
                        return x.produtoAvulsoId != value.itemAvulso.itemAvulsoId;
                    });
                    dadosArmazenados.avulsos = vrecebe;
                    localStorage.setItem('dadoscompralove', JSON.stringify(dadosArmazenados))
                    this.$forceUpdate();

                }
            },
            carregaModalOpcional(value) {
                // console.log("value " + value)
                let dadosArmazenados = localStorage.getItem('dadoscompralove');
                dadosArmazenados = JSON.parse(dadosArmazenados);
                if (dadosArmazenados.avulsos != '' || dadosArmazenados.foto != '') {
                    this.trocafoto3 = true;
                    this.mostrabtnopcional = false;
                }
                if (value == 313) {
                    this.showModal_addProd = false
                    this.showModal_foto = true
                    this.showModal_balao = false
                }
                if (value == 382) {
                    this.showModal_foto = false
                    this.showModal_addProd = true
                    this.showModal_balao = false
                }
                if (value == 387) {
                    this.showModal_foto = false
                    this.showModal_addProd = false
                    this.showModal_balao = true
                }

            },
            carregaFoto() {
                let file = document.getElementById("arquivo").files[0];
                let erroarquivo = "";
                if (file != null) {
                    var ext = file.name.split(".").pop();

                    if (ext != "jpg" && ext != "png" && ext != "gif" && ext != "jpeg") {
                        erroarquivo =
                            "Por favor selecione uma imagem ('.jpg', '.jpeg', '.png' ou '.gif')";
                    } else {
                        erroarquivo = "";
                    }
                }
                if (erroarquivo != null && erroarquivo.length > 0) {
                    this.$mensagem_normal(erroarquivo);
                }
                else {
                    let data = new FormData();
                    if (file != null) {
                        data.append("arquivo", file);
                        this.$http.post(this.$apiUrl + "/cadoperadora/upload", data).then(
                            (response3) => {
                                // get body data
                                this.FotoCarregada = response3.body;
                            },
                            (response3) => {
                                // error callback
                                this.$mensagem_erro(response3.body);
                            }
                        );
                    }
                }
            },
            carregaDadosFoto() {
                if (this.FotoCarregada == null) {
                    this.$mensagem_normal("Você precisa adicionar foto ou marcar enviar sem foto.");
                }
                else {
                    this.showModal_opcionais = true
                    this.showModal_foto = false
                    this.ArmazenaDados('foto', null);
                    this.trocafoto3 = true;
                    this.totalizaavulsos();
                    this.totalizafoto();
                    this.$mensagem_normal("Foto cadastrada com sucesso.");
                    this.mostrabtnopcional = false;
                }

            },
            carregaOpcionais() {
                let dadosArmazenados = localStorage.getItem('dadoscompralove');
                if (dadosArmazenados) {
                    dadosArmazenados = JSON.parse(dadosArmazenados);
                    if (dadosArmazenados.foto[0] == null && dadosArmazenados.avulsos[0] == null && dadosArmazenados.baloes[0] == null) {
                        this.$mensagem_normal("Você precisa adicionar algum opcional ou clicar em sem opcionais.");
                    }
                    else {
                        //this.CarregaModal(0);
                        if (this.Pagina != null) {
                            this.$redirect("/" + this.Pagina);
                        } else {
                            this.showModal_opcionais = false;
                        }
                    }
                }

            },
            carregarAvulsos() {
                let dadosArmazenados = localStorage.getItem('dadoscompralove');
                if (dadosArmazenados) {
                    dadosArmazenados = JSON.parse(dadosArmazenados);
                    if (dadosArmazenados.avulsos == '') {
                        this.$mensagem_normal("Você precisa adicionar produtos ou marcar enviar sem avulsos.");
                    }
                    else {
                        this.showModal_opcionais = true
                        this.showModal_addProd = false
                        if (dadosArmazenados.avulsos != '') {
                            this.$mensagem_normal("Complementos adicionados com sucesso.");
                        }
                    }
                }

            },
            enviarSemAvulsos() {
                let dadosArmazenados = localStorage.getItem('dadoscompralove');
                if (dadosArmazenados) {
                    dadosArmazenados = JSON.parse(dadosArmazenados);
                    dadosArmazenados.avulsos = [];
                    dadosArmazenados.avulsos.push({ produtoAvulsoId: 0, avulso: "Sem Avulsos" });
                    localStorage.setItem('dadoscompralove', JSON.stringify(dadosArmazenados))
                    this.showModal_opcionais = true
                    this.showModal_addProd = false
                    this.mostrabtnopcional = false;
                }
            },
            enviarSemBalao() {
                let dadosArmazenados = localStorage.getItem('dadoscompralove');
                if (dadosArmazenados) {
                    dadosArmazenados = JSON.parse(dadosArmazenados);
                    dadosArmazenados.baloes = [];
                    dadosArmazenados.baloes.push({ produtoAvulsoId: 0, avulso: "Sem Balao" });
                    localStorage.setItem('dadoscompralove', JSON.stringify(dadosArmazenados))
                    this.showModal_opcionais = true
                    this.showModal_balao = false
                    this.showModal_editarBalao = false
                }
            },
            EnviarSemOpcionais() {
                let dadosArmazenados = localStorage.getItem('dadoscompralove');
                if (dadosArmazenados) {
                    dadosArmazenados = JSON.parse(dadosArmazenados);
                    dadosArmazenados.avulsos = [];
                    dadosArmazenados.avulsos.push({ produtoAvulsoId: 0, avulso: "Sem Avulsos" });
                    dadosArmazenados.foto = [];
                    dadosArmazenados.foto.push({ imagemId: 0, imagemUrl: 'Sem foto' });
                    dadosArmazenados.baloes = [];
                    dadosArmazenados.baloes.push({ produtoAvulsoId: 0, avulso: "Sem Balao" });
                    localStorage.setItem('dadoscompralove', JSON.stringify(dadosArmazenados))
                    //this.CarregaModal(0);
                    this.showModal_opcionais = false;
                }
            },
            EnviarComOpcionais() {
                this.CarregaModal(0);
            },
            verificaAvulso(value) {
                let dadosArmazenados = localStorage.getItem('dadoscompralove');
                if (dadosArmazenados) {
                    dadosArmazenados = JSON.parse(dadosArmazenados);

                    let vrecebe = dadosArmazenados.avulsos.filter((x) => {
                        return x.produtoAvulsoId == value.itemAvulso.itemAvulsoId;
                    });

                    if (vrecebe != '') {
                        return false;
                    }
                    else {
                        return true;
                    }
                }
                else {
                    return true;
                }

            },
            CarregaFinal() {
                if (this.Pagina != null) {
                    this.$redirect("/" + this.Pagina);
                } else {
                    //this.carregaAgenda()
                    this.ArmazenaDados('produtos', null)
                    this.$redirect("/carrinho");

                }
            },
            ArmazenaDados(item, value) {

                let varray = value;
                //console.log("array " + value)

                if (item == 'produtos') {
                    let dadosArmazenados = localStorage.getItem('dadoscompralove');
                    if (dadosArmazenados) {
                        dadosArmazenados = JSON.parse(dadosArmazenados);
                        //let vrecebe = dadosArmazenados.produtos.filter((x) => {
                        //    return x.produtoId == parseInt(this.ProdutoId, 10);
                        //});

                        // if (vrecebe == '') {
                        let vdados = ''
                        if (this.pshowcustomizado) {
                            vdados += 'Produto: ' + this.ProdutoId + '<br />Nome: ' + this.Produto.nomeProduto + '<br />dados<br /><br />' + 'Linha 1 <br />Texto: ' + this.plinha1 + '<br /> Fonte:' + this.pfocused_font + '<br />Cor: ' + this.pfocused_cor + ' nome Cor: ' + this.pFiltroCornome + ' <br /><br /> ' + 'Linha 2 <br />Texto: ' + this.plinha2 + '<br />Fonte: ' + this.pfocused_font2 + ' <br />Cor: ' + this.pfocused_cor + ' nome Cor: ' + this.pFiltroCornome + ' <br /><br /> ' + 'Linha 3 <br />Texto: ' + this.plinha3 + '<br />Fonte: ' + this.pfocused_font3 + ' <br />Cor: ' + this.pfocused_cor + ' nome Cor: ' + this.pFiltroCornome + '<br />--'
                        }

                        dadosArmazenados.produtos.push({ produtoId: parseInt(this.ProdutoId, 10), qtde: 1, produto: this.Produto, dados: vdados });
                        localStorage.setItem('dadoscompralove', JSON.stringify(dadosArmazenados))
                        // }
                    }

                }

                if (item == 'agenda') {
                    let dadosArmazenados = localStorage.getItem('dadoscompralove');
                    if (dadosArmazenados) {
                        dadosArmazenados = JSON.parse(dadosArmazenados);
                        if (dadosArmazenados.agenda[0] != null) {
                            dadosArmazenados.agenda[0] = {
                                estado: this.Estadoselected,
                                cidade: this.Cidadeselected,
                                bairro: this.Bairroselected,
                                endereco: this.Endereco,
                                numero: this.Numero,
                                complemento: this.Complemento,
                                cep: this.CEP,
                                data: this.date,
                                horaId: null,
                                periodoEntrega: this.periodo,
                            };
                        }
                        else {
                            dadosArmazenados.agenda.push({
                                estado: this.Estadoselected,
                                cidade: this.Cidadeselected,
                                bairro: this.Bairroselected,
                                endereco: this.Endereco,
                                numero: this.Numero,
                                complemento: this.Complemento,
                                cep: this.CEP,
                                data: this.date,
                                horaId: null,
                                periodoEntrega: this.periodo,
                            });
                        }
                    }
                    localStorage.setItem('dadoscompralove', JSON.stringify(dadosArmazenados))
                }

                if (item == 'mensagem') {
                    let dadosArmazenados = localStorage.getItem('dadoscompralove');
                    if (dadosArmazenados) {
                        dadosArmazenados = JSON.parse(dadosArmazenados);
                        if (dadosArmazenados.mensagem[0] != null) {
                            dadosArmazenados.mensagem[0] = {
                                imagemId: this.ModeloCartaoEscolhido.institucionalId,
                                urlImagem: this.ModeloCartaoEscolhidoUrl,
                                textoMensagem: this.ConteudoCartao,
                            };
                        }
                        else {
                            dadosArmazenados.mensagem.push({
                                imagemId: this.ModeloCartaoEscolhido.institucionalId,
                                urlImagem: this.ModeloCartaoEscolhidoUrl,
                                textoMensagem: this.ConteudoCartao,
                            });
                        }
                    }
                    localStorage.setItem('dadoscompralove', JSON.stringify(dadosArmazenados))
                }

                if (item == 'avulsos') {
                    let dadosArmazenados = localStorage.getItem('dadoscompralove');
                    if (dadosArmazenados) {
                        dadosArmazenados = JSON.parse(dadosArmazenados);

                        let vrecebe = dadosArmazenados.avulsos.filter((x) => {
                            return x.produtoAvulsoId == varray.itemAvulso.itemAvulsoId;
                        });

                        let vrecebe2 = dadosArmazenados.avulsos.filter((x) => {
                            return x.produtoAvulsoId == 0;
                        });

                        if (vrecebe2 != '') {
                            dadosArmazenados.avulsos = [];
                        }

                        if (vrecebe == '') {
                            dadosArmazenados.avulsos.push({ produtoAvulsoId: varray.itemAvulso.itemAvulsoId, avulso: varray.itemAvulso });
                            localStorage.setItem('dadoscompralove', JSON.stringify(dadosArmazenados))
                        }
                    }
                }

                if (item == 'baloes') {
                    let dadosArmazenados = localStorage.getItem('dadoscompralove');
                    if (dadosArmazenados) {
                        dadosArmazenados = JSON.parse(dadosArmazenados);

                        let vrecebe2 = dadosArmazenados.baloes.filter((x) => {
                            return x.produtoAvulsoId == 0;
                        });

                        if (vrecebe2 != '') {
                            dadosArmazenados.baloes = [];
                        }

                        let vdados = ''
                        if (varray[0].itemAvulso.vendaAvulsa) {
                            vdados += 'Produto Adicional: ' + varray[0].itemAvulso.itemAvulsoId + '<br />Nome: ' + varray[0].itemAvulso.nomeItemAvulso + '<br /><br />Dados<br />' + 'Linha 1 <br />Texto: ' + this.linha1 + '<br />Fonte:' + this.focused_font + '<br />Cor: ' + this.focused_cor + ' nome Cor: ' + this.FiltroCornome + ' <br /><br /> ' + 'Linha 2 <br />Texto: ' + this.linha2 + ' <br />Fonte: ' + this.focused_font2 + ' <br />Cor: ' + this.focused_cor + ' + nome Cor: ' + this.FiltroCornome + ' <br /><br /> ' + 'Linha 3 <br />Texto: ' + this.linha3 + '<br />Fonte: ' + this.focused_font3 + ' <br />Cor: ' + this.focused_cor + ' nome Cor: ' + this.FiltroCornome + '<br />--'
                        }

                        dadosArmazenados.baloes.push({ produtoAvulsoId: varray[0].itemAvulso.itemAvulsoId, avulso: varray[0].itemAvulso, dados: vdados });
                        localStorage.setItem('dadoscompralove', JSON.stringify(dadosArmazenados))

                    }
                }

                if (item == 'foto') {
                    let dadosArmazenados = localStorage.getItem('dadoscompralove');
                    if (dadosArmazenados) {
                        dadosArmazenados = JSON.parse(dadosArmazenados);
                        if (dadosArmazenados.foto[0] != null) {
                            dadosArmazenados.foto[0] = {
                                imagemUrl: this.FotoCarregada,
                                valor: this.TextosFotos[0].valor1,
                            };
                        }
                        else {
                            dadosArmazenados.foto.push({
                                imagemUrl: this.FotoCarregada,
                                valor: this.TextosFotos[0].valor1,
                            });
                        }
                    }
                    localStorage.setItem('dadoscompralove', JSON.stringify(dadosArmazenados))
                }


                //DadosCompra: {
                //    produtos: [],
                //    mensagem: [],
                //    agenda: [],
                //    foto: [],
                //    avulsos: [],
                //    baloes: [],

                //console.log();
            },
            totalizaavulsos() {
                let dadosArmazenados = localStorage.getItem('dadoscompralove');
                if (dadosArmazenados) {
                    dadosArmazenados = JSON.parse(dadosArmazenados);
                    if (dadosArmazenados.avulsos != '') {
                        if (dadosArmazenados.avulsos[0].avulso == "Sem Avulsos") {
                            return 0;
                        }
                        else {
                            let recebe = dadosArmazenados.avulsos.reduce((sum, item) => sum + item.avulso.valorDe, 0);
                            return recebe;
                        }
                    }
                    else {
                        return 0;
                    }

                }
                else {
                    return 0;
                }
            },
            totalizafoto() {
                let dadosArmazenados = localStorage.getItem('dadoscompralove');
                if (dadosArmazenados) {
                    dadosArmazenados = JSON.parse(dadosArmazenados);
                    if (dadosArmazenados.foto != '') {
                        if (dadosArmazenados.foto[0].imagemUrl == "Sem foto") {
                            return 0;
                        }
                        else {
                            return dadosArmazenados.foto[0].valor;
                        }
                    }
                    else {
                        return 0;
                    }

                }
                else {
                    return 0;
                }
            },
            validaHora(value) {
                var _hora = value.toString();
                if (_hora.length == 1)
                    _hora = "0" + _hora;
                return _hora;
            },
            carregaListaHorarios(vgridfiltraExcecao, vgridEntregaFrete, Horaatual, datasel, tipo) {
                //das 20:00 até as 07:30 tem que pegar o segundo horario do dia
                //07:30 a 10:00
                //10:00 a 12:00
                //console.log(vgridfiltraEntregaFrete.valorFrete)
                 console.log('Horaatual')
                console.log(Horaatual)
                console.log('tipo')
                console.log(tipo)
                //validar se tem horario adicional no produto ou nos produtos já armazenados
                let dadosArmazenados = localStorage.getItem('dadoscompralove');
                dadosArmazenados = JSON.parse(dadosArmazenados);
                let vtempoproducao = 0;
                if (dadosArmazenados.produtos != '') {
                    dadosArmazenados.produtos.forEach((itemp) => {
                        if (itemp.produto.tempoProducao > 0) {
                            if (vtempoproducao < itemp.produto.tempoProducao) {
                                vtempoproducao = itemp.produto.tempoProducao;
                            }
                        }
                    });
                }
                if (dadosArmazenados.avulsos != '') {
                    dadosArmazenados.avulsos.forEach((itemp) => {
                        if (itemp.avulso.tempoProducao > 0) {
                            if (vtempoproducao < itemp.avulso.tempoProducao) {
                                vtempoproducao = itemp.avulso.tempoProducao;
                            }
                        }
                    });
                }
                if (dadosArmazenados.baloes != '') {
                    dadosArmazenados.baloes.forEach((itemp) => {
                        if (itemp.avulso.tempoProducao > 0) {
                            if (vtempoproducao < itemp.avulso.tempoProducao) {
                                vtempoproducao = itemp.avulso.tempoProducao;
                            }
                        }
                    });
                }

                if (vtempoproducao < this.Produto.tempoProducao) {
                    vtempoproducao = this.Produto.tempoProducao
                }
                console.log("TEMPO PRODUCAO:" + vtempoproducao)

                if (tipo == "E") {
                    let vvalorFrete = vgridEntregaFrete[0].valorFrete;

                    let vlistaHorarios = []
                    if (datasel == null) {
                        vgridfiltraExcecao.forEach((item) => {
                            if (moment().add(parseInt(item.atrasoHs, 10) + vtempoproducao, 'hour').format() <= moment().format('YYYY-MM-DDT' + this.validaHora(item.horaIni) + ':' + this.validaHora(item.minIni))) {
                                vlistaHorarios.push({ codigo: item.entregaExcecaoId, valorFrete: vvalorFrete, TipoFrete: tipo, dataEntrega: moment().format('YYYY-MM-DD'), texto: this.validaHora(item.horaIni) + ':' + this.validaHora(item.minIni) + ' às ' + this.validaHora(item.horaFim) + ':' + this.validaHora(item.minFim) + ' - valor R$ ' + parseFloat(vvalorFrete).toFixed(2).toString().replace('.', ',') });
                            }
                        });
                    }
                    else {
                        console.log("datasel e  " + datasel)
                        //mostra grid inteira a partir do tempo de produção do produto
                        vgridfiltraExcecao.forEach((item) => {
                            if (moment().add(parseInt(item.atrasoHs, 10) + vtempoproducao, 'hour').format() <= moment().format(datasel + 'T' + this.validaHora(item.horaIni) + ':' + this.validaHora(item.minIni))) {
                                vlistaHorarios.push({ codigo: item.entregaExcecaoId, valorFrete: vvalorFrete, TipoFrete: tipo, dataEntrega: datasel, texto: this.validaHora(item.horaIni) + ':' + this.validaHora(item.minIni) + ' às ' + this.validaHora(item.horaFim) + ':' + this.validaHora(item.minFim) + ' - valor R$ ' + parseFloat(vvalorFrete).toFixed(2).toString().replace('.', ',') });
                            }
                        });
                    }
                    this.ListasHorarios = vlistaHorarios;
                    if (this.ListasHorarios == '') {
                        if (vtempoproducao > 0) {
                            this.$mensagem_normal("Existe produtos selecionados que necessitam tempo de produção de " + vtempoproducao + " horas , por favor selecione nova data.");
                            //this.CarregaModal(1)
                        } else {
                            this.$mensagem_normal("Sem horário disponível nessa data.");
                        }
                    }
                }
                else {
                    let vlistaHorarios2 = []
                    this.$http
                        .get(this.$apiUrl + "/entregahorario")
                        .then((res2) => res2.json())
                        .then((tiu2) => {
                            this.ListaHorariosEntregaFrete = tiu2.filter(x => x.status)
                             
                            if (datasel == null) {
                                vgridEntregaFrete.forEach((item) => {
                                     let vcont = 0;
                                    console.log(vcont);
                                    //let agora = moment("2022-04-29T17:50:56.144");
                                    this.ListaHorariosEntregaFrete.forEach((itemhora) => {
                                         let agora = moment();
                                         let agora2 = moment();
/*                                          let debug = {
                                             atraso: item.atrasoHs,
                                             tempo: vtempoproducao,
                                             agora: agora.add(parseInt(item.atrasoHs, 10) + vtempoproducao, 'hour').format('YYYY-MM-DDTHH:mm'),
                                             compara: agora2.format('YYYY-MM-DDT' + this.validaHora(itemhora.horaIni) + ':' + this.validaHora(itemhora.minIni))
                                         }
                                         console.log("DEBUGA")
                                         console.log(debug); */
                                        if (agora.add(parseInt(item.atrasoHs, 10) + vtempoproducao, 'hour').format('YYYY-MM-DDTHH:mm') <= agora2.format('YYYY-MM-DDT' + this.validaHora(itemhora.horaIni) + ':' + this.validaHora(itemhora.minIni))) {
                                            console.log("hora");
                                            console.log(agora.format("HH:mm"));
                                            console.log(item.atrasoHs + " - " + vtempoproducao)
                                            if((agora.format('HH') < 7 || (agora.format('HH') == 7 && agora.format('mm') < 30)) && vcont == 0){
                                                console.log("gravei2")
                                            }
                                            else{
                                                vlistaHorarios2.push({ codigo: item.entregaFreteId, codigoHorario: itemhora.entregaHorarioId, valorFrete: item.valorFrete, TipoFrete: tipo, dataEntrega: moment().format('YYYY-MM-DD'), texto: this.validaHora(itemhora.horaIni) + ':' + this.validaHora(itemhora.minIni) + ' às ' + this.validaHora(itemhora.horaFim) + ':' + this.validaHora(itemhora.minFim) + ' - valor R$ ' + parseFloat(item.valorFrete).toFixed(2).toString().replace('.', ',') /*+ ' Tipo: ' + item.tipoEntrega*/ });
                                            }
                                            vcont++;
                                        
                                       }
                                    });
                                });
                            }
                            else {
                                console.log("datasel n  " + datasel)
                                //mostra grid inteira a partir do tempo de produção do produto
                                vgridEntregaFrete.forEach((item) => {
                                    let vcont = 0;
                                    //console.log(vcont);
                                    this.ListaHorariosEntregaFrete.forEach((itemhora) => {
                                        if (moment().add(parseInt(item.atrasoHs, 10) + vtempoproducao, 'hour').format() <= moment().format(datasel + 'T' + this.validaHora(itemhora.horaIni) + ':' + this.validaHora(itemhora.minIni))) {
                                            
                                             //console.log(moment().format('HH'));
                                             if (moment().format('HH') >= 20 && moment().add(1, 'day').format('YYYY-MM-DD') == moment(datasel).format('YYYY-MM-DD') && vcont == 0){
                                                console.log('gravei');
                                            }
                                            else{
                                                vlistaHorarios2.push({ codigo: item.entregaFreteId, codigoHorario: itemhora.entregaHorarioId, valorFrete: item.valorFrete, TipoFrete: tipo, dataEntrega: datasel, texto: this.validaHora(itemhora.horaIni) + ':' + this.validaHora(itemhora.minIni) + ' às ' + this.validaHora(itemhora.horaFim) + ':' + this.validaHora(itemhora.minFim) + ' - valor R$ ' + parseFloat(item.valorFrete).toFixed(2).toString().replace('.', ',') /* + ' Tipo: ' + item.tipoEntrega*/ });
                                            }
                                           
                                            vcont++;
                                        }
                                    });
                                });
                            }
                            this.ListasHorarios = vlistaHorarios2;
                            if (this.ListasHorarios == '') {
                                if (vtempoproducao > 0) {
                                    this.$mensagem_normal("Existe produtos selecionados que necessitam tempo de produção de " + vtempoproducao + " horas , por favor selecione nova data.");
                                    //this.CarregaModal(1)
                                } else {
                                    this.$mensagem_normal("Sem horário disponível nessa data.");
                                }
                            }
                        });


                }
            },
            carregaAgenda() {
                this.ListasHorarios = []
                this.periodo = ''
                if (this.date != null) {
                    moment.locale("pt-br");
                    let dataatual = moment().format('YYYY-MM-DD');
                    let horaatual = moment().format('HH');
                    if (this.Estadoselected == null) {
                        this.$mensagem_normal("Por favor escolha o estado");
                    } else if (this.Cidadeselected == null) {
                        this.$mensagem_normal("Por favor escolha a cidade");
                    } else if (this.Bairroselected == null) {
                        this.$mensagem_normal("Por favor escolha o bairro");
                    } else if (moment(dataatual).format('YYYY-MM-DD') > moment(this.date).format('YYYY-MM-DD')) {
                        this.$mensagem_normal("Data deve ser maior que a data atual.");
                    }
                    else {
                        //verifica se tem exceção para data e horario
                        this.$http
                            .get(this.$apiUrl + "/entregaexcecao")
                            .then((res2) => res2.json())
                            .then((tiu2) => {
                                this.ListaExcecao = tiu2.filter(x => moment(x.dataExcecao).format('LL') == moment(this.date).format('LL') && x.status)
                                let vcontrole = 0;
                                let filtraExcecao = []
                                //console.log(this.ListaExcecao)
                                let filtraEntregaFrete = []
                                if (this.ListaExcecao != '') {
                                    //testar se tem bairro
                                    this.ListaExcecao.forEach((itemex) => {
                                        try {
                                            if (itemex.estado.estadoId == this.EstadoId && itemex.cidade.cidadeId == this.CidadeId && itemex.bairro.bairroId == this.BairroId) {
                                                filtraExcecao.push(itemex)
                                                vcontrole = 1;
                                            }
                                        }
                                        catch {
                                            console.log(vcontrole)
                                        }
                                    });

                                    if (vcontrole == 0) {
                                        this.ListaExcecao.forEach((itemex) => {
                                            try {
                                                if (itemex.estado.estadoId == this.EstadoId && itemex.cidade.cidadeId == this.CidadeId && itemex.bairro == null) {
                                                    filtraExcecao.push(itemex)
                                                    vcontrole = 1;
                                                }
                                            }
                                            catch {
                                                console.log(vcontrole)
                                            }
                                        });
                                    }

                                    if (vcontrole == 0) {
                                        this.ListaExcecao.forEach((itemex) => {
                                            try {
                                                if (itemex.estado.estadoId == this.EstadoId && itemex.cidade == null && itemex.bairro == null) {
                                                    filtraExcecao.push(itemex)
                                                    vcontrole = 1;
                                                }
                                            }
                                            catch {
                                                console.log(vcontrole)
                                            }
                                        });
                                    }

                                    if (vcontrole == 0) {
                                        this.ListaExcecao.forEach((itemex) => {
                                            try {
                                                if (itemex.estado == null && itemex.cidade == null && itemex.bairro == null) {
                                                    filtraExcecao.push(itemex)
                                                    vcontrole = 1;
                                                }
                                            }
                                            catch {
                                                console.log(vcontrole)
                                            }
                                        });
                                    }

                                }



                                //não tem exceção
                                this.$http
                                    .get(this.$apiUrl + "/entregafrete")
                                    .then((res2) => res2.json())
                                    .then((tiu2) => {
                                        this.ListaEntregaFrete = tiu2.filter(x => x.status)
                                        //console.log(this.ListaEntregaFrete)
                                        if (this.ListaEntregaFrete != '') {

                                            let vcontrole2 = 0;
                                            //testar se tem bairro

                                            this.ListaEntregaFrete.forEach((itemex) => {
                                                try {
                                                    if (itemex.estado.estadoId == this.EstadoId && itemex.cidade.cidadeId == this.CidadeId && itemex.bairro.bairroId == this.BairroId) {
                                                        filtraEntregaFrete.push(itemex)
                                                        vcontrole2 = 1;
                                                    }
                                                }
                                                catch {
                                                    console.log(vcontrole2)
                                                }
                                            });

                                            if (vcontrole2 == 0) {
                                                this.ListaEntregaFrete.forEach((itemex) => {
                                                    try {
                                                        if (itemex.estado.estadoId == this.EstadoId && itemex.cidade.cidadeId == this.CidadeId && itemex.bairro == null) {
                                                            filtraEntregaFrete.push(itemex)
                                                            vcontrole2 = 1;
                                                        }
                                                    }
                                                    catch {
                                                        console.log(vcontrole2)
                                                    }
                                                });
                                            }

                                            if (vcontrole2 == 0) {
                                                this.ListaEntregaFrete.forEach((itemex) => {
                                                    try {
                                                        if (itemex.estado.estadoId == this.EstadoId && itemex.cidade == null && itemex.bairro == null) {
                                                            filtraEntregaFrete.push(itemex)
                                                            vcontrole2 = 1;
                                                        }
                                                    }
                                                    catch {
                                                        console.log(vcontrole2)
                                                    }
                                                });
                                            }

                                            if (vcontrole2 == 0) {
                                                this.ListaEntregaFrete.forEach((itemex) => {
                                                    try {
                                                        if (itemex.estado == null && itemex.cidade == null && itemex.bairro == null) {
                                                            filtraEntregaFrete.push(itemex)
                                                            vcontrole2 = 1;
                                                        }
                                                    }
                                                    catch {
                                                        console.log(vcontrole2)
                                                    }
                                                });
                                            }
                                        }


                                        if (filtraExcecao != 0) {
                                            if (filtraExcecao[0].qtde > 0) {
                                                if (moment(dataatual).format('YYYY-MM-DD') == moment(this.date).format('YYYY-MM-DD')) {
                                                    this.carregaListaHorarios(filtraExcecao, filtraEntregaFrete, horaatual, null, 'E')
                                                }
                                                else {
                                                    //a agenda não é hoje
                                                    this.carregaListaHorarios(filtraExcecao, filtraEntregaFrete, null, moment(this.date).format('YYYY-MM-DD'), 'E')
                                                }
                                            }
                                            else {
                                                this.$mensagem_normal("Não existe horários disponíveis para essa data.");
                                            }
                                        }
                                        else {
                                            //console.log(filtraEntregaFrete)
                                            if (moment(dataatual).format('YYYY-MM-DD') == moment(this.date).format('YYYY-MM-DD')) {
                                                this.carregaListaHorarios(filtraExcecao, filtraEntregaFrete, horaatual, null, 'N')
                                            }
                                            else {
                                                //a agenda não é hoje
                                                this.carregaListaHorarios(filtraExcecao, filtraEntregaFrete, null, moment(this.date).format('YYYY-MM-DD'), 'N')
                                            }
                                        }
                                    });
                            });
                    }
                }
            },
            deslizaHorarios() {
                console.log("endereco")
                let element = document.querySelector(".vHorarios");
                element.scrollIntoView();
            },
            VerificaExisteOpcional(value){
               if (value == 387){
                   //balao
                   if (this.arr_baloes == '' || this.arr_baloes == null){
                       return false
                   }
                   else{
                       return true
                   }
               }
               else if (value == 382){
                    if (this.ProdutosAvulso == '' || this.ProdutosAvulso == null){
                       return false
                   }
                   else{
                       return true
                   }
               }
               else{
                   return true
               }
            }
        },    
        created() {
          
            let dadosArmazenados = ''
            this.totalizaavulsos();
            this.totalizafoto();
            //carrega informações do produto
            this.$http
                .get(this.$apiUrl + "/produto/" + parseInt(this.ProdutoId, 10))
                .then((res2) => res2.json())
                .then((tiu2) => {
                    this.Produto = tiu2
                    console.log(this.Produto)
                    this.Indisponivel = this.Produto.indisponivel
                    if (this.Produto.filtroFabrica != null) {
                        if (this.Produto.filtroFabrica.nomeFabrica == 'Sim') {
                            this.Produto.imagens.sort(function (a, b) {
                                return a.ordem - b.ordem;
                            }).reverse().forEach(element => {
                                this.ImgProduto.push({ src: this.$Url + element.urlImg, thumbnail: this.$Url + element.urlImg })
                            }
                            );
                        } else {
                            this.Produto.imagens.sort(function (a, b) {
                                return a.ordem - b.ordem;
                            }).forEach(element => {
                                this.ImgProduto.push({ src: this.$Url + element.urlImg, thumbnail: this.$Url + element.urlImg })
                            }
                            );
                        }
                    }
                    else {
                        this.Produto.imagens.sort(function (a, b) {
                            return a.ordem - b.ordem;
                        }).forEach(element => {
                            this.ImgProduto.push({ src: this.$Url + element.urlImg, thumbnail: this.$Url + element.urlImg })
                        }
                        );
                    }


                    // console.log(this.Produto)

                    //verifica se esse produto é customizavel
                    if (this.Produto.filtroFabrica.filtroFabricaId == 2) {
                        this.pshowcustomizado = true;

                        //verifica qual tipo de fonte dos produtos customizados this.pavailable_fonts = tiu2.filter(x => x.visivel);
                        if (this.Produto.filtroCaracteristica.filtroCaracteristicaId == 1) {
                            //Fontes Opcionais
                            this.$http
                                .get(this.$apiUrl + "/filtromarca")
                                .then((res) => res.json())
                                .then((tiu) => {
                                    this.NomeFontes = tiu.filter(x => x.visivel)
                                    this.NomeFontes.forEach((itemex) => {
                                        this.pavailable_fonts.push({ fontId: itemex.filtroMarcaId, nome: itemex.nomeMarca })
                                    });
                                });

                        } else if (this.Produto.filtroCaracteristica.filtroCaracteristicaId == 2) {
                            //Fontes Produtos Gr
                            this.$http
                                .get(this.$apiUrl + "/filtrotamanho")
                                .then((res) => res.json())
                                .then((tiu) => {
                                    this.NomeFontes = tiu.filter(x => x.visivel)
                                    this.NomeFontes.forEach((itemex) => {
                                        this.pavailable_fonts.push({ fontId: itemex.filtrotamanhoId, nome: itemex.nomeTamanho })
                                    });
                                });
                        }
                        else {
                            //Fontes Produtos Pq
                            this.$http
                                .get(this.$apiUrl + "/filtrovoltagem")
                                .then((res) => res.json())
                                .then((tiu) => {
                                    this.NomeFontes = tiu.filter(x => x.visivel)
                                    this.NomeFontes.forEach((itemex) => {
                                        this.pavailable_fonts.push({ fontId: itemex.filtrovoltagemId, nome: itemex.nomeVoltagem })
                                    });
                                });

                        }

                        //verifica quantas linhas de texto

                        this.qtdeLinhas = parseInt(this.Produto.filtroMateriaPrima.nomeMateriaPrima, 10);

                    }
                });

            //carrega informações dos produtos inclusos
            this.$http
                .get(this.$apiUrl + "/produtoincluso/Produto/" + parseInt(this.ProdutoId, 10))
                .then((res2) => res2.json())
                .then((tiu2) => {
                    this.ProdutosIncluso = tiu2.sort(function (a, b) {
                        return a.ordem - b.ordem;
                    });
                });

            //carrega icones da barra do detalhe do produto
            this.$http
                .get(this.$apiUrl + "/institucional/telas/iconesbarra")
                .then((res2) => res2.json())
                .then((tiu2) => {
                    this.IconesBarra = tiu2
                });

            //carrega textos modal calendario
            this.$http
                .get(this.$apiUrl + "/institucional/telas/textos%20calendario")
                .then((res2) => res2.json())
                .then((tiu2) => {
                    this.TextoModalCalendario = tiu2[0];
                });

            //carrega estados ativos
            this.$http
                .get(this.$apiUrl + "/estados/ativos")
                .then((res) => res.json())
                .then((tiu) => {
                    this.Estados = tiu
                });

            //carrega informações de todos os produtos
            this.$http
                .get(this.$apiUrl + "/produtotextogeral")
                .then((res2) => res2.json())
                .then((tiu2) => {
                    if (tiu2 != null) {
                        this.UrlimgBannerGeral = tiu2[0].urlImg;
                        this.parcelas = parseInt(tiu2[0].texto1, 10);
                        this.parcelasTexto = tiu2[0].texto2;
                    }
                });

            //carrega titulos temas mensagens
            this.$http
                .get(this.$apiUrl + "/institucional/telas/textostema")
                .then((res2) => res2.json())
                .then((tiu2) => {
                    this.TextosTema = tiu2;
                });

            //carrega temas mensagens
            this.$http
                .get(this.$apiUrl + "/segmentoinstnivel1")
                .then((res2) => res2.json())
                .then((tiu2) => {
                    this.TemaMensagem = tiu2.filter(x => x.visivel);
                });

            //carrega titulos modelos mensagens
            this.$http
                .get(this.$apiUrl + "/institucional/telas/textosmodelo")
                .then((res2) => res2.json())
                .then((tiu2) => {
                    this.TextosModelo = tiu2;
                });

            //carrega modelos mensagens
            this.$http
                .get(this.$apiUrl + "/institucional/telas/modelo%20de%20cartoes")
                .then((res2) => res2.json())
                .then((tiu2) => {
                    this.ModeloMensagem = tiu2.filter(x => x.visivel);
                });

            //carrega instruções cartao
            this.$http
                .get(this.$apiUrl + "/institucional/telas/instrucoes%20cartao")
                .then((res2) => res2.json())
                .then((tiu2) => {
                    this.InstrucaoCartao = tiu2;
                });

            //carrega titulos textos opcionais
            this.$http
                .get(this.$apiUrl + "/institucional/telas/textosopcionais")
                .then((res2) => res2.json())
                .then((tiu2) => {
                    this.TextosOpcionais = tiu2;
                });

            //carrega textos e banner das opções dos opcionais
            this.$http
                .get(this.$apiUrl + "/institucional/telas/opcionais")
                .then((res2) => res2.json())
                .then((tiu2) => {
                    this.BannersOpcionais = tiu2.filter(x => x.visivel).sort(function (a, b) {
                        return a.ordem - b.ordem;
                    });
                });

            //carrega titulos textos fotos
            this.$http
                .get(this.$apiUrl + "/institucional/telas/textosfotos")
                .then((res2) => res2.json())
                .then((tiu2) => {
                    this.TextosFotos = tiu2;
                });

            //carrega titulos avulsos
            this.$http
                .get(this.$apiUrl + "/institucional/telas/textosavulsos")
                .then((res2) => res2.json())
                .then((tiu2) => {
                    this.TextosAvulsos = tiu2;
                });

            //carrega produtos avulsos e balões
            this.$http
                .get(this.$apiUrl + "/ProdutoAvulso/produto/" + parseInt(this.ProdutoId, 10))
                .then((res2) => res2.json())
                .then((tiu2) => {
                    this.ProdutosAvulso = tiu2.filter(x => x.produto.visivel && !x.itemAvulso.vendaAvulsa).sort(function (a, b) {
                        return a.ordem - b.ordem;
                    });

                    this.arr_baloes = tiu2.filter(x => x.produto.visivel && x.itemAvulso.vendaAvulsa).sort(function (a, b) {
                        return a.ordem - b.ordem;
                    });

                });

            //carrega filtro cores
            this.$http
                .get(this.$apiUrl + "/filtrocor")
                .then((res2) => res2.json())
                .then((tiu2) => {
                    this.FitroCores = tiu2.filter(x => x.visivel);
                });

            //Carrega Fontes Opcionais pavailable_fonts
            this.$http
                .get(this.$apiUrl + "/filtromarca")
                .then((res2) => res2.json())
                .then((tiu2) => {
                    this.available_fonts = tiu2.filter(x => x.visivel);
                });


            // cria localstorage para armazenar informações da compra
            dadosArmazenados = localStorage.getItem('dadoscompralove');
            if (!dadosArmazenados) {
                dadosArmazenados = this.DadosCompra;
                localStorage.setItem('dadoscompralove', JSON.stringify(dadosArmazenados))
            }
            else {
                dadosArmazenados = JSON.parse(dadosArmazenados);

                if (dadosArmazenados.produtos == '' && dadosArmazenados.avulsos != '') {
                    dadosArmazenados.avulsos = [];
                    localStorage.setItem('dadoscompralove', JSON.stringify(dadosArmazenados))
                }
                else {

                    if (dadosArmazenados.produtos != '') {
                        this.barraVisivel = false;
                    }

                    if (dadosArmazenados.agenda != '') {
                        this.Estadoselected = dadosArmazenados.agenda[0].estado
                        this.MudaEstado(dadosArmazenados.agenda[0].estado)
                        this.Cidadeselected = dadosArmazenados.agenda[0].cidade
                        this.MudaCidade(dadosArmazenados.agenda[0].cidade)
                        this.Bairroselected = dadosArmazenados.agenda[0].bairro
                        this.MudaBairro(dadosArmazenados.agenda[0].bairro)
                        this.Endereco = dadosArmazenados.agenda[0].endereco
                        this.Numero = dadosArmazenados.agenda[0].numero
                        this.Complemento = dadosArmazenados.agenda[0].complemento
                        this.date = dadosArmazenados.agenda[0].data
                        this.CEP = dadosArmazenados.agenda[0].cep
                        this.carregaAgenda();
                        this.periodo = dadosArmazenados.agenda[0].periodoEntrega
                        this.trocafoto1 = true;
                    }
                    else {
                        this.trocafoto1 = false;
                    }
                    if (dadosArmazenados.mensagem != '') {
                        if (dadosArmazenados.mensagem[0].imagemId != 0) {
                            this.ModeloCartaoEscolhidoUrl = dadosArmazenados.mensagem[0].urlImagem
                            this.ConteudoCartao = dadosArmazenados.mensagem[0].textoMensagem
                            this.trocafoto2 = true;
                        }
                        else {
                            this.trocafoto2 = true;
                        }
                    }
                    else {
                        this.trocafoto2 = false;
                    }

                    if (dadosArmazenados.foto != '') {
                        if (dadosArmazenados.foto[0].imagemUrl == 'Sem foto') {
                            this.totalfoto = 0;
                            this.trocafoto3 = true;
                        }
                        else {
                            this.FotoCarregada = dadosArmazenados.foto[0].imagemUrl
                            this.totalfoto = dadosArmazenados.foto[0].valor
                        }

                    }
                    else {
                        this.trocafoto3 = false;
                        this.totalfoto = 0;
                    }

                    if (dadosArmazenados.avulsos != '' || dadosArmazenados.foto != '' || dadosArmazenados.baloes != '') {
                        this.trocafoto3 = true;
                    }
                    else {
                        this.trocafoto3 = false;
                    }

                    if (this.Pagina != null && this.vModal != null) {
                        this.CarregaModal(parseInt(this.vModal, 10));
                    }
                   /*  else {
                        if (dadosArmazenados.agenda[0] != null && dadosArmazenados.mensagem[0] != null && (dadosArmazenados.foto != '' || dadosArmazenados.avulsos != '' || dadosArmazenados.baloes != '')) {
                            this.CarregaModal(4);
                        } 
                    }*/
                    // carrega informações já cadastradas
                }
            }
        },
        mounted() {

        },
        computed: {
            subtotal: function () {
                return this.totalfoto + this.totalavulsos + this.Produto.valorAte;
            },
        }
    }
</script>

<style scoped>
    @import '/css/produto_detalhes.css';
    @import '/css/nav_detalhes.css';
    @import '/css/modais.css';
    @import url('https://fonts.googleapis.com/css2?family=Redressed&display=swap');

   .texto-destaque {
    font-family: 'MyriadPro-LightB';
    color: #ef6f15;
    font-size: 18px;
    font-weight: 600;
   }

    p {
        margin-top: 0;
        margin-bottom: 0px !important;
    }

    a.btn-outline-card:hover, button.btn-outline-card:hover {
        background-color: #ffffff;
        color: #59c229;
        font-weight: 400;
    }

    @media (max-width:600px) {
        .textareacartao {
            background: transparent;
            color: #000000;
            resize: none;
            border: 0 none;
            width: 100%;
            outline: none;
            height: 270px;
            overflow: hidden;
            position: relative;
            padding: 10px;
            font-family: Handlee !important;
            font-size: 12px;
        }

        .imagemCartao {
            height: 270px;
            width: auto;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center top;
        }

        .imgcel {
            width: 90%;
        }

        .tamvalor{
            font-size:13px;
        }
          .tamvalor2{
            font-size:12px;
        }
         .texto-destaque {
            font-family: 'MyriadPro-LightB';
            color: #ef6f15;
            font-size: 14px;
            font-weight: 600;
        }
    }

    @media (min-width:608px) and (max-width:991px) {
        .textareacartao {
            background: transparent;
            color: #000000;
            resize: none;
            border: 0 none;
            width: 98%;
            outline: none;
            height: 450px;
            overflow: hidden;
            position: relative;
            padding: 10px;
            font-family: Handlee !important;
            font-size: 21px;
        }

        .imagemCartao {
            height: 450px;
            width: auto;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center top;
        }

        .imgcel {
            width: 80%;
        }
    }

    @media (min-width:992px) and (max-width:1199px) {
        .textareacartao {
            background: transparent;
            color: #000000;
            resize: none;
            border: 0 none;
            width: 90%;
            outline: none;
            height: 450px;
            overflow: hidden;
            position: relative;
            padding: 10px;
            font-family: Handlee !important;
            font-size: 21px;
        }

        .imagemCartao {
            height: 450px;
            width: auto;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center top;
        }

        .imgcel {
            width: 70%;
        }
    }

    @media (min-width:1200px) {
        .textareacartao {
            background: transparent;
            color: #000000;
            resize: none;
            border: 0 none;
            width: 90%;
            outline: none;
            height: 450px;
            overflow: hidden;
            position: relative;
            padding: 10px;
            font-family: Handlee !important;
            font-size: 21px;
        }

        .imagemCartao {
            height: 450px;
            width: auto;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center top;
        }

        .imgcel {
            width: 70%;
        }
    }

    /*balao nos opcionais*/
    #container {
        display: inline-block;
        position: relative;
        text-align: center;
    }

        #container figcaption {
            position: absolute;
            top: 102px;
            font-size: 29px;
            line-height: 1.1;
            color: black;
            /*            text-shadow: 0px 0px 5px black;*/
        }

    /*balao no detalhe*/
    #container2 {
        display: inline-block;
        position: relative;
        text-align: center;
    }

        #container2 figcaption {
            position: absolute;
            top: 70px;
            font-size: 30px;
            color: black;
            /*text-shadow: 0px 0px 5px black;*/
        }

    @media (max-width:600px) {

        /*balao nos opcionais*/
        #container {
            display: inline-block;
            position: relative;
            text-align: center;
        }

            #container figcaption {
                position: absolute;
                top: 26px;
                font-size: 25px;
                text-align: center;
                line-height: .65;
                color: black;
                /*            text-shadow: 0px 0px 5px black;*/
            }

        /*balao no detalhe*/
        #container2 {
            display: inline-block;
            position: relative;
            text-align: center;
        }

            #container2 figcaption {
                position: absolute;
                top: 70px;
                font-size: 30px;
                color: black;
                /*text-shadow: 0px 0px 5px black;*/
            }
    }

    /* Fonts */
  @font-face {
    font-family: 'BELLGOTHIC'; 
    src: url('/fonts/BELL.eot');
    src: url('/fonts/BELL.eot') format('embedded-opentype'),
    url('/fonts/BELL.woff2') format('woff2'),
    url('/fonts/BELL.woff') format('woff'),
    url('/fonts/BELL.TTF') format('truetype'),
    url('/fonts/BELL.svg#GOTHIC') format('svg');
  }

  @font-face {
    font-family: 'BELLGOTHICB';
    src: url('/fonts/BELLB.eot');
    src: url('/fonts/BELLB.eot') format('embedded-opentype'), url('/fonts/BELLB.woff2') format('woff2'), url('/fonts/BELLB.woff') format('woff'), url('/fonts/BELLB.ttf') format('truetype'), url('/fonts/BELLB.svg#GOTHICB') format('svg');
  }

  @font-face {
    font-family: 'BELLGOTHICI';
    src: url('/fonts/BELLI.eot');
    src: url('/fonts/BELLI.eot') format('embedded-opentype'), url('/fonts/BELLI.woff2') format('woff2'), url('/fonts/BELLI.woff') format('woff'), url('/fonts/BELLI.ttf') format('truetype'), url('/fonts/BELLI.svg#GOTHICI') format('svg');
  }

  @font-face {
    font-family: 'GOTHIC'; 
    src: url('/fonts/GOTHIC.eot');
    src: url('/fonts/GOTHIC.eot') format('embedded-opentype'), url('/fonts/GOTHIC.woff2') format('woff2'), url('/fonts/GOTHIC.woff') format('woff'), url('/fonts/GOTHIC.ttf') format('truetype'), url('/fonts/GOTHIC.svg#GOTHIC') format('svg');
  }

  @font-face {
    font-family: 'GOTHICB'; 
    src: url('/fonts/GOTHICB.eot');
    src: url('/fonts/GOTHICB.eot') format('embedded-opentype'), url('/fonts/GOTHICB.woff2') format('woff2'), url('/fonts/GOTHICB.woff') format('woff'), url('/fonts/GOTHICB.ttf') format('truetype'), url('/fonts/GOTHICB.svg#GOTHICB') format('svg');
  }

  @font-face {
    font-family: 'FontAwesome';
    src: url('/fonts/fontawesome-webfont.eot?v=4.2.0');
    src: url('/fonts/fontawesome-webfont.eot?#iefix&v=4.2.0') format('embedded-opentype'),url('/fonts/fontawesome-webfont.woff?v=4.2.0') format('woff'),url('/fonts/fontawesome-webfont.ttf?v=4.2.0') format('truetype'),url('/fonts/fontawesome-webfont.svg?v=4.2.0#fontawesomeregular') format('svg');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'MyriadPro-Bold';
    src: url('/fonts/myriadpro-bold-webfont.eot?v=4.2.0');
    src: url('/fonts/myriadpro-bold-webfont.eot?#iefix&v=4.2.0') format('embedded-opentype'), url('/fonts/myriadpro-bold-webfont.woff?v=4.2.0') format('woff'), url('/fonts/myriadwebpro-bold.ttf') format('truetype');

    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'MyriadPro-Regular';
    src: url('/fonts/MyriadPro-Regular.eot?v=4.2.0');
    src: local('Myriad Web Pro'), url('/fonts/MyriadPro-Regular.eot?#iefix&v=4.2.0') format('embedded-opentype'), url('/fonts/MyriadPro-Regular.woff?v=4.2.0') format('woff'), url('/fonts/myriad-web-pro.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'FontAwesome';
    src: url('/fonts/fontawesome-webfont.eot?v=4.2.0');
    src: url('/fonts/fontawesome-webfont.eot?#iefix&v=4.2.0') format('embedded-opentype'), url('/fonts/fontawesome-webfont.woff?v=4.2.0') format('woff'), url('/fonts/fontawesome-webfont.ttf?v=4.2.0') format('truetype'), url('/fonts/fontawesome-webfont.svg?v=4.2.0#fontawesomeregular') format('svg');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'MyriadPro-LightB';
    src: url('/fonts/myriadpro-bold-webfont.eot?v=4.2.0');
    src: url('/fonts/myriadpro-bold-webfont.eot?#iefix&v=4.2.0') format('embedded-opentype'), url('/fonts/myriadpro-bold-webfont.woff?v=4.2.0') format('woff'), url('/fonts/myriadwebpro-bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'MyriadPro-Regular';
    src: url('/fonts/MyriadPro-Regular.eot?v=4.2.0');
    src: local('Myriad Web Pro'), url('/fonts/MyriadPro-Regular.eot?#iefix&v=4.2.0') format('embedded-opentype'), url('/fonts/myriad-web-pro.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }


  @font-face {
    font-family: 'Myriad-CondensadaIt';
    src: url('/fonts/MyriadProCondIt.eot');
    src: url('/fonts/MyriadProCondIt.eot') format('embedded-opentype'), url('/fonts/MyriadProCondIt.woff2') format('woff2'), url('/fonts/MyriadProCondIt.woff') format('woff'), url('/fonts/MyriadProCondIt.ttf') format('truetype'), url('/fonts/MyriadProCondIt.svg#MyriadProCondIt') format('svg');
    font-weight: normal;
    font-style: normal;
  }

   @font-face {
    font-family: 'Myriad-Condensada';
    src: url('/fonts/MyriadProCond.eot');
    src: url('/fonts/MyriadProCond.eot') format('embedded-opentype'), url('/fonts/MyriadProCond.woff2') format('woff2'), url('/fonts/MyriadProCond.woff') format('woff'), url('/fonts/MyriadProCond.ttf') format('truetype'), url('/fonts/MyriadProCond.svg#MyriadProCond') format('svg');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Myriad-semibold';
    src: url('/fonts/MyriadProSemibold.eot');
    src: url('/fonts/MyriadProSemibold.eot') format('embedded-opentype'), url('/fonts/MyriadProSemibold.woff2') format('woff2'), url('/fonts/MyriadProSemibold.woff') format('woff'), url('/fonts/MyriadProSemibold.ttf') format('truetype'), url('/fonts/MyriadProSemibold.svg#MyriadProSemibold') format('svg');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'MyriadPro-Light';
    src: url('/fonts/MyriadPro-Light.eot');
    src: url('/fonts/MyriadPro-Light.eot') format('embedded-opentype'), url('/fonts/MyriadPro-Light.woff2') format('woff2'), url('/fonts/MyriadPro-Light.woff') format('woff'), url('/fonts/MyriadPro-Light.ttf') format('truetype'), url('/fonts/MyriadPro-Light.svg#MyriadPro-Light') format('svg');
    font-weight: normal;
    font-style: normal;
  }

    @font-face {
        font-family: 'Akhir Tahun';
        src: url('/fonts/aAkhirTahun.otf');
        src: url('/fonts/aAkhirTahun.otf') format('embedded-opentype'), url('/fonts/aAkhirTahun.ttf') format('truetype');
        font-weight: normal;
        font-style: normal;
    }

    @font-face {
        font-family: 'Adelia';
        src: url('/fonts/adelia.ttf');
        src: url('/fonts/adelia.ttf') format('truetype');
        font-weight: normal;
        font-style: normal;
    }

    @font-face {
        font-family: 'Afilia';
        src: url('/fonts/Afilia.otf');
        src: url('/fonts/Afilia.otf') format('embedded-opentype'), url('/fonts/Afilia.ttf') format('truetype');
        font-weight: normal;
        font-style: normal;
    }

     @font-face {
        font-family: 'Angelin';
        src: url('/fonts/Angelin.otf');
        src: url('/fonts/Angelin.otf') format('embedded-opentype'), url('/fonts/Angelin.ttf') format('truetype');
        font-weight: normal;
        font-style: normal;
    }

    @font-face {
        font-family: 'Artistic Calligraphy';
        src: url('/fonts/ArtisticCalligraphy.otf');
        src: url('/fonts/ArtisticCalligraphy.otf') format('embedded-opentype'), url('/fonts/ArtisticCalligraphy.ttf') format('truetype');
        font-weight: normal;
        font-style: normal;
    }

    @font-face {
        font-family: 'Awake the Beauty';
        src: url('/fonts/AwaketheBeauty.ttf');
        src: url('/fonts/AwaketheBeauty.ttf') format('truetype');
        font-weight: normal;
        font-style: normal;
    }

    @font-face {
        font-family: 'Back to Black';
        src: url('/fonts/BacktoBlackDemo.ttf');
        src: url('/fonts/BacktoBlackDemo.ttf') format('truetype');
        font-weight: normal;
        font-style: normal;
    }

    @font-face {
        font-family: 'Black Bird';
        src: url('/fonts/Blackbird.ttf');
        src: url('/fonts/Blackbird.ttf') format('truetype');
        font-weight: normal;
        font-style: normal;
    }

    @font-face {
        font-family: 'Blue Yellow';
        src: url('/fonts/BlueYellow.otf');
        src: url('/fonts/BlueYellow.otf') format('embedded-opentype'), url('/fonts/BlueYellow.ttf') format('truetype');
        font-weight: normal;
        font-style: normal;
    }

    @font-face {
        font-family: 'Butterfly Winter';
        src: url('/fonts/ButterflyWinter.otf');
        src: url('/fonts/ButterflyWinter.otf') format('embedded-opentype'), url('/fonts/ButterflyWinter.ttf') format('truetype');
        font-weight: normal;
        font-style: normal;
    }

    @font-face {
        font-family: 'Candy Cake';
        src: url('/fonts/CandyCake.ttf');
        src: url('/fonts/CandyCake.ttf') format('truetype');
        font-weight: normal;
        font-style: normal;
    }

    @font-face {
        font-family: 'Choco Crunch';
        src: url('/fonts/ChocoCrunch.ttf');
        src: url('/fonts/ChocoCrunch.ttf') format('truetype');
        font-weight: normal;
        font-style: normal;
    }

    @font-face {
        font-family: 'Chocolate';
        src: url('/fonts/Chocolateotf.otf');
        src: url('/fonts/Chocolateotf.otf') format('embedded-opentype'), url('/fonts/Chocolate.ttf') format('truetype');
        font-weight: normal;
        font-style: normal;
    }

    @font-face {
        font-family: 'Cornelia';
        src: url('/fonts/Cornelia.ttf');
        src: url('/fonts/Cornelia.ttf') format('truetype');
        font-weight: normal;
        font-style: normal;
    }

    @font-face {
        font-family: 'Gamerock';
        src: url('/fonts/Gamerock.otf');
        src: url('/fonts/Gamerock.otf') format('embedded-opentype');
        font-weight: normal;
        font-style: normal;
    }

    @font-face {
        font-family: 'Goldena';
        src: url('/fonts/Goldena.otf');
        src: url('/fonts/Goldena.otf') format('embedded-opentype');
        font-weight: normal;
        font-style: normal;
    }

    @font-face {
        font-family: 'Happy Birthday';
        src: url('/fonts/happyBirthday.ttf');
        src: url('/fonts/happyBirthday.ttf') format('truetype');
        font-weight: normal;
        font-style: normal;
    }

    @font-face {
        font-family: 'Harmony';
        src: url('/fonts/HARMONYPersonal.otf');
        src: url('/fonts/HARMONYPersonal.otf') format('embedded-opentype');
        font-weight: normal;
        font-style: normal;
    }

    @font-face {
        font-family: 'Homework';
        src: url('/fonts/Homework.otf');
        src: url('/fonts/Homework.otf') format('embedded-opentype'), url('/fonts/Homework.ttf') format('truetype');
        font-weight: normal;
        font-style: normal;
    }

    @font-face {
        font-family: 'Keep Calm';
        src: url('/fonts/KeepCalm-Medium.ttf');
        src: url('/fonts/KeepCalm-Medium.ttf') format('truetype');
        font-weight: normal;
        font-style: normal;
    }

    @font-face {
        font-family: 'Kidgames';
        src: url('/fonts/KidGames.ttf');
        src: url('/fonts/KidGames.ttf') format('truetype');
        font-weight: normal;
        font-style: normal;
    }

    @font-face {
        font-family: 'Kids on the Moon';
        src: url('/fonts/KidsOnTheMoon.ttf');
        src: url('/fonts/KidsOnTheMoon.ttf') format('truetype');
        font-weight: normal;
        font-style: normal;
    }

    @font-face {
        font-family: 'Lambresia';
        src: url('/fonts/lambresia.otf');
        src: url('/fonts/lambresia.otf') format('embedded-opentype');
        font-weight: normal;
        font-style: normal;
    }

    @font-face {
        font-family: 'Le Chant des Albatros';
        src: url('/fonts/LechantdesAlbatros.ttf');
        src: url('/fonts/LechantdesAlbatros.ttf') format('truetype');
        font-weight: normal;
        font-style: normal;
    }

    @font-face {
        font-family: 'Love Script';
        src: url('/fonts/Lovescript.otf');
        src: url('/fonts/Lovescript.otf') format('embedded-opentype'), url('/fonts/Lovescript.ttf') format('truetype');
        font-weight: normal;
        font-style: normal;
    }

    @font-face {
        font-family: 'Machington';
        src: url('/fonts/MachingtonDemoRegular.ttf');
        src: url('/fonts/MachingtonDemoRegular.ttf') format('truetype');
        font-weight: normal;
        font-style: normal;
    }

    @font-face {
        font-family: 'Magic Heart';
        src: url('/fonts/MagicHeart.otf');
        src: url('/fonts/MagicHeart.otf') format('embedded-opentype');
        font-weight: normal;
        font-style: normal;
    }

    @font-face {
        font-family: 'Magical Holiday';
        src: url('/fonts/MagicalHoliday.ttf');
        src: url('/fonts/MagicalHoliday.ttf') format('truetype');
        font-weight: normal;
        font-style: normal;
    }

    @font-face {
        font-family: 'Making Lovem';
        src: url('/fonts/makinglovem.ttf');
        src: url('/fonts/makinglovem.ttf') format('truetype');
        font-weight: normal;
        font-style: normal;
    }

    @font-face {
        font-family: 'Mango Salsa';
        src: url('/fonts/MangoSalsa.ttf');
        src: url('/fonts/MangoSalsa.ttf') format('truetype');
        font-weight: normal;
        font-style: normal;
    }

    @font-face {
        font-family: 'Minecrafter';
        src: url('/fonts/Minecrafter.ttf');
        src: url('/fonts/Minecrafter.ttf') format('truetype');
        font-weight: normal;
        font-style: normal;
    }

    @font-face {
        font-family: 'Samantha';
        src: url('/fonts/SamanthaDemo.ttf');
        src: url('/fonts/SamanthaDemo.ttf') format('truetype');
        font-weight: normal;
        font-style: normal;
    }

    @font-face {
        font-family: 'Sweet Chili';
        src: url('/fonts/SweetChiliDemo.ttf');
        src: url('/fonts/SweetChiliDemo.ttf') format('truetype');
        font-weight: normal;
        font-style: normal;
    }

    @font-face {
        font-family: 'Sweet Purple';
        src: url('/fonts/sweetpurple.otf');
        src: url('/fonts/sweetpurple.otf') format('embedded-opentype'), url('/fonts/Lovescript.ttf') format('truetype');
        font-weight: normal;
        font-style: normal;
    }

    @font-face {
        font-family: 'Valentine';
        src: url('/fonts/Valentime.otf');
        src: url('/fonts/Valentime.otf') format('embedded-opentype');
        font-weight: normal;
        font-style: normal;
    }

    @font-face {
        font-family: 'Vintage Party';
        src: url('/fonts/VintageParty-FreeVersion.ttf');
        src: url('/fonts/VintageParty-FreeVersion.ttf') format('truetype');
        font-weight: normal;
        font-style: normal;
    }


    @font-face {
        font-family: 'Waltograph';
        src: url('/fonts/waltograph42.otf');
        src: url('/fonts/waltograph42.otf') format('embedded-opentype'), url('/fonts/waltographUI.ttf') format('truetype');
        font-weight: normal;
        font-style: normal;
    }

    @font-face {
        font-family: 'Weather Sunday';
        src: url('/fonts/WeatherSunday.otf');
        src: url('/fonts/WeatherSunday.otf') format('embedded-opentype');
        font-weight: normal;
        font-style: normal;
    }

    @font-face {
        font-family: 'Built Titling';
        src: url('/fonts/builttitlingrg.ttf');
        src: url('/fonts/builttitlingrg.ttf') format('truetype');
        font-weight: normal;
        font-style: normal;
    }

    @font-face {
        font-family: 'Go Bold';
        src: url('/fonts/GoboldRegular.otf');
        src: url('/fonts/GoboldRegular.otf') format('embedded-opentype');
        font-weight: normal;
        font-style: normal;
    }
</style>