<template>
 
    <div class="agendamento" v-if="DadosPaypal!=''">
         <div class="row">
            <div class="col-sm-7 offset-sm-5" style="text-align:center;">
                <img v-if="DadosPaypal.cadOperadora!=''" class="img-fluid" :src="$Url + DadosPaypal.cadOperadora.urlImg" style="width:200px;height:auto;" alt="">
            </div>
        </div>
        <div class="row" style="text-align:center;">
            <div class="col-sm-5"><p class="texto"></p></div>
            <div class="col-sm-7">
                <p class="texto-destaque">Valor Total da compra R$ {{ parseFloat(TotalProdutos).toFixed(2).toString().replace('.', ',') }}</p>
                <p class="texto-strong d-inline-block mb-0 mr-2">
                Clique abaixo para confirmar seu pedido.
                </p>

            </div>
            <div class="col-sm-7 offset-sm-5">
                <!-- eslint-disable-next-line vue/attribute-hyphenation  -->
                <!--PaypalButtons :on-approve="onApprove" :create-order="createOrder" :on-shipping-change="onShippingChange" :on-error="onError" :style-object="style" /-->
                <button @click="ComprovanteEfetuado()" class="btn btn-success w-100">Confirmar Pedido</button>
            </div>
        </div>
    </div>

</template>

<script>
    //import Vue from 'vue';

    export default {
/*         components: {
            'paypal-buttons': window.paypal.Buttons.driver('vue', Vue)
        }, */

        data() {
            return {
                paypalRequestId: '',
                TotalProdutos: 0,
                DadosPaypal: '',
                FotoCarregada: '',
            };
        },
        methods: {
            ComprovanteEfetuado() {
                let dadosArmazenados = localStorage.getItem('dadoscompralove');
                dadosArmazenados = JSON.parse(dadosArmazenados);
                dadosArmazenados.pagamento = []
                dadosArmazenados.pagamento.push({
                    FormaPgto: "Paypal",
                    TipoPgto: 'cartao',
                    CodId: 3,
                    ValorTotal: this.TotalProdutos,
                    FotoComprova: this.FotoCarregada,
                });
                localStorage.setItem('dadoscompralove', JSON.stringify(dadosArmazenados))
                this.$redirect("/pedidoRealizado");
            },
        },
        created() {

            this.$http
                .get(this.$apiUrl + "/configoperadora/4")
                .then((res) => res.json())
                .then((retornoAPI) => {
                    this.DadosPaypal = retornoAPI;
                    //this.TokenYapay = '';
                });

            let dadosArmazenados = localStorage.getItem('dadoscompralove');
            dadosArmazenados = JSON.parse(dadosArmazenados);

            let _totalProdutos = 0;
            if (dadosArmazenados.produtos != '') {
                dadosArmazenados.produtos.forEach((itemprod) => {
                    _totalProdutos += parseFloat(itemprod.produto.valorAte)
                });
            }

            if (dadosArmazenados.avulsos != '') {
                if (dadosArmazenados.avulsos[0].produtoAvulsoId != 0) {
                    dadosArmazenados.avulsos.forEach((itemavul) => {
                        _totalProdutos += parseFloat(itemavul.avulso.valorDe)
                    });
                }
            }

            if (dadosArmazenados.foto != '') {
                if (dadosArmazenados.foto[0].imagemUrl != "Sem foto") {
                    dadosArmazenados.foto.forEach((itemfoto) => {
                        _totalProdutos += parseFloat(itemfoto.valor)
                    });
                }
            }

             if (dadosArmazenados.baloes != '') {
                    if (dadosArmazenados.baloes[0].avulso != 'Sem Balao') {
                        dadosArmazenados.baloes.forEach((itemavul) => {
                            _totalProdutos += parseFloat(itemavul.avulso.valorDe)
                        });
                    }
                }

                 let _cupom = 0;
                  if (dadosArmazenados.cupom != '') {
                     _cupom = parseFloat(dadosArmazenados.cupom[0].ValorCupom, 10)
                 }

            if (dadosArmazenados.agenda != '') {
                if (dadosArmazenados.agenda[0].periodoEntrega != '') {
                    _totalProdutos += parseFloat(dadosArmazenados.agenda[0].periodoEntrega.valorFrete)

                }
            }

            this.TotalProdutos = _totalProdutos  - _cupom;
        },
        computed: {
            createOrder: function () {
                return (data, actions) => {
                    return actions.order.create({
                        purchase_units: [
                            {
                                reference_id: "COMPRALOVECESTAS",
                                amount: {
                                    value: this.TotalProdutos.toString(),
                                },
                            },
                        ],
                    });
                }
            },
            onApprove: function () {
                return (data, actions) => {
                    return actions.order.capture().then(function (orderData) {
                        console.log('Capture result', orderData, JSON.stringify(orderData, null, 2));
                        var transaction = orderData.purchase_units[0].payments.captures[0];

                        let dadosArmazenados = localStorage.getItem('dadoscompralove');
                        dadosArmazenados = JSON.parse(dadosArmazenados);
                        dadosArmazenados.pagamento = []
                        dadosArmazenados.pagamento.push({
                            FormaPgto: 'Paypal - Transaction ' + transaction.status + ': ' + transaction.id,
                            TipoPgto: 'cartao',
                            CodId: '3',
                            ValorTotal: this.TotalProdutos,
                            FotoComprova: '',
                        });
                        localStorage.setItem('dadoscompralove', JSON.stringify(dadosArmazenados))
                        this.$redirect("/pedidoRealizado");
                    });
                }
            },
            onShippingChange: function () {
                return (data, actions) => {
                    if (data.shipping_address.country_code != 'BR') {
                        return actions.reject();
                    }
                    return actions.resolve();
                }
            },
            onError: function () {
                return (err) => {
                    //console.log(err);
                    this.$mensagem_normal("N�o foi poss�vel efetuar o pagamento. - COD: " + err);
                }
            },
            style: function () {
                return {
                    shape: 'rect',
                    color: 'blue',
                    layout: 'horizontal',
                    size: 'responsive',
                    label: 'paypal',
                    tagline: true
                }
            },
        },
    beforeMount() {
       /* const src = `https://www.paypal.com/sdk/js?currency=BRL&client-id=AU97o2ln20hVGR6ffCzYR8fP44nUmz9bC40QF9qKMM0kflj_jMNUByMpxrFUfcoeCJhsXBONnNyiClKg`;
        if (document.querySelectorAll(`head script[src="${src}"]`).length <= 0) {
            const script = document.createElement('script');
            script.setAttribute('src', src);
            script.onload = () => {
                const PaypalButtons = window.paypal.Buttons.driver('vue', Vue);
                Vue.component('PaypalButtons', PaypalButtons);
            }
            document.head.appendChild(script);
        } else {
            const PaypalButtons = window.paypal.Buttons.driver('vue', Vue);
            Vue.component('PaypalButtons', PaypalButtons);
        }*/
    }
    };
</script>