<template>

  <section class="topbar">
    <div class="container-fluid px-xl-4">
      <div class="row">
        <div class="col topbar-content">
          <!-- Links Sociais -->
            <div class="topbar-content-social">
                <a v-if="Face!=null && Face!=''" :href="Face[0].nome" target="_blank"><i class="fab fa-facebook-square facebook"></i></a>
                <a v-if="Insta!=null && Insta!=''" :href="Insta[0].nome" target="_blank"><i class="fab fa-instagram-square instagram"></i></a>
                <a v-if="Linke!=null && Linke!=''" :href="Linke[0].nome" target="_blank"><i class="fab fa-linkedin linkedin"></i></a>
                <a v-if="Yout!=null && Yout!=''" :href="Yout[0].nome" target="_blank"><i class="fab fa-youtube-square youtube"></i></a>
                <router-link to="/login">Minha Conta</router-link>
            </div>

          <!-- Whatsapp -->
          <div class="topbar-content-call hidden-sm-md">
            <div class="after" v-if="Telefone!=''">
              <i class="fas fa-phone-alt"></i><a>{{ Telefone[0].nome }}</a></div>
            <div v-for="What in Whats" :key="What.institucionalId">
                <img src="/img/social/whatsapp.png" alt="">
                <a :href="What.linkBtn1" target="_blank">{{ What.nome }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

</template>

<script>
    // import Cabecalho from "./_shared/Cabecalho.vue";
    // import Rodape from "./_shared/Rodape.vue";

     export default {
        data() {
            return {
                Face: [],
                Insta: [],
                Linke: [],
                Yout: [],
                Telefone: [],
                Whats: [],
                What: {},
            };
        },
        methods: {
        },
        created() {

            this.$http
                .get(this.$apiUrl + "/institucional/telas/facebook")
                .then((res2) => res2.json())
                .then((tiu2) => {
                    this.Face = tiu2.filter(x=> x.visivel)
                });

            this.$http
                .get(this.$apiUrl + "/institucional/telas/instagram")
                .then((res2) => res2.json())
                .then((tiu2) => {
                    this.Insta = tiu2.filter(x=> x.visivel)
                });

            this.$http
                .get(this.$apiUrl + "/institucional/telas/linkedin")
                .then((res2) => res2.json())
                .then((tiu2) => {
                    this.Linke = tiu2.filter(x=> x.visivel)
                });

            this.$http
                .get(this.$apiUrl + "/institucional/telas/youtube")
                .then((res2) => res2.json())
                .then((tiu2) => {
                    this.Yout = tiu2.filter(x=> x.visivel)
                });

            this.$http
                .get(this.$apiUrl + "/institucional/telas/telefone")
                .then((res2) => res2.json())
                .then((tiu2) => {
                    this.Telefone = tiu2
                });

            this.$http
                .get(this.$apiUrl + "/institucional/telas/Lista%20Whats")
                .then((res2) => res2.json())
                .then((tiu2) => {
                    this.Whats = tiu2;
                    this.Whats = this.Whats.filter(x => x.visivel == true);
                });
        },
        mounted() {

        },
        components: {
        },
    };
</script>

<style scoped>

  @import '/css/topbar.css';

</style>