<template>

    <section class="carrinho">

        <CheckAcesso />
        <Header />
        <Fita class="hidden-sm-md hidden-xs" />
        <section class="main-minha_conta">
            <div class="container">
                <div class="row">
                    <!-- Sidenav -->
                    <div class="col-lg-2 col-xl-3">
                        <ul class="lista-sidebar_links">
                            <li><router-link to="/meusDados" class="texto">Meus Dados</router-link></li>
                            <li><router-link to="/meusPedidos" class="texto active">Meus Pedidos</router-link></li>
                        </ul>
                    </div>
                    <!-- Main -->
                    <div class="col-lg-10 col-xl-9 mt-5 mt-lg-0">
                        <h3 class="titulo-principal text-center mb-4">Minha Conta</h3>
                        <h6 class="subtitulo h5 mb-4">Meus Pedidos</h6>
                        <!--<div class="row">
                        <div class="col-lg-9">-->
                        <!-- Search -->
                        <!--<form action="#">
                                    <div class="input-group mb-3">
                                        <input type="text" class="form-control" placeholder="Buscar por número do pedido" onfocus="this.placeholder=''"
                                               onblur="this.placeholder='Buscar por número do pedido'">
                                        <div class="input-group-append">
                                            <button type="submit" class="input-group-text bg-success" id="#">
                                                <i class="fas fa-search text-white"></i>
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>-->
                        <!-- Lista de Pedidos -->
                        <ul class="listaPedidos">
                            <li v-for="pedidocliente in PedidosCliente" :key="pedidocliente.pedidoId">
                                <hr>
                                <div class="row">
                                    <div class="col-md-4 col-lg-4 mb-4 mb-lg-0">
                                        <div class="pedido_id">
                                            <i class="fas fa-clipboard-list text-success fa-2x"></i>
                                            <div style="padding-top:6px;">
                                                <h6 class="d-inline-block">Ped: {{ pedidocliente.pedidoId }}</h6>
                                                - {{ pedidocliente.formaPgto }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-2 col-lg-2 d-flex align-items-center">
                                        <div class="pedido_dados">{{ FormataData(pedidocliente.dataPedido) }}</div>
                                    </div>
                                    <div class="col-md-2 col-lg-2 d-flex align-items-center">
                                        <div class="pedido_dados">Qtde: {{ calculaQtde(pedidocliente) }}</div>
                                    </div>
                                    <div class="col-md-3 col-lg-2 d-flex align-items-center">
                                        <div class="pedido_dados">R$ {{ parseFloat(calculaValor(pedidocliente)).toFixed(2).toString().replace('.',',') }}</div>
                                    </div>
                                    <div class="col-lg-2 mt-4 mt-lg-0 d-flex align-items-center">
                                        <router-link :to="/meusPedidosDetalhe/ + pedidocliente.pedidoId" class="btn btn-success">Ver Detalhes</router-link>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
        <FitaRodape class="" titulo=""></FitaRodape>
        <Footer />
    </section>

</template>

<script>

    import CheckAcesso from '@/components/site/shared/CheckAcesso.vue'
    import Header from '@/components/site/shared/Header.vue'
    import Fita from '@/components/site/shared/Fita.vue'
    import FitaRodape from '@/components/site/shared/FitaRodape.vue'
    import Footer from '@/components/site/shared/Footer.vue'
    import moment from 'moment';
    import { VMoney } from 'v-money'

    export default {
        components: {
            CheckAcesso,
            Header,
            Fita,
            FitaRodape,
            Footer,
        },
        directives: { money: VMoney },
        data() {
            return {
                ClienteId: 0,
                PedidosCliente: [],
                pedidocliente: {},
                UltimoPedido: {},
                TotalPedido: 0,
                PedidosEndereco: [],
                PedidosAgendamento: [],
            }
        },
        methods: {
            validaHora(value) {
                var _hora = value.toString();
                if (_hora.length == 1)
                    _hora = "0" + _hora;
                return _hora;
            },
            FormataData(value) {
                return moment(value).format('DD/MM/YYYY')
            },
            calculaValor(value) {
                let valor = 0
                let recebe = value.listaPedidoItem;
                recebe.forEach((item) => {
                    valor += item.valorTotalProduto
                });

                let recebe2 = value.listaPedidoItemAdicional;
                recebe2.forEach((item) => {
                    valor += item.valorTotalProduto
                });

                let recebe3 = value.listaPedidoItemManual;
                recebe3.forEach((item) => {
                    valor += item.valorTotalProduto
                });
                valor += value.valorFrete

                return (valor - value.valorCupom)
            },
            calculaQtde(value) {
                let qtde = 0
                let recebe = value.listaPedidoItem;
                recebe.forEach((item) => {
                    qtde += item.qtdeProduto
                });

                let recebe2 = value.listaPedidoItemAdicional;
                recebe2.forEach((item) => {
                    qtde += item.qtdeProduto
                });

                let recebe3 = value.listaPedidoItemManual;
                recebe3.forEach((item) => {
                    qtde += item.qtdeProduto
                });
                return qtde
            },
        },
        created() {

            let usuarioTmp = JSON.parse(this.$cookie.get("usuarioLove"));
            
            if (usuarioTmp != null) {
                this.ClienteId = parseInt(usuarioTmp.usuarioId, 10)
                console.log(this.ClienteId)
                //carrega Pedido do cliente
                this.$http
                    .get(this.$apiUrl + "/Pedido/completoClienteId/" + parseInt(this.ClienteId, 10))
                    .then((res2) => res2.json())
                    .then((tiu2) => {
                        this.PedidosCliente = tiu2;
                        /* .filter(x => x.cliente != null && x.cliente.clienteId == parseInt(this.ClienteId, 10)).sort(function (a, b) {
                            return a.pedidoId - b.pedidoId;
                        }).reverse();

 */                        //calcula valor total
                        //let valor = 0
                        //let recebe = this.PedidosCliente.listaPedidoItem;
                        //recebe.forEach((item) => {
                        //    valor += item.valorTotalProduto
                        //});

                        //let recebe2 = this.PedidosCliente.listaPedidoItemAdicional;
                        //recebe2.forEach((item) => {
                        //    valor += item.valorTotalProduto
                        //});

                        //let recebe3 = this.PedidosCliente.listaPedidoItemManual;
                        //recebe3.forEach((item) => {
                        //    valor += item.valorTotalProduto
                        //});

                        //valor += this.PedidosCliente.valorFrete

                        //this.TotalPedido = (valor - this.PedidosCliente.valorCupom)


                        //carrega Pedido endereco
                       /* this.$http
                            .get(this.$apiUrl + "/PedidoEndereco")
                            .then((res2) => res2.json())
                            .then((tiu2) => {
                                this.PedidosEndereco = tiu2.filter(x => x.pedido.pedidoId == parseInt(this.UltimoPedido.pedidoId, 10))

                            });*/

                        //carrega Pedido agendamento
                       /* this.$http
                            .get(this.$apiUrl + "/PedidoAgendamento")
                            .then((res2) => res2.json())
                            .then((tiu2) => {
                                this.PedidosAgendamento = tiu2.filter(x => x.pedido.pedidoId == parseInt(this.UltimoPedido.pedidoId, 10))
                            });*/
                    });
            }
        }
    }

</script>

<style scoped>

    @import '/css_cart/carrinho.css';
    @import '/css_cart/minha_conta.css';
</style>