<template>

    <section class="carrinho">

        <Header />
        <Fita class="hidden-sm" titulo="Presentes exclusivos para momentos especiais"></Fita>

        <section class="main-minha_conta">

            <div class="container">
                <div class="row">
                    <!-- Sidenav -->
                    <div class="col-lg-4 col-xl-3">

                        <ul class="lista-sidebar-suporte_links">
                            <h5 class="title-sidebar-suporte mb-4">Suporte</h5>
                            <li>
                                <router-link to="/comoComprar" class="texto active">
                                    <i class="fas fa-angle-double-right icon-arrow"></i>
                                    Como Comprar
                                </router-link>
                            </li>
                            <li>
                                <router-link to="/comoPagar" class="texto">
                                    <i class="fas fa-angle-double-right icon-arrow"></i>
                                    Como Pagar
                                </router-link>
                            </li>
                            <li>
                                <router-link to="/entregas" class="texto">
                                    <i class="fas fa-angle-double-right icon-arrow"></i>
                                    Entregas
                                </router-link>
                            </li>
                            <li>
                                <router-link to="/fretes" class="texto">
                                    <i class="fas fa-angle-double-right icon-arrow"></i>
                                    Fretes
                                </router-link>
                            </li>
                            <li>
                                <router-link to="/duvidasFrequentes" class="texto">
                                    <i class="fas fa-angle-double-right icon-arrow"></i>
                                    Dúvidas Frequentes
                                </router-link>
                            </li>
                            <li>
                                <router-link to="/trocasDevolucoes" class="texto">
                                    <i class="fas fa-angle-double-right icon-arrow"></i>
                                    Trocas e Devoluções
                                </router-link>
                            </li>
                            <li>
                                <router-link to="/itensDecoracoes" class="texto">
                                    <i class="fas fa-angle-double-right icon-arrow"></i>
                                    Sobre itens e decorações<br /> das cestas
                                </router-link>
                            </li>
                            <li>
                                <router-link to="/politicaCookies" class="texto">
                                    <i class="fas fa-angle-double-right icon-arrow"></i>
                                    Política Cookies
                                </router-link>
                            </li>
                            <li>
                                <router-link to="/termosUso" class="texto">
                                    <i class="fas fa-angle-double-right icon-arrow"></i>
                                    Termos de Uso
                                </router-link>
                            </li>
                            <li>
                                <router-link to="/politicaPrivacidade" class="texto">
                                    <i class="fas fa-angle-double-right icon-arrow"></i>
                                    Política de Privacidade
                                </router-link>
                            </li>
                        </ul>
                    </div>
                    <!-- Main -->
                    <div class="col-lg-10 col-xl-9 mt-5 mt-lg-0">
                        <h3 class="titulo-principal-suporte mb-4">{{ Titulo }}</h3>
                        <!-- <h6 class="subtitulo h5 mb-4">Meus Pedidos</h6> -->
                        <div class="row">
                            <div class="col">
                                <div class="content">
                                    <div v-html="Texto"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </section>

        <FitaRodape class="ribbon-lg"></FitaRodape>
        <Footer />

    </section>

</template>

<script>

    import Header from '@/components/site/shared/Header.vue'
    import Fita from '@/components/site/shared/Fita.vue'
    import FitaRodape from '@/components/site/shared/FitaRodape.vue'
    import Footer from '@/components/site/shared/Footer.vue'

    export default {
        components: {
            Header,
            Fita,
            FitaRodape,
            Footer,
        },
        data() {
            return {
                Retorno: [],
                Titulo: '',
                Texto: '',
            }
        },
        methods: {
        },
        created() {

            this.$http
                .get(this.$apiUrl + "/institucional/telas/como%20comprar")
                .then((res2) => res2.json())
                .then((tiu2) => {
                    this.Retorno = tiu2;

                    if (this.Retorno != null) {
                        this.Titulo = this.Retorno[0].nome;
                        this.Texto = this.Retorno[0].resumo1;
                    }

                });
        },
        mounted() {

        },
    }

</script>

<style scoped>

    @import '/css_cart/carrinho.css';
    @import '/css_cart/minha_conta.css';
    @import '/css/suporte.css';
</style>