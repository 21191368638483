<template>

    <section class="home">

        <HeaderHome />

        <Fita class="hidden-sm" titulo=""></Fita>

        <virtual-list style="height: 100%;" 
            :data-key="'homeMontaItemId'"
            :data-sources="ItensHome"
            :data-component="itemComponent"
            />

        <FitaRodape class="" titulo=""></FitaRodape>

        <Footer />

    </section>

</template>

<script>

    import HeaderHome from '@/components/site/shared/HeaderHome.vue'
    import Fita from '@/components/site/shared/Fita.vue'
    import Footer from '@/components/site/shared/Footer.vue'
    import FitaRodape from '@/components/site/shared/FitaRodape.vue'
    import MontaHome from '@/components/site/shared/MontaHome.vue'
    import VirtualList from 'vue-virtual-scroll-list'
    import moment from 'moment';


    export default {
        components: {
            HeaderHome,
            Fita,
            FitaRodape,
            Footer,
            'virtual-list': VirtualList,
        },
        data() {
            return {
                itemComponent: MontaHome,
                ItensHome: [],
                DadosCompra: {
                    produtos: [],
                    mensagem: [],
                    agenda: [],
                    foto: [],
                    avulsos: [],
                    baloes: [],
                    informacao: [],
                    pagamento: [],
                    cupom: [],
                },
            }
        },
        methods: {
        },
        created() {
           
            this.$http
                .get(this.$apiUrl + "/homemontaitem")
                .then((res2) => res2.json())
                .then((tiu2) => {
                    this.ItensHome = tiu2;
                   // console.log('this.ItensHome-Home')
                   // console.log(this.ItensHome)
                });

            let dadosArmazenados = localStorage.getItem('dadoscompralove');
            if (dadosArmazenados != null && dadosArmazenados.length > 0) {
                dadosArmazenados = JSON.parse(dadosArmazenados);
                if (dadosArmazenados.agenda != '') {
                    if (moment(dadosArmazenados.agenda[0].periodoEntrega.dataEntrega).format('YYYY-MM-DD') < moment().format('YYYY-MM-DD')) {
                        dadosArmazenados = this.DadosCompra;
                        localStorage.setItem('dadoscompralove', JSON.stringify(dadosArmazenados))
                    }
                }
            }
            else {
                dadosArmazenados = this.DadosCompra;
                localStorage.setItem('dadoscompralove', JSON.stringify(dadosArmazenados))
            }

        },
        mounted() {
             
        },
    }

</script>

