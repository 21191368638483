<template>
  
  <section v-if="Fita!=''" class="ribbon" :style="{'backgroundColor': Fita[0].cor1}">
    <div class="container-fluid">
      <div class="row">
        <div class="col">
          <span :style="{'color': Fita[0].cor2}" v-html="Fita[0].resumo1"></span>
        </div>
      </div>
    </div>
  </section>

</template>

<script>
    export default {
        data() {
            return {
                Fita: [],
            };
        },
        methods: {
        },
        created() {

            this.$http
                .get(this.$apiUrl + "/institucional/telas/barra%20topo")
                .then((res2) => res2.json())
                .then((tiu2) => {
                    this.Fita = tiu2;
                });
        },
        mounted() {

        },
        components: {
        },
    };
</script>

<style>

  @import '/css/fita.css';
  p {
        margin-top: 0;
        margin-bottom: 0px !important;
    }
</style>