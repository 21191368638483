import Vue from 'vue'
import VueRouter from 'vue-router'

/* const Home = () => import('@/components/site/views/PageHome.vue')
const Produtos = () => import('@/components/site/views/PageProdutos.vue')
const ProdutosBusca = () => import('@/components/site/views/PageProdutosBusca.vue')
const ProdutoDetalhe = () => import('@/components/site/views/PageProdutoDetalhe.vue')
const ProdutoEditar = () => import('@/components/site/views/PageProdutoEditar.vue')
const Carrinho = () => import('@/components/site/views/PageCarrinho.vue')
const Login = () => import('@/components/site/views/PageLogin.vue')
const NovaContaLogin = () => import('@/components/site/views/PageNovaContaLogin.vue')
const NovaContaEndereco = () => import('@/components/site/views/PageNovaContaEndereco.vue')
const NovaContaPagamento = () => import('@/components/site/views/PageNovaContaPagamento.vue')
const NovaContaConfirmacao = () => import('@/components/site/views/PageNovaContaConfirmacao.vue')
const PagamentoResumo = () => import('@/components/site/views/PagePagamentoResumo.vue')
const PedidoRealizado = () => import('@/components/site/views/PagePedidoRealizado.vue')
const MeusDados = () => import('@/components/site/views/PageMeusDados.vue')
const MeusDadosEditar = () => import('@/components/site/views/PageMeusDadosEditar.vue')
const MeusPedidos = () => import('@/components/site/views/PageMeusPedidos.vue')
const MeusPedidosDetalhe = () => import('@/components/site/views/PageMeusPedidosDetalhe.vue')
const ComoComprar = () => import('@/components/site/views/PageComoComprar.vue')
const ComoPagar = () => import('@/components/site/views/PageComoPagar.vue')
const Entregas = () => import('@/components/site/views/PageEntregas.vue')
const Fretes = () => import('@/components/site/views/PageFretes.vue')
const DuvidasFrequentes = () => import('@/components/site/views/PageDuvidasFrequentes.vue')
const TrocasDevolucoes = () => import('@/components/site/views/PageTrocasDevolucoes.vue')
const ItensDecoracoes = () => import('@/components/site/views/PageItensDecoracoes.vue')
const PoliticaCookies = () => import('@/components/site/views/PagePoliticaCookies.vue')
const TermosUso = () => import('@/components/site/views/PageTermosUso.vue')
const PoliticaPrivacidade = () => import('@/components/site/views/PagePoliticaPrivacidade.vue')
const Sobre = () => import('@/components/site/views/PageSobre.vue')
const Contato = () => import('@/components/site/views/PageContato.vue')
const MensagemAvulsa = () => import('@/components/site/views/PageMensagemAvulsa.vue')
const MensagemAvulsaFinalizada = () => import('@/components/site/views/PageMensagemAvulsaFinalizada.vue')
const FotoAvulsa = () => import('@/components/site/views/PageFotoAvulsa.vue')
const FotoAvulsaFinalizada = () => import('@/components/site/views/PageFotoAvulsaFinalizada.vue')
const PageProdutosBanner = () => import('@/components/site/views/PageProdutosBanner.vue')
const PageProdutosXML = () => import('@/components/site/views/PageProdutosXML.vue')
const PageProdutosXML2 = () => import('@/components/site/views/PageProdutosXML2.vue')
const PageSetaAvulsos = () => import('@/components/site/views/PageSetaAvulsos.vue')
const PageSetaProduosAvulsos = () => import('@/components/site/views/PageSetaProduosAvulsos.vue')
const PageSetaSegmentosProduto = () => import('@/components/site/views/PageSetaSegmentosProduto.vue')
const PageSetaProdutosSegmento = () => import('@/components/site/views/PageSetaProdutosSegmento.vue') */

import Home from '@/components/site/views/PageHome.vue'
import Produtos from '@/components/site/views/PageProdutos.vue'
import ProdutosBusca from '@/components/site/views/PageProdutosBusca.vue'
import ProdutoDetalhe from '@/components/site/views/PageProdutoDetalhe.vue'
import ProdutoEditar from '@/components/site/views/PageProdutoEditar.vue'
import Carrinho from '@/components/site/views/PageCarrinho.vue'
import Login from '@/components/site/views/PageLogin.vue'
import NovaContaLogin from '@/components/site/views/PageNovaContaLogin.vue'
import NovaContaEndereco from '@/components/site/views/PageNovaContaEndereco.vue'
import NovaContaPagamento from '@/components/site/views/PageNovaContaPagamento.vue'
import NovaContaConfirmacao from '@/components/site/views/PageNovaContaConfirmacao.vue'
import PagamentoResumo from '@/components/site/views/PagePagamentoResumo.vue'
import PedidoRealizado from '@/components/site/views/PagePedidoRealizado.vue'
import MeusDados from '@/components/site/views/PageMeusDados.vue'
import MeusDadosEditar from '@/components/site/views/PageMeusDadosEditar.vue'
import MeusPedidos from '@/components/site/views/PageMeusPedidos.vue'
import MeusPedidosDetalhe from '@/components/site/views/PageMeusPedidosDetalhe.vue'
import ComoComprar from '@/components/site/views/PageComoComprar.vue'
import ComoPagar from '@/components/site/views/PageComoPagar.vue'
import Entregas from '@/components/site/views/PageEntregas.vue'
import Fretes from '@/components/site/views/PageFretes.vue'
import DuvidasFrequentes from '@/components/site/views/PageDuvidasFrequentes.vue'
import TrocasDevolucoes from '@/components/site/views/PageTrocasDevolucoes.vue'
import ItensDecoracoes from '@/components/site/views/PageItensDecoracoes.vue'
import PoliticaCookies from '@/components/site/views/PagePoliticaCookies.vue'
import TermosUso from '@/components/site/views/PageTermosUso.vue'
import PoliticaPrivacidade from '@/components/site/views/PagePoliticaPrivacidade.vue'
import Sobre from '@/components/site/views/PageSobre.vue'
import Contato from '@/components/site/views/PageContato.vue'
import MensagemAvulsa from '@/components/site/views/PageMensagemAvulsa.vue'
import MensagemAvulsaFinalizada from '@/components/site/views/PageMensagemAvulsaFinalizada.vue'
import FotoAvulsa from '@/components/site/views/PageFotoAvulsa.vue'
import FotoAvulsaFinalizada from '@/components/site/views/PageFotoAvulsaFinalizada.vue'
import PageProdutosBanner from '@/components/site/views/PageProdutosBanner.vue'
import PageProdutosXML from '@/components/site/views/PageProdutosXML.vue'
import PageProdutosXML2 from '@/components/site/views/PageProdutosXML2.vue'
import PageSetaAvulsos from '@/components/site/views/PageSetaAvulsos.vue'
import PageSetaProduosAvulsos from '@/components/site/views/PageSetaProduosAvulsos.vue'
import PageSetaSegmentosProduto from '@/components/site/views/PageSetaSegmentosProduto.vue'
import PageSetaProdutosSegmento from '@/components/site/views/PageSetaProdutosSegmento.vue'
import PageCorporativo from '@/components/site/views/PageCorporativo.vue'

Vue.use(VueRouter)

export default new VueRouter({
    mode: 'history',
    linkActiveClass: 'active',
    scrollBehavior(to, from, savedPosition) {
        return new Promise((resolve) => {
            setTimeout(() => {
                if (savedPosition) {
                    return resolve(savedPosition)
                }
                if (to.hash) {
                    return resolve({
                        selector: to.hash,
                        offset: { x: 0, y: 0 }
                    })
                }
                return resolve({
                    x: 0, y: 0,
                    behavior: 'smooth'
                })
            }, 700)
        })
    },
    routes: [
        { path: '/', component: Home },
        { path: '/produtos', component: Produtos },
        { path: '/produtosbusca/:texto', component: ProdutosBusca },
        //{ path: '/produtos/:nome/:id', component: Produtos },
        { path: '/produtos/:id/:idcategoria/:segmento/:nome', component: Produtos },
        { path: '/produtos/:id/:idcategoria/:segmento', component: Produtos },
        { path: '/produtoDetalhe/:id', component: ProdutoDetalhe },
        { path: '/produtoDetalhe/:id/:pagina/:modal', component: ProdutoDetalhe },
        { path: '/produtoEditar', component: ProdutoEditar },
        { path: '/carrinho', component: Carrinho },
        { path: '/login', component: Login },
        { path: '/novaContaLogin', component: NovaContaLogin },
        { path: '/novaContaEndereco', component: NovaContaEndereco },
        { path: '/novaContaPagamento', component: NovaContaPagamento },
        { path: '/novaContaConfirmacao', component: NovaContaConfirmacao },
        { path: '/pagamentoResumo', component: PagamentoResumo },
        { path: '/pedidoRealizado', component: PedidoRealizado },
        { path: '/meusDados', component: MeusDados },
        { path: '/meusDadosEditar', component: MeusDadosEditar },
        { path: '/meusPedidos', component: MeusPedidos },
        { path: '/meusPedidosDetalhe', component: MeusPedidosDetalhe },
        { path: '/meusPedidosDetalhe/:id', component: MeusPedidosDetalhe },
        { path: '/comoComprar', component: ComoComprar },
        { path: '/ComoPagar', component: ComoPagar },
        { path: '/entregas', component: Entregas },
        { path: '/fretes', component: Fretes },
        { path: '/duvidasFrequentes', component: DuvidasFrequentes },
        { path: '/trocasDevolucoes', component: TrocasDevolucoes },
        { path: '/itensDecoracoes', component: ItensDecoracoes },
        { path: '/politicaCookies', component: PoliticaCookies },
        { path: '/termosUso', component: TermosUso },
        { path: '/politicaPrivacidade', component: PoliticaPrivacidade },
        { path: '/sobre', component: Sobre },
        { path: '/contato', component: Contato },
        { path: '/mensagemavulsa', component: MensagemAvulsa },
        { path: '/mensagemavulsafinalizada', component: MensagemAvulsaFinalizada },
        { path: '/fotoavulsa', component: FotoAvulsa },
        { path: '/fotoavulsafinalizada', component: FotoAvulsaFinalizada },
        { path: '/pageprodutosbanner/:id', component: PageProdutosBanner },
        { path: '/pageprodutosxml', component: PageProdutosXML },
        { path: '/pageprodutosxml2', component: PageProdutosXML2 },
        { path: '/Pagesetaavulsos', component: PageSetaAvulsos },
        { path: '/PageSetaProduosAvulsos', component: PageSetaProduosAvulsos },
        { path: '/PageSetaSegmentosProduto', component: PageSetaSegmentosProduto },
        { path: '/PageSetaProdutosSegmento', component: PageSetaProdutosSegmento },
        { path: '/Corporativo', component: PageCorporativo },
    ]
})