<template>

    <section class="produtos">

        <Header />

        <div v-if="bg_imagem!=''" class="topo-internas set-img"
             :style="{ backgroundImage: 'url(' + $Url  + bg_imagem + ')' }">
        </div>
        <div v-else class="topo-internas set-img"
             :style="{ backgroundImage: 'url(' + $Url  + '/imagens/generica.jpg)' }">
        </div>

        <div class="produtos mb-5">
            <div class="container">
                <div class="row">
                    <div class="col-12 text-center mt-3">
                        <h3 class="titulo_section titulo_section-xl text-center">{{ListaItensGeral[0].homeBanner.nomeBloco.replaceAll('mobile','').replaceAll('desktop','')}}</h3>
</div>
                </div>
                <div class="row">
                    <div class="grid-produtos-mobile col-6 col-md-4 col-lg-3 mt-3 mb-lg-0" v-for="produto in ListaItensGeral" :key="produto.homeBannerItemId">
                        <div class="produto-card produto-card_2">
                            <a href="" @click="$redirect_reload('/produtoDetalhe/' + produto.produto.produtoId)">
                                <div v-if="produto.produto.imagens[0] != null" class="produto-img set-img set-tam4" :style="{ backgroundImage: 'url('  + $Url  + produto.produto.imagens[0].urlImg + ')', }">
                                    <img v-for="ItemEtiqueta in CarregaEtiqueta(produto.produto.produtoId)" :key="ItemEtiqueta.produtoPremioId" :src="ItemEtiqueta.urlImg" alt="">
                                    <div class="middle middle_2">
                                        <div class="text">Ver Mais Detalhes</div>
                                    </div>
                                </div>
                                <div v-else class="produto-img set-img set-tam4" :style="{ backgroundImage: 'url(' + $Url  + '/imagens/indisponivel.jpg)', }">
                                    <img v-for="ItemEtiqueta in CarregaEtiqueta(produto.produto.produtoId)" :key="ItemEtiqueta.produtoPremioId" :src="ItemEtiqueta.urlImg" alt="">
                                    <div class="middle middle_2">
                                        <div class="text">Ver Mais Detalhes</div>
                                    </div>
                                </div>
                            </a>
                            <div class="produto-content" style="padding-top:10px;">
                                <h6 class="titulo-card">{{ produto.produto.nomeProduto }}</h6>
                                <span v-if="produto.produto.valorDe > 0" class="preco-antigo">De {{   parseFloat(produto.produto.valorDe,10).toFixed(2).toString().replace('.',',') }}</span>
                                <p>
                                    <span class="texto-destaque">{{ parcelas }} x</span>
                                    de R$ <span class="texto-destaque"> {{ parseFloat(produto.produto.valorAte/parcelas,10).toFixed(2).toString().replace('.',',') }}</span> {{ parcelasTexto }}
                                </p>
                                <p>
                                    R$ <span class="texto-destaque"> {{ parseFloat(produto.produto.valorAte,10).toFixed(2).toString().replace('.',',') }}</span>
                                </p>
                                <!--<router-link :to="/produtoDetalhe/ + produto.produtoId" class="btn btn-card" style="margin-top: 5px;">Ver Detalhes</router-link>-->
                                <a href="" v-if="produto.produto.indisponivel != true" @click="$redirect_reload('/produtoDetalhe/' + produto.produto.produtoId)" class="btn btn-card" style="margin-top: 5px;">Ver Detalhes</a>
                                <a v-else class="texto-destaque" style="margin-top: 5px;">Indispon&iacute;vel</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <FitaRodape class="" titulo=""></FitaRodape>

        <Footer />

    </section>

</template>

<script>

    import Header from '@/components/site/shared/Header.vue'
    import Footer from '@/components/site/shared/Footer.vue'
    import FitaRodape from '@/components/site/shared/FitaRodape.vue'
    import { VMoney } from 'v-money'

    export default {
        components: {
            Header,
            FitaRodape,
            Footer,
        },
        data() {
            return {
                HomeBannerId: this.$route.params.id,
                ProdutosGerais: [],
                Produtos: [],
                produto: {},
                ListaEtiquetaImg: [],
                Ordemselected: 'Ordenar por',
                Ordenar: [
                    {
                        nome: "Maior Valor",
                        valor: 3
                    },
                    {
                        nome: "Menor Valor",
                        valor: 4
                    }
                ],
                parcelas: 0,
                parcelasTexto: '',
                bg_imagem: '',
                ListaItensGeral:[],
                money: {
                    decimal: ',',
                    thousands: '.',
                    precision: 2,
                    masked: true
                }
            }
        },
        directives: { money: VMoney },
        methods: {
            MudaOrdem() {
                if (this.Ordemselected == 3) {
                    this.Produtos.sort(function (a, b) {
                        return a.valorAte - b.valorAte;
                    }).reverse();
                } else if (this.Ordemselected == 4) {
                    this.Produtos.sort(function (a, b) {
                        return a.valorAte - b.valorAte;
                    });
                } else {
                    this.Produtos.sort(function (a, b) {
                        return a.produtoId - b.produtoId;
                    });
                }

            },
            CarregaEtiqueta(value) {
                if (this.ListaEtiquetaImg.produto != null) {
                    var retorno = this.ListaEtiquetaImg.filter(x => x.produto.produtoId == value);
                    return retorno.sort(function (a, b) {
                        return a.ordem - b.ordem;
                    });
                }
                else {
                    return null
                }
            },
            CarregaImagemUnica(value) {
                if (value != null) {
                    value.sort(function (a, b) {
                        return a.ordem - b.ordem;
                    });

                    //value.sort(function (a, b) {
                    //    if (a.ordem > b.ordem) {
                    //        return 1;
                    //    }
                    //    if (a.ordem < b.ordem) {
                    //        return -1;
                    //    }
                    //    return 0;
                    //});
                    return this.$Url + value[0].urlImg;
                }
                else {
                    return this.$Url + "/imagens/indisponivel.jpg"
                }

            },
        },
        created() {

            this.$http
                .get(this.$apiUrl + "/homebannerItem")
                .then((res2) => res2.json())
                .then((tiu2) => {
                    this.ListaItensGeral = tiu2.filter(x => x.visivel && x.homeBanner.homeBannerId == this.HomeBannerId).sort(function (a, b) {
                        return a.ordem - b.ordem;
                    });
                    console.log(this.ListaItensGeral)
                });

            this.$http
                .get(this.$apiUrl + "/produtopremio")
                .then((res2) => res2.json())
                .then((tiu2) => {
                    this.ListaEtiquetaImg = tiu2.sort(function (a, b) {
                        return a.ordem - b.ordem;
                    });
                });

            this.$http
                .get(this.$apiUrl + "/produtotextogeral")
                .then((res2) => res2.json())
                .then((tiu2) => {
                    this.parcelas = parseInt(tiu2[0].texto1, 10);
                    this.parcelasTexto = tiu2[0].texto2;
                });

        },
        mounted() {

        },
    }

</script>

<style scoped>

    @import '/css/produtos.css';
    @import '/css/nav_produtos.css';
    @import '/css/nav_produtos_2.css';
</style>