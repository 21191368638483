<template>

    <section class="carrinho">

        <CheckAcesso />
        <Header />
        <Fita class="hidden-sm-md hidden-xs" />

        <section class="main-carrinho pedido-realizado set-img" :style="{ backgroundImage: 'url(' + $Url + ListaTextos[0].urlImg + ')' }">

            <div class="container">
                <div class="row">
                    <div class="col-md-8 col-lg-6 offset-lg-1">
                        <div class="content p-4">
                            <p v-html="ListaTextos[0].textoFinalizada"></p>
                            <div class="mt-4" v-if="showcomprovante==false"><a @click="mensagem()" class="btn btn-success">Ver andamento do pedido</a></div>
                        </div>
                            <div v-if="showcomprovante==true" class="mb-4 px-md-4">
                                <p v-if="mostrarchavepix==true">
                                        Chave PIX: <textarea ref="copiar" style="margin-top: 0px; margin-bottom: 0px; height: 32px; width: 145px; resize: none;line-height: 1 font-size: 19px; background: transparent; border: none; padding-top: 13px;overflow-y: hidden;">29009464000140</textarea>
                                        <a @click="copy" data-toggle="tooltip" data-placement="top" title="Copiar">
                                            <i class="far fa-copy icon-copy" style="font-size:20px;"></i>
                                        </a>
                                    </p>
                                <p v-if="mostrarchavepix==true"> {{ textocopiado }}</p>
                                <p><strong> Valor R$ {{ parseFloat(ValorTotal).toFixed(2).toString().replace('.', ',') }}</strong></p>
                                <h5 class="subtitulo mb-4">Enviar Comprovante</h5>
                                <div class="agendamento">
                                    <input id="arquivo" type="file" class="" />
                                </div>
                                <div class="text-center mt-5" style="display: inline-flex;">
                                     <a @click="mensagem()" class="btn btn-outline-primary px-5 mt-0" >Enviar depois</a>
                                    <button @click="carregaFoto()" class="btn btn-success px-5 mt-0" style="margin-left: 10px !important;">ENVIAR COMPROVANTE</button>
                                </div>
                           </div>
                    </div>
                    
                </div>
            </div>
        </section>

        <FitaRodape class="" titulo=""></FitaRodape>
        <Footer />

    </section>

</template>

<script>

    import CheckAcesso from '@/components/site/shared/CheckAcesso.vue'
    import Header from '@/components/site/shared/Header.vue'
    import Fita from '@/components/site/shared/Fita.vue'
    import FitaRodape from '@/components/site/shared/FitaRodape.vue'
    import Footer from '@/components/site/shared/Footer.vue'
    import moment from 'moment';
    import { VMoney } from 'v-money'

    export default {
        components: {
            CheckAcesso,
            Header,
            Fita,
            FitaRodape,
            Footer,
        },
        directives: { money: VMoney },
        data() {
            return {
                bg_imagem: 'img/33.png',
                ListaTextos: [],
                Pedido: [],
                PedidoItem: [],
                PedidoItemAdicional: [],
                PedidoItemManual: [],
                Horarios: {},
                PedidoAgendamento: [],
                PedidoEndereco: [],
                PedidoMensagem: [],
                PedidoFoto: [],
                Contato: {
                    NomeEmpresa: "",
                    Assunto: "",
                    Destinatario: "",
                    EmailResposta: "",
                    TextoEmail: "",
                    Anexo: "",
                    NomeAnexo: "",
                    TipoAnexo: "",
                },
                ValorTotal: 0.0,
                Cupons: [],
                showcomprovante: false,
                FotoCarregada: '',
                PedidoComprova:[],
                 textocopiado: '',
                 mostrarchavepix:false,
            }
        },
        methods: {
            mensagem() {
                /*this.$redirect("/meusPedidosDetalhe/" + parseInt(this.Pedido.pedidoId, 10));*/
                this.$redirect("/meusPedidos");
            },
            CarregaPedido() {
                let dadosArmazenados = localStorage.getItem('dadoscompralove');
                dadosArmazenados = JSON.parse(dadosArmazenados);

                let usuarioTmp = JSON.parse(this.$cookie.get("usuarioLove"));
                let validacarga = true
                let dadosBaloes = '';

                if (dadosArmazenados.baloes != '') {
                    dadosArmazenados.baloes.forEach((itemex) => {
                        dadosBaloes += itemex.dados;
                    });
                }

                dadosArmazenados.produtos.forEach((itemex) => {
                    console.log(itemex)
                        dadosBaloes += itemex.dados;
                });

               let _pedido = {}
                if (dadosArmazenados.cupom != '') {
                   _pedido = {
                        PedidoId: 0,
                        DataPedido: moment().format('YYYY-MM-DD'),
                        OrigemPedido: 'Site',
                        Cliente: { ClienteId: parseInt(usuarioTmp.usuarioId, 10) },
                        FormaPgto: dadosArmazenados.pagamento[0].FormaPgto,
                        TipoFrete: dadosArmazenados.agenda[0].periodoEntrega.TipoFrete,
                        ValorFrete: dadosArmazenados.agenda[0].periodoEntrega.valorFrete,
                        SituacaoPedido: 'Aguardando',
                        Ibr: dadosBaloes,
                        CodigoCupom: dadosArmazenados.cupom[0].Cupom,
                        ValorCupom: dadosArmazenados.cupom[0].ValorCupom,
                        RetCielo: dadosArmazenados.pagamento[0].RetCielo,
                        TidCielo: dadosArmazenados.pagamento[0].TidCielo,
                    }

                   //aqui baixa Qtde Cupom
                   this.$http.get(this.$apiUrl + "/cupom/")
                     .then((res) => res.json())
                     .then((retornoAPI) => {
                      this.Cupons = retornoAPI.filter(x => x.codigoCupom == dadosArmazenados.cupom[0].Cupom);
                       
                        if (this.Cupons!= ''){
                            if (this.Cupons[0].usoUnico == false && this.Cupons[0].qtdeUso > 0){
                               this.Cupons[0].qtdeUso = (parseInt(this.Cupons[0].qtdeUso,10) - 1)
                                if (this.Cupons[0].qtdeUso == 0){
                                    this.Cupons[0].qtdeUso = -1;
                                }
                            }
                            else{
                                this.Cupons[0].ativo = false;

                            }
                                this.$http.put(this.$apiUrl + "/cupom/" + this.Cupons[0].cupomId, this.Cupons[0]).then(
                                (response) => {
                                if (response.ok) {
                                console.log('Cupom QtdeUso ajustado')
                                }
                                },
                                (error) => {
                                console.log(error.bodyText);
                                } 
                            );
                        }
                     });
                     //aqui baixa Qtde Cupom
                } else {
                    _pedido = {
                        PedidoId: 0,
                        DataPedido: moment().format('YYYY-MM-DD'),
                        OrigemPedido: 'Site',
                        Cliente: { ClienteId: parseInt(usuarioTmp.usuarioId, 10) },
                        FormaPgto: dadosArmazenados.pagamento[0].FormaPgto,
                        TipoFrete: dadosArmazenados.agenda[0].periodoEntrega.TipoFrete,
                        ValorFrete: dadosArmazenados.agenda[0].periodoEntrega.valorFrete,
                        SituacaoPedido: 'Aguardando',
                        Ibr: dadosBaloes,
                        RetCielo: dadosArmazenados.pagamento[0].RetCielo,
                        TidCielo: dadosArmazenados.pagamento[0].TidCielo,
                    }
                }
                console.log(_pedido)
                this.$http
                    .post(this.$apiUrl + "/Pedido", _pedido)
                    .then(
                        response2 => {
                            // get body data
                            this.Pedido = response2.body;
                            // let script = document.createElement("script");
                            // script.innerHTML = "gtag('event', 'conversion', {'send_to': 'AW-954551404/90GzCMaOybcDEOyYlccD','value': "+this.ValorTotal+",'currency': 'BRL','transaction_id': ' "+this.Pedido.pedidoId+" '});";
                            // script.innerHTML += "console.log('"+this.ValorTotal+"' + '-gtag-' + "+this.Pedido.pedidoId+" );";
                            // document.getElementsByTagName('body').appendChild(script);
                            //inserir pedido item
                            let _pedidoItem = {}
                            dadosArmazenados.produtos.forEach((itemex) => {
                                _pedidoItem = {
                                    PedidoItemId: 0,
                                    Pedido: { PedidoId: parseInt(this.Pedido.pedidoId, 10) },
                                    Produto: { ProdutoId: parseInt(itemex.produto.produtoId, 10) },
                                    QtdeProduto: 1,
                                    ValorProduto: itemex.produto.valorAte,
                                    ValorTotalProduto: itemex.produto.valorAte,
                                    DataCadastro: moment().format('YYYY-MM-DD'),
                                }
                                this.$http
                                    .post(this.$apiUrl + "/PedidoItem", _pedidoItem)
                                    .then(
                                        response3 => {
                                            // get body data
                                            this.PedidoItem = response3.body;
                                            console.log("gravei item pedido")
                                            validacarga = true;
                                        },
                                        async response3 => {
                                            let recebe2 = response3.body;
                                            this.$mensagem_normal(recebe2);
                                            validacarga = false;
                                        }
                                    );
                            });

                            //inserir pedido item adicional
                            let _pedidoAcional = {}
                            if (dadosArmazenados.avulsos != '') {
                                if (dadosArmazenados.avulsos[0].avulso != 'Sem Avulsos') {
                                    dadosArmazenados.avulsos.forEach((itemex) => {
                                        _pedidoAcional = {
                                            PedidoItemAdiconalId: 0,
                                            Pedido: { PedidoId: parseInt(this.Pedido.pedidoId, 10) },
                                            ItemAvulso: { ItemAvulsoId: parseInt(itemex.avulso.itemAvulsoId, 10) },
                                            QtdeProduto: 1,
                                            ValorProduto: itemex.avulso.valorDe,
                                            ValorTotalProduto: itemex.avulso.valorDe,
                                            DataCadastro: moment().format('YYYY-MM-DD'),
                                        }
                                        this.$http
                                            .post(this.$apiUrl + "/PedidoItemAdicional", _pedidoAcional)
                                            .then(
                                                response4 => {
                                                    // get body data
                                                    this.PedidoItemAdicional = response4.body;
                                                    console.log("gravei item pedido adicional")
                                                    validacarga = true;
                                                },
                                                async response4 => {
                                                    let recebe2 = response4.body;
                                                    this.$mensagem_normal(recebe2);
                                                    validacarga = false;
                                                }
                                            );
                                    });
                                }
                            }

                            //inserir pedido item adicional balao
                            let _pedidoAcionalBalao = {}
                            if (dadosArmazenados.baloes != '') {
                                if (dadosArmazenados.baloes[0].avulso != 'Sem Balao') {
                                    dadosArmazenados.baloes.forEach((itemex) => {
                                        _pedidoAcionalBalao = {
                                            PedidoItemAdiconalId: 0,
                                            Pedido: { PedidoId: parseInt(this.Pedido.pedidoId, 10) },
                                            ItemAvulso: { ItemAvulsoId: parseInt(itemex.avulso.itemAvulsoId, 10) },
                                            QtdeProduto: 1,
                                            ValorProduto: itemex.avulso.valorDe,
                                            ValorTotalProduto: itemex.avulso.valorDe,
                                            DataCadastro: moment().format('YYYY-MM-DD'),
                                        }
                                        this.$http
                                            .post(this.$apiUrl + "/PedidoItemAdicional", _pedidoAcionalBalao)
                                            .then(
                                                response4 => {
                                                    // get body data
                                                    this._pedidoAcionalBalao = response4.body;
                                                    console.log("gravei item pedido adicional balao")
                                                    validacarga = true;
                                                },
                                                async response4 => {
                                                    let recebe2 = response4.body;
                                                    this.$mensagem_normal(recebe2);
                                                    validacarga = false;
                                                }
                                            );
                                    });
                                }
                            }

                            //inserir pedido item manual
                            let _pedidoManual = {}
                            if (dadosArmazenados.foto != '') {
                                if (dadosArmazenados.foto[0].imagemUrl != 'Sem foto') {
                                    _pedidoManual = {
                                        PedidoItemManualId: 0,
                                        Pedido: { PedidoId: parseInt(this.Pedido.pedidoId, 10) },
                                        ItemAvulsoManual: { ItemAvulsoManualId: 1 },
                                        QtdeProduto: 1,
                                        ValorProduto: dadosArmazenados.foto[0].valor,
                                        ValorTotalProduto: dadosArmazenados.foto[0].valor,
                                        DataCadastro: moment().format('YYYY-MM-DD'),
                                    }
                                    this.$http
                                        .post(this.$apiUrl + "/pedidoitemmanual", _pedidoManual)
                                        .then(
                                            response5 => {
                                                // get body data
                                                this.PedidoItemManual = response5.body;
                                                console.log("gravei item pedido manual")
                                                validacarga = true;
                                            },
                                            async response5 => {
                                                let recebe2 = response5.body;
                                                this.$mensagem_normal(recebe2);
                                                validacarga = false;
                                            }
                                        );
                                }
                            }

                            //inserir pedidoagendamento
                            let _pedidoAgendamento = {}
                            if (dadosArmazenados.agenda != '') {

                                //carrega titulos avulsos
                                console.log("DADOS1: ");
                                console.log(dadosArmazenados.agenda[0].periodoEntrega);
                                if(!Object.prototype.hasOwnProperty.call(dadosArmazenados.agenda[0].periodoEntrega, "codigoHorario")){
                                    //excao usa codigo 
                                       this.$http
                                    .get(this.$apiUrl + "/entregaExcecao/" + parseInt(dadosArmazenados.agenda[0].periodoEntrega.codigo, 10))
                                    .then(tiu2 => {
                                        this.Horarios = tiu2.body;
                                        console.log("DADOS2: " + tiu2);
                                        console.log("DADOS3: " + this.Horarios);
                                        _pedidoAgendamento = {
                                            PedidoAgendamentoId: 0,
                                            DiaEntrega: moment(dadosArmazenados.agenda[0].periodoEntrega.dataEntrega).format('YYYY-MM-DD'),
                                            HoraIni: this.Horarios.horaIni,
                                            MinIni: this.Horarios.minIni,
                                            HoraFim: this.Horarios.horaFim,
                                            MinFim: this.Horarios.minFim,
                                            Pedido: { PedidoId: parseInt(this.Pedido.pedidoId, 10) },
                                        }
                                        console.log("DADOS4:");
                                        console.log(_pedidoAgendamento)
                                        this.$http
                                            .post(this.$apiUrl + "/PedidoAgendamento", _pedidoAgendamento)
                                            .then(
                                                response6 => {
                                                    // get body data
                                                    this.PedidoAgendamento = response6.body;
                                                    console.log("gravei pedido agendamento")
                                                    validacarga = true;
                                                },
                                                async response6 => {
                                                    let recebe2 = response6.body;
                                                    this.$mensagem_normal(recebe2);
                                                    validacarga = false;
                                                }
                                            );
                                    });

                                }else{
                                    //normal usa codigoHorario
                                       this.$http
                                    .get(this.$apiUrl + "/EntregaHorario/" + parseInt(dadosArmazenados.agenda[0].periodoEntrega.codigoHorario, 10))
                                    .then(tiu2 => {
                                        this.Horarios = tiu2.body;
                                        console.log("DADOS2: " + tiu2);
                                        console.log("DADOS3: " + this.Horarios);
                                        _pedidoAgendamento = {
                                            PedidoAgendamentoId: 0,
                                            DiaEntrega: moment(dadosArmazenados.agenda[0].periodoEntrega.dataEntrega).format('YYYY-MM-DD'),
                                            HoraIni: this.Horarios.horaIni,
                                            MinIni: this.Horarios.minIni,
                                            HoraFim: this.Horarios.horaFim,
                                            MinFim: this.Horarios.minFim,
                                            Pedido: { PedidoId: parseInt(this.Pedido.pedidoId, 10) },
                                        }
                                        console.log("DADOS4:");
                                        console.log(_pedidoAgendamento)
                                        this.$http
                                            .post(this.$apiUrl + "/PedidoAgendamento", _pedidoAgendamento)
                                            .then(
                                                response6 => {
                                                    // get body data
                                                    this.PedidoAgendamento = response6.body;
                                                    console.log("gravei pedido agendamento")
                                                    validacarga = true;
                                                },
                                                async response6 => {
                                                    let recebe2 = response6.body;
                                                    this.$mensagem_normal(recebe2);
                                                    validacarga = false;
                                                }
                                            );
                                    });

                                }

                             
                                //inserir pedido endereco
                                let _pedidoEndereco = {
                                    PedidoEnderecoId: 0,
                                    Endereco: dadosArmazenados.agenda[0].endereco,
                                    Numero: parseInt(dadosArmazenados.agenda[0].numero, 10),
                                    Complemento: dadosArmazenados.agenda[0].complemento,
                                    Pais: { PaisId: 1 },
                                    Estado: { EstadoId: dadosArmazenados.agenda[0].estado.estadoId },
                                    Cidade: { CidadeId: dadosArmazenados.agenda[0].cidade.cidadeId },
                                    Bairro: dadosArmazenados.agenda[0].bairro.nomeBairro,
                                    CEP: dadosArmazenados.agenda[0].cep,
                                    Pedido: { PedidoId: parseInt(this.Pedido.pedidoId, 10) },
                                }
                                console.log(_pedidoEndereco)
                                this.$http
                                    .post(this.$apiUrl + "/PedidoEndereco", _pedidoEndereco)
                                    .then(
                                        response7 => {
                                            // get body data
                                            this.PedidoEndereco = response7.body;
                                            console.log("gravei pedido endereco")
                                            validacarga = true;
                                        },
                                        async response7 => {
                                            let recebe2 = response7.body;
                                            this.$mensagem_normal(recebe2);
                                            validacarga = false;
                                        }
                                    );
                            }

                            /* //inserir pedido pagamento comprova
                            let _pedidoPagamentoComprova = {}
                            if (dadosArmazenados.pagamento != '') {
                                _pedidoPagamentoComprova = {
                                    PedidoPagtoComprovaId: 0,
                                    UrlImg: dadosArmazenados.pagamento[0].FotoComprova,
                                    Pedido: { PedidoId: parseInt(this.Pedido.pedidoId, 10) },
                                }
                                
                                this.$http
                                .post(this.$apiUrl + "/PedidoPagtoComprova", _pedidoPagamentoComprova)
                                .then(
                                    response6 => {
                                        // get body data
                                        this.PedidoComprova = response6.body;
                                        console.log("gravei item pedido pagamento comprova")
                                        console.log(this.PedidoComprova)
                                        validacarga = true;
                                    },
                                    async response6 => {
                                        let recebe2 = response6.body;
                                        this.$mensagem_normal(recebe2);
                                        validacarga = false;
                                    }
                                );
                            } */

                            //inserir pedido mensagem
                            let _pedidoMensagem = {}
                            if (dadosArmazenados.mensagem != '') {
                                _pedidoMensagem = {
                                    PedidoMensagemId: 0,
                                    Pedido: { PedidoId: parseInt(this.Pedido.pedidoId, 10) },
                                    UrlImagem: dadosArmazenados.mensagem[0].urlImagem,
                                    NomeDe: dadosArmazenados.informacao[0].nomeDe,
                                    NomePara: dadosArmazenados.informacao[0].nomePara,
                                    TelefoneRecebe: dadosArmazenados.informacao[0].telefoneRecebe,
                                    Texto: dadosArmazenados.mensagem[0].textoMensagem,

                                }
                                console.log(_pedidoMensagem)
                                this.$http
                                    .post(this.$apiUrl + "/PedidoMensagem", _pedidoMensagem)
                                    .then(
                                        response7 => {
                                            // get body data
                                            this.PedidoMensagem = response7.body;
                                            console.log("gravei item pedido mensagem")
                                            validacarga = true;
                                        },
                                        async response7 => {
                                            let recebe3 = response7.body;
                                            this.$mensagem_normal(recebe3);
                                            validacarga = false;
                                        }
                                    );
                            }

                            //inserir pedido foto
                            let _pedidoFoto = {}
                            if (dadosArmazenados.foto != '') {
                                _pedidoFoto = {
                                    PedidoFotoId: 0,
                                    Pedido: { PedidoId: parseInt(this.Pedido.pedidoId, 10) },
                                    UrlImagem: dadosArmazenados.foto[0].imagemUrl,
                                    ValorFoto: dadosArmazenados.foto[0].valor,
                                }
                                console.log(_pedidoFoto)
                                this.$http
                                    .post(this.$apiUrl + "/PedidoFoto", _pedidoFoto)
                                    .then(
                                        response8 => {
                                            // get body data
                                            this.PedidoFoto = response8.body;
                                            console.log("gravei item pedido Foto")
                                            validacarga = true;
                                        },
                                        async response8 => {
                                            let recebe4 = response8.body;
                                            this.$mensagem_normal(recebe4);
                                            validacarga = false;
                                        }
                                    );
                            }

                            if (validacarga) {
                                localStorage.removeItem('dadoscompralove');

                                this.Contato.NomeEmpresa = "Love Cestas"
                                this.Contato.Assunto = "Pedido Realizado no Site - Pedido: " + this.Pedido.pedidoId
                                this.Contato.Destinatario = this.ListaTextos[0].emailAvisoCompra;
                                this.Contato.EmailResposta = usuarioTmp.email
                                this.Contato.TextoEmail = "Pedido Realizado no site Love Cestas - Pedido:" + this.Pedido.pedidoId + " Nome: " + usuarioTmp.nomeUsuario + " Código:" + usuarioTmp.usuarioId + " E-mail: " + usuarioTmp.email
                                this.Contato.Anexo = ""
                                this.Contato.NomeAnexo = ""
                                this.Contato.TipoAnexo = "";

                                this.EnviaEmail();
                                //this.$mensagem_normal("Pedido atualizado!");
                            }
                        },
                        async response2 => {
                            let recebe = response2.body;
                            this.$mensagem_normal(recebe);
                        }
                    );
            },
            EnviaEmail() {
                //var arquivos = document.getElementById("arquivo").files;
                //SE TIVER ANEXO:
                //if (arquivos != null && arquivos.length > 0) {
                //    var arq = "";
                //    var reader = new FileReader();
                //    var arquivoAnexo = arquivos[arquivos.length - 1];
                //    reader.readAsDataURL(arquivoAnexo);
                //    reader.onload = () => {
                //        arq = reader.result;
                //        this.Contato.Anexo = arq.toString().split(",")[1];
                //        this.Contato.NomeAnexo = arquivoAnexo.name;
                //        this.Contato.TipoAnexo = arquivoAnexo.type;
                //        this.$http
                //            .post(this.$apiUrl + "/contato/enviamail", this.Contato)
                //            .then(
                //                (response) => {
                //                    if (response.ok) alert("Enviado");
                //                },
                //                (error) => {
                //                    alert(error.bodyText);
                //                }
                //            );
                //    };
                //}
                //else {
                //SE NAO TIVER ANEXO:
                this.$http.post(this.$apiUrl + "/contato/enviamail", this.Contato)
                    .then(response => {
                        if (response.ok)
                            console.log("Enviado");
                    }, error => {
                        console.log(error.bodyText)
                    });
                //}
            },
             EnviaEmail2() {
                //var arquivos = document.getElementById("arquivo").files;
                //SE TIVER ANEXO:
                //if (arquivos != null && arquivos.length > 0) {
                //    var arq = "";
                //    var reader = new FileReader();
                //    var arquivoAnexo = arquivos[arquivos.length - 1];
                //    reader.readAsDataURL(arquivoAnexo);
                //    reader.onload = () => {
                //        arq = reader.result;
                //        this.Contato.Anexo = arq.toString().split(",")[1];
                //        this.Contato.NomeAnexo = arquivoAnexo.name;
                //        this.Contato.TipoAnexo = arquivoAnexo.type;
                //        this.$http
                //            .post(this.$apiUrl + "/contato/enviamail", this.Contato)
                //            .then(
                //                (response) => {
                //                    if (response.ok) alert("Enviado");
                //                },
                //                (error) => {
                //                    alert(error.bodyText);
                //                }
                //            );
                //    };
                //}
                //else {
                //SE NAO TIVER ANEXO:
                this.$http.post(this.$apiUrl + "/contato/enviamail", this.Contato)
                    .then(response => {
                        if (response.ok)
                            console.log("Enviado");
                    }, error => {
                        console.log(error.bodyText)
                    });
                //}
            },
            copy() {
                var copyTextarea = this.$refs.copiar
                copyTextarea.select();
                try {
                    var successful = document.execCommand('copy');
                    if (successful) {
                        this.textocopiado = "chave copiada!";
                        this.mostra = false;
                    }
                } catch (err) {
                    alert('Opa, Não conseguimos copiar o texto, é possivel que o seu navegador não tenha suporte, tente usar Crtl+C.');
                }
            },
            carregaFoto() {
                let file = document.getElementById("arquivo").files[0];
                let erroarquivo = "";
                if (file != null) {
                    var ext = file.name.split(".").pop();

                    if (ext != "jpg" && ext != "png" && ext != "gif" && ext != "jpeg") {
                        erroarquivo =
                            "Por favor selecione uma imagem ('.jpg', '.jpeg', '.png' ou '.gif')";
                    } else {
                        erroarquivo = "";
                    }
                }
                else {
                    erroarquivo = "Por favor selecione uma imagem ('.jpg', '.jpeg', '.png' ou '.gif')";
                }
                if (erroarquivo != null && erroarquivo.length > 0) {
                    this.$mensagem_normal(erroarquivo);
                }
                else {
                    let data = new FormData();
                    if (file != null) {
                        data.append("arquivo", file);
                        this.$http.post(this.$apiUrl + "/PedidoPagtoComprova/upload", data).then(
                            (response3) => {
                                this.FotoCarregada = response3.body;
                                    //inserir pedido pagamento comprova
                                    let _pedidoPagamentoComprova = {}
                                        _pedidoPagamentoComprova = {
                                            PedidoPagtoComprovaId: 0,
                                            UrlImg: this.FotoCarregada,
                                            Pedido: { PedidoId: parseInt(this.Pedido.pedidoId, 10) },
                                        }
                                        
                                        this.$http
                                        .post(this.$apiUrl + "/PedidoPagtoComprova", _pedidoPagamentoComprova)
                                        .then(
                                            response6 => {
                                                // get body data
                                                this.PedidoComprova = response6.body;
                                                let usuarioTmp = JSON.parse(this.$cookie.get("usuarioLove"));
                                                console.log("gravei item pedido pagamento comprova")
                                                console.log(this.PedidoComprova)
                                                 this.Contato.NomeEmpresa = "Love Cestas"
                                                 this.Contato.Assunto = "Anexado comprovanto Pgto - Pedido: " + this.Pedido.pedidoId
                                                 this.Contato.Destinatario = "atendimento@lovecestas.com.br";
                                                 this.Contato.EmailResposta = "atendimento@lovecestas.com.br";
                                                 this.Contato.TextoEmail = "Anexado comprovanto Pgto - Pedido:" + this.Pedido.pedidoId + " Nome: " + usuarioTmp.nomeUsuario + " Código:" + usuarioTmp.usuarioId + " E-mail: " + usuarioTmp.email
                                                 this.Contato.Anexo = ""
                                                 this.Contato.NomeAnexo = ""
                                                 this.Contato.TipoAnexo = "";

                                                 this.EnviaEmail2();
                                                 this.$redirect("/meusPedidosDetalhe/" + parseInt(this.Pedido.pedidoId, 10));
                                            },
                                            async response6 => {
                                                let recebe2 = response6.body;
                                                this.$mensagem_normal(recebe2);
                                            }
                                        );
                            },
                            (response3) => {
                                // error callback
                                this.$mensagem_erro(response3.body);
                            }
                        );
                    }
                }
            },
        },
        mounted() {

        },
        created() {
            let dadosArmazenados = localStorage.getItem('dadoscompralove');
            dadosArmazenados = JSON.parse(dadosArmazenados);
            if (dadosArmazenados) {

                /*valor total conversão*/
                let _totalProdutos = 0;
                if (dadosArmazenados.produtos != '') {
                    dadosArmazenados.produtos.forEach((itemprod) => {
                    
                        _totalProdutos += parseFloat(itemprod.produto.valorAte)
                    });
                }

                if (dadosArmazenados.avulsos != '') {
                    if (dadosArmazenados.avulsos[0].produtoAvulsoId != 0) {
                        dadosArmazenados.avulsos.forEach((itemavul) => {
                        
                            _totalProdutos += parseFloat(itemavul.avulso.valorDe)
                        });
                    }
                }

                if (dadosArmazenados.agenda != '') {
                    if (dadosArmazenados.agenda[0].periodoEntrega != '') {
                    
                        _totalProdutos += parseFloat(dadosArmazenados.agenda[0].periodoEntrega.valorFrete)
                    }
                }

                if (dadosArmazenados.foto != '') {
                    if (dadosArmazenados.foto[0].imagemUrl != "Sem foto") {
                        dadosArmazenados.foto.forEach((itemfoto) => {
                            
                            _totalProdutos += parseFloat(itemfoto.valor)
                        });
                    }
                }

                if (dadosArmazenados.baloes != '') {
                    if (dadosArmazenados.baloes[0].avulso != 'Sem Balao') {
                        dadosArmazenados.baloes.forEach((itemavul) => {
                            _totalProdutos += parseFloat(itemavul.avulso.valorDe)
                        });
                    }
                }

                 let _cupom = 0;
                  if (dadosArmazenados.cupom != '') {
                     _cupom = parseFloat(dadosArmazenados.cupom[0].ValorCupom, 10)
                 }


                this.ValorTotal = _totalProdutos - _cupom;

                if (dadosArmazenados.pagamento != '') {
                    if (dadosArmazenados.pagamento[0].TipoPgto == 'cartao') {
                        //carrega informações da lista de opções de pagamento
                        this.$http
                            .get(this.$apiUrl + "/configoperadora")
                            .then((res2) => res2.json())
                            .then((tiu2) => {
                                this.ListaTextos = tiu2.filter(x => x.cadOperadora.cadOperadoraId == parseInt(dadosArmazenados.pagamento[0].CodId, 10))
                                this.showcomprovante = false;
                                 this.mostrarchavepix = false;
                            });
                    } else if (dadosArmazenados.pagamento[0].TipoPgto == 'pix') {
                        //carrega informações da lista de opções de pagamento
                        this.$http
                            .get(this.$apiUrl + "/configpix")
                            .then((res2) => res2.json())
                            .then((tiu2) => {
                                this.ListaTextos = tiu2.filter(x => x.cadPix.cadPixId == parseInt(dadosArmazenados.pagamento[0].CodId, 10))
                                this.showcomprovante = true;
                                this.mostrarchavepix = true;
                            });
                    } else if (dadosArmazenados.pagamento[0].TipoPgto == 'banco') {
                        //carrega informações da lista de opções de pagamento
                        this.$http
                            .get(this.$apiUrl + "/configbanco")
                            .then((res2) => res2.json())
                            .then((tiu2) => {
                                this.ListaTextos = tiu2.filter(x => x.cadBanco.cadBancoId == parseInt(dadosArmazenados.pagamento[0].CodId, 10))
                                console.log(this.ListaTextos)
                                 this.showcomprovante = true;
                                 this.mostrarchavepix = false;
                            });
                    }
                    //descarrega informações no pedido
                    this.CarregaPedido();
                }
                else {
                    this.$redirect("/");
                }
            } else {
               //console.log('this.PedidoComprova')
               ///console.log(this.PedidoComprova)
               this.$redirect("/");
            }
        }
    }

</script>

<style scoped>

    @import '/css_cart/carrinho.css';
    @import '/css_cart/nova_conta.css';
    @import '/css_cart/pedido_realizado.css';
</style>