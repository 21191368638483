<template>

    <section class="produtos">

        <Header :SegmentoId="parseInt(segmentoProdNivel1Id,10)" />

        <div v-if="bg_imagem!=''" class="topo-internas set-img"
             :style="{ backgroundImage: 'url(' + $Url  + bg_imagem + ')' }">
        </div>
        <div v-else class="topo-internas set-img"
             :style="{ backgroundImage: 'url(' + $Url  + '/imagens/generica.jpg)' }">
        </div>

        <nav class="nav-produtos_2">
            <div class="container">
                <div class="row">
                    <div class="col-md-6 col-lg-12 col-xl-10">

                        <ul class="nav_produtos_2 hidden-sm-md">
                            <li v-for="MenuSegmento in MenuSegmentos" :key="MenuSegmento.segmentoProdNivel2Id"
                                class="produto-item produto-item_2" style="border: 1px solid #ebebeb; border-radius: 8px; padding: 1px;">
                                <a class="produto-link" @click="CarregaSegmento2(MenuSegmento)">{{ MenuSegmento.nomeSegmento }}</a>
                            </li>
                        </ul>

                        <!-- Somente MOBILE -->
                        <v-select v-model="SegselectedNav" @input="MudaSegMobile"
                                  :options="SegMobile"
                                  :value="SegMobile.segmentoProdNivel2Id"
                                  single-line
                                  :searchable="false"
                                  label="nomeSegmento" class="style-chooser hidden-lg hidden-xl mt-4 mt-md-0"></v-select>

                    </div>
                    <div class="col-md-6 col-lg-3 offset-lg-9 col-xl-2 offset-xl-0 mt-3 mt-md-0">
                        <v-select v-model="Ordemselected" @input="MudaOrdem"
                                  :options="Ordenar"
                                  :reduce="nome => nome.valor"
                                  single-line
                                   :searchable="false"
                                  label="nome"
                                  class="style-chooser">
                        </v-select>
                    </div>

                </div>

            </div>
        </nav>
        <div class="produtos mb-5">
            <div class="container">
                <div class="row">
                    <div class="col-md-12 text-center">
                        <h3 class="titulo_section titulo_section-xl text-center">{{ NomeSegmentoFilho }}</h3>
                    </div>
                    <div class="grid-produtos-mobile col-6 col-md-4 col-lg-3 mt-3 mb-lg-0" v-for="produto in Produtos" :key="produto.produtoId">
                        <div class="produto-card produto-card_2">
                            <a href="" @click="$redirect_reload('/produtoDetalhe/' + produto.produtoId)">
                                <div v-if="produto.imagens[0] != null" class="produto-img set-img set-tam4" :style="{ backgroundImage: 'url('  + $Url  + produto.imagens[0].urlImg + ')',backgroundSize:'contain' }">
                                    <img v-for="ItemEtiqueta in CarregaEtiqueta(produto.produtoId)" :key="ItemEtiqueta.produtoPremioId" :src="ItemEtiqueta.urlImg" alt="">
                                    <div class="middle middle_2">
                                        <div class="text">Ver Mais Detalhes</div>
                                    </div>
                                </div>
                                <div v-else class="produto-img set-img set-tam4" :style="{ backgroundImage: 'url(' + $Url  + '/imagens/indisponivel.jpg)', }">
                                    <img v-for="ItemEtiqueta in CarregaEtiqueta(produto.produtoId)" :key="ItemEtiqueta.produtoPremioId" :src="ItemEtiqueta.urlImg" alt="">
                                    <div class="middle middle_2">
                                        <div class="text">Ver Mais Detalhes</div>
                                    </div>
                                </div>
                            </a>
                            <div class="produto-content" style="padding-top:10px;">
                                <h6 class="titulo-card">{{ produto.nomeProduto }}</h6>
                                <span v-if="produto.valorDe > 0" class="preco-antigo">De {{   parseFloat(produto.valorDe,10).toFixed(2).toString().replace('.',',') }}</span>
                                <p>
                                    <span class="texto-destaque">{{ parcelas }} x</span>
                                    de R$ <span class="texto-destaque"> {{ parseFloat(produto.valorAte/parcelas,10).toFixed(2).toString().replace('.',',') }}</span> <span class="texto-destaque2">{{ parcelasTexto }}</span>
                                </p>
                                <p>
                                    R$ <span class="texto-destaque3"> {{ parseFloat(produto.valorAte,10).toFixed(2).toString().replace('.',',') }}</span>
                                </p>
                                <!--<router-link :to="/produtoDetalhe/ + produto.produtoId" class="btn btn-card" style="margin-top: 5px;">Ver Detalhes</router-link>-->
                                <a href="" v-if="produto.indisponivel != true" @click="$redirect_reload('/produtoDetalhe/' + produto.produtoId)" class="btn btn-card" style="margin-top: 5px;">Ver Detalhes</a>
                                <a v-else class="texto-destaque3" style="margin-top: 5px;">Indispon&iacute;vel</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <FitaRodape class="" titulo=""></FitaRodape>

        <Footer />

    </section>

</template>

<script>

    import Header from '@/components/site/shared/Header.vue'
    import Footer from '@/components/site/shared/Footer.vue'
    import FitaRodape from '@/components/site/shared/FitaRodape.vue'
    import vSelect from 'vue-select';
    import { VMoney } from 'v-money'

    export default {
        components: {
            Header,
            FitaRodape,
            Footer,
            vSelect
        },
        data() {
            return {
                segmentoProdNivel1Id: this.$route.params.id,
                segmentoProdNivel2Id: this.$route.params.idcategoria,
                MenuSegmentos: [],
                MenuSegmento: {},
                ProdutosGerais: [],
                Produtos: [],
                produto: {},
                ListaEtiquetaImg: [],
                NomeSegmentoFilho: '',
                Ordemselected: 'Ordenar por',
                Ordenar: [
                    {
                        nome: "Maior Valor",
                        valor: 3
                    },
                    {
                        nome: "Menor Valor",
                        valor: 4
                    }
                ],
                SegselectedNav: 'Escolha sua cesta',
                SegMobile: [],
                parcelas: 0,
                parcelasTexto: '',
                bg_imagem: '',
                money: {
                    decimal: ',',
                    thousands: '.',
                    precision: 2,
                    masked: true
                }
            }
        },
        directives: { money: VMoney },
        methods: {
            MudaOrdem() {
                if (this.Ordemselected == 3) {
                    this.Produtos.sort(function (a, b) {
                        return a.valorAte - b.valorAte;
                    }).reverse();
                } else if (this.Ordemselected == 4) {
                    this.Produtos.sort(function (a, b) {
                        return a.valorAte - b.valorAte;
                    });
                } else {
                    this.Produtos.sort(function (a, b) {
                        return a.segmentos[0].ordem - b.segmentos[0].ordem;
                    });
                }

            },
            MudaSegMobile(value) {
                //console.log(value)
                
                this.Produtos = this.ProdutosGerais.filter(x => x.visivel && x.segmentos.some(y => y.segmentoProdNivel2.segmentoProdNivel2Id == parseInt(value.segmentoProdNivel2Id, 10))).sort(function (a, b) {
                    return a.segmentos.filter(w => w.segmentoProdNivel2.segmentoProdNivel2Id == parseInt(value.segmentoProdNivel2Id, 10))[0].ordem - b.segmentos.filter(w => w.segmentoProdNivel2.segmentoProdNivel2Id == parseInt(value.segmentoProdNivel2Id, 10))[0].ordem
                });
                this.NomeSegmentoFilho = value.nomeSegmento;
                console.log("value");
                console.log(value);
                if (value.imagens != null && value.imagens.length > 0) {
                     this.bg_imagem = value.imagens[0].urlImagem;
                }
                else {
                    this.bg_imagem = '';
                     document.getElementsByClassName('topo-internas')[0].style.display = "none";
                }
                //this.MudaOrdem();
            },
            CarregaSegmento2(value) {
                this.Produtos = this.ProdutosGerais.filter(x => x.visivel && x.segmentos.some(y => y.segmentoProdNivel2.segmentoProdNivel2Id == parseInt(value.segmentoProdNivel2Id, 10))).sort(function (a, b) {
                    return a.segmentos.filter(w => w.segmentoProdNivel2.segmentoProdNivel2Id == parseInt(value.segmentoProdNivel2Id, 10))[0].ordem - b.segmentos.filter(w => w.segmentoProdNivel2.segmentoProdNivel2Id == parseInt(value.segmentoProdNivel2Id, 10))[0].ordem
                });
                console.log(this.Produtos)
                this.NomeSegmentoFilho = value.nomeSegmento;
                 console.log("value");
                console.log(value);
                 if (value.imagens != null && value.imagens.length > 0) {
                    this.bg_imagem = value.imagens[0].urlImagem;
                }
                else {
                    this.bg_imagem = '';
                     document.getElementsByClassName('topo-internas')[0].style.display = "none";
                }
                //this.MudaOrdem();

            },
            CarregaEtiqueta(value) {
                if (this.ListaEtiquetaImg.produto != null) {
                    var retorno = this.ListaEtiquetaImg.filter(x => x.produto.produtoId == value);
                    return retorno.sort(function (a, b) {
                        return a.ordem - b.ordem;
                    });
                }
                else {
                    return null
                }
            },
            CarregaImagemUnica(value) {
                if (value != null) {
                    value.sort(function (a, b) {
                        return a.ordem - b.ordem;
                    });

                    //value.sort(function (a, b) {
                    //    if (a.ordem > b.ordem) {
                    //        return 1;
                    //    }
                    //    if (a.ordem < b.ordem) {
                    //        return -1;
                    //    }
                    //    return 0;
                    //});
                    return this.$Url + value[0].urlImg;
                }
                else {
                    return this.$Url + "/imagens/indisponivel.jpg"
                }

            },
        },
        created() {

            this.$http
                .get(this.$apiUrl + "/SegmentoProdNivel2/Nivel1/" + parseInt(this.segmentoProdNivel1Id, 10))
                .then((res2) => res2.json())
                .then((tiu2) => {
                    this.MenuSegmentos = tiu2.filter(x => x.visivel).sort(function (a, b) {
                        return a.ordem - b.ordem;
                    });
                    this.SegMobile = this.MenuSegmentos;

                    if (this.MenuSegmentos != null) {
                        if(this.MenuSegmentos.length > 0 && this.MenuSegmentos[0].imagens.length > 0){
                            this.bg_imagem = this.MenuSegmentos[0].imagens[0].urlImagem;
                        }
                        else{
                            this.bg_imagem = '';
                            document.getElementsByClassName('topo-internas')[0].style.display = "none";
                        }
                   }
                    
                    this.$http
                        .get(this.$apiUrl + "/produto/segmentoprodnivel1/" + parseInt(this.segmentoProdNivel1Id, 10))
                        .then((res2) => res2.json())
                        .then((tiu2) => {
                            this.ProdutosGerais = tiu2;

                            if (this.MenuSegmentos != '') {
                              
                                if (this.segmentoProdNivel2Id == 0) {
                                    this.CarregaSegmento2(this.MenuSegmentos[0])
                                    this.NomeSegmentoFilho = this.MenuSegmentos[0].nomeSegmento;
                                }
                                else {
                                    this.SegselectedNav = this.MenuSegmentos.filter(x => x.segmentoProdNivel2Id == parseInt(this.segmentoProdNivel2Id))
                                    console.log('this.SegselectedNav')
                                    console.log(this.SegselectedNav)
                                    this.CarregaSegmento2(this.SegselectedNav[0])
                                }
                            }
                            else {
                                this.Produtos = this.ProdutosGerais;
                                this.NomeSegmentoFilho = '';
                                this.MudaOrdem();
                            }

                        });
                    });

            this.$http
                .get(this.$apiUrl + "/produtopremio")
                .then((res2) => res2.json())
                .then((tiu2) => {
                    this.ListaEtiquetaImg = tiu2.sort(function (a, b) {
                        return a.ordem - b.ordem;
                    });
                });

            this.$http
                .get(this.$apiUrl + "/produtotextogeral")
                .then((res2) => res2.json())
                .then((tiu2) => {
                    this.parcelas = parseInt(tiu2[0].texto1, 10);
                    this.parcelasTexto = tiu2[0].texto2;
                });

        },
        mounted() {

        },
    }

</script>

<style scoped>

    @import '/css/produtos.css';
    @import '/css/nav_produtos.css';
    @import '/css/nav_produtos_2.css';
</style>