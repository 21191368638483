<template>

    <section v-if="Fitas!=''" class="ribbon ribbon-lg container-fluid" :style="{'backgroundColor': Fitas[0].cor1,'margin-top': '0px'}">
        <div class="row">
            <div class="col">
                <span :style="{'color': Fitas[0].cor2, 'font-family': 'MyriadPro-Light'}" v-html=" Fitas[0].resumo1"></span>
            </div>
        </div>
    </section>

</template>

<script>
    export default {
        data() {
            return {
                Fitas: [],
            };
        },
        methods: {
        },
        created() {

            this.$http
                .get(this.$apiUrl + "/institucional/telas/texto%20barra")
                .then((res2) => res2.json())
                .then((tiu2) => {
                    this.Fitas = tiu2;
                });
        },
        mounted() {

        },
        components: {
        },
    };
</script>

<style scoped>

  @import '/css/fita.css';

</style>