<template>
    <section class="carrinho">

        <Header />
        <Fita class="hidden-sm" titulo="Presentes exclusivos para momentos especiais"></Fita>

        <section class="main-minha_conta">

            <div class="container">
                <div class="row">
                    <!-- Sidenav -->
                    <div class="col-lg-12 col-xl-12 mb-5">
                        <h3 class="titulo_section titulo_section-xl text-center mb-4">{{ Titulo }}</h3>
                        <div class="row">
                            <div class="col">
                                <div class="content text-center">
                                    <div v-html="Texto"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Main -->
                    <div class="col-lg-12 col-xl-12 mt-5 mt-lg-0">
                        <div class="container">
                            <div class="row">
                                <div class="grid-produtos-mobile col-6 col-md-4 col-lg-3 mt-3 mb-lg-0"
                                    v-for="produto in Produtos" :key="produto.institucionalId" @click="show($Url + produto.institucionalImg[0].urlImagem)">
                                    <div class="produto-card produto-card_2">
                                        <div v-if="produto.institucionalImg != null" class="produto-img set-img set-tam4"
                                            :style="{ backgroundImage: 'url(' + $Url + produto.institucionalImg[0].urlImagem + ')', backgroundSize: 'contain' }">
                                            <div class="middle middle_2">
                                                <div class="text">{{ produto.nome }}</div>
                                            </div>
                                        </div>
                                        <div v-else class="produto-img set-img set-tam4"
                                            :style="{ backgroundImage: 'url(' + $Url + '/imagens/indisponivel.jpg)', }">
                                            <div class="middle middle_2">
                                                <div class="text">{{ produto.nome }}</div>
                                            </div>
                                        </div>
                                        <div class="produto-content" style="padding-top:10px;">
                                            <h6 class="titulo-card" style="min-height: 75px">{{ produto.nome }}</h6>
                                            <a :href="'https://api.whatsapp.com/send?phone=5551996817372&text=Ol%C3%A1%2C%20gostaria%20de%20informa%C3%A7%C3%B5es%20sobre%20o%20produto%20corporativo%20' + ' ' + produto.nome"
                                                class="btn btn-card" style="margin-top: 5px;" target="_blank">Obter Orçamento</a>
                                        </div>
                                    </div>
                                </div>
                                <viewer :images="images">
                                     <img v-for="src in images" :key="src" :src="src">
                                </viewer>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </section>

        <FitaRodape class="ribbon-lg"></FitaRodape>
        <Footer />

    </section>
</template>

<script>

import Header from '@/components/site/shared/Header.vue'
import Fita from '@/components/site/shared/Fita.vue'
import FitaRodape from '@/components/site/shared/FitaRodape.vue'
import Footer from '@/components/site/shared/Footer.vue'
import 'viewerjs/dist/viewer.css'

export default {
    components: {
        Header,
        Fita,
        FitaRodape,
        Footer,
    },
    data() {
        return {
            Retorno: [],
            Titulo: '',
            Texto: '',
            Produtos: [],
            image:[],
        }
    },
    methods: {
        show(value) {
            console.log(value)
        this.$viewerApi({
          images: [value],
        })
      },
    },
    created() {

        this.$http
            .get(this.$apiUrl + "/institucional/telas/corporativotopo")
            .then((res2) => res2.json())
            .then((tiu2) => {
                this.Retorno = tiu2;
                if (this.Retorno != null) {
                    this.Titulo = this.Retorno[0].nome;
                    this.Texto = this.Retorno[0].resumo1;
                }

            });

        this.$http
            .get(this.$apiUrl + "/institucional/telas/corporativoprodutos")
            .then((res2) => res2.json())
            .then((tiu2) => {
                this.Produtos = tiu2.filter(x => x.visivel).sort(function (a, b) {
                    return a.ordem - b.ordem;
                });
            });
    },
    mounted() {

    },
}

</script>

<style scoped>
@import '/css_cart/carrinho.css';
@import '/css_cart/minha_conta.css';
@import '/css/suporte.css';
</style>