<template>

    <section class="carrinho">

        <Header />

        <Fita class="hidden-sm-md hidden-xs" />

        <section class="main-carrinho">

            <div class="container">
                <div class="row py-5">
                    <div class="col-lg-6 hidden-sm-md">
                        <div class="carrinho-bg set-img">
                            <img v-if="Titulos[0].institucionalImg[0] != null " class="img-fluid" :style="{ backgroundImage: 'url(' + $apiUrlIndex + Titulos[0].institucionalImg[0].urlImagem + ')' }" alt="">
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="box_content">
                            <h3 class="titulo-principal text-center mb-4">Criar Uma Nova Conta</h3>

                            <!-- Status -->
                            <div class="status-novaConta px-md-5">
                                <div class="status-item active pos-item2">
                                    <div class="status-number"><span>1</span></div>
                                    <span class="status-text">Login</span>
                                </div>
                                <div class="status-item pos-item2">
                                    <div class="status-number"><span>2</span></div>
                                    <span class="status-text">Informação</span>
                                </div>
                                <div class="status-item">
                                    <div class="status-number"><span>3</span></div>
                                    <span class="status-text">Pagamento</span>
                                </div>
                                <!-- <div class="status-item">
                                  <div class="status-number"><span>4</span></div>
                                  <span class="status-text">Confirmação</span>
                                </div> -->
                            </div>
                            <hr>
                            <!-- Form -->
                            <form class=" px-md-5 my-5">
                                <div class="form-group row">
                                    <label for="inputName" class="col-sm-5 col-form-label login-label">Nome Completo*</label>
                                    <div class="col-sm-7">
                                        <input v-model="Nomecli" type="text" class="form-control" id="inputName">
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label for="inputwhats" class="col-sm-5 col-form-label login-label">Whatsapp*</label>
                                    <div class="col-sm-7">
                                        <input v-model="Whatscli" type="text" class="form-control" id="inputwhats">
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label for="inputEmail" class="col-sm-5 col-form-label login-label">Email*</label>
                                    <div class="col-sm-7">
                                        <input v-model="Emailcli" type="email" class="form-control" id="inputEmail">
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label for="inputPassword" class="col-sm-5 col-form-label login-label">Senha*</label>
                                    <div class="col-sm-7">
                                        <input v-model="Senhacli" type="password" class="form-control" id="inputPassword">
                                    </div>
                                </div>
                            </form>

                            <div class="box_content-footer">
                                <div class="voltar"><a class="voltar text-secondary" href="javascript:window.history.go(-1)">Voltar</a></div>
                                <div class="mt-4 mt-md-0">
                                    <button @click="carregaCliente()" class="btn btn-success px-md-5 mt-0">Avançar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <FitaRodape class="" titulo=""></FitaRodape>
        <Footer />

    </section>

</template>

<script>

    import Header from '@/components/site/shared/Header.vue'
    import Fita from '@/components/site/shared/Fita.vue'
    import FitaRodape from '@/components/site/shared/FitaRodape.vue'
    import Footer from '@/components/site/shared/Footer.vue'
    import moment from 'moment';

    export default {
        components: {
            Header,
            Fita,
            FitaRodape,
            Footer
        },
        data() {
            return {
                Titulos: {},
                Nomecli: '',
                Whatscli: '',
                Emailcli: '',
                Senhacli: '',
                showModal_senha: false,
                Contato: {
                    NomeEmpresa: "",
                    Assunto: "",
                    Destinatario: "",
                    EmailResposta: "",
                    TextoEmail: "",
                    Anexo: "",
                    NomeAnexo: "",
                    TipoAnexo: "",
                },
            }
        },
        created() {

            //----------------- Titulos planos
            this.$http
                .get(this.$apiUrl + "/institucional/telas/textoconta")
                .then((res) => res.json())
                .then((retornoAPI) => {
                    this.Titulos = retornoAPI;
                });

        },
        methods: {
            carregaCliente() {
                if (this.Nomecli == '') {
                    this.$mensagem_normal("Por favor preencha o nome completo");
                } else if (this.Whatscli == '') {
                    this.$mensagem_normal("Por favor preencha o whatsapp");
                } else if (this.Emailcli == '') {
                    this.$mensagem_normal("Por favor preencha o e-mail");
                } else if (this.Senhacli == '') {
                    this.$mensagem_normal("Por favor preencha a Senha");
                } else {

                    let _cliente =
                    {
                        clienteId: 0,
                        nomeEmpresa: this.Nomecli,
                        email: this.Emailcli,
                        whatsapp: this.Whatscli,
                        tipoCliente: { tipoClienteId: 1 },
                        ativo: true,
                        visivel: true,
                        DataCadastro: moment().format(),
                        senha: this.$sha1(this.Senhacli),
                    };

                    this.$http.post(this.$apiUrl + "/clientes/", _cliente).then(
                        (response) => {
                            this.Cliente = response.body;
                            let codcli = this.Cliente.clienteId;

                            let _dados = {
                                nomeUsuario: '',
                                usuarioId: '',
                                senha: '',
                                email: '',
                            }
                            _dados.nomeUsuario = this.Nomecli;
                            _dados.usuarioId = codcli;
                            _dados.senha = this.$sha1(this.Senhacli);
                            _dados.email = this.Emailcli;

                            this.$http
                                .get(this.$apiUrl + "/institucional/telas/contato")
                                .then((res2) => res2.json())
                                .then((tiu2) => {
                                    this.Retorno = tiu2;
                                    if (this.Retorno != null) {

                                        this.Contato.NomeEmpresa = "Love Cestas"
                                        this.Contato.Assunto = "Cadastro de Cliente Novo " + _dados.nomeUsuario
                                        this.Contato.Destinatario = this.Retorno[0].referencia
                                        this.Contato.EmailResposta = _dados.email
                                        this.Contato.TextoEmail = "Novo cliente cadastrado no site Love Cestas - Data Cadastro:" + _cliente.DataCadastro + " Nome: " + _dados.nomeUsuario + " E-mail: " + _dados.email + " Telefone: " + _cliente.whatsapp
                                        this.Contato.Anexo = ""
                                        this.Contato.NomeAnexo = ""
                                        this.Contato.TipoAnexo = "";

                                        this.EnviaEmail();
                                    }

                                    this.$cookie.set("usuarioLove", JSON.stringify(_dados), {
                                        expires: "60m",
                                    });
                                    this.$redirect_reload("/novacontaendereco");
                                });

                        });
                }
            },
            EnviaEmail() {
                // var arquivos = document.getElementById("arquivo").files;
                //SE TIVER ANEXO:
                //if (arquivos != null && arquivos.length > 0) {
                //    var arq = "";
                //    var reader = new FileReader();
                //    var arquivoAnexo = arquivos[arquivos.length - 1];
                //    reader.readAsDataURL(arquivoAnexo);
                //    reader.onload = () => {
                //        arq = reader.result;
                //        this.Contato.Anexo = arq.toString().split(",")[1];
                //        this.Contato.NomeAnexo = arquivoAnexo.name;
                //        this.Contato.TipoAnexo = arquivoAnexo.type;
                //        this.$http
                //            .post(this.$apiUrl + "/contato/enviamail", this.Contato)
                //            .then(
                //                (response) => {
                //                    if (response.ok) alert("Enviado");
                //                },
                //                (error) => {
                //                    alert(error.bodyText);
                //                }
                //            );
                //    };
                //}
                //else {
                //SE NAO TIVER ANEXO:
                this.$http.post(this.$apiUrl + "/contato/enviamail", this.Contato)
                    .then(response => {
                        if (response.ok)
                            console.log("Enviado");
                    }, error => {
                        console.log(error.bodyText)
                    });
                //}
            },
        }
    }

</script>

<style scoped>

    @import '/css_cart/carrinho.css';
    @import '/css_cart/nova_conta.css';
    @import '/css_cart/status_conta.css';
</style>