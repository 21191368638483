<template>

    <div class="carrinho">
        <div class="border-top py-4">
            <h6 class="texto-destaque mb-0" style="text-align: right !important;">Valor Total: R$ {{ parseFloat(TotalProdutos).toFixed(2).toString().replace('.', ',') }}</h6>
        </div>
        <div class="row">
            <div class="form-group row align-items-center mb-3">
                <div class="col-sm-8 col-7">
                    <label for="inputNumCartao" class="col-sm-5 col-form-label label-pagto2">Número Cartão</label>
                    <input v-model="numCartao" type="text" v-mask="'####-####-####-####'" inputmode="numeric" @blur="Carregaparcelas()" class="form-control">
                </div>
                <div class="col-sm-4 col-5">
                    <label for="inputcvc" class="col-sm-12 col-form-label label-pagto2">CVC <span class="feedback">3 digitos atrás cartão</span></label>
                    <input v-model="numcvc" type="text" v-mask="'####'" inputmode="numeric" class="form-control">
                </div>
                <div class="col-sm-12 col-12">
                    <label for="inputNome" class="col-sm-12 col-form-label label-pagto2" style="padding-top: 20px;">
                        Nome Completo <span class="feedback">Titular do Cartão</span>
                    </label>
                    <input v-model="nomeTitular" type="text" class="form-control">
                </div>
                <div class="col-sm-6 col-6">
                    <label for="inputValidade" class="col-sm-12 col-form-label label-pagto2">Mês validade</label>
                    <v-select v-model="mesSelected" @input="MudaMes" pattern="\d*"
                              :options=" [{ valor: '01', texto: 'Janeiro', numtexto: '01' },
                                    { valor: '02', texto: 'Fevereiro', numtexto: '02' },
                                    { valor: '03', texto: 'Março', numtexto: '03' },
                                    { valor: '04', texto: 'Abril', numtexto: '04' },
                                    { valor: '05', texto: 'Maio', numtexto: '05' },
                                    { valor: '06', texto: 'Junho', numtexto: '06' },
                                    { valor: '07', texto: 'Julho', numtexto: '07' },
                                    { valor: '08', texto: 'Agosto', numtexto: '08' },
                                    { valor: '09', texto: 'Setembro', numtexto: '09' },
                                    { valor: '10', texto: 'Outubro', numtexto: '10' },
                                    { valor: '11', texto: 'Novembro', numtexto: '11' },
                                    { valor: '12', texto: 'Dezembro', numtexto: '12' },]"
                              :reduce="(x) => x.valor"
                              single-line
                              label="numtexto" placeholder="Mês" style="width:100%;"></v-select>
                </div>
                <div class="col-sm-6 col-6">
                    <label for="inputValidade" class="col-sm-12 col-form-label label-pagto2">Ano validade</label>
                    <v-select v-model="anoSelected" @input="MudaAno" pattern="\d*"
                              :options=" [{ano:'2021'}, {ano:'2022'}, {ano:'2023'}, {ano:'2024'}, {ano:'2025'}, {ano:'2026'}, {ano:'2027'}, {ano:'2028'}, {ano:'2029'}, {ano:'2030'}, {ano:'2031'}, {ano:'2032'}, {ano:'2033'}, {ano:'2034'}, {ano:'2035'}]"
                              :reduce="(x) => x.ano"
                              single-line
                              label="ano" placeholder="Ano"></v-select>
                </div>
                <div v-if="retornoparcelas != ''" class="col-sm-12 col-12">
                    <label for="inputcvc" class="col-sm-12 col-form-label label-pagto2">Qtde Parcelas</label>
                    <v-select v-model="Parcelaselected" @input="MudaParcela"
                              :options="retornoparcelas"
                              :value="retornoparcelas.pagamentoId"
                              single-line
                              label="textopagamento" class="style-chooser w-100"></v-select>
                </div>
            </div>
        </div>
        <div class="box_content-footer">
            <div class="voltar"><a class="voltar text-secondary" href="javascript:window.history.go(-1)">Voltar</a></div>
            <div class="mt-4 mt-md-0">
                <button v-if="btnavancar==true" @click="EfetuarPagamento()" class="btn btn-success px-md-5 mt-0">Avançar</button>
            </div>
        </div>


    </div>

</template>

<script>

    import vSelect from 'vue-select'

    export default {
        metaInfo: {
            htmlAttrs: {
                lang: 'pt-br'
            },
            meta: [
                { charset: 'utf-8' },
                { name: 'viewport', content: 'width=device-width,initial-scale=1.0' }
            ]
        },
        components: {
            vSelect,
        },
        data() {
            return {
                numCartao: '',
                nomeTitular: '',
                cpfTitular: '',
                numcvc: '',
                endereco: '',
                numeroend: '',
                complemento: '',
                siglaEstado: '',
                nomeCidade: '',
                nomeBairro: '',
                numCep: '',
                usuario: [],
                IP: {},
                mesSelected: null,
                CodMes: '',
                anoSelected: null,
                Retornocep: {},
                CodAno: 0,
                data: {},
                TokenYapay: '',
                DadosYapay: [],
                produtos: [],
                TipoCartao: '',
                CodParcela: '',
                retornopagamento: {},
                retornoparcelas: [],
                Parcelaselected: null,
                TotalProdutos: 0,
                dynamicMask: '###.###.###-##',
                showend: false,
                btnavancar: false,
            }
        },
        methods: {
            limpa() {
                if (this.numCep == '') {
                    this.showend = false
                    this.btnavancar = false
                }
            },
            MudaMes(value) {
                if (value != null) {
                    this.CodMes = value;
                }
                else {
                    this.CodMes = '';
                }
            },
            MudaAno(value) {
                if (value != null) {
                    this.CodAno = value;
                }
                else {
                    this.CodAno = 0;
                }
            },
            MudaParcela(value) {
                if (value != null) {
                    this.CodParcela = value.pagamentoId;
                }
                else {
                    this.CodParcela = "0";
                }
            },
            EfetuarPagamento() {
                if (this.numCartao == '') {
                    this.$mensagem_normal("Por favor preencha dados do cartão");
                } else if (this.nomeTitular == '') {
                    this.$mensagem_normal("Por favor preencha nome completo do titular");
                } else if (this.numcvc == '') {
                    this.$mensagem_normal("Por favor preencha Codigo CVV (digitos atrás do cartão)");
                } else if (this.mesSelected == null) {
                    this.$mensagem_normal("Selecione mês de validade do cartão");
                } else if (this.anoSelected == null) {
                    this.$mensagem_normal("Selecione ano de validade do cartão");
                } else if (this.Parcelaselected == null) {
                    this.$mensagem_normal("Selecione quantidade de parcelas para pagamento");
                }
                else {
                    const date = new Date();
                    this.TipoCartao = this.BuscaCartao(this.numCartao);
                    this.data =
                    {
                        MerchantOrderId:(date.getFullYear() +
                            ("0" + (date.getMonth() + 1)).slice(-2) +
                            ("0" + date.getDate()).slice(-2) +
                            ("0" + date.getHours()).slice(-2) +
                            ("0" + date.getMinutes()).slice(-2) +
                            ("0" + date.getSeconds()).slice(-2)).toString(),
                        Customer: {
                            Name: this.nomeTitular,
                            Email: this.usuario.email,
                        },
                        Payment: {
                            Type: "CreditCard",
                            Amount: parseFloat(this.TotalProdutos)*100,
                            Installments: parseInt(this.CodParcela),
                            SoftDescriptor: "LOVECESTAS SITE",
                            Capture:false,
                            CreditCard: {
                                CardNumber: this.numCartao.replaceAll('-', ''),
                                Holder: this.nomeTitular,
                                ExpirationDate: this.CodMes + "/" + this.CodAno,
                                SecurityCode: this.numcvc,
                                Brand: this.TipoCartao, //"Visa",
                                CardOnFile: {
                                    Usage: "Used",
                                    Reason: "Unscheduled"
                                }
                            },
                            IsCryptoCurrencyNegotiation: false
                        }
                    }
                    console.log(this.data)
                    
                      this.$http.post(this.$apiUrl + "/Cielo", this.data)
                        .then(response => {
                            if (response.ok) {
                                this.retornopagamento = response.body;
                                console.log('this.retornopagamento')
                                console.log(this.retornopagamento)
                                let dadosArmazenados = localStorage.getItem('dadoscompralove');
                                dadosArmazenados = JSON.parse(dadosArmazenados);
                                dadosArmazenados.pagamento = []
                                dadosArmazenados.pagamento.push({
                                    FormaPgto: 'Cielo', //+ this.retornopagamento.data_response.transaction.transaction_id,
                                    TipoPgto: 'cartao',
                                    CodId: '4',
                                    ValorTotal: this.TotalProdutos,
                                    FotoComprova: '',
                                    RetCielo: this.retornopagamento.MerchantOrderId.toString(),
                                    TidCielo: this.retornopagamento.Payment.Tid.toString(),
                                });

                              //  if ( this.retornopagamento.Payment.Links[1].Rel == "capture"){
                                   this.$http.put(this.$apiUrl + "/Cielo/" + this.retornopagamento.Payment.PaymentId.toString())
                                        .then(response => {
                                            if (response.ok) {
                                              console.log("retorno put cielo");
                                              console.log(response);
                                              this.Contato.NomeEmpresa = "Love Cestas"
                                              this.Contato.Assunto = "Venda Cielo no Site - TID: " + this.retornopagamento.Payment.Tid.toString()
                                              this.Contato.Destinatario = "financeiro@lovecestas.com.br";
                                              this.Contato.EmailResposta = "financeiro@lovecestas.com.br"
                                              this.Contato.TextoEmail = "Venda Cielo no site Love Cestas - TID:" + this.retornopagamento.Payment.Tid.toString()
                                              this.Contato.Anexo = ""
                                              this.Contato.NomeAnexo = ""
                                              this.Contato.TipoAnexo = "";

                                              this.EnviaEmail();
                                            }
                                        });
                                //}
                                localStorage.setItem('dadoscompralove', JSON.stringify(dadosArmazenados))
                                this.$redirect("/pedidoRealizado");
                            }
                        },
                            error => {
                                
                                try {
                                    this.$mensagem_erro(error.bodyText)

                                } catch {
                                    try {
                                        this.$mensagem_erro(error.data.error_response.validation_errors[0].code + " - " + error.data.error_response.validation_errors[0].message_complete)
                                    } catch {
                                        console.log(error)
                                        this.$mensagem_erro("Verifique os dados do cartão.")
                                    }
                                }
                            }); 
                }
 
            },
            BuscaCartao(cardnumber) {
                cardnumber = cardnumber.replace(/[^0-9]+/g, '');
                var cards = {
                    Visa: /^4[0-9]{12}(?:[0-9]{3})/, //visa
                    Master: /^5[1-5][0-9]{14}/, //mastercard
                    Diners: /^3(?:0[0-5]|[68][0-9])[0-9]{11}/,
                    Amex: /^3[47][0-9]{13}/, //amex
                    Discover: /^6(?:011|5[0-9]{2})[0-9]{12}/,
                    Hipercard: /^(606282\d{10}(\d{3})?)|(3841\d{15})/, //hipercard
                    Elo: /^((((636368)|(438935)|(504175)|(451416)|(636297))\d{0,10})|((5067)|(4576)|(4011))\d{0,12})/, //elo
                    JCB: /^(?:2131|1800|35\d{3})\d{11}/,
                    Aura: /^(5078\d{2})(\d{2})(\d{11})$/
                };

                for (var flag in cards) {
                    if (cards[flag].test(cardnumber)) {
                        return flag;
                    }
                }

                return false;
            },
            Buscacep(value) {
                this.$http
                    .get("https://viacep.com.br/ws/" + value + "/json/")
                    .then((res2) => res2.json())
                    .then((tiu2) => {
                        this.Retornocep = tiu2

                        if (this.Retornocep != null) {
                            this.endereco = this.Retornocep.logradouro;
                            this.numeroend = null;
                            this.complemento = this.Retornocep.complemento;
                            this.siglaEstado = this.Retornocep.uf;
                            this.nomeCidade = this.Retornocep.localidade;
                            this.nomeBairro = this.Retornocep.bairro;
                            this.showend = true;
                            this.btnavancar = true;
                        }
                        else {
                            alert("CEP inexistente");
                        }
                    });
            },
            Carregaparcelas() {

                this.retornoparcelas = [];
                this.retornoparcelas.push({ pagamentoId: 1, textopagamento: "1x de R$ " + parseFloat(this.TotalProdutos).toFixed(2).toString().replace('.', ',') + " Sem Juros" })
                this.retornoparcelas.push({ pagamentoId: 2, textopagamento: "2x de R$ " + (parseFloat(this.TotalProdutos) / 2).toFixed(2).toString().replace('.', ',') + " Sem Juros" })
                this.retornoparcelas.push({ pagamentoId: 3, textopagamento: "3x de R$ " + (parseFloat(this.TotalProdutos) /3).toFixed(2).toString().replace('.', ',') + " Sem Juros" })
                 this.retornoparcelas.push({ pagamentoId: 4, textopagamento: "4x de R$ " + (parseFloat(this.TotalProdutos) /4).toFixed(2).toString().replace('.', ',') + " Sem Juros" })
                this.retornoparcelas.push({ pagamentoId: 5, textopagamento: "5x de R$ " + (parseFloat(this.TotalProdutos) /5).toFixed(2).toString().replace('.', ',') + " Sem Juros" })
                this.retornoparcelas.push({ pagamentoId: 6, textopagamento: "6x de R$ " + (parseFloat(this.TotalProdutos) /6).toFixed(2).toString().replace('.', ',') + " Sem Juros" })
            /*    this.retornoparcelas.push({ pagamentoId: 7, textopagamento: "7x de R$ " + (parseFloat(this.TotalProdutos) /7).toFixed(2).toString().replace('.', ',') + " Sem Juros" })
                this.retornoparcelas.push({ pagamentoId: 8, textopagamento: "8x de R$ " + (parseFloat(this.TotalProdutos) /8).toFixed(2).toString().replace('.', ',') + " Sem Juros" })
                this.retornoparcelas.push({ pagamentoId: 9, textopagamento: "9x de R$ " + (parseFloat(this.TotalProdutos) /9).toFixed(2).toString().replace('.', ',') + " Sem Juros" })
                this.retornoparcelas.push({ pagamentoId: 10, textopagamento: "10x de R$ " + (parseFloat(this.TotalProdutos) /10).toFixed(2).toString().replace('.', ',') + " Sem Juros" })
                this.retornoparcelas.push({ pagamentoId: 11, textopagamento: "11x de R$ " + (parseFloat(this.TotalProdutos) /11).toFixed(2).toString().replace('.', ',') + " Sem Juros" })
                this.retornoparcelas.push({ pagamentoId: 12, textopagamento: "12x de R$ " + (parseFloat(this.TotalProdutos) /12).toFixed(2).toString().replace('.', ',') + " Sem Juros" }) */

                this.btnavancar = true;

                //if (this.numCartao != '') {
                //    this.TipoCartao = this.BuscaCartao(this.numCartao);
                //    let dados = {
                //        token_account: this.TokenYapay,
                //        price: this.TotalProdutos.toString(),
                //        type_response: "J"
                //    }
                    //let retorno = []
                    //console.log("aqui")
                    //this.$http.post(this.$apiUrl + "/get?url=https://api.intermediador.yapay.com.br/v1/transactions/simulate_splitting", dados)
                    //    .then(response => {
                    //        if (response.ok) {
                    //            try {
                    //                console.log(response)
                    //                retorno = response.body.data_response.payment_methods.filter(x => x.payment_method_id == parseInt(this.TipoCartao, 10));

                    //                if (retorno != '') {
                    //                    retorno[0].splittings.forEach((itemex) => {
                    //                        if (itemex.split_rate == 0) {
                    //                            this.retornoparcelas.push({ pagamentoId: itemex.split, textopagamento: itemex.split + "x de R$ " + parseFloat(itemex.value_split).toFixed(2).toString().replace('.', ',') + " Sem Juros" })
                    //                        }
                    //                        else {
                    //                            this.retornoparcelas.push({ pagamentoId: itemex.split, textopagamento: itemex.split + "x de R$ " + parseFloat(itemex.value_split).toFixed(2).toString().replace('.', ',') })
                    //                        }

                    //                    });
                    //                }
                    //                else {
                    //                    this.$mensagem_erro("Número de cartão inválido para esta compra.")
                    //                }

                    //            } catch {
                    //                this.$mensagem_erro("Número de cartão inválido para esta compra.")
                    //            }

                    //        }
                    //    },
                    //        error => {
                    //            this.$mensagem_erro("Número de cartão inválido para esta compra.")
                    //            console.log(error)
                    //        });
                //}
            },
            EnviaEmail() {
                //var arquivos = document.getElementById("arquivo").files;
                //SE TIVER ANEXO:
                //if (arquivos != null && arquivos.length > 0) {
                //    var arq = "";
                //    var reader = new FileReader();
                //    var arquivoAnexo = arquivos[arquivos.length - 1];
                //    reader.readAsDataURL(arquivoAnexo);
                //    reader.onload = () => {
                //        arq = reader.result;
                //        this.Contato.Anexo = arq.toString().split(",")[1];
                //        this.Contato.NomeAnexo = arquivoAnexo.name;
                //        this.Contato.TipoAnexo = arquivoAnexo.type;
                //        this.$http
                //            .post(this.$apiUrl + "/contato/enviamail", this.Contato)
                //            .then(
                //                (response) => {
                //                    if (response.ok) alert("Enviado");
                //                },
                //                (error) => {
                //                    alert(error.bodyText);
                //                }
                //            );
                //    };
                //}
                //else {
                //SE NAO TIVER ANEXO:
                this.$http.post(this.$apiUrl + "/contato/enviamail", this.Contato)
                    .then(response => {
                        if (response.ok)
                            console.log("Enviado");
                    }, error => {
                        console.log(error.bodyText)
                    });
                //}
            },
        },
        created() {
            let usuarioTmp = {
                email: '',
                senha: '',
            }
            usuarioTmp = JSON.parse(this.$cookie.get("usuarioLove"));
            if (usuarioTmp != null) {
                let _usuario = {
                    email: usuarioTmp.email,
                    senha: usuarioTmp.senha,
                };
                this.$http.post(this.$apiUrl + "/clientes/login", _usuario).then(
                    (response) => {
                        // get body data
                        this.usuario = response.body;
                    },
                );
            }

            //this.$http
            //    .get("https://api.ipify.org/?format=json")
            //    .then((res2) => res2.json())
            //    .then((tiu2) => {
            //        this.IP = tiu2
            //    });

            //this.$http
            //    .get(this.$apiUrl + "/configoperadora/4")
            //    .then((res) => res.json())
            //    .then((retornoAPI) => {
            //        this.DadosYapay = retornoAPI;
            //        this.TokenYapay = this.DadosYapay.tokenOperadora;
            //    });
            //this.TokenYapay = '27591a88edf668b';


            let dadosArmazenados = localStorage.getItem('dadoscompralove');
            dadosArmazenados = JSON.parse(dadosArmazenados);

            let _produtos = []
            let _totalProdutos = 0;
            let _cupom = 0;
            if (dadosArmazenados.produtos != '') {
                dadosArmazenados.produtos.forEach((itemprod) => {
                    _produtos.push({
                        description: itemprod.produto.nomeProduto,
                        quantity: "1",
                        price_unit: itemprod.produto.valorAte,
                        code: itemprod.produto.produtoId,
                        sku_code: itemprod.produto.referencia,
                    })
                    _totalProdutos += parseFloat(itemprod.produto.valorAte)
                });
            }

            if (dadosArmazenados.avulsos != '') {
                if (dadosArmazenados.avulsos[0].produtoAvulsoId != 0) {
                    dadosArmazenados.avulsos.forEach((itemavul) => {
                        _produtos.push({
                            description: itemavul.avulso.nomeItemAvulso,
                            quantity: "1",
                            price_unit: itemavul.avulso.valorDe,
                            code: itemavul.avulso.itemAvulsoId,
                            sku_code: itemavul.avulso.itemAvulsoId,
                        })
                        _totalProdutos += parseFloat(itemavul.avulso.valorDe)
                    });
                }
            }

              if (dadosArmazenados.baloes != '') {
                if (dadosArmazenados.baloes[0].avulso != 'Sem Balao') {
                    dadosArmazenados.baloes.forEach((itemavul) => {
                        _produtos.push({
                            description: itemavul.avulso.nomeItemAvulso,
                            quantity: "1",
                            price_unit: itemavul.avulso.valorDe,
                            code: itemavul.avulso.itemAvulsoId,
                            sku_code: itemavul.avulso.itemAvulsoId,
                        })
                        _totalProdutos += parseFloat(itemavul.avulso.valorDe)
                    });
                }
            }

            if (dadosArmazenados.agenda != '') {
                if (dadosArmazenados.agenda[0].periodoEntrega != '') {
                    if (parseFloat(dadosArmazenados.agenda[0].periodoEntrega.valorFrete) > 0) {
                        _produtos.push({  
                            description: "Frete",
                            quantity: "1",
                            price_unit: parseFloat(dadosArmazenados.agenda[0].periodoEntrega.valorFrete),
                            code: "999998",
                            sku_code: "999998",});
                    }
                    _totalProdutos += parseFloat(dadosArmazenados.agenda[0].periodoEntrega.valorFrete)
                }
            }

            if (dadosArmazenados.foto != '') {
                if (dadosArmazenados.foto[0].imagemUrl != "Sem foto") {
                    dadosArmazenados.foto.forEach((itemfoto) => {
                        _produtos.push({
                            description: "Envio de Foto junto Cesta",
                            quantity: "1",
                            price_unit: itemfoto.valor,
                            code: "999999",
                            sku_code: "999999",
                        })
                        _totalProdutos += parseFloat(itemfoto.valor)
                    });
                }
            }

            if (dadosArmazenados.cupom != '') {
                _cupom = parseFloat(dadosArmazenados.cupom[0].ValorCupom, 10)
            }

            this.produtos = _produtos;
            this.TotalProdutos = _totalProdutos - _cupom;
        },
    }

</script>

