<template>
  
  <section class="carrinho">

    <HeaderCart />
    <Fita />

    <section class="main-carrinho">
    
      <div class="container">
        <div class="row py-5">
          <div class="col-lg-6 hidden-sm-md">
            <div class="carrinho-bg set-img">
              <img class="img-fluid" src="img/32.png" alt="">
            </div>
          </div>
          <div class="col-lg-6">
            <div class="box_content">
              <h3 class="titulo-principal text-center mb-4">Você selecionou pagar via:</h3>

              <hr>

              <div class="mb-4 px-md-4">

                <div class="agendamento">
                  
                  <div class="row">                    
                    <div class="col-sm-7 offset-sm-5 mb-5">
                      <img class="img-fluid" src="img/pix.jpg" alt="">  
                    </div>                    
                  </div>
                  <div class="row">
                    <div class="col-sm-5"><p class="texto">Chave Pix:</p></div>                    
                    <div class="col-sm-7">
                      <p class="texto-strong d-inline-block mb-0 mr-2">
                        CNPJ: 12.345.678/0001-23
                      </p> 
                      <a href="#" data-toggle="tooltip" data-placement="top" title="Copiar">
                        <i class="far fa-copy icon-copy"></i>
                      </a>
                      <p class="texto-strong">Love Cestas</p>                       
                      <p class="texto-destaque">Valor Total da compra R$ 140,00</p>  
                    </div>                    
                  </div>
                </div>           
              </div>
              <hr>
              <div class="mb-4 px-md-4">
                <h5 class="subtitulo mb-4">Enviar Comprovante</h5>
                <div class="agendamento">
                  <input type="file">
                </div>           
              </div>

              <div class="text-center mt-5">
                <a href="" @click="$redirect_reload('/pedidoRealizado')" class="btn btn-success px-5 mt-0">Enviar Comprovante</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <Fita class="ribbon-lg" titulo="A melhor empresa de cestas e presentes do Rio Grande do Sul!! Há 8 anos levando carinho, amor e alegrias a nossos clientes!"></Fita>
    <Footer />

  </section>

</template>

<script>

  import HeaderCart from '@/components/site/shared/HeaderCart.vue'
  import Fita from '@/components/site/shared/Fita.vue'
  import Footer from '@/components/site/shared/Footer.vue'

  export default {
    components: {
      HeaderCart,
      Fita,
      Footer
    }
  }

</script>

<style scoped>

  @import '/css_cart/carrinho.css';
  @import '/css_cart/nova_conta.css';
  
</style>