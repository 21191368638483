<template>

    <section class="carrinho">

        <Header />
        <Fita class="hidden-sm" titulo="Presentes exclusivos para momentos especiais"></Fita>

        <section class="main-carrinho pedido-realizado set-img" :style="{ backgroundImage: 'url(' + UrlImg + ')' }">

            <div class="container">
                <div class="row">
                    <div class="col-md-8 col-lg-5 offset-lg-1">
                        <h2>{{ Titulo }}</h2>
                        <div class="content p-4">
                            <p v-html="Texto"></p>
                           
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <FitaRodape class="ribbon-lg"></FitaRodape>
        <Footer />

    </section>

</template>

<script>

    import Header from '@/components/site/shared/Header.vue'
    import Fita from '@/components/site/shared/Fita.vue'
    import FitaRodape from '@/components/site/shared/FitaRodape.vue'
    import Footer from '@/components/site/shared/Footer.vue'

    export default {
        components: {
            Header,
            Fita,
            FitaRodape,
            Footer,
        },
        data() {
            return {
                Titulo: '',
                Texto: '',
                UrlImg:'',
            }
        },
        methods: {
        },
        created() {
            this.Titulo = 'Mensagem gravada com sucesso.'
            //this.Texto = 'Agora vai no site e faça todo processo de compra novamente!!!'
            this.Texto = ''
            this.UrlImg = '/img/33.png';
        },
        mounted() {

        },
    }

</script>

<style scoped>
   
</style>
