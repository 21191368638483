<template>
  
  <section class="produto-detalhe">
  
    <Header />

    <Fita />

    <div class="my-4">
      <div class="container">
        <div class="row">

          <div class="col-md-6 col-xl-4">
            <div class="detalhe-pedido mb-4 mb-xl-0">
              <h4 class="titulo_detalhes mb-3">Nome do Produto</h4>
              <h6 class="subtitulo_detalhes mb-3">Digite a mensagem e escolha a fonte</h6>
              <form action="#">
                <div class="row">
                  <div class="col-12 col-xl-6">
                    <input type="text" class="form-control mb-2" placeholder="Minha">
                  </div>
                  <div class="col-12 col-xl-6">
                    <v-select 
                      placeholder="Selecione a Fonte"
                      :options="option_1"
                      class=" mb-2">
                    </v-select>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 col-xl-6">
                    <input type="text" class="form-control mb-2" placeholder="Mensagem">
                  </div>
                  <div class="col-12 col-xl-6">
                    <v-select 
                      placeholder="Selecione a Fonte"
                      :options="option_2"
                      class=" mb-2">
                    </v-select>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 col-xl-6">
                    <input type="text" class="form-control mb-2" placeholder="Max 12 Caracteres">
                  </div>
                  <div class="col-12 col-xl-6">
                    <v-select 
                      placeholder="Selecione a Fonte"
                      :options="option_3"
                      class=" mb-2">
                    </v-select>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 col-xl-6">
                    <input type="text" class="form-control mb-2" placeholder="Max 12 Caracteres">
                  </div>
                  <div class="col-12 col-xl-6">
                    <v-select 
                      placeholder="Selecione a Fonte"
                      :options="option_4"
                      class=" mb-2">
                    </v-select>
                  </div>
                </div>
              </form>

              <div class="mt-4">
                <h6 class="subtitulo_detalhes mb-3">Escolha a cor das letras</h6>
                <div class="color color_1"></div>
                <div class="color color_2"></div>
                <div class="color color_3"></div>
              </div>

            </div>
          </div>

          <div class="col-md-6 col-xl-4">
            <div class="border rounded p-1">
              <img class="img-fluid" src="img/31.png" alt="">
            </div>
          </div>

          <div class="col-xl-4">
            <div class="detalhe-pedido border rounded p-3">
              <div class="row">
                <div class="col-lg-4">
                  <h5 class="subtitulo_detalhes">Meu Pedido</h5>
                </div>
                <div class="col-lg-8">
                  <div class="d-flex align-items-center justify-content-between">
                    <span class="texto_detalhe">Valor da cesta:</span>
                    <span class="texto_destaque">100,00</span>
                  </div>
                  <div class="d-flex align-items-center justify-content-between">
                    <span class="texto_detalhe">Foto:</span>
                    <span class="texto_destaque">0,00</span>
                  </div>
                  <div class="d-flex align-items-center justify-content-between">
                    <span class="texto_detalhe">Itens Adicionados:</span>
                    <span class="texto_destaque">0,00</span>
                  </div>
                  <div class="d-flex align-items-center justify-content-between">
                    <span class="texto_detalhe">Balão:</span>
                    <span class="texto_destaque">0,00</span>
                  </div>
                  <div class="d-flex align-items-center justify-content-between">
                    <span class="texto_detalhe">Subtotal:</span>
                    <span class="texto_destaque">0,00</span>
                  </div>
                  <div class="d-flex align-items-center justify-content-between">
                    <span class="texto_destaque">Total</span>
                    <span class="texto_destaque">100,00</span>
                  </div>
                  <div class="d-flex align-items-center justify-content-between mt-4">
                    <a class="btn-voltar" type="" href="javascript:window.history.go(-1)">Voltar</a>
                    <button type="button"  class="btn btn-card" @click="showModal_data=true">Avançar</button>
                  </div>
                </div>
              </div>
              
            </div>

            <div class="py-3">
              <p>Nullam ornare velit sit amet urna auctor, eu plar cerat orci elementum. Proin turpis sapien, tempor vitae efficitur luctus, auctor eu massa.</p>
            </div>

          </div>

        </div>
      </div>
    </div>

    <!-- Navbar Detalhes desktop -->
    <div class="navbar_detalhes hidden-sm-md">
      <div class="container">
        <div class="row">

          <div class="col">
            <div class="navbar_detalhes-item navbar_item-1">
              <img class="navbar_detalhes-img" src="img/nav/icon_nav_1.png" alt="">
              <a class="navbar_detalhes-link"
                 @click="showModal_data=true">
                 Escolha uma Data
              </a>
            </div>
          </div>

          <div class="col">
            <div class="navbar_detalhes-item navbar_item-2">
              <img class="navbar_detalhes-img" src="img/nav/icon_nav_2.png" alt="">              
              <a class="navbar_detalhes-link" 
                 @click="showModal_mensagem=true">
                 Adicionar uma Mensagem
              </a>
            </div>
          </div>

          <div class="col">
            <div class="navbar_detalhes-item navbar_item-3">
              <img class="navbar_detalhes-img" src="img/nav/icon_nav_3.png" alt="">
              <a class="navbar_detalhes-link" 
                 @click="showModal_foto=true">
                 Adicionar uma Foto
              </a>
            </div>
          </div>

          <div class="col">
            <div class="navbar_detalhes-item navbar_item-4">
              <img class="navbar_detalhes-img" src="img/nav/icon_nav_4.png" alt="">
              <a class="navbar_detalhes-link" 
                 @click="showModal_addProd=true">
                 Adicione mais Produtos
              </a>
            </div>
          </div>

          <div class="col">
            <div class="navbar_detalhes-item navbar_item-5">
              <img class="navbar_detalhes-img" src="img/nav/icon_nav_5.png" alt="">
              <a class="navbar_detalhes-link"
                 @click="showModal_balao=true">
                 Adicione Balão Personalizado
              </a>
            </div>
          </div>

        </div>
      </div>
    </div>

    <!-- Navbar Detalhes responsive -->
    <div class="navbar_detalhes-responsive hidden-lg hidden-xl">
      <div class="container">
        <div class="row">
          <div class="col text-center">
            <div class="item">
              <a @click="showModal_data=true">
                <img class="item-img" src="img/nav/icon_nav_1.png" alt="">           
              </a>
            </div>
            <div class="item">
              <a @click="showModal_mensagem=true">
                <img class="item-img" src="img/nav/icon_nav_2.png" alt="">           
              </a>
            </div>
            <div class="item">
              <a @click="showModal_foto=true">
                <img class="item-img" src="img/nav/icon_nav_3.png" alt="">           
              </a>
            </div>
            <div class="item">
              <a @click="showModal_addProd=true">
                <img class="item-img" src="img/nav/icon_nav_4.png" alt="">           
              </a>
            </div>
            <div class="item">
              <a @click="showModal_balao=true">
                <img class="item-img" src="img/nav/icon_nav_5.png" alt="">           
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal Calendário -->
    <Modal v-model="showModal_data" title="" class="teste">
            
      <section class="modal-calendario">
        <div class="container">
          <div class="row">
            <div class="col text-center">
              <h4 class="titulo-modal">Selecione a data e o período de entrega</h4>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6 cabecalho first text-center pl-0">
              <h6 class="subtitulo-modal">Data Escolhida</h6>
              <p class="texto-modal">30 de Julho de 2021 - Sexta-Feira</p>
            </div>
            <div class="col-md-6 cabecalho second text-center pr-0">
              <h6 class="subtitulo-modal">Período Escolhido</h6>
              <p class="texto-modal">Comercial(9h - 12h) - R$ 21,00</p>
            </div>
          </div>

          <div class="row py-3">

            <!-- Formulário (Endereço) -->
            <div class="col-lg mb-4 mb-lg-0">

              <form action="#">
                <div class="input-group mb-2">
                  <input type="text" class="form-control" placeholder="CEP">
                  <div class="input-group-append">
                    <span class="input-group-text" id="basic-addon2">Verificar</span>
                  </div>
                </div>
                <div class="text-center my-2"><h6 class="subtitulo-modal">Ou</h6></div>
                <div class="input-group mb-2">
                  <label for="#"></label>
                  <input type="text" class="form-control" placeholder="Endereço">
                </div>
                <div class="input-group mb-2">
                  <label for="#"></label>
                  <input type="text" class="form-control" placeholder="Complemento">
                </div> 
                <v-select 
                  placeholder="Estado"
                  :options="estado"
                  class=" mb-2">
                </v-select>               
                <v-select 
                  placeholder="Cidade"
                  :options="cidade"
                  class=" mb-2">
                </v-select>               
                <v-select 
                  placeholder="Bairro"
                  :options="bairro"
                  class=" mb-2">
                </v-select>               
              </form>

            </div>

            <!-- Calendário -->
            <div class="col-md-4 col-lg-3 text-center">
              <input type="date">
              <div>
                <a class="btn btn-card" 
                   @click="showModal_tema=true, showModal_data=false">
                   Concluir e Avançar
                </a>
              </div>     
            </div>

            <!-- Frete -->
            <div class="col-md-7 offset-md-1 col-lg offset-lg-0 mt-4 mt-md-0">
              <div class="form-check">
                <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" value="opcao1" checked>
                <label class="form-check-label" for="exampleRadios1">
                  <p class="texto-modal texto-frete">Lorem ipsum dolor sit amet consectur - R$ 12,35</p>
                </label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios2" value="opcao2">
                <label class="form-check-label" for="exampleRadios2">
                  <p class="texto-modal texto-frete">Lorem ipsum dolor sit amet consectur - R$ 12,35</p>
                </label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" value="opcao1" checked>
                <label class="form-check-label" for="exampleRadios1">
                  <p class="texto-modal texto-frete">Lorem ipsum dolor sit amet consectur - R$ 12,35</p>
                </label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios2" value="opcao2">
                <label class="form-check-label" for="exampleRadios2">
                  <p class="texto-modal texto-frete">Lorem ipsum dolor sit amet consectur - R$ 12,35</p>
                </label>
              </div>
            </div>
          </div>
        </div>
      </section>

    </Modal>

    <!-- Escolha o Cartão -->
    <Modal v-model="showModal_tema" title="">
            
      <section class="modal-calendario">
        <div class="container">
          <div class="row mb-4">
            <div class="col-4 col-md-4 col-lg-3">
              <div class="voltar d-flex align-items-center">
                <a @click="showModal_data=true, showModal_tema=false">Voltar</a>
              </div>
            </div>
            <div class="col-8 col-md-4 offset-md-4 col-lg-3 offset-lg-6">
              <a class="btn btn-outline-danger position-right" 
                 @click="showModal_modelo=true, showModal_tema=false">
                 Enviar sem Mensagem
              </a>
            </div>
          </div>
          <div class="row">
            <div class="col text-center">
              <h4 class="titulo-modal">Clique no tema e escolha o cartão que deseja enviar!</h4>
            </div>
          </div>

          <!-- Cartões -->
          <div class="row">
            <div class="col-6 col-md-4 col-lg my-4">
              <div class="cartao text-center">
                <img class="img-fluid" src="img/22.png" alt="Tema do Cartão">
                <a href="#"><h5 class="subtitulo_detalhes mt-2">Comemorações</h5></a>
              </div>             
            </div>
            <div class="col-6 col-md-4 col-lg d-flex align-items-center justify-content-around my-4">
              <div class="cartao text-center">
                <img class="img-fluid" src="img/23.png" alt="Tema do Cartão">
                <a href="#"><h5 class="subtitulo_detalhes mt-2">Românticos</h5></a>
              </div>             
            </div>
            <div class="col-6 col-md-4 col-lg d-flex align-items-center justify-content-around my-4">
              <div class="cartao text-center">
                <img class="img-fluid" src="img/24.png" alt="Tema do Cartão">
                <a href="#"><h5 class="subtitulo_detalhes mt-2">Agradecimentos</h5></a>
              </div>             
            </div>
            <div class="col-6 col-md-4 col-lg d-flex align-items-center justify-content-around my-4">
              <div class="cartao text-center">
                <img class="img-fluid" src="img/25.png" alt="Tema do Cartão">
                <a href="#"><h5 class="subtitulo_detalhes mt-2">Profissões</h5></a>
              </div>             
            </div>
            <div class="col-6 col-md-4 col-lg d-flex align-items-center justify-content-around my-4">
              <div class="cartao text-center">
                <img class="img-fluid" src="img/26.png" alt="Tema do Cartão">
                <a href="#"><h5 class="subtitulo_detalhes mt-2">Aniversários</h5></a>
              </div>             
            </div>
            <div class="col-6 col-md-4 col-lg d-flex align-items-center justify-content-around my-4">
              <div class="cartao text-center">
                <img class="img-fluid" src="img/22.png" alt="Tema do Cartão">
                <a href="#"><h5 class="subtitulo_detalhes mt-2">Café Manhã/Tarde</h5></a>
              </div>             
            </div>              
          </div>

          <!-- Instruções -->
          <div class="row">
            <div class="col text-center">
              <h6 class="subtitulo-modal">Instruções para escrever a mensagem</h6>
              <p class="texto_detalhe">* Clique no cartão para escrever;</p>
              <p class="texto_detalhe">* Observe se sua mensagem não ultrapassou os limites da imagem do cartão;</p>
              <p class="texto_detalhe">* Não esqueça de assinar o cartão.</p>
            </div>
          </div>

        </div>
      </section>

    </Modal>

    <!-- Escolha o Modelo -->
    <Modal v-model="showModal_modelo" title="">
            
      <section class="modal-calendario">
        <div class="container">
          <div class="row mb-4">
            <div class="col-4 col-md-4 col-lg-3">
              <div class="voltar d-flex align-items-center">
                <a @click="showModal_tema=true, showModal_modelo=false">Voltar</a>
              </div>
            </div>
            <div class="col-8 col-md-4 offset-md-4 col-lg-3 offset-lg-6">
              <a class="btn btn-outline-danger position-right"
                 @click="showModal_mensagem=true, showModal_modelo=false">Enviar sem Mensagem</a>
            </div>
          </div>

          <div class="row">
            <div class="col text-center">
              <h4 class="titulo-modal">Escolha o Modelo</h4>
              <h5 class="subtitulo_detalhes mt-2">Cartão 15X10 (Envelope)</h5>
            </div>
          </div>

          <!-- Cartões -->
          <div class="row">
            <div class="col-6 col-md-3 my-4">
              <div class="cartao text-center">
                <a href="#"><img class="w-100" src="img/27.png" alt="Tema do Cartão"></a>
              </div>             
            </div>              
            <div class="col-6 col-md-3 my-4">
              <div class="cartao text-center">
                <a href="#"><img class="w-100" src="img/27.png" alt="Tema do Cartão"></a>
              </div>             
            </div>              
            <div class="col-6 col-md-3 my-4">
              <div class="cartao text-center">
                <a href="#"><img class="w-100" src="img/27.png" alt="Tema do Cartão"></a>
              </div>             
            </div>              
            <div class="col-6 col-md-3 my-4">
              <div class="cartao text-center">
                <a href="#"><img class="w-100" src="img/27.png" alt="Tema do Cartão"></a>
              </div>             
            </div>              
          </div>
        </div>
      </section>

    </Modal>

    <!-- Escreva a Mensagem -->
    <Modal v-model="showModal_mensagem" title="">
            
      <section class="modal-calendario">
        <div class="container">
          <div class="row mb-4">
            <div class="col-4 col-md-4 col-lg-3">
              <div class="voltar d-flex align-items-center">
                <a @click="showModal_modelo=true, showModal_mensagem=false">Voltar</a>
              </div>
            </div>
            <div class="col-8 col-md-4 offset-md-4 col-lg-3 offset-lg-6">
              <a class="btn btn-outline-danger position-right"
                 @click="showModal_foto=true, showModal_mensagem=false">Enviar sem Mensagem</a>
            </div>
          </div>

          <div class="row">
            <div class="col text-center">
              <h4 class="titulo-modal">Instruções</h4>
            </div>
          </div>

          <!-- Instruções -->
          <div class="row">
            <div class="col text-center">
              <p class="texto_detalhe">* Clique no cartão para escrever;</p>
              <p class="texto_detalhe">* Observe se sua mensagem não ultrapassou os limites da imagem do cartão;</p>
              <p class="texto_detalhe">* Não esqueça de assinar o cartão.</p>
            </div>
          </div>

          <div class="row justify-content-center">
            <div class="col-lg-8 col-xl-6 text-center">
              <div class="editar-cartao mt-4">                
                <img class="img-fluid" src="img/28.png" alt="">
              </div>
              <a class="btn btn-card"
                 @click="showModal_foto=true, showModal_mensagem=false">
                 Concluir e Avançar
              </a>
            </div>
          </div>

        </div>
      </section>

    </Modal>

    <!-- Selecione sua foto -->
    <Modal v-model="showModal_foto" title="">
            
      <section class="modal-calendario">
        <div class="container">
          <div class="row mb-4">
            <div class="col-4 col-md-4 col-lg-3">
              <div class="voltar d-flex align-items-center">
                <a @click="showModal_mensagem=true, showModal_foto=false">Voltar</a>
              </div>
            </div>
            <div class="col-8 col-md-4 offset-md-4 col-lg-3 offset-lg-6">
              <a class="btn btn-outline-danger position-right"
                 @click="showModal_addProd=true, showModal_foto=false">Enviar sem Foto</a>
            </div>
          </div>

          <div class="row">
            <div class="col text-center">
              <h4 class="titulo-modal mb-3">Selecione a sua foto em escolher arquivo, <br /> clique em enviar e aguarde até que a imagem apareça abaixo:</h4>
              <input type="file">
            </div>
          </div>

          <div class="row justify-content-center">
            <div class="col-lg-8 col-xl-6 text-center">
              <div class="editar-cartao mt-4">                
                <img class="img-fluid" src="img/29.png" alt="">
              </div>
            </div>
          </div>

          <!-- Instruções -->
          <div class="row justify-content-center">
            <div class="col-lg-7 text-center mt-4">
              <p class="texto_detalhe">* A qualidade e formatação da imagem pode variar de acordo com o tamanho e qualidade da foto que for inclusa.</p>
              <p class="texto_detalhe">* Clique em salvar e continuar para voltar a cesta e terminar sua compra.</p>
              <a class="btn btn-card"
                 @click="showModal_addProd=true, showModal_foto=false">
                 Concluir e Avançar
              </a>
            </div>
          </div>

        </div>
      </section>

    </Modal>

    <!-- Adicione mais Produtos -->
    <Modal v-model="showModal_addProd" title="">
            
      <section class="modal-calendario">
        <div class="container">
          <div class="row mb-4">
            <div class="col-4 col-md-4 col-lg-3">
              <div class="voltar d-flex align-items-center">
                <a @click="showModal_foto=true, showModal_addProd=false">Voltar</a>
              </div>
            </div>
            <div class="col-8 col-md-4 offset-md-4 col-lg-3 offset-lg-6">
              <a class="btn btn-outline-danger position-right"
              @click="showModal_balao=true, showModal_addProd=false">Enviar sem itens adicionais</a>
            </div>
          </div>

          <div class="row">
            <div class="col text-center">
              <h4 class="titulo-modal">Adicione um ou mais produtos à sua cesta</h4>
            </div>
          </div>

          <!-- Cartões -->
          <div class="row">
            <div class="col-6 col-md-4 col-lg-2 my-4"
             v-for="card in arr_modalProdutos" :key="card.id">
              <div class="produto-card produto-card_modal">
                <router-link to="produtoDetalhe">
                  <div class="produto-img set-img" :style="{ backgroundImage: 'url(' + card.imagem + ')' }">
                    <div class="middle middle_modal">
                      <div class="text">###</div>
                    </div>
                  </div>
                </router-link>
                <div class="produto-content">
                  <h6 class="titulo-card">{{ card.titulo}}</h6>
                  <p>R$ <span class="texto-destaque">{{ card.preco }}</span></p>
                  <a class="btn btn-card d-block">Adicionar</a>
                </div>
              </div>
            </div>
              
          </div>

        </div>

      </section>

      <footer class="modal_footer_fixo">
        <div class="container">
          <div class="row">
            <div class="col">
              <a class="btn btn-card mt-0"
              @click="showModal_balao=true, showModal_addProd=false">Concluir e Avançar</a>
            </div>
          </div>
        </div>
      </footer>
      
    </Modal>

    <!-- Escolha o Balão -->
    <Modal v-model="showModal_balao" title="">
            
      <section class="modal-calendario">
        <div class="container">
          <div class="row mb-4">
            <div class="col-4 col-md-4 col-lg-3">
              <div class="voltar d-flex align-items-center">
                <a @click="showModal_addProd=true, showModal_balao=false">Voltar</a>
              </div>
            </div>
            <div class="col-8 col-md-4 offset-md-4 col-lg-3 offset-lg-6">
              <a class="btn btn-outline-danger position-right"
                 @click="showModal_editarBalao=true, showModal_balao=false">
                 Enviar sem Balão
              </a>
            </div>
          </div>

          <div class="row">
            <div class="col text-center">
              <h4 class="titulo-modal">Clique no modelo e escolha o balão que deseja enviar!</h4>
            </div>
          </div>

          <!-- Cartões -->
          <div class="row">
            <div class="col-6 col-md-4 col-lg my-4">
              <div class="cartao text-center">
                <img class="img-fluid border rounded" src="img/30.png" alt="Tema do Cartão">
                <a href="#"><h5 class="subtitulo_detalhes mt-2">Modelo 1</h5></a>
              </div>             
            </div>
            <div class="col-6 col-md-4 col-lg d-flex align-items-center justify-content-around my-4">
              <div class="cartao text-center">
                <img class="img-fluid border rounded" src="img/30.png" alt="Tema do Cartão">
                <a href="#"><h5 class="subtitulo_detalhes mt-2">Modelo 2</h5></a>
              </div>             
            </div>
            <div class="col-6 col-md-4 col-lg d-flex align-items-center justify-content-around my-4">
              <div class="cartao text-center">
                <img class="img-fluid border rounded" src="img/30.png" alt="Tema do Cartão">
                <a href="#"><h5 class="subtitulo_detalhes mt-2">Modelo 3</h5></a>
              </div>             
            </div>
            <div class="col-6 col-md-4 col-lg d-flex align-items-center justify-content-around my-4">
              <div class="cartao text-center">
                <img class="img-fluid border rounded" src="img/30.png" alt="Tema do Cartão">
                <a href="#"><h5 class="subtitulo_detalhes mt-2">Modelo 4</h5></a>
              </div>             
            </div>
            <div class="col-6 col-md-4 col-lg d-flex align-items-center justify-content-around my-4">
              <div class="cartao text-center">
                <img class="img-fluid border rounded" src="img/30.png" alt="Tema do Cartão">
                <a href="#"><h5 class="subtitulo_detalhes mt-2">Modelo 5</h5></a>
              </div>             
            </div>
            <div class="col-6 col-md-4 col-lg d-flex align-items-center justify-content-around my-4">
              <div class="cartao text-center">
                <img class="img-fluid border rounded" src="img/30.png" alt="Tema do Cartão">
                <a href="#"><h5 class="subtitulo_detalhes mt-2">Modelo 6</h5></a>
              </div>             
            </div>              
          </div>

        </div>
      </section>

    </Modal>

    <!-- Editar Balão -->
    <Modal v-model="showModal_editarBalao" title="">
            
      <section class="modal-calendario">
        <div class="container">

          <div class="row mb-4">
            <div class="col-4 col-md-4 col-lg-3">
              <div class="voltar d-flex align-items-center">
                <a @click="showModal_balao=true, showModal_editarBalao=false">Voltar</a>
              </div>
            </div>
            <div class="col-8 col-md-4 offset-md-4 col-lg-3 offset-lg-6">
              <a class="btn btn-outline-danger position-right"
                 @click="showModal_editarBalao=false">
                 Enviar sem Foto
              </a>
            </div>
          </div>
          
          <div class="row">

            <div class="col-md-6 col-xl-5">
              <div class="detalhe-pedido mb-4 mb-xl-0">
                <h4 class="titulo_detalhes mb-3">Nome do Produto</h4>
                <h6 class="subtitulo_detalhes mb-3">Digite a mensagem e escolha a fonte</h6>
                <form action="#">
                  <div class="row">
                    <div class="col-12 col-xl-6">
                      <input type="text" class="form-control mb-2" placeholder="Minha">
                    </div>
                    <div class="col-12 col-xl-6">
                      <v-select 
                        placeholder="Selecione a Fonte"
                        :options="option_1"
                        class=" mb-2">
                      </v-select>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 col-xl-6">
                      <input type="text" class="form-control mb-2" placeholder="Mensagem">
                    </div>
                    <div class="col-12 col-xl-6">
                      <v-select 
                        placeholder="Selecione a Fonte"
                        :options="option_2"
                        class=" mb-2">
                      </v-select>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 col-xl-6">
                      <input type="text" class="form-control mb-2" placeholder="Max 12 Caracteres">
                    </div>
                    <div class="col-12 col-xl-6">
                      <v-select 
                        placeholder="Selecione a Fonte"
                        :options="option_3"
                        class=" mb-2">
                      </v-select>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 col-xl-6">
                      <input type="text" class="form-control mb-2" placeholder="Max 12 Caracteres">
                    </div>
                    <div class="col-12 col-xl-6">
                      <v-select 
                        placeholder="Selecione a Fonte"
                        :options="option_4"
                        class=" mb-2">
                      </v-select>
                    </div>
                  </div>
                </form>

                <div class="mt-4">
                  <h6 class="subtitulo_detalhes mb-3">Escolha a cor das letras</h6>
                  <div class="color color_1"></div>
                  <div class="color color_2"></div>
                  <div class="color color_3"></div>
                </div>

              </div>
            </div>

            <div class="col-md-6 col-xl-4">
              <div class="border rounded p-1">
                <img class="img-fluid" src="img/31.png" alt="">
              </div>
            </div>

            <div class="col-xl-3">

              <div class="py-3">
                <p>Nullam ornare velit sit amet urna auctor, eu plar cerat orci elementum. Proin turpis sapien, tempor vitae efficitur luctus, auctor eu massa.</p>
              </div>
            </div>

          </div>

          <div class="row">
            <div class="col text-center">
              <a class="btn btn-card"
                 @click="showModal_editarBalao=false">
                 Concluir e Avançar
              </a>
            </div>
          </div>

        </div>
      </section>

    </Modal>
    

    <!-- <NavDetalhes /> -->

  </section>

</template>

<script>

  import Header from '@/components/site/shared/Header.vue'
  import Fita from '@/components/site/shared/Fita.vue'
  // import NavDetalhes from '@/components/site/shared/NavDetalhes.vue'
  import VueModal from '@kouts/vue-modal'


  export default {
    components: {
      Header,
      Fita,
      // NavDetalhes,
      'Modal': VueModal
    },
    data() {
      return {
        bgEditarCartao: '/img/28.png',
        option_1: [
          'Font A',
          'Font B',
          'Font C'
        ],
        option_2: [
          'Font D',
          'Font E',
          'Font F'
        ],
        option_3: [
          'Font G',
          'Font H',
          'Font I'
        ],
        option_4: [
          'Font J',
          'Font K',
          'Font L'
        ],
        
        showModal_data: false,
        showModal_tema: false,
        showModal_modelo: false,
        showModal_mensagem: false,
        showModal_foto: false,
        showModal_addProd: false,
        showModal_balao: false,
        showModal_editarBalao: false,
      }
    }
  }
</script>

<style scoped>

  @import '/css/produto_detalhes.css';
  @import '/css/produto_editar.css';
  @import '/css/nav_detalhes.css';
  @import '/css/modais.css';

</style>