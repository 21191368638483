<template>

    <section class="carrinho">

        <Header />

        <Fita class="hidden-sm-md hidden-xs" />

        <section class="" :style="{ backgroundImage: 'url(' + $Url + fundotela + ')',backgroundColor:'#e8eaf6',backgroundSize:'100%',backgroundRepeat:'no-repeat' }">
            <div class="container">
                <div class="row py-5">
                    <div class="col-lg-6 hidden-sm-md">
                        <div class="carrinho-bg set-img">
                            
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="box_content">
                            <h3 class="titulo-principal text-center">{{ Titulos[0].nome }}</h3>
                            <table class="table table-cart">
                                <thead>
                                    <tr class="text-center">
                                        <th scope="col-8">Produto</th>
                                        <th scope="col-1">Editar</th>
                                        <th scope="col-2">Valores</th>
                                        <th scope="col-1"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(listaProduto,index) in ListaProdutos" :key="index" class="table-cart-line" style="background-color: #f5f5f5;">
                                        <td class="d-flex align-items-center">
                                            <img class="img-cart img-fluid" style="border:1px solid #cccccc;height: 80px;width: auto;" :src="$Url + listaProduto.produto.imagens[0].urlImg">
                                            <h6 class="texto-destaque">{{ listaProduto.produto.nomeProduto }}</h6>
                                        </td>
                                        <td></td>
                                        <td class="table-cart-data" style="width:20%">R$ {{ parseFloat(listaProduto.produto.valorAte,10).toFixed(2).toString().replace('.',',') }}</td>
                                        <td class="table-cart-data">
                                            <a data-toggle="tooltip" data-placement="top" @click="ExcluirProduto(index)" title="Excluir">
                                                <i class="fas fa-window-close" style="color: black;"></i>
                                            </a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="4">Complementos:</td>
                                    </tr>
                                    <tr v-for="listaAvulso in ListaAvulsos" :key="listaAvulso.produtoAvulsoId" class="table-cart-line">
                                        <td class="d-flex align-items-center">
                                            <img class="img-cart img-fluid" style="border:1px solid #cccccc;height: 50px;width: auto;" :src="$Url + listaAvulso.avulso.urlImg">
                                            <h6 class="texto-destaque">{{ listaAvulso.avulso.nomeItemAvulso }}</h6>
                                        </td>
                                        <td></td>
                                        <td class="table-cart-data">R$ {{ parseFloat(listaAvulso.avulso.valorDe ,10).toFixed(2).toString().replace('.',',') }}</td>
                                        <td class="table-cart-data">
                                            <a data-toggle="tooltip" data-placement="top" @click="ExcluirAvulso(listaAvulso)" title="Excluir">
                                                <i class="fas fa-window-close"></i>
                                            </a>
                                        </td>
                                    </tr>
                                    <tr v-for="balao in ListaBaloes" :key="balao.produtoAvulsoId" class="table-cart-line">
                                        <td class="d-flex align-items-center">
                                            <img class="img-cart img-fluid" style="border:1px solid #cccccc;height: 50px;width: auto;" :src="$Url + balao.avulso.urlImg">
                                            <h6 class="texto-destaque">{{ balao.avulso.nomeItemAvulso }}</h6>
                                            <small v-html="montatextobalao(balao.dados)" style="padding-left:15px;"></small>
                                        </td>
                                        <td></td>
                                        <td class="table-cart-data">R$ {{ parseFloat(balao.avulso.valorDe ,10).toFixed(2).toString().replace('.',',') }}</td>
                                        <td class="table-cart-data">
                                            <a data-toggle="tooltip" data-placement="top" @click="ExcluirBalao(balao)" title="Excluir">
                                                <i class="fas fa-window-close"></i>
                                            </a>
                                        </td>
                                    </tr>
                                    <tr v-for="listaFoto in ListaFoto" :key="listaFoto.id" class="table-cart-line">
                                        <td class="d-flex align-items-center">
                                            <img class="img-cart img-fluid" style="border:1px solid #cccccc;height: 60px;width: auto;" :src="$Url + listaFoto.imagemUrl">
                                            <h6 class="texto-destaque">Você adicionou uma foto</h6>
                                        </td>
                                        <td></td>
                                        <td class="table-cart-data">R$ {{ parseFloat(listaFoto.valor ,10).toFixed(2).toString().replace('.',',') }}</td>
                                        <td class="table-cart-data">
                                            <a data-toggle="tooltip" data-placement="top" @click="ExcluirFoto()" title="Excluir">
                                                <i class="fas fa-window-close"></i>
                                            </a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table class="table table-cart">
                                <tbody>
                                    <tr v-for="IconeBarra in IconesBarra2" :key="IconeBarra.institucionalId" class="table-cart-line">
                                        <td class="d-flex align-items-center">
                                            <a href="" @click="$redirect_reload('/produtodetalhe/' + ProdutoId + '/carrinho/3')">
                                                <img class="img-icon" :src="$Url + IconeBarra.institucionalImg[0].urlImagem " alt="">
                                            </a>
                                        </td>
                                        <td style="width:80%">
                                            <a href="" @click="$redirect_reload('/produtodetalhe/' + ProdutoId + '/carrinho/3')">
                                                <span class="texto-mensagem" style="font-size: 17px;">Você pode editar itens adicionais</span>
                                            </a>
                                        </td>
                                    </tr>
                                    <tr v-for="(IconeBarra2,index) in IconesBarra" :key="index" class="table-cart-line">
                                        <td class="d-flex align-items-center">
                                            <a href="" @click="$redirect_reload('/produtodetalhe/' + ProdutoId + '/carrinho/2')">
                                                <img class="img-icon" :src="$Url + IconeBarra2.institucionalImg[0].urlImagem " alt="">
                                            </a>
                                        </td>
                                        <td>
                                            <a href="" @click="$redirect_reload('/produtodetalhe/' + ProdutoId + '/carrinho/2')">
                                                <span class="texto-mensagem" style="font-size: 17px;">Você pode editar mensagem</span>
                                            </a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <div class="d-flex align-items-center justify-content-between mb-2" style="padding-right:5px;">
                                <h6 class="texto-destaque mb-0">Subtotal:</h6>
                                <h6 class="texto-destaque text-center mb-0">R$ {{ parseFloat(subtotal).toFixed(2).toString().replace('.',',') }}</h6>
                            </div>

                            <div class="mb-4">
                                <h6 class="texto-destaque mb-0">{{ Titulos[0].referencia }}</h6>
                                <div class="d-flex align-items-center py-1">
                                    <input type="text" v-model="numcupom" @input="validacampocupom()" class="form-control form-cupom" placeholder="">
                                    <button class="btn btn-outline-success ml-4" @click="CalculaCupom()">Atualizar</button>
                                </div>
                            </div>

                            <div v-if="ValorCupom > 0" class="d-flex align-items-center justify-content-between border-top py-3">
                                <h6 class="texto-destaque mb-0">Cupom Desconto:</h6>
                                <h6 class="texto-destaque text-center mb-0" style="color:red;">- R$ {{ parseFloat(ValorCupom).toFixed(2).toString().replace('.',',') }}</h6>
                            </div>
                            <div class="d-flex align-items-center justify-content-between border-top py-3">
                                <h6 class="texto-destaque mb-0">Frete:</h6>
                                <h6 v-if="ValorFrete>0" class="texto-destaque text-center mb-0">R$ {{ parseFloat(ValorFrete).toFixed(2).toString().replace('.',',') }}</h6>
                                <h6 v-else class="texto-destaque text-center mb-0">Grátis</h6>
                            </div>
                            <div class="d-flex align-items-center justify-content-between border-top py-3">
                                <h6 class="texto-destaque mb-0">Total:</h6>
                                <h6 class="texto-destaque text-center mb-0">R$ {{ parseFloat(valorTotal).toFixed(2).toString().replace('.',',') }}</h6>
                            </div>
                            <div class="voltar border-top pt-4 hidden-md hidden-lg hidden-xl">
                                <a class="voltar text-secondary" href="javascript:window.history.go(-1)">Voltar</a>
                            </div>
                            <div class="box_content-footer pt-md-5">
                                <div class="voltar hidden-sm"><a class="voltar text-secondary" href="javascript:window.history.go(-1)">Voltar</a></div>
                                <div class="mt-4 mt-md-0">
                                    <a href="" @click="$redirect_reload('/')" class="btn btn-outline-danger btn-maisProdutos">{{ Titulos[0].textoBtn1 }}</a>
                                    <a @click="validaSetemOpcionais()" class="btn btn-success px-md-5 mt-0" data-toggle="modal" data-target="#exampleModal">{{ Titulos[0].textoBtn2 }}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </section>
        <FitaRodape class="" titulo=""></FitaRodape>
        <Footer />      
    </section>

</template>

<script>

    import Header from '@/components/site/shared/Header.vue'
    import Fita from '@/components/site/shared/Fita.vue'
    import FitaRodape from '@/components/site/shared/FitaRodape.vue'
    import Footer from '@/components/site/shared/Footer.vue'
    //import VueModal from '@kouts/vue-modal'
    import { VMoney } from 'v-money'
    import moment from 'moment';

    export default {
        components: {
            Header,
            Fita,
            FitaRodape,
            Footer,
            //'Modal': VueModal,
        },
        data() {
            return {
                ProdutoId: 0,
                ListaProdutos: [],
                listaProduto: {},
                ListaAvulsos: [],
                listaAvulso: {},
                ListaFoto: [],
                listaFoto: {},
                ValorCupom: 0,
                ValorFrete: 0,
                subtotal: 0,
                valorTotal: 0,
                IconeBarra: [],
                IconesBarra2: [],
                ListaExcecao: [],
                ListaEntregaFrete: [],
                ListaHorariosEntregaFrete: [],
                DadosCompra: {
                    produtos: [],
                    mensagem: [],
                    agenda: [],
                    foto: [],
                    avulsos: [],
                    baloes: [],
                    informacao: [],
                    pagamento: [],
                    cupom: [],
                },
                Titulos: [],
                fundotela: '',
                ListaBaloes: [],
                balao: {},
                numcupom:'',
                showModal:false,
            }
        },
        directives: { money: VMoney },
        methods: {
            ArmazenaDados(item, value) {
                console.log(value)
                if (item == 'produtos') {
                    let dadosArmazenados = localStorage.getItem('dadoscompralove');
                    if (dadosArmazenados) {
                        dadosArmazenados = JSON.parse(dadosArmazenados);
                        let vrecebe = dadosArmazenados.produtos.filter((x) => {
                            return x.produtoId == parseInt(this.ProdutoId, 10);
                        });

                        if (vrecebe == '') {
                            dadosArmazenados.produtos.push({ produto: this.Produto, Qtde: 1 });
                            localStorage.setItem('dadoscompralove', JSON.stringify(dadosArmazenados))
                        }
                    }

                }
            },
            ExcluirProduto(value) {
                let dadosArmazenados = localStorage.getItem('dadoscompralove');
                if (dadosArmazenados) {
                    dadosArmazenados = JSON.parse(dadosArmazenados);
                    this.ListaProdutos.splice(value, 1);
                    dadosArmazenados.produtos = this.ListaProdutos;
                    localStorage.setItem('dadoscompralove', JSON.stringify(dadosArmazenados))
                    this.$forceUpdate();
                    this.calcsubtotal();
                    this.calcvalorTotal();
                    if (this.ListaProdutos == '') {
                        dadosArmazenados = this.DadosCompra;
                        localStorage.setItem('dadoscompralove', JSON.stringify(dadosArmazenados))
                        this.$mensagem_normal("Seu carrinho está vazio, escolha produtos e adicione no carrinho.");
                        this.$redirect("/");
                    }
                }
            },
            ExcluirAvulso(value) {
                let dadosArmazenados = localStorage.getItem('dadoscompralove');
                if (dadosArmazenados) {
                    dadosArmazenados = JSON.parse(dadosArmazenados);

                    let vrecebe = dadosArmazenados.avulsos.filter((x) => {
                        return x.produtoAvulsoId != value.avulso.itemAvulsoId;
                    });
                    dadosArmazenados.avulsos = vrecebe;
                    localStorage.setItem('dadoscompralove', JSON.stringify(dadosArmazenados))
                    this.ListaAvulsos = dadosArmazenados.avulsos;
                    this.$forceUpdate();
                    this.calcsubtotal();
                    this.calcvalorTotal();
                }
            },
            ExcluirBalao(value) {
                let dadosArmazenados = localStorage.getItem('dadoscompralove');
                if (dadosArmazenados) {
                    dadosArmazenados = JSON.parse(dadosArmazenados);

                    let vrecebe = dadosArmazenados.baloes.filter((x) => {
                        return x.produtoAvulsoId != value.avulso.itemAvulsoId;
                    });
                    dadosArmazenados.baloes = vrecebe;
                    localStorage.setItem('dadoscompralove', JSON.stringify(dadosArmazenados))
                    this.ListaBaloes = dadosArmazenados.baloes;
                    this.$forceUpdate();
                    this.calcsubtotal();
                    this.calcvalorTotal();
                }
            },
            ExcluirFoto() {
                let dadosArmazenados = localStorage.getItem('dadoscompralove');
                if (dadosArmazenados) {
                    dadosArmazenados = JSON.parse(dadosArmazenados);
                    dadosArmazenados.foto = [];
                    localStorage.setItem('dadoscompralove', JSON.stringify(dadosArmazenados))
                    this.ListaFoto = dadosArmazenados.foto;
                    this.$forceUpdate();
                    this.calcsubtotal();
                    this.calcvalorTotal();
                }
            },
            calcsubtotal() {
                let dadosArmazenados = localStorage.getItem('dadoscompralove');
                if (!dadosArmazenados) {
                    return 0;
                }
                else {
                    dadosArmazenados = JSON.parse(dadosArmazenados);
                    let vproduto = 0;
                    if (dadosArmazenados.produtos != '') {
                        vproduto = dadosArmazenados.produtos.reduce((sum, item) => sum + item.produto.valorAte, 0);
                    }

                    let vavulso = 0
                    if (dadosArmazenados.avulsos != '') {
                        if (dadosArmazenados.avulsos[0].produtoAvulsoId != 0) {
                            vavulso = dadosArmazenados.avulsos.reduce((sum, item) => sum + item.avulso.valorDe, 0);
                        }
                    }

                    let vbalao = 0
                    if (dadosArmazenados.baloes != '') {
                        if (dadosArmazenados.baloes[0].produtoAvulsoId != 0) {
                            vbalao = dadosArmazenados.baloes.reduce((sum, item) => sum + item.avulso.valorDe, 0);
                        }
                    }

                    let vfoto = 0
                    if (dadosArmazenados.foto != '') {
                        if (dadosArmazenados.foto[0].imagemId != 0) {
                            vfoto = dadosArmazenados.foto[0].valor;
                        }
                    }

                    this.subtotal = vproduto + vavulso + vfoto + vbalao;
                }
            },
            calcvalorTotal() {
                let dadosArmazenados = localStorage.getItem('dadoscompralove');
                if (!dadosArmazenados) {
                    return 0;
                }
                else {
                     if (this.numcupom != ''){
                        this.CalculaCupom();
                     }
                    dadosArmazenados = JSON.parse(dadosArmazenados);
                    let vproduto = 0;
                    if (dadosArmazenados.produtos != '') {
                        vproduto = dadosArmazenados.produtos.reduce((sum, item) => sum + item.produto.valorAte, 0);
                    }

                    let vavulso = 0
                    if (dadosArmazenados.avulsos != '') {
                        if (dadosArmazenados.avulsos[0].produtoAvulsoId != 0) {
                            vavulso = dadosArmazenados.avulsos.reduce((sum, item) => sum + item.avulso.valorDe, 0);
                        }
                    }

                    let vbalao = 0
                    if (dadosArmazenados.baloes != '') {
                        if (dadosArmazenados.baloes[0].produtoAvulsoId != 0) {
                            vbalao = dadosArmazenados.baloes.reduce((sum, item) => sum + item.avulso.valorDe, 0);
                        }
                    }

                    let vfoto = 0
                    if (dadosArmazenados.foto != '') {
                        if (dadosArmazenados.foto[0].imagemId != 0) {
                            vfoto = dadosArmazenados.foto[0].valor;
                        }
                    }

                    this.valorTotal = (vproduto + vavulso + vfoto + vbalao + this.ValorFrete) - this.ValorCupom;
                }
            },
            validaSetemOpcionais(){
           //  let dadosArmazenados = localStorage.getItem('dadoscompralove');
            //    dadosArmazenados = JSON.parse(dadosArmazenados);
            // if (dadosArmazenados.avulsos == '' || dadosArmazenados.baloes == '')
             //  {
                this.$fire({
                        title: '<strong>Ainda da tempo!</strong>',
                        html:
                            'Você pode adicionar produtos extras na sua cesta como <b>balões, chocolates, flores e muito mais.</b> ',
                        showCloseButton: true,
                        showCancelButton: true,
                        focusConfirm: true,
                        cancelButtonText:
                            'Não quero',
                        confirmButtonText:
                            'Sim eu quero',
                             }).then((result) => {
                                if (result.value) {
                                   this.$redirect_reload('/produtodetalhe/' + this.ProdutoId + '/carrinho/3')
                                } else 
                                {
                                   let dadosArmazenados = localStorage.getItem('dadoscompralove');
                                    if (dadosArmazenados) {
                                        dadosArmazenados = JSON.parse(dadosArmazenados);
                                        if (this.ValorCupom != '') {
                                            dadosArmazenados.cupom = []
                                            dadosArmazenados.cupom.push({ ValorCupom: this.ValorCupom, Cupom: this.numcupom });
                                        }
                                        else{
                                            dadosArmazenados.cupom = [];
                                        }

                                        localStorage.setItem('dadoscompralove', JSON.stringify(dadosArmazenados))
                                       
                                    }
                                    this.validaAgenda();
                                }
                        })
                //}
                //else
                //{
                //    this.validaAgenda();
                //}
            },
            validaAgenda() {
            let dadosArmazenados = localStorage.getItem('dadoscompralove');
                dadosArmazenados = JSON.parse(dadosArmazenados);
                if (dadosArmazenados.agenda != '') {

                    let dadosAgenda = {
                        dataEntrega: dadosArmazenados.agenda[0].periodoEntrega.dataEntrega,
                        EstadoId: dadosArmazenados.agenda[0].estado.estadoId,
                        CidadeId: dadosArmazenados.agenda[0].cidade.cidadeId,
                        BairroId: dadosArmazenados.agenda[0].bairro.bairroId,
                        TipoEntrega: dadosArmazenados.agenda[0].periodoEntrega.TipoFrete, 
                    }

                    //verifica se tem exceção para data e horario
                    this.$http
                        .get(this.$apiUrl + "/entregaexcecao")
                        .then((res2) => res2.json())
                        .then((tiu2) => {
                            this.ListaExcecao = tiu2.filter(x => moment(x.dataExcecao).format('LL') == moment(dadosAgenda.dataEntrega).format('LL') && x.status)
                            let vcontrole = 0;
                            let filtraExcecao = []
                            let filtraEntregaFrete = []
                            if (this.ListaExcecao != '') {
                                //testar se tem bairro
                                this.ListaExcecao.forEach((itemex) => {
                                    try {
                                        if (itemex.estado.estadoId == dadosAgenda.EstadoId && itemex.cidade.cidadeId == dadosAgenda.CidadeId && itemex.bairro.bairroId == dadosAgenda.BairroId) {
                                            filtraExcecao.push(itemex)
                                            vcontrole = 1;
                                        }
                                    }
                                    catch {
                                        console.log(vcontrole)
                                    }
                                });

                                if (vcontrole == 0) {
                                    this.ListaExcecao.forEach((itemex) => {
                                        try {
                                            if (itemex.estado.estadoId == dadosAgenda.EstadoId && itemex.cidade.cidadeId == dadosAgenda.CidadeId && itemex.bairro == null) {
                                                filtraExcecao.push(itemex)
                                                vcontrole = 1;
                                            }
                                        }
                                        catch {
                                            console.log(vcontrole)
                                        }
                                    });
                                }

                                if (vcontrole == 0) {
                                    this.ListaExcecao.forEach((itemex) => {
                                        try {
                                            if (itemex.estado.estadoId == dadosAgenda.EstadoId && itemex.cidade == null && itemex.bairro == null) {
                                                filtraExcecao.push(itemex)
                                                vcontrole = 1;
                                            }
                                        }
                                        catch {
                                            console.log(vcontrole)
                                        }
                                    });
                                }

                                if (vcontrole == 0) {
                                    this.ListaExcecao.forEach((itemex) => {
                                        try {
                                            if (itemex.estado == null && itemex.cidade == null && itemex.bairro == null) {
                                                filtraExcecao.push(itemex)
                                                vcontrole = 1;
                                            }
                                        }
                                        catch {
                                            console.log(vcontrole)
                                        }
                                    });
                                }

                            }



                            //não tem exceção
                            this.$http
                                .get(this.$apiUrl + "/entregafrete")
                                .then((res2) => res2.json())
                                .then((tiu2) => {
                                    this.ListaEntregaFrete = tiu2.filter(x => x.status)

                                    if (this.ListaEntregaFrete != '') {

                                        let vcontrole2 = 0;
                                        //testar se tem bairro

                                        this.ListaEntregaFrete.forEach((itemex) => {
                                            try {
                                                if (itemex.estado.estadoId == dadosAgenda.EstadoId && itemex.cidade.cidadeId == dadosAgenda.CidadeId && itemex.bairro.bairroId == dadosAgenda.BairroId) {
                                                    filtraEntregaFrete.push(itemex)
                                                    vcontrole2 = 1;
                                                }
                                            }
                                            catch {
                                                console.log(vcontrole2)
                                            }
                                        });

                                        if (vcontrole2 == 0) {
                                            this.ListaEntregaFrete.forEach((itemex) => {
                                                try {
                                                    if (itemex.estado.estadoId == dadosAgenda.EstadoId && itemex.cidade.cidadeId == dadosAgenda.CidadeId && itemex.bairro == null) {
                                                        filtraEntregaFrete.push(itemex)
                                                        vcontrole2 = 1;
                                                    }
                                                }
                                                catch {
                                                    console.log(vcontrole2)
                                                }
                                            });
                                        }

                                        if (vcontrole2 == 0) {
                                            this.ListaEntregaFrete.forEach((itemex) => {
                                                try {
                                                    if (itemex.estado.estadoId == dadosAgenda.EstadoId && itemex.cidade == null && itemex.bairro == null) {
                                                        filtraEntregaFrete.push(itemex)
                                                        vcontrole2 = 1;
                                                    }
                                                }
                                                catch {
                                                    console.log(vcontrole2)
                                                }
                                            });
                                        }

                                        if (vcontrole2 == 0) {
                                            this.ListaEntregaFrete.forEach((itemex) => {
                                                try {
                                                    if (itemex.estado == null && itemex.cidade == null && itemex.bairro == null) {
                                                        filtraEntregaFrete.push(itemex)
                                                        vcontrole2 = 1;
                                                    }
                                                }
                                                catch {
                                                    console.log(vcontrole2)
                                                }
                                            });
                                        }
                                    }

                                    let vtempoproducao = 0;
                                    if (dadosArmazenados.produtos != '') {
                                        dadosArmazenados.produtos.forEach((itemp) => {
                                            if (itemp.produto.tempoProducao > 0) {
                                                if (vtempoproducao < itemp.produto.tempoProducao) {
                                                    vtempoproducao = itemp.produto.tempoProducao;
                                                }
                                            }
                                        });
                                    }
                                    if (dadosArmazenados.avulsos != '') {
                                        dadosArmazenados.avulsos.forEach((itemp2) => {
                                            if (itemp2.avulso.tempoProducao > 0) {
                                                if (vtempoproducao < itemp2.avulso.tempoProducao) {
                                                    vtempoproducao = itemp2.avulso.tempoProducao;
                                                }
                                            }
                                        });
                                    }
                                    if (dadosArmazenados.baloes != '') {
                                        dadosArmazenados.baloes.forEach((itemp) => {
                                            if (itemp.avulso.tempoProducao > 0) {
                                                if (vtempoproducao < itemp.avulso.tempoProducao) {
                                                    vtempoproducao = itemp.avulso.tempoProducao;
                                                }
                                            }
                                        });
                                    }

                                    if (dadosAgenda.TipoEntrega == "E" || filtraExcecao != '') {
                                        let vvalorFrete = filtraEntregaFrete[0].valorFrete;

                                        let vlistaHorarios = []
                                        //mostra grid inteira a partir do tempo de produção do produto
                                        filtraExcecao.forEach((item) => {
                                            if (moment().add(parseInt(item.atrasoHs, 10) + vtempoproducao, 'hour').format('YYYY-MM-DDTHH:mm') <= moment().format(dadosAgenda.dataEntrega + 'T' + this.validaHora(item.horaIni) + ':' + this.validaHora(item.minIni))) {
                                                vlistaHorarios.push({ codigo: item.entregaExcecaoId, valorFrete: vvalorFrete, TipoFrete: dadosAgenda.TipoEntrega, dataEntrega: dadosAgenda.dataEntrega, texto: this.validaHora(item.horaIni) + ':' + this.validaHora(item.minIni) + ' às ' + this.validaHora(item.horaFim) + ':' + this.validaHora(item.minFim) + ' - valor R$ ' + parseFloat(vvalorFrete).toFixed(2).toString().replace('.', ',') });
                                            }
                                        });

                                        if (vlistaHorarios.filter(x => x.codigo == dadosArmazenados.agenda[0].periodoEntrega.codigo).length > 0) {
                                            this.$redirect("/login");
                                        }
                                        else {
                                            this.$mensagem_normal("Infelizmente esta esgotada as entregas nesse horário, favor escolher nova agenda.");
                                            this.$redirect("/produtodetalhe/" + this.ProdutoId + '/carrinho/1');
                                        }
                                    }
                                    else {
                                        let vlistaHorarios2 = []
                                        this.$http
                                            .get(this.$apiUrl + "/entregahorario")
                                            .then((res2) => res2.json())
                                            .then((tiu2) => {
                                                this.ListaHorariosEntregaFrete = tiu2.filter(x => x.status)

                                               //mostra grid inteira a partir do tempo de produção do produto
                                                filtraEntregaFrete.forEach((item) => {
                                                    this.ListaHorariosEntregaFrete.forEach((itemhora) => {
                                                        if (moment().add(parseInt(item.atrasoHs, 10) + vtempoproducao, 'hour').format() <= moment().format(dadosAgenda.dataEntrega + 'T' + this.validaHora(itemhora.horaIni) + ':' + this.validaHora(itemhora.minIni))) {
                                                            vlistaHorarios2.push({ codigo: item.entregaFreteId, codigoHorario: itemhora.entregaHorarioId, valorFrete: item.valorFrete, TipoFrete: dadosAgenda.TipoEntrega, dataEntrega: dadosAgenda.dataEntrega, texto: this.validaHora(itemhora.horaIni) + ':' + this.validaHora(itemhora.minIni) + ' às ' + this.validaHora(itemhora.horaFim) + ':' + this.validaHora(itemhora.minFim) + ' - valor R$ ' + parseFloat(item.valorFrete).toFixed(2).toString().replace('.', ',') /* + ' Tipo: ' + item.tipoEntrega*/ });
                                                        }
                                                    });
                                                });

                                                if (vlistaHorarios2.filter(x => x.codigoHorario == dadosArmazenados.agenda[0].periodoEntrega.codigoHorario).length > 0) {
                                                    this.$redirect("/login");
                                                }
                                                else {
                                                    this.$mensagem_normal("Infelizmente esta esgotada as entregas nesse horário, favor escolher nova agenda.");
                                                    this.$redirect("/produtodetalhe/" + this.ProdutoId + '/carrinho/1');
                                                }
                                            });
                                    }

                                    //comparar se o que tem no armazena tem nos arrays se tiver ok, senão mensagem e volta para calendario
                                });
                        });
                }
           },
            validaHora(value) {
                var _hora = value.toString();
                if (_hora.length == 1)
                    _hora = "0" + _hora;
                return _hora;
            },
            montatextobalao(value) {
                let recebeinicio1 = value.indexOf("Linha 1")
                let recebestring1 = value.substring(recebeinicio1 + 20)
                let recebefim1 = recebestring1.indexOf("Fonte")
                let resultado1 = recebestring1.substring(0, recebefim1)

                let recebeinicio2 = value.indexOf("Linha 2")
                let recebestring2 = value.substring(recebeinicio2 + 20)
                let recebefim2 = recebestring2.indexOf("Fonte")
                let resultado2 = recebestring2.substring(0, recebefim2)

                let recebeinicio3 = value.indexOf("Linha 3")
                let recebestring3 = value.substring(recebeinicio3 + 20)
                let recebefim3 = recebestring3.indexOf("Fonte")
                let resultado3 = recebestring3.substring(0, recebefim3)

                let resultadofinal = resultado1 + resultado2 + resultado3

                return resultadofinal.trim('"');
            },
             CalculaCupom() {
                let dadosArmazenados = localStorage.getItem('dadoscompralove');

                if (dadosArmazenados) {
                    dadosArmazenados = JSON.parse(dadosArmazenados);
                    
                   let _vFotos = 0; 
                    if (dadosArmazenados.foto != '') {
                        if (dadosArmazenados.foto[0].imagemId != 0) {
                            _vFotos = dadosArmazenados.foto[0].valor;

                        }
                    }

                   let _vAvulso = []; 
                    if (dadosArmazenados.avulsos != '') {
                        if (dadosArmazenados.avulsos[0].produtoAvulsoId != 0) {
                            _vAvulso = dadosArmazenados.avulsos;
                        }
                    }

                   let _vBaloes = []; 
                    if (dadosArmazenados.baloes != '') {
                        if (dadosArmazenados.baloes[0].produtoAvulsoId != 0) {
                            _vBaloes = dadosArmazenados.baloes;
                        }
                    }

                    this.data =
                    {
                        produtos: dadosArmazenados.produtos,
                        avulsos: _vAvulso,
                        baloes: _vBaloes,
                        Foto: _vFotos,
                        valorFrete: parseFloat(this.ValorFrete, 10),
                        codigo: this.numcupom
                    }
                    console.log(this.data)

                    this.$http.post(this.$apiUrl + "/cupom/verificar", this.data)
                        .then(response => {
                            if (response.ok) {
                                console.log(response);
                                this.ValorCupom = parseFloat(response.body, 10)
                                 if (this.ValorCupom == 0){
                                        this.$mensagem_normal("Este cupom não é valido");
                                    }
                                //this.calcsubtotal();
                                //this.calcvalorTotal();
                            }
                        },
                            error => {
                                try {
                                    this.$mensagem_erro(error.bodyText)
                                    console.log(error);

                                } catch {
                                    this.$mensagem_erro(error.bodyText)
                                    console.log(error);
                                }
                            });
                }
            },
             validacampocupom(){
                if (this.numcupom == ''){
                    this.ValorCupom = 0;
                    this.calcvalorTotal();
                }
            },
        },
        created() {

            //----------------- Titulos
            this.$http
                .get(this.$apiUrl + "/institucional/telas/textocarrinho")
                .then((res) => res.json())
                .then((retornoAPI) => {
                    this.Titulos = retornoAPI;
                    if (this.Titulos[0].institucionalImg != '') {
                        this.fundotela = this.Titulos[0].institucionalImg[0].urlImagem
                    }
                    else {
                        this.fundotela = '';
                    }
                });


            // cria localstorage para armazenar informações da compra
            let dadosArmazenados = localStorage.getItem('dadoscompralove');
            if (!dadosArmazenados) {
                this.$mensagem_normal("Seu carrinho está vazio, escolha produtos e adicione no carrinho.");
                this.$redirect("/");
            }
            else {
                dadosArmazenados = JSON.parse(dadosArmazenados);
                if (dadosArmazenados.produtos != '') {
                    this.ListaProdutos = dadosArmazenados.produtos;
                    this.ProdutoId = dadosArmazenados.produtos[0].produtoId;

                     if (dadosArmazenados.cupom != ''){
                        this.numcupom = dadosArmazenados.cupom[0].Cupom;
                        this.CalculaCupom();
                    }
                }
                else {
                    this.$mensagem_normal("Seu carrinho está vazio, escolha produtos e adicione no carrinho.");
                    this.$redirect("/");
                }

                if (dadosArmazenados.avulsos != '') {
                    if (dadosArmazenados.avulsos[0].produtoAvulsoId != 0) {
                        this.ListaAvulsos = dadosArmazenados.avulsos;
                    }
                }

                if (dadosArmazenados.baloes != '') {
                    if (dadosArmazenados.baloes[0].produtoAvulsoId != 0) {
                        this.ListaBaloes = dadosArmazenados.baloes;
                    }
                }

                if (dadosArmazenados.agenda != '') {
                    if (dadosArmazenados.agenda[0].periodoEntrega != '') {
                        this.ValorFrete = dadosArmazenados.agenda[0].periodoEntrega.valorFrete;
                    }
                }

                if (dadosArmazenados.foto != '') {
                    if (dadosArmazenados.foto[0].imagemId != 0) {
                        this.ListaFoto = dadosArmazenados.foto;
                    }
                }
            }
            this.calcsubtotal();
            this.calcvalorTotal();
            //carrega icones da barra do detalhe do produto
            this.$http
                .get(this.$apiUrl + "/institucional/telas/iconesbarra")
                .then((res2) => res2.json())
                .then((tiu2) => {
                    this.IconesBarra = tiu2.slice(1, 2)
                    this.IconesBarra2 = tiu2.slice(2, 3)
                });
        },
        computed: {
        }
    }

</script>

<style scoped>

 @import '/css_cart/carrinho.css';
</style>