<template>
  
  <section class="carrinho">

    <HeaderCart />
    <Fita />

    <section class="main-carrinho">
    
      <div class="container">
        <div class="row py-5">
          <div class="col-lg-6 hidden-sm-md">
            <div class="carrinho-bg set-img">
              <img class="img-fluid" src="img/32.png" alt="">
            </div>
          </div>
          <div class="col-lg-6">
            <div class="box_content">
              <h3 class="titulo-principal text-center mb-4">Confirmação</h3>

              <!-- Status -->
              <div class="status-novaConta px-md-5">
                <div class="status-item pos-item">
                  <div class="status-number"><i class="fas fa-check"></i></div>
                  <span class="status-text">Login</span>
                </div>
                <div class="status-item pos-item">
                  <div class="status-number"><span><i class="fas fa-check"></i></span></div>
                  <span class="status-text">Informação</span>
                </div>
                <div class="status-item pos-item">
                  <div class="status-number"><span><i class="fas fa-check"></i></span></div>
                  <span class="status-text">Pagamento</span>
                </div>
                <div class="status-item active">
                  <div class="status-number"><span>4</span></div>
                  <span class="status-text">Confirmação</span>
                </div>
              </div>
              <hr>

              <div class="mb-4 px-md-4">

                <div class="agendamento">
                  <div class="row">
                    <div class="col-sm-5"><p class="texto mb-0">Nome Completo</p></div>                    
                    <div class="col-sm-7"><p class="texto-strong">Nome Sobrenome</p></div>                    
                  </div>
                  <div class="row">
                    <div class="col-sm-5"><p class="texto mb-0">E-mail</p></div>                    
                    <div class="col-sm-7"><p class="texto-strong">exemplo@gmail.com</p></div>                    
                  </div>
                  <div class="row">
                    <div class="col-sm-5"><p class="texto mb-0">Telefone</p></div>                    
                    <div class="col-sm-7"><p class="texto-strong">51 3031-3233</p></div>                    
                  </div>
                  <div class="row">
                    <div class="col-sm-5"><p class="texto mb-0">Forma de Pagamento</p></div>                    
                    <div class="col-sm-7"><p class="texto-strong">Cartão de crédito</p></div>                    
                  </div>
                  <div class="row">
                    <div class="col-sm-5"><p class="texto mb-0">Endereço da Entrega</p></div>                    
                    <div class="col-sm-7">
                      <p class="texto-strong">Rua da Barca, 1234<br /> Bairro Tristeza - Porto Alegre/RS<br />CEP 92300-123</p></div>                    
                  </div>
                </div>           
              </div>
              <hr>
              <div class="mb-4 px-md-4">
                <h5 class="subtitulo text-center mb-4">Agendamento</h5>
                <div class="agendamento">
                  <div class="row">
                    <div class="col-sm-5"><p class="texto mb-0">Data</p></div>                    
                    <div class="col-sm-7"><p class="texto-strong">30 de Julho de 2021 - Sexta-Feira</p></div>                    
                  </div>
                  <div class="row">
                    <div class="col-sm-5"><p class="texto mb-0">Horário</p></div>                    
                    <div class="col-sm-7"><p class="texto-strong">Comercial (9h - 12h) - R$ 21,00</p></div>                    
                  </div>
                </div>           
              </div>
              
              <hr>

              <div class="form-check px-md-5">
                <input class="form-check-input" type="checkbox" value="" id="defaultCheck1">
                <label class="texto-mensagem form-check-label" for="defaultCheck1">
                  Concordo com os termos do serviço e a política de privacidade
                </label>
              </div>

              <div class="box_content-footer mt-5">
                
                <div class="voltar"><a class="voltar text-secondary" href="javascript:window.history.go(-1)">Voltar</a></div>
                <div class="mt-4 mt-md-0">
                  <router-link to="/pagamentoResumo" class="btn btn-success px-5 mt-0">Finalizar</router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <Fita class="ribbon-lg" titulo="A melhor empresa de cestas e presentes do Rio Grande do Sul!! Há 8 anos levando carinho, amor e alegrias a nossos clientes!"></Fita>
    <Footer />

  </section>

</template>

<script>

  import HeaderCart from '@/components/site/shared/HeaderCart.vue'
  import Fita from '@/components/site/shared/Fita.vue'
  import Footer from '@/components/site/shared/Footer.vue'

  export default {
    components: {
      HeaderCart,
      Fita,
      Footer
    }
  }

</script>

<style scoped>

  @import '/css_cart/carrinho.css';
  @import '/css_cart/nova_conta.css';
  
</style>