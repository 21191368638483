<template>
  <section class="carrinho">
    <CheckAcesso />
    <Header />

    <Fita class="hidden-sm-md hidden-xs" />

    <section
      :style="{
        backgroundImage: 'url(' + $Url + fundotela + ')',
        backgroundColor: '#e8eaf6',
        backgroundSize: '100%',
        backgroundRepeat: 'no-repeat',
      }"
    >
      <div class="container">
        <div class="row py-5">
          <div class="col-xl-6 hidden-sm-md">
            <div class="carrinho-bg set-img"></div>
          </div>
          <div class="col-xl-6">
            <div class="box_content">
              <h3 class="titulo-principal text-center mb-4">
                {{ Titulos[0].nome }}
              </h3>

              <!-- Status -->
              <div class="status-novaConta px-md-5">
                <div class="status-item pos-item2">
                  <div class="status-number"><i class="fas fa-check"></i></div>
                  <span class="status-text">Login</span>
                </div>
                <div class="status-item pos-item2">
                  <div class="status-number"><i class="fas fa-check"></i></div>
                  <span class="status-text">Informação</span>
                </div>
                <div class="status-item active">
                  <div class="status-number"><span>3</span></div>
                  <span class="status-text">Pagamento</span>
                </div>
                <!-- <div class="status-item">
                                  <div class="status-number"><span>4</span></div>
                                  <span class="status-text">Confirmação</span>
                                </div> -->
              </div>

              <!-- Dados do Cartão -->
              <div class="form-group row align-items-center mb-3">
                <label
                  for="inputPGTO"
                  class="col-sm-5 col-form-label label-pagto2"
                  >Forma Pagamento</label
                >
                <div class="col-sm-7">
                  <v-select
                    v-model="ListaformasPagamentoselected"
                    @input="MudaListaformasPagamento"
                    :options="ListaformasPagamento"
                    :value="ListaformasPagamento"
                    single-line
                    :searchable="false"
                    label="texto"
                    class="style-chooser w-100"
                  ></v-select>
                </div>
              </div>
              <PagamentoYapay v-if="showyapay == true" />
              <PagamentoPaypal v-if="showpaypal == true" />
               <PagamentoCielo v-if="showcielo == true" />
              <PagamentoBancos
                v-if="showbancos == true"
                :BancoId="OperacaoId"
                :key="componentKey"
              />
              <PagamentoPix
                v-if="showPix == true"
                :PixId="OperacaoId"
                :key="componentKeypix"
              />
              <PagamentoPagseguro v-if="showpagseguro == true" />
            </div>
          </div>
        </div>
      </div>
    </section>

    <FitaRodape class="" titulo=""></FitaRodape>
    <Footer />
  </section>
</template>

<script>
import CheckAcesso from "@/components/site/shared/CheckAcesso.vue";
import Header from "@/components/site/shared/Header.vue";
import Fita from "@/components/site/shared/Fita.vue";
import FitaRodape from "@/components/site/shared/FitaRodape.vue";
import Footer from "@/components/site/shared/Footer.vue";
import PagamentoYapay from "@/components/site/shared/PagamentoYapay.vue";
import PagamentoCielo from "@/components/site/shared/PagamentoCielo.vue";
import PagamentoPaypal from "@/components/site/shared/PagamentoPaypal.vue";
import PagamentoBancos from "@/components/site/shared/PagamentoBancos.vue";
import PagamentoPix from "@/components/site/shared/PagamentoPix.vue";
import PagamentoPagseguro from "@/components/site/shared/PagamentoPagseguro.vue";
import vSelect from "vue-select";
    import moment from 'moment';

export default {
  components: {
    Header,
    vSelect,
    Fita,
    FitaRodape,
    Footer,
    PagamentoYapay,
    PagamentoPaypal,
    PagamentoBancos,
    PagamentoPix,
    PagamentoPagseguro,
    PagamentoCielo,
    CheckAcesso,
  },
  data() {
    return {
      ListaformasPagamentoOperadora: [],
      ListaformasPagamentoBanco: [],
      ListaformasPagamentoPix: [],
      ListaformasPagamento: [],
      OperacaoId: 0,
      Operacao: "",
      ListaformasPagamentoselected: null,
      showyapay: false,
      showpaypal: false,
      showbancos: false,
      showPix: false,
      showpagseguro: false,
      showcielo: false,
      componentKey: 0,
      componentKeypix: 0,
      Titulos: [],
      fundotela: "",
    };
  },
  methods: {
    MudaListaformasPagamento(value) {
      this.validaAgenda();

      if (value != null) {
        this.OperacaoId = value.OperacaoId;
        this.Operacao = value.Operacao;
        if (
          parseInt(this.OperacaoId, 10) == 1 &&
          this.Operacao == "cadOperadora"
        ) {
          this.showyapay = true;
          this.showpaypal = false;
          this.showbancos = false;
          this.showPix = false;
          this.showpagseguro = false;
          this.showcielo = false;
        } else if (
          parseInt(this.OperacaoId, 10) == 3 &&
          this.Operacao == "cadOperadora"
        ) {
          this.showyapay = false;
          this.showpaypal = true;
          this.showbancos = false;
          this.showPix = false;
          this.showpagseguro = false;
           this.showcielo = false;
        } else if (
          parseInt(this.OperacaoId, 10) == 2 &&
          this.Operacao == "cadOperadora"
        ) {
          this.showyapay = false;
          this.showpaypal = false;
          this.showbancos = false;
          this.showPix = false;
          this.showpagseguro = true;
           this.showcielo = false;
        }
        else if (
          parseInt(this.OperacaoId, 10) == 4 &&
          this.Operacao == "cadOperadora"
        ) {
          this.showyapay = false;
          this.showpaypal = false;
          this.showbancos = false;
          this.showPix = false;
          this.showpagseguro = false;
           this.showcielo = true;
        }
        else if (this.Operacao == "cadBanco") {
          this.showyapay = false;
          this.showpaypal = false;
          this.showbancos = true;
          this.showPix = false;
          this.showpagseguro = false;
           this.showcielo = false;
          this.forceRerender();
        } else if (this.Operacao == "cadPix") {
          this.showyapay = false;
          this.showpaypal = false;
          this.showbancos = false;
          this.showPix = true;
          this.showpagseguro = false;
           this.showcielo = false;
          this.forceRerenderPix();
        } else {
          this.OperacaoId = 0;
          this.Operacao = "";
          this.showyapay = false;
          this.showpaypal = false;
          this.showbancos = false;
          this.showPix = false;
          this.showpagseguro = false;
           this.showcielo = false;
        }
      } else {
        this.OperacaoId = 0;
        this.Operacao = "";
        this.showyapay = false;
        this.showpaypal = false;
        this.showbancos = false;
        this.showPix = false;
        this.showpagseguro = false;
         this.showcielo = false;
      }
    },
    forceRerender() {
      this.componentKey += 1;
    },
    forceRerenderPix() {
      this.componentKeypix += 1;
    },
     validaAgenda() {
                let dadosArmazenados = localStorage.getItem('dadoscompralove');
                dadosArmazenados = JSON.parse(dadosArmazenados);
                if (dadosArmazenados.agenda != '') {

                    let dadosAgenda = {
                        dataEntrega: dadosArmazenados.agenda[0].periodoEntrega.dataEntrega,
                        EstadoId: dadosArmazenados.agenda[0].estado.estadoId,
                        CidadeId: dadosArmazenados.agenda[0].cidade.cidadeId,
                        BairroId: dadosArmazenados.agenda[0].bairro.bairroId,
                        TipoEntrega: dadosArmazenados.agenda[0].periodoEntrega.TipoFrete,
                    }

                    //verifica se tem exceção para data e horario
                    this.$http
                        .get(this.$apiUrl + "/entregaexcecao")
                        .then((res2) => res2.json())
                        .then((tiu2) => {
                            this.ListaExcecao = tiu2.filter(x => moment(x.dataExcecao).format('LL') == moment(dadosAgenda.dataEntrega).format('LL') && x.status)
                            let vcontrole = 0;
                            let filtraExcecao = []
                            let filtraEntregaFrete = []
                            if (this.ListaExcecao != '') {
                                //testar se tem bairro
                                this.ListaExcecao.forEach((itemex) => {
                                    try {
                                        if (itemex.estado.estadoId == dadosAgenda.EstadoId && itemex.cidade.cidadeId == dadosAgenda.CidadeId && itemex.bairro.bairroId == dadosAgenda.BairroId) {
                                            filtraExcecao.push(itemex)
                                            vcontrole = 1;
                                        }
                                    }
                                    catch {
                                        console.log(vcontrole)
                                    }
                                });

                                if (vcontrole == 0) {
                                    this.ListaExcecao.forEach((itemex) => {
                                        try {
                                            if (itemex.estado.estadoId == dadosAgenda.EstadoId && itemex.cidade.cidadeId == dadosAgenda.CidadeId && itemex.bairro == null) {
                                                filtraExcecao.push(itemex)
                                                vcontrole = 1;
                                            }
                                        }
                                        catch {
                                            console.log(vcontrole)
                                        }
                                    });
                                }

                                if (vcontrole == 0) {
                                    this.ListaExcecao.forEach((itemex) => {
                                        try {
                                            if (itemex.estado.estadoId == dadosAgenda.EstadoId && itemex.cidade == null && itemex.bairro == null) {
                                                filtraExcecao.push(itemex)
                                                vcontrole = 1;
                                            }
                                        }
                                        catch {
                                            console.log(vcontrole)
                                        }
                                    });
                                }

                                if (vcontrole == 0) {
                                    this.ListaExcecao.forEach((itemex) => {
                                        try {
                                            if (itemex.estado == null && itemex.cidade == null && itemex.bairro == null) {
                                                filtraExcecao.push(itemex)
                                                vcontrole = 1;
                                            }
                                        }
                                        catch {
                                            console.log(vcontrole)
                                        }
                                    });
                                }

                            }



                            //não tem exceção
                            this.$http
                                .get(this.$apiUrl + "/entregafrete")
                                .then((res2) => res2.json())
                                .then((tiu2) => {
                                    this.ListaEntregaFrete = tiu2.filter(x => x.status)

                                    if (this.ListaEntregaFrete != '') {

                                        let vcontrole2 = 0;
                                        //testar se tem bairro

                                        this.ListaEntregaFrete.forEach((itemex) => {
                                            try {
                                                if (itemex.estado.estadoId == dadosAgenda.EstadoId && itemex.cidade.cidadeId == dadosAgenda.CidadeId && itemex.bairro.bairroId == dadosAgenda.BairroId) {
                                                    filtraEntregaFrete.push(itemex)
                                                    vcontrole2 = 1;
                                                }
                                            }
                                            catch {
                                                console.log(vcontrole2)
                                            }
                                        });

                                        if (vcontrole2 == 0) {
                                            this.ListaEntregaFrete.forEach((itemex) => {
                                                try {
                                                    if (itemex.estado.estadoId == dadosAgenda.EstadoId && itemex.cidade.cidadeId == dadosAgenda.CidadeId && itemex.bairro == null) {
                                                        filtraEntregaFrete.push(itemex)
                                                        vcontrole2 = 1;
                                                    }
                                                }
                                                catch {
                                                    console.log(vcontrole2)
                                                }
                                            });
                                        }

                                        if (vcontrole2 == 0) {
                                            this.ListaEntregaFrete.forEach((itemex) => {
                                                try {
                                                    if (itemex.estado.estadoId == dadosAgenda.EstadoId && itemex.cidade == null && itemex.bairro == null) {
                                                        filtraEntregaFrete.push(itemex)
                                                        vcontrole2 = 1;
                                                    }
                                                }
                                                catch {
                                                    console.log(vcontrole2)
                                                }
                                            });
                                        }

                                        if (vcontrole2 == 0) {
                                            this.ListaEntregaFrete.forEach((itemex) => {
                                                try {
                                                    if (itemex.estado == null && itemex.cidade == null && itemex.bairro == null) {
                                                        filtraEntregaFrete.push(itemex)
                                                        vcontrole2 = 1;
                                                    }
                                                }
                                                catch {
                                                    console.log(vcontrole2)
                                                }
                                            });
                                        }
                                    }

                                    let vtempoproducao = 0;
                                    if (dadosArmazenados.produtos != '') {
                                        dadosArmazenados.produtos.forEach((itemp) => {
                                            if (itemp.produto.tempoProducao > 0) {
                                                if (vtempoproducao < itemp.produto.tempoProducao) {
                                                    vtempoproducao = itemp.produto.tempoProducao;
                                                }
                                            }
                                        });
                                    }
                                    if (dadosArmazenados.avulsos != '') {
                                        dadosArmazenados.avulsos.forEach((itemp2) => {
                                            if (itemp2.avulso.tempoProducao > 0) {
                                                if (vtempoproducao < itemp2.avulso.tempoProducao) {
                                                    vtempoproducao = itemp2.avulso.tempoProducao;
                                                }
                                            }
                                        });
                                    }

                                    if (dadosArmazenados.baloes != '') {
                                        dadosArmazenados.baloes.forEach((itemp) => {
                                            if (itemp.avulso.tempoProducao > 0) {
                                                if (vtempoproducao < itemp.avulso.tempoProducao) {
                                                    vtempoproducao = itemp.avulso.tempoProducao;
                                                }
                                            }
                                        });
                                    }
                                    //console.log(vtempoproducao)

                                    if (dadosAgenda.TipoEntrega == "E" || filtraExcecao != '') {
                                        let vvalorFrete = filtraEntregaFrete[0].valorFrete;

                                        let vlistaHorarios = []
                                        //mostra grid inteira a partir do tempo de produção do produto
                                        filtraExcecao.forEach((item) => {
                                            if (moment().add(parseInt(item.atrasoHs, 10) + vtempoproducao, 'hour').format('YYYY-MM-DDTHH:mm') <= moment().format(dadosAgenda.dataEntrega + 'T' + this.validaHora(item.horaIni) + ':' + this.validaHora(item.minIni))) {
                                                vlistaHorarios.push({ codigo: item.entregaExcecaoId, valorFrete: vvalorFrete, TipoFrete: dadosAgenda.TipoEntrega, dataEntrega: dadosAgenda.dataEntrega, texto: this.validaHora(item.horaIni) + ':' + this.validaHora(item.minIni) + ' às ' + this.validaHora(item.horaFim) + ':' + this.validaHora(item.minFim) + ' - valor R$ ' + parseFloat(vvalorFrete).toFixed(2).toString().replace('.', ',') });
                                            }
                                        });

                                        if (vlistaHorarios.filter(x => x.codigo == dadosArmazenados.agenda[0].periodoEntrega.codigo).length > 0) {
                                            console.log('Ok')
                                        }
                                        else {
                                            this.$mensagem_normal("Infelizmente esta esgotada as entregas nesse horário, favor escolher nova agenda.");
                                            this.$redirect("/produtodetalhe/" + this.ProdutoId + '/novaContaEndereco/1');
                                        }
                                    }
                                    else {
                                        let vlistaHorarios2 = []
                                        this.$http
                                            .get(this.$apiUrl + "/entregahorario")
                                            .then((res2) => res2.json())
                                            .then((tiu2) => {
                                                this.ListaHorariosEntregaFrete = tiu2.filter(x => x.status)

                                                //mostra grid inteira a partir do tempo de produção do produto
                                                filtraEntregaFrete.forEach((item) => {
                                                    this.ListaHorariosEntregaFrete.forEach((itemhora) => {
                                                        if (moment().add(parseInt(item.atrasoHs, 10) + vtempoproducao, 'hour').format() <= moment().format(dadosAgenda.dataEntrega + 'T' + this.validaHora(itemhora.horaIni) + ':' + this.validaHora(itemhora.minIni))) {
                                                            vlistaHorarios2.push({ codigo: item.entregaFreteId, codigoHorario: itemhora.entregaHorarioId, valorFrete: item.valorFrete, TipoFrete: dadosAgenda.TipoEntrega, dataEntrega: dadosAgenda.dataEntrega, texto: this.validaHora(itemhora.horaIni) + ':' + this.validaHora(itemhora.minIni) + ' às ' + this.validaHora(itemhora.horaFim) + ':' + this.validaHora(itemhora.minFim) + ' - valor R$ ' + parseFloat(item.valorFrete).toFixed(2).toString().replace('.', ',') /* + ' Tipo: ' + item.tipoEntrega*/ });
                                                        }
                                                    });
                                                });

                                                if (vlistaHorarios2.filter(x => x.codigoHorario == dadosArmazenados.agenda[0].periodoEntrega.codigoHorario).length > 0) {
                                                    console.log('Ok')
                                                }
                                                else {
                                                    this.$mensagem_normal("Infelizmente esta esgotada as entregas nesse horário, favor escolher nova agenda.");
                                                    this.$redirect("/produtodetalhe/" + this.ProdutoId + '/novaContaEndereco/1');
                                                }
                                            });
                                    }

                                    //comparar se o que tem no armazena tem nos arrays se tiver ok, senão mensagem e volta para calendario
                                });
                        });
                }
            },
            validaHora(value) {
                var _hora = value.toString();
                if (_hora.length == 1)
                    _hora = "0" + _hora;
                return _hora;
            },
  },
  created() {
    let dadosArmazenados = localStorage.getItem("dadoscompralove");
    if (!dadosArmazenados) {
      dadosArmazenados = this.DadosCompra;
      localStorage.setItem("dadoscompralove", JSON.stringify(dadosArmazenados));
      this.$redirect_reload("/meuspedidos");
    } else {
      dadosArmazenados = JSON.parse(dadosArmazenados);
      if (dadosArmazenados.produtos == "") {
        this.$redirect_reload("/meuspedidos");
      }
    }

    let _totalProdutos = 0;
    if (dadosArmazenados.produtos != "") {
      dadosArmazenados.produtos.forEach((itemprod) => {
        _totalProdutos += parseFloat(itemprod.produto.valorAte);
      });
    }

    if (dadosArmazenados.avulsos != "") {
      if (dadosArmazenados.avulsos[0].produtoAvulsoId != 0) {
        dadosArmazenados.avulsos.forEach((itemavul) => {
          _totalProdutos += parseFloat(itemavul.avulso.valorDe);
        });
      }
    }

    if (dadosArmazenados.agenda != "") {
      if (dadosArmazenados.agenda[0].periodoEntrega != "") {
        if (
          parseFloat(dadosArmazenados.agenda[0].periodoEntrega.valorFrete) > 0
        ) {
          _totalProdutos += parseFloat(
            dadosArmazenados.agenda[0].periodoEntrega.valorFrete
          );
        }
      }
    }

    if (dadosArmazenados.foto != "") {
      if (dadosArmazenados.foto[0].imagemUrl != "Sem foto") {
        dadosArmazenados.foto.forEach((itemfoto) => {
          _totalProdutos += parseFloat(itemfoto.valor);
        });
      }
    }
    localStorage.setItem("total", _totalProdutos.toString());
    //----------------- Titulos
    this.$http
      .get(this.$apiUrl + "/institucional/telas/textopagamento")
      .then((res) => res.json())
      .then((retornoAPI) => {
        this.Titulos = retornoAPI;

        if (this.Titulos[0].institucionalImg != "") {
          this.fundotela = this.Titulos[0].institucionalImg[0].urlImagem;
        } else {
          this.fundotela = "";
        }
      });

    let _formasPagamento = [];
    //carrega informações da lista de opções de pagamento
    this.$http
      .get(this.$apiUrl + "/configoperadora")
      .then((res2) => res2.json())
      .then((tiu2) => {
        //carrega informações da lista de opções de pagamento
        this.$http
          .get(this.$apiUrl + "/configpix")
          .then((res2) => res2.json())
          .then((tiu3) => {
            this.ListaformasPagamentoPix = tiu3.filter((x) => x.status);
            if (this.ListaformasPagamentoPix != null) {
              this.ListaformasPagamentoPix.forEach((item) => {
                _formasPagamento.push({
                  OperacaoId: item.cadPix.cadPixId,
                  Operacao: "cadPix",
                  texto: item.cadPix.nomePix,
                });
              });
            }
             console.log('tiu2')
             console.log(tiu2)
            this.ListaformasPagamentoOperadora = tiu2.filter(x => x.status);
             
            if (this.ListaformasPagamentoOperadora != null) {
              this.ListaformasPagamentoOperadora.forEach((item) => {
                let recebe='';
                if (item.cadOperadora.cadOperadoraId == 4 || item.cadOperadora.cadOperadoraId == 1){
                  recebe = "Cartão de Crédito"
                }
                else{
                  recebe = item.cadOperadora.nomeOperadora;
                }

                _formasPagamento.push({
                  OperacaoId: item.cadOperadora.cadOperadoraId,
                  Operacao: "cadOperadora",
                  texto: recebe,
                });
              });
            }

            //carrega informações da lista de opções de pagamento
            this.$http
              .get(this.$apiUrl + "/configbanco")
              .then((res2) => res2.json())
              .then((tiu2) => {
                this.ListaformasPagamentoBanco = tiu2.filter((x) => x.status);
                if (this.ListaformasPagamentoBanco != null) {
                  this.ListaformasPagamentoBanco.forEach((item) => {
                    _formasPagamento.push({
                      OperacaoId: item.cadBanco.cadBancoId,
                      Operacao: "cadBanco",
                      texto: item.cadBanco.nomeBanco,
                    });
                  });
                }
              });
          });
      });

    this.ListaformasPagamento = _formasPagamento;
    this.ListaformasPagamento.sort(function (a, b) {
      return a.texto - b.texto;
    });
  },
};
</script>

<style>
@import "/css_cart/carrinho.css";
@import "/css_cart/nova_conta.css";
</style>