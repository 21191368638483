<template>
    <div>
        {{ Produtos }}
    </div>
</template>

<script>

    export default {
        components: {

        },
        data() {
            return {
                Produto: [],
                Produtos: '',
                LigacaoProduto:[],
            }
        },
        methods: {
            CarregaSegmento(value) {
                let NomeSeg = this.LigacaoProduto.filter(x => x.produto.produtoId == parseInt(value.produtoId,10))

                if (NomeSeg != '') {
                    return NomeSeg[0].segmentoProdNivel2.segmentoProdNivel2Id;
                }
                else {
                    return 1;
                }
                

            },
        },
             
        created() {
           var doc = document.implementation.createDocument('', '', null);

            //create the outer tag
            var urlset = doc.createElement("urlset");
            urlset.setAttribute("xmlns"," http://www.sitemaps.org/schemas/sitemap/0.9");

            var url = ''; var changefreq = ''; var loc = '';
                                    
            //first create static sites (note, that this is a selection)
            var staticSites = ['terms', 'privacy', '', 'about'];
            for (var i=0; i < staticSites.length; i++) {
                url = doc.createElement("url");
                loc = doc.createElement('loc');
                loc.innerHTML = 'https://www.epiloge.com/' + staticSites[i];
                changefreq = doc.createElement("changefreq");
                changefreq.innerHTML = 'monthly';
                url.appendChild(loc);
                url.appendChild(changefreq);
                urlset.appendChild(url);
            }            
   
            //Let's add the dynamic data here
                    
            doc.appendChild(urlset);

            this.Produtos = urlset

        /*     this.$http
                .get(this.$apiUrl + "/Ligacaosegmentoproduto")
                .then((res2) => res2.json())
                .then((tiu3) => {
                    this.LigacaoProduto = tiu3; */

/*                     this.$http
                        .get(this.$apiUrl + "/produto")
                        .then((res2) => res2.json())
                        .then((tiu2) => {
                            this.Produto = tiu2;
                            let xml = "<rss xmlns:g=\"http://base.google.com/ns/1.0\" version=\"2.0\">\n<channel>\n<title>Love Cestas</title>\n<link>lovecestas.com.br</link>\n<description>A Love Cestas é uma loja de cestas, flores e presentes on-line. Fundada em 2013, visa atender a necessidade de presentear com agilidade, bom gosto e qualidade nossos clientes.</description>";

                            this.Produto.forEach(element => {
                                if (element.imagens != '') {
                                xml += "\n\n<item>";
                                xml += "\n<title><![CDATA[ " + element.nomeProduto + " ]]></title>";
                                xml += "\n<link><![CDATA[ https://lovecestas.com.br/produtoDetalhe/" + element.produtoId + "]]></link>";
                                xml += "\n<description><![CDATA[ " + element.resuno + " ]]></description>";
                                xml += "\n<g:image_link>" + this.$Url + element.imagens[0].urlImg + "</g:image_link>";
                                xml += "\n<g:price>" + element.valorAte + " BRL</g:price>";
                                xml += "\n<g:condition>new</g:condition>";
                                xml += "\n<g:availability><![CDATA[ in stock ]]></g:availability>";
                                xml += "\n<g:id><![CDATA[ " + element.produtoId + " ]]></g:id>";
                                xml += "\n<g:item_group_id><![CDATA[ " + this.CarregaSegmento(element) + " ]]></g:item_group_id>";
                                xml += "\n<g:installment><g:months>12</g:months><g:amount>" + element.valorAte + " BRL</g:amount></g:installment>";
                                xml += "\n<g:brand><![CDATA[ Love Cestas ]]></g:brand>";
                                xml += "\n<g:product_type><![CDATA[ Produtos ]]></g:product_type>";
                                xml += "\n<g:identifier_exists>FALSE</g:identifier_exists>";
                                xml += "\n<g:online_only>y</g:online_only>";
                                    xml += "\n</item>";
                                }
                            }); */

                            //let parser = new DOMParser();
                            //this.Produtos = parser.parseFromString(xml, "text/xml");
                            //this.Produtos = xml;
                    /*     });
                }); */
        },
        mounted() {

        },
    }

</script>

<style scoped>
</style>