<template>

    <section class="carrinho">
        <CheckAcesso />
        <Header />

        <Fita class="hidden-sm-md hidden-xs" />

        <section :style="{ backgroundImage: 'url(' + $Url + fundotela + ')',backgroundColor:'#e8eaf6',backgroundSize:'100%',backgroundRepeat:'no-repeat' }">

            <div class="container">
                <div class="row py-5">
                    <div class="col-lg-6 hidden-sm-md">
                        <div class="carrinho-bg set-img">
                           
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="box_content">
                            <h3 class="titulo-principal text-center mb-4">Informação</h3>

                            <!-- Status -->
                            <div class="status-novaConta px-md-5">
                                <div class="status-item pos-item2">
                                    <div class="status-number"><i class="fas fa-check"></i></div>
                                    <span class="status-text">Login</span>
                                </div>
                                <div class="status-item active pos-item2">
                                    <div class="status-number"><span>2</span></div>
                                    <span class="status-text">Informação</span>
                                </div>
                                <div class="status-item">
                                    <div class="status-number"><span>3</span></div>
                                    <span class="status-text">Pagamento</span>
                                </div>
                                <!-- <div class="status-item">
                                  <div class="status-number"><span>4</span></div>
                                  <span class="status-text">Confirmação</span>
                                </div> -->
                            </div>
                            <hr>
                            <!-- Form -->
                            <div class="text-center">
                                <h5 class="subtitulo mb-0">{{ Titulos[0].nome }}</h5>
                                <p class="texto-mensagem">{{ Titulos[0].referencia }}</p>
                            </div>

                            <form class=" px-md-5 my-5">
                                <div class="form-group row">
                                    <label for="inputEmail" class="col-sm-5 col-form-label login-label">De:</label>
                                    <div class="col-sm-7">
                                        <input v-model="NomeDe" type="text" class="form-control" id="inputEmail">
                                        <div class="input-feedback">
                                            <span>Nome da pessoa que está enviando</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row mt-2">
                                    <label for="inputName" class="col-sm-5 col-form-label login-label">Para:</label>
                                    <div class="col-sm-7">
                                        <input v-model="NomePara" type="text" class="form-control" id="inputName">
                                        <div class="input-feedback">
                                            <span>Nome da pessoa que vai receber</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row mt-2">
                                    <label for="inputwhats" class="col-sm-5 col-form-label login-label">Telefone:</label>
                                    <div class="col-sm-7">
                                        <input v-model="TelefoneRecebe" type="text" class="form-control" id="inputwhats">
                                        <div class="input-feedback">
                                            <span>Telefone da pessoa que vai receber</span>
                                        </div>
                                    </div>
                                </div>

                                <div class="input-alert text-center">
                                    <span class="text-danger">{{ Titulos[0].campoExtra1 }}</span>
                                </div>

                            </form>
                            <hr>

                            <div class="mb-4">
                                <h5 class="subtitulo text-center mb-4">{{ Titulos[0].campoExtra2 }}</h5>
                                <p class="texto mb-0">{{ EnderecoEntrega.endereco }}, {{ EnderecoEntrega.numero }} - {{ EnderecoEntrega.complemento }}</p>
                                <p class="texto mb-0">{{ EnderecoEntrega.bairro.nomeBairro }} - {{ EnderecoEntrega.cidade.nomeCidade }} - {{ EnderecoEntrega.estado.siglaEstado }}</p>
                                <p class="texto">{{ EnderecoEntrega.cep }}</p>
                            </div>

                            <div class="mb-4">
                                <h5 class="subtitulo text-center mb-4">{{ Titulos[0].campoExtra3 }}</h5>
                                <div class="agendamento">
                                    <div class="row">
                                        <div class="col-sm-5"><p class="texto mb-0">Data</p></div> 
                                        <div class="col-sm-7"><p class="texto mb-0">{{ currentDateTime(EnderecoEntrega.data) }}</p></div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-5"><p class="texto">Horário</p></div>
                                        <div class="col-sm-7"><p class="texto">{{ EnderecoEntrega.periodoEntrega.texto }}</p></div>
                                    </div>
                                </div>
                                <img class="mr-10" src="img/cart/icon10.png" alt="">
                                <router-link :to="/produtodetalhe/ + ProdutoId + '/novacontaendereco/1'" class="cart-links">
                                    Editar endereço ou agendamento
                                </router-link>
                            </div>
                            <hr>

                            <div class="box_content-footer">
                                <div class="voltar"><router-link to="/carrinho" class="voltar text-secondary">Voltar</router-link></div>
                                <div class="mt-4 mt-md-0">
                                    <button @click="validaAgenda()" class="btn btn-success px-md-5 mt-0">Avançar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <FitaRodape class="" titulo=""></FitaRodape>
        <Footer />

    </section>

</template>

<script>

    import CheckAcesso from '@/components/site/shared/CheckAcesso.vue'
    import Header from '@/components/site/shared/Header.vue'
    import Fita from '@/components/site/shared/Fita.vue'
    import Footer from '@/components/site/shared/Footer.vue'
    import FitaRodape from '@/components/site/shared/FitaRodape.vue'
    import moment from 'moment';

    export default {
        components: {
            Header,
            Fita,
            FitaRodape,
            Footer,
            CheckAcesso,
        },
        data() {
            return {
                ProdutoId:0,
                NomeDe: '',
                NomePara: '',
                TelefoneRecebe: '',
                EnderecoEntrega: [],
                ListaExcecao: [],
                ListaEntregaFrete: [],
                ListaHorariosEntregaFrete: [],
                Titulos: [],
                fundotela:'',
            }
        },
        created() {

            //----------------- Titulos planos
            this.$http
                .get(this.$apiUrl + "/institucional/telas/textoinformacao")
                .then((res) => res.json())
                .then((retornoAPI) => {
                    this.Titulos = retornoAPI;

                    if (this.Titulos[0].institucionalImg != '') {
                        this.fundotela = this.Titulos[0].institucionalImg[0].urlImagem
                    }
                    else {
                        this.fundotela = '';
                    }
                });

            let dadosArmazenados = localStorage.getItem('dadoscompralove');
            if (!dadosArmazenados) {
                dadosArmazenados = this.DadosCompra;
                localStorage.setItem('dadoscompralove', JSON.stringify(dadosArmazenados))
                this.$redirect_reload("/meuspedidos");
            } else {
                dadosArmazenados = JSON.parse(dadosArmazenados);
                if (dadosArmazenados.pagamento != '') {
                    if (dadosArmazenados.pagamento[0] != '') {
                        this.$redirect_reload("/pedidoRealizado");
                    }
                    else {
                        this.$redirect_reload("/meuspedidos");
                    }
                }
                else {
                    if (dadosArmazenados.produtos != '') {
                        this.ProdutoId = dadosArmazenados.produtos[0].produtoId;

                        this.EnderecoEntrega = dadosArmazenados.agenda[0];

                        if (dadosArmazenados.informacao != '') {
                            this.NomeDe = dadosArmazenados.informacao[0].nomeDe;
                            this.NomePara = dadosArmazenados.informacao[0].nomePara;
                            this.TelefoneRecebe = dadosArmazenados.informacao[0].telefoneRecebe;
                        }
                    }
                    else {
                        this.$redirect_reload("/meuspedidos");
                    }
                }

            }

        },
        methods: {
            currentDateTime(vdate) {
                //moment.locale("pt-br");
                //moment().endOf('second');
                //dateFormat(this.Cotacao.dataDia, "yyyy-mm-dd");
                //currentDateTime(Cotacao.dataDia)
                //return moment(vdate).format('DD/MM/YYYY')
                return moment(vdate).format('LL');
            },
            carregaDados() {
                if (this.NomeDe == '') {
                    this.$mensagem_normal("Por favor preencha o nome da pessoa que está enviando");
                } else if (this.NomePara == '') {
                    this.$mensagem_normal("Por favor preencha o nome da pessoa que vai receber");
                //} else if (this.TelefoneRecebe == '') {
                //    this.$mensagem_normal("Por favor preencha o telefone da pessoa que vai receber");
                } else {

                    let dadosArmazenados = localStorage.getItem('dadoscompralove');
                    if (dadosArmazenados) {
                        dadosArmazenados = JSON.parse(dadosArmazenados);
                        dadosArmazenados.informacao = [];
                        dadosArmazenados.informacao.push({ informacaoId: 0, nomeDe: this.NomeDe, nomePara: this.NomePara, telefoneRecebe: this.TelefoneRecebe });
                        localStorage.setItem('dadoscompralove', JSON.stringify(dadosArmazenados))
                        this.$redirect_reload("/novaContaPagamento");
                    }
                  
                }
            },
            validaAgenda() {
                let dadosArmazenados = localStorage.getItem('dadoscompralove');
                dadosArmazenados = JSON.parse(dadosArmazenados);
                if (dadosArmazenados.agenda != '') {

                    let dadosAgenda = {
                        dataEntrega: dadosArmazenados.agenda[0].periodoEntrega.dataEntrega,
                        EstadoId: dadosArmazenados.agenda[0].estado.estadoId,
                        CidadeId: dadosArmazenados.agenda[0].cidade.cidadeId,
                        BairroId: dadosArmazenados.agenda[0].bairro.bairroId,
                        TipoEntrega: dadosArmazenados.agenda[0].periodoEntrega.TipoFrete,
                    }

                    //verifica se tem exceção para data e horario
                    this.$http
                        .get(this.$apiUrl + "/entregaexcecao")
                        .then((res2) => res2.json())
                        .then((tiu2) => {
                            this.ListaExcecao = tiu2.filter(x => moment(x.dataExcecao).format('LL') == moment(dadosAgenda.dataEntrega).format('LL') && x.status)
                            let vcontrole = 0;
                            let filtraExcecao = []
                            let filtraEntregaFrete = []
                            if (this.ListaExcecao != '') {
                                //testar se tem bairro
                                this.ListaExcecao.forEach((itemex) => {
                                    try {
                                        if (itemex.estado.estadoId == dadosAgenda.EstadoId && itemex.cidade.cidadeId == dadosAgenda.CidadeId && itemex.bairro.bairroId == dadosAgenda.BairroId) {
                                            filtraExcecao.push(itemex)
                                            vcontrole = 1;
                                        }
                                    }
                                    catch {
                                        console.log(vcontrole)
                                    }
                                });

                                if (vcontrole == 0) {
                                    this.ListaExcecao.forEach((itemex) => {
                                        try {
                                            if (itemex.estado.estadoId == dadosAgenda.EstadoId && itemex.cidade.cidadeId == dadosAgenda.CidadeId && itemex.bairro == null) {
                                                filtraExcecao.push(itemex)
                                                vcontrole = 1;
                                            }
                                        }
                                        catch {
                                            console.log(vcontrole)
                                        }
                                    });
                                }

                                if (vcontrole == 0) {
                                    this.ListaExcecao.forEach((itemex) => {
                                        try {
                                            if (itemex.estado.estadoId == dadosAgenda.EstadoId && itemex.cidade == null && itemex.bairro == null) {
                                                filtraExcecao.push(itemex)
                                                vcontrole = 1;
                                            }
                                        }
                                        catch {
                                            console.log(vcontrole)
                                        }
                                    });
                                }

                                if (vcontrole == 0) {
                                    this.ListaExcecao.forEach((itemex) => {
                                        try {
                                            if (itemex.estado == null && itemex.cidade == null && itemex.bairro == null) {
                                                filtraExcecao.push(itemex)
                                                vcontrole = 1;
                                            }
                                        }
                                        catch {
                                            console.log(vcontrole)
                                        }
                                    });
                                }

                            }



                            //não tem exceção
                            this.$http
                                .get(this.$apiUrl + "/entregafrete")
                                .then((res2) => res2.json())
                                .then((tiu2) => {
                                    this.ListaEntregaFrete = tiu2.filter(x => x.status)

                                    if (this.ListaEntregaFrete != '') {

                                        let vcontrole2 = 0;
                                        //testar se tem bairro

                                        this.ListaEntregaFrete.forEach((itemex) => {
                                            try {
                                                if (itemex.estado.estadoId == dadosAgenda.EstadoId && itemex.cidade.cidadeId == dadosAgenda.CidadeId && itemex.bairro.bairroId == dadosAgenda.BairroId) {
                                                    filtraEntregaFrete.push(itemex)
                                                    vcontrole2 = 1;
                                                }
                                            }
                                            catch {
                                                console.log(vcontrole2)
                                            }
                                        });

                                        if (vcontrole2 == 0) {
                                            this.ListaEntregaFrete.forEach((itemex) => {
                                                try {
                                                    if (itemex.estado.estadoId == dadosAgenda.EstadoId && itemex.cidade.cidadeId == dadosAgenda.CidadeId && itemex.bairro == null) {
                                                        filtraEntregaFrete.push(itemex)
                                                        vcontrole2 = 1;
                                                    }
                                                }
                                                catch {
                                                    console.log(vcontrole2)
                                                }
                                            });
                                        }

                                        if (vcontrole2 == 0) {
                                            this.ListaEntregaFrete.forEach((itemex) => {
                                                try {
                                                    if (itemex.estado.estadoId == dadosAgenda.EstadoId && itemex.cidade == null && itemex.bairro == null) {
                                                        filtraEntregaFrete.push(itemex)
                                                        vcontrole2 = 1;
                                                    }
                                                }
                                                catch {
                                                    console.log(vcontrole2)
                                                }
                                            });
                                        }

                                        if (vcontrole2 == 0) {
                                            this.ListaEntregaFrete.forEach((itemex) => {
                                                try {
                                                    if (itemex.estado == null && itemex.cidade == null && itemex.bairro == null) {
                                                        filtraEntregaFrete.push(itemex)
                                                        vcontrole2 = 1;
                                                    }
                                                }
                                                catch {
                                                    console.log(vcontrole2)
                                                }
                                            });
                                        }
                                    }

                                    let vtempoproducao = 0;
                                    if (dadosArmazenados.produtos != '') {
                                        dadosArmazenados.produtos.forEach((itemp) => {
                                            if (itemp.produto.tempoProducao > 0) {
                                                if (vtempoproducao < itemp.produto.tempoProducao) {
                                                    vtempoproducao = itemp.produto.tempoProducao;
                                                }
                                            }
                                        });
                                    }
                                    if (dadosArmazenados.avulsos != '') {
                                        dadosArmazenados.avulsos.forEach((itemp2) => {
                                            if (itemp2.avulso.tempoProducao > 0) {
                                                if (vtempoproducao < itemp2.avulso.tempoProducao) {
                                                    vtempoproducao = itemp2.avulso.tempoProducao;
                                                }
                                            }
                                        });
                                    }

                                    if (dadosArmazenados.baloes != '') {
                                        dadosArmazenados.baloes.forEach((itemp) => {
                                            if (itemp.avulso.tempoProducao > 0) {
                                                if (vtempoproducao < itemp.avulso.tempoProducao) {
                                                    vtempoproducao = itemp.avulso.tempoProducao;
                                                }
                                            }
                                        });
                                    }
                                    //console.log(vtempoproducao)

                                    if (dadosAgenda.TipoEntrega == "E" || filtraExcecao != '') {
                                        let vvalorFrete = filtraEntregaFrete[0].valorFrete;

                                        let vlistaHorarios = []
                                        //mostra grid inteira a partir do tempo de produção do produto
                                        filtraExcecao.forEach((item) => {
                                            if (moment().add(parseInt(item.atrasoHs, 10) + vtempoproducao, 'hour').format('YYYY-MM-DDTHH:mm') <= moment().format(dadosAgenda.dataEntrega + 'T' + this.validaHora(item.horaIni) + ':' + this.validaHora(item.minIni))) {
                                                vlistaHorarios.push({ codigo: item.entregaExcecaoId, valorFrete: vvalorFrete, TipoFrete: dadosAgenda.TipoEntrega, dataEntrega: dadosAgenda.dataEntrega, texto: this.validaHora(item.horaIni) + ':' + this.validaHora(item.minIni) + ' às ' + this.validaHora(item.horaFim) + ':' + this.validaHora(item.minFim) + ' - valor R$ ' + parseFloat(vvalorFrete).toFixed(2).toString().replace('.', ',') });
                                            }
                                        });

                                        if (vlistaHorarios.filter(x => x.codigo == dadosArmazenados.agenda[0].periodoEntrega.codigo).length > 0) {
                                            this.carregaDados();
                                        }
                                        else {
                                            this.$mensagem_normal("Infelizmente esta esgotada as entregas nesse horário, favor escolher nova agenda.");
                                            this.$redirect("/produtodetalhe/" + this.ProdutoId + '/novaContaEndereco/1');
                                        }
                                    }
                                    else {
                                        let vlistaHorarios2 = []
                                        this.$http
                                            .get(this.$apiUrl + "/entregahorario")
                                            .then((res2) => res2.json())
                                            .then((tiu2) => {
                                                this.ListaHorariosEntregaFrete = tiu2.filter(x => x.status)

                                                //mostra grid inteira a partir do tempo de produção do produto
                                                filtraEntregaFrete.forEach((item) => {
                                                    this.ListaHorariosEntregaFrete.forEach((itemhora) => {
                                                        if (moment().add(parseInt(item.atrasoHs, 10) + vtempoproducao, 'hour').format() <= moment().format(dadosAgenda.dataEntrega + 'T' + this.validaHora(itemhora.horaIni) + ':' + this.validaHora(itemhora.minIni))) {
                                                            vlistaHorarios2.push({ codigo: item.entregaFreteId, codigoHorario: itemhora.entregaHorarioId, valorFrete: item.valorFrete, TipoFrete: dadosAgenda.TipoEntrega, dataEntrega: dadosAgenda.dataEntrega, texto: this.validaHora(itemhora.horaIni) + ':' + this.validaHora(itemhora.minIni) + ' às ' + this.validaHora(itemhora.horaFim) + ':' + this.validaHora(itemhora.minFim) + ' - valor R$ ' + parseFloat(item.valorFrete).toFixed(2).toString().replace('.', ',') /* + ' Tipo: ' + item.tipoEntrega*/ });
                                                        }
                                                    });
                                                });

                                                if (vlistaHorarios2.filter(x => x.codigoHorario == dadosArmazenados.agenda[0].periodoEntrega.codigoHorario).length > 0) {
                                                    this.carregaDados();
                                                }
                                                else {
                                                    this.$mensagem_normal("Infelizmente esta esgotada as entregas nesse horário, favor escolher nova agenda.");
                                                    this.$redirect("/produtodetalhe/" + this.ProdutoId + '/novaContaEndereco/1');
                                                }
                                            });
                                    }

                                    //comparar se o que tem no armazena tem nos arrays se tiver ok, senão mensagem e volta para calendario
                                });
                        });
                }
            },
            validaHora(value) {
                var _hora = value.toString();
                if (_hora.length == 1)
                    _hora = "0" + _hora;
                return _hora;
            },
        }
    }

</script>

<style scoped>

    @import '/css_cart/carrinho.css';
    @import '/css_cart/nova_conta.css';
</style>