<template>
    <div class="container mt-5">

        <div class="row">
            <div class="col-lg-12 mb-lg-0" style="text-align:center;">
                <h3>Distribuicao de avulsos por produtos</h3>
            </div>
            <div class="col-lg-9 mb-1 mb-lg-0" style="text-align:center;">
                <v-select v-model="Listaselected" @input="MudaLista"
                          :options="Lista"
                          :value="Lista.itemAvulsoId"
                          single-line
                          label="nomeItemAvulso" class="style-chooser w-100"></v-select>
            </div>
            <div class="col-lg-3 mb-1 mb-lg-0" style="text-align:center;">
                <a class="btn btn-card mt-0 w-100"
                   @click="CarregaDados()">Listar</a>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-10">
                <div class="drag-container" v-drag-and-drop:options="options">
                    <ul class="drag-list">
                        <li class="drag-column" v-for="group in groups" :key="group.id">
                            <span class="drag-column-header">
                                <h5>{{ group.name }}</h5>
                                <feather-icon type="more-vertical"></feather-icon>
                            </span>
                            <vue-draggable-group v-model="group.items"
                                                 :groups="groups"
                                                 :data-id="group.id"
                                                 @change="onGroupsChange">
                                <ul class="drag-inner-list" :data-id="group.id">
                                    <li class="drag-item" v-for="item in group.items" :key="item.id" :data-id="item.id">
                                        <img v-if="item.produto.imagens!=null && item.produto.imagens.length>0 " :src="$Url + item.produto.imagens[0].urlImg" style="width:50px;height:auto;" class="hidden-xs" />
                                        <div class="drag-item-text" style="display: inline-flex;">{{ item.produto.nomeProduto }}</div>
                                    </li>
                                </ul>
                            </vue-draggable-group>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-2">
                <a class="btn btn-card w-100" style="margin-top:70px;"
                   @click="SalvaDados()">Salvar Resultado</a>
            </div>
        </div>
    </div>
</template>

<script>
    import vSelect from 'vue-select';
    export default {
        components: {
            vSelect,
        },
        data() {
            return {
                ListaId: 0,
                Lista: [],
                Listaselected: null,
                ProdutosAvulso: [],
                Produtos: [],
                resultado: {},
                groups: [],
                options: {
                    dropzoneSelector: ".drag-inner-list",
                    draggableSelector: ".drag-item"
                }
            };
        },
        methods: {
            MudaLista(value) {
                this.ListaId = value.itemAvulsoId;
            },
            onGroupsChange(e) {
                console.log({ e });
                this.resultado = e;
            },
            SalvaDados() {
                if (this.resultado.length > 0) {
                    let salvafinalizado = true;
                    //console.log(this.ProdutosAvulso)
                    //exclui os retirados
                    this.ProdutosAvulso.forEach((itemex) => {
                        let recebe = this.resultado[1].items.filter(x => x.produto.produtoId == itemex.produto.produtoId)
                        if (recebe == '') {
                            this.$http.delete(this.$apiUrl + "/ProdutoAvulso/" + parseInt(itemex.produtoAvulsoId, 10)).then(
                                (response) => {
                                    console.log(response);
                                },
                                (error) => {
                                    this.$mensagem_erro(error.body);
                                    salvafinalizado = false;
                                }
                            );
                        }
                    });

                    //alterar ou incluir ajustando ordem
                    let vordem = 0;
                    this.resultado[1].items.forEach((itemex2) => {
                        vordem++;
                        let recebe2 = this.ProdutosAvulso.filter(x => x.produto.produtoId == itemex2.produto.produtoId)
                        //console.log("recebe2")
                        //console.log(recebe2)
                        if (recebe2 == '') {
                            //insere
                            let _produtoAvulsoAdd = {
                                ProdutoAvulsoId: 0,
                                Produto: { ProdutoId: parseInt(itemex2.produto.produtoId, 10) },
                                ItemAvulso: { ItemAvulsoId: parseInt(this.Listaselected.itemAvulsoId, 10) },
                                Ordem: vordem,
                            }
                            this.$http
                                .post(this.$apiUrl + "/ProdutoAvulso", _produtoAvulsoAdd)
                                .then(
                                    response4 => {
                                        // get body data
                                        _produtoAvulsoAdd = response4.body;
                                        console.log("gravei item pedido adicional produto")
                                    },
                                    async response4 => {
                                        let recebe2 = response4.body;
                                        console.log(recebe2);
                                        salvafinalizado = false;
                                    }
                                );

                        } else {
                            //altera
                            //console.log(recebe2[0])
                            let _produtoAvulsoAlt = {
                                ProdutoAvulsoId: parseInt(recebe2[0].produtoAvulsoId, 10),
                                Produto: { ProdutoId: parseInt(itemex2.produto.produtoId, 10) },
                                ItemAvulso: { ItemAvulsoId: parseInt(this.Listaselected.itemAvulsoId, 10) },
                                Ordem: parseInt(recebe2[0].ordem, 10),
                            }
                            //console.log(_produtoAvulsoAlt)
                            this.$http
                                .put(this.$apiUrl + "/ProdutoAvulso/" + parseInt(recebe2[0].produtoAvulsoId, 10), _produtoAvulsoAlt)
                                .then(
                                    (response) => {
                                        if (response.ok) {
                                            console.log("Item alterado com sucesso");
                                        }
                                    },
                                    (error) => {
                                        console.log(error.bodyText);
                                        salvafinalizado = false;
                                    }
                                );
                        }
                    });

                    if (salvafinalizado) {
                        this.$mensagem_normal("Resultado atualizado.");
                        this.CarregaDados();
                    }
                    else {
                        this.$mensagem_normal("Houve um erro ao carregar.");
                    }
                }
                else {
                    this.$mensagem_normal("Sem itens para atualizar.");
                }

            },
            CarregaDados() {
                if (this.Listaselected != null) {
                    this.groups = []
                    let vcount = 0;

                    //carrega lista de todos produtos
                    this.$http
                        .get(this.$apiUrl + "/produto")
                        .then((res2) => res2.json())
                        .then((tiu2) => {
                            this.Produtos = tiu2.filter(x => x.visivel).sort(function (a, b) {
                                return a.nomeProduto - b.nomeProduto;
                            });

                            //carrega produtos do avulso selecionado
                            this.$http
                                .get(this.$apiUrl + "/ProdutoAvulso")
                                .then((res2) => res2.json())
                                .then((tiu2) => {
                                    this.ProdutosAvulso = tiu2.filter(x => x.produto.visivel && x.itemAvulso.itemAvulsoId == parseInt(this.ListaId, 10)).sort(function (a, b) {
                                        return a.produto.nomeProduto - b.produto.nomeProduto;
                                    });

                                    this.groups.push({ id: 1, name: "Produtos Geral", items: [] })

                                    this.Produtos.forEach((itemex) => {
                                        vcount++
                                        let recebe = this.ProdutosAvulso.filter(x => x.produto.produtoId == itemex.produtoId);
                                        if (recebe == '') {
                                            this.groups[0].items.push({ id: vcount, produto: itemex, groupId: 1 })
                                        }

                                    });

                                    this.groups.push({ id: 3, name: "Selecionados Avulso", items: [] })
                                    //console.log(this.ProdutosAvulso)
                                    this.ProdutosAvulso.forEach((itemex) => {
                                        vcount++
                                        this.groups[1].items.push({ id: vcount, produto: itemex.produto, groupId: 3 })
                                    });
                                });
                            console.log(this.groups)
                        });
                } else {
                    this.$mensagem_normal("Voc� deve selecionar Item Avulso.");
                }
            },
        },
        created() {
            this.$http
                .get(this.$apiUrl + "/itemAvulso")
                .then((res2) => res2.json())
                .then((tiu2) => {
                    this.Lista = tiu2.filter(x => x.visivel).sort(function (a, b) {
                        return a.nomeItemAvulso - b.nomeItemAvulso;
                    });
                });
        }
    };
</script>
<style scoped>

    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
    }

    .drag-container {
        max-width: 1000px;
        margin: 20px auto;
    }

    .drag-list {
        display: flex;
        align-items: flex-start;
    }

    .drag-column {
        flex: 1;
        margin: 0 10px;
        position: relative;
        background: rgba(black, 0.2);
        overflow: hidden;
    }

    .drag-column-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px;
        user-select: none;
    }

    .drag-inner-list {
        height: 85vh;
        overflow: auto;
    }

    .drag-item {
        margin: 5px;
        height: 60px;
        background: #f2f2f2;
        transition: ease-out;
    }

    .drag-item-text {
        font-size: 1rem;
        padding-left: 1rem;
        padding-top: 1rem;
    }


    .drag-header-more {
        cursor: pointer;
    }

    @keyframes nodeInserted {
        from {
            opacity: 0.2;
        }

        to {
            opacity: 0.8;
        }
    }

    .item-dropzone-area {
        height: 6rem;
        background: #888;
        opacity: 0.8;
        animation-duration: 0.5s;
        animation-name: nodeInserted;
        margin-left: 0.6rem;
        margin-right: 0.6rem;
    }

    @media (max-width:600px) {

        .drag-item {
            margin: 2px;
            height: 40px;
            background: #f2f2f2;
            transition: ease-out;
        }

        .drag-item-text {
            font-size: 12px;
            padding-left: 1rem;
            padding-top: 3px;
            line-height: 1.1;
        }

        .hidden-xs {
            display: none !important;
        }

        .item-dropzone-area {
            height: 3rem;
            background: #888;
            opacity: 0.8;
            animation-duration: 0.5s;
            animation-name: nodeInserted;
            margin-left: 0.6rem;
            margin-right: 0.6rem;
        }

        .drag-inner-list {
            height: 55vh;
            overflow: auto;
        }
    }
</style>